// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { TcsWrapperModelsMedicationModel } from './tcsWrapperModelsMedicationModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class TcsWrapperModelsClaimModel {
    'dateProcessed'?: string;
    'dateOfFill'?: string;
    'initialOutOfPocket'?: string;
    'prescriberName'?: string;
    'pharmacyName'?: string;
    'quantityDispensed'?: string;
    'daysSupply'?: string;
    'refillNumber'?: string;
    'refillsAuthorized'?: string;
    'benefitPaid'?: string;
    'medication'?: TcsWrapperModelsMedicationModel | null;
    'memberNumber'?: string;
    'ndcPromotionGroupId'?: string;
    'claimStatus'?: string;
    'rejectDescription'?: string;
    'mailInRebate'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof TcsWrapperModelsClaimModel>> = [
        {
            "name":     "dateProcessed",
            "baseName": "dateProcessed",
            "type":     "string"
        },
        {
            "name":     "dateOfFill",
            "baseName": "dateOfFill",
            "type":     "string"
        },
        {
            "name":     "initialOutOfPocket",
            "baseName": "initialOutOfPocket",
            "type":     "string"
        },
        {
            "name":     "prescriberName",
            "baseName": "prescriberName",
            "type":     "string"
        },
        {
            "name":     "pharmacyName",
            "baseName": "pharmacyName",
            "type":     "string"
        },
        {
            "name":     "quantityDispensed",
            "baseName": "quantityDispensed",
            "type":     "string"
        },
        {
            "name":     "daysSupply",
            "baseName": "daysSupply",
            "type":     "string"
        },
        {
            "name":     "refillNumber",
            "baseName": "refillNumber",
            "type":     "string"
        },
        {
            "name":     "refillsAuthorized",
            "baseName": "refillsAuthorized",
            "type":     "string"
        },
        {
            "name":     "benefitPaid",
            "baseName": "benefitPaid",
            "type":     "string"
        },
        {
            "name":     "medication",
            "baseName": "medication",
            "type":     "TcsWrapperModelsMedicationModel"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "ndcPromotionGroupId",
            "baseName": "ndcPromotionGroupId",
            "type":     "string"
        },
        {
            "name":     "claimStatus",
            "baseName": "claimStatus",
            "type":     "string"
        },
        {
            "name":     "rejectDescription",
            "baseName": "rejectDescription",
            "type":     "string"
        },
        {
            "name":     "mailInRebate",
            "baseName": "mailInRebate",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return TcsWrapperModelsClaimModel.attributeTypeMap;
    }
}
