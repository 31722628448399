// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class LocationAddressModel {
    'businessName'?: string;
    'line1'?: string;
    'line2'?: string;
    'city'?: string;
    'state'?: string;
    'postalCode'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof LocationAddressModel>> = [
        {
            "name":     "businessName",
            "baseName": "businessName",
            "type":     "string"
        },
        {
            "name":     "line1",
            "baseName": "line1",
            "type":     "string"
        },
        {
            "name":     "line2",
            "baseName": "line2",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "postalCode",
            "baseName": "postalCode",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return LocationAddressModel.attributeTypeMap;
    }
}
