// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { NoteTaskModel } from './noteTaskModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class NoteTaskModelResponse {
    'success': boolean;
    'messages': Array<string>;
    'data': NoteTaskModel | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof NoteTaskModelResponse>> = [
        {
            "name":     "success",
            "baseName": "success",
            "type":     "boolean"
        },
        {
            "name":     "messages",
            "baseName": "messages",
            "type":     "Array<string>"
        },
        {
            "name":     "data",
            "baseName": "data",
            "type":     "NoteTaskModel"
        },
    ];

    static getAttributeTypeMap() {
        return NoteTaskModelResponse.attributeTypeMap;
    }
}
