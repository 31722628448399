// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EntlibResponseany } from './entlibResponseany.js';
import type { MangoV1CardDetailsMedicalCard } from './mangoV1CardDetailsMedicalCard.js';
import type { MangoV1CardDetailsPharmacyCard } from './mangoV1CardDetailsPharmacyCard.js';
import type { MangoV1CardDetailsVirtualDebitCard } from './mangoV1CardDetailsVirtualDebitCard.js';
import type { Type } from './type.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MangoV1CardDetailsGetCardDetailsResponse {
    'data': any | null;
    'success': boolean;
    'messages': Array<string> | null;
    'pharmacyCards': Array<MangoV1CardDetailsPharmacyCard>;
    'medicalCards': Array<MangoV1CardDetailsMedicalCard>;
    'virtualDebitCards': Array<MangoV1CardDetailsVirtualDebitCard>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MangoV1CardDetailsGetCardDetailsResponse>> = [
        {
            "name":     "data",
            "baseName": "data",
            "type":     "any"
        },
        {
            "name":     "success",
            "baseName": "success",
            "type":     "boolean"
        },
        {
            "name":     "messages",
            "baseName": "messages",
            "type":     "Array<string>"
        },
        {
            "name":     "pharmacyCards",
            "baseName": "pharmacyCards",
            "type":     "Array<MangoV1CardDetailsPharmacyCard>"
        },
        {
            "name":     "medicalCards",
            "baseName": "medicalCards",
            "type":     "Array<MangoV1CardDetailsMedicalCard>"
        },
        {
            "name":     "virtualDebitCards",
            "baseName": "virtualDebitCards",
            "type":     "Array<MangoV1CardDetailsVirtualDebitCard>"
        },
    ];

    static getAttributeTypeMap() {
        return MangoV1CardDetailsGetCardDetailsResponse.attributeTypeMap;
    }
}
