// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyBiMedicarePartD {
    'catastrophicCoInsuranceValue'?: string | null;
    'catastrophicCopay'?: string | null;
    'coverageGapCopay'?: string | null;
    'currentStage'?: string | null;
    'gapCoInsuranceValue'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyBiMedicarePartD>> = [
        {
            "name":     "catastrophicCoInsuranceValue",
            "baseName": "catastrophicCoInsuranceValue",
            "type":     "string"
        },
        {
            "name":     "catastrophicCopay",
            "baseName": "catastrophicCopay",
            "type":     "string"
        },
        {
            "name":     "coverageGapCopay",
            "baseName": "coverageGapCopay",
            "type":     "string"
        },
        {
            "name":     "currentStage",
            "baseName": "currentStage",
            "type":     "string"
        },
        {
            "name":     "gapCoInsuranceValue",
            "baseName": "gapCoInsuranceValue",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyBiMedicarePartD.attributeTypeMap;
    }
}
