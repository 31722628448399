// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { SimpleIntakeSurveyAnswerModel } from './simpleIntakeSurveyAnswerModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SimpleIntakeSurveyModel {
    'surveyId': number;
    'sourceId'?: number;
    'systemId'?: number;
    'answers': Array<SimpleIntakeSurveyAnswerModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SimpleIntakeSurveyModel>> = [
        {
            "name":     "surveyId",
            "baseName": "surveyId",
            "type":     "number"
        },
        {
            "name":     "sourceId",
            "baseName": "sourceId",
            "type":     "number"
        },
        {
            "name":     "systemId",
            "baseName": "systemId",
            "type":     "number"
        },
        {
            "name":     "answers",
            "baseName": "answers",
            "type":     "Array<SimpleIntakeSurveyAnswerModel>"
        },
    ];

    static getAttributeTypeMap() {
        return SimpleIntakeSurveyModel.attributeTypeMap;
    }
}
