// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PartyExternalIdType } from './partyExternalIdType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PrescriberSearchRequestModel {
    'maxRecordCount'?: number;
    'firstName'?: string;
    'lastName'?: string;
    'dea'?: string;
    'npi'?: string;
    'stateLicenseId'?: string;
    'prescriberTaxId'?: string;
    'addressOne'?: string;
    'city'?: string;
    'state'?: string;
    'zip'?: string;
    'externalIdType'?: PartyExternalIdType;
    'externalId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PrescriberSearchRequestModel>> = [
        {
            "name":     "maxRecordCount",
            "baseName": "maxRecordCount",
            "type":     "number"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "dea",
            "baseName": "dea",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "stateLicenseId",
            "baseName": "stateLicenseId",
            "type":     "string"
        },
        {
            "name":     "prescriberTaxId",
            "baseName": "prescriberTaxId",
            "type":     "string"
        },
        {
            "name":     "addressOne",
            "baseName": "addressOne",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "zip",
            "baseName": "zip",
            "type":     "string"
        },
        {
            "name":     "externalIdType",
            "baseName": "externalIdType",
            "type":     "PartyExternalIdType"
        },
        {
            "name":     "externalId",
            "baseName": "externalId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PrescriberSearchRequestModel.attributeTypeMap;
    }
}
