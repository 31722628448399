// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Payment reject details
*/
export class DigitalpaymentRejectDetails {
    'rejectAmount'?: number | null;
    'rejectDate'?: Moment | null;
    'rejectReasonCode'?: string | null;
    'rejectReasonDescription'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof DigitalpaymentRejectDetails>> = [
        {
            "name":     "rejectAmount",
            "baseName": "rejectAmount",
            "type":     "number"
        },
        {
            "name":     "rejectDate",
            "baseName": "rejectDate",
            "type":     "Date"
        },
        {
            "name":     "rejectReasonCode",
            "baseName": "rejectReasonCode",
            "type":     "string"
        },
        {
            "name":     "rejectReasonDescription",
            "baseName": "rejectReasonDescription",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return DigitalpaymentRejectDetails.attributeTypeMap;
    }
}
