// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ClaimAdjudicationCodeModel } from './claimAdjudicationCodeModel.js';
import type { ClaimConditionModel } from './claimConditionModel.js';
import type { ClaimExternalReferenceModel } from './claimExternalReferenceModel.js';
import type { ClaimPayorModel } from './claimPayorModel.js';
import type { ClaimProviderModel } from './claimProviderModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class GatewayMedicalClaimModel {
    'rejectTypeIds': Array<number>;
    'exceptionTypeIds': Array<number>;
    'overrideTypeIds': Array<number>;
    'eobAdjudicationCodeIds': Array<number>;
    'providerIds': Array<number>;
    'adjudicationCodes': Array<ClaimAdjudicationCodeModel>;
    /**
    * @description Member Number
    */
    'memberNumber'?: string | null;
    /**
    * @description External Reference Type IDs. (e.g PDMI Member Number, Alternate Card Number, Lash Claim ID, etc.)
    */
    'externalReferences'?: Array<ClaimExternalReferenceModel> | null;
    /**
    * @description Card Promotion ID
    */
    'promotionId'?: number | null;
    /**
    * @description Processor ID. (e.g. IMS, PDMI, TrialCard, etc.)   Must be a valid lookup ID in the the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.Processors.
    */
    'processorId'?: number;
    /**
    * @description Claim Status ID. (e.g. Rejected, Paid, Reversed, Adjusted, Unprocessed, Under Review, Approved etc)  Must be a valid lookup ID in the the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.ClaimStatuses.
    */
    'claimStatusId'?: number;
    /**
    * @description Requested Payment Method Type ID (e.g. DbCard, Chk, VirMasterCard, ThrdPartyAdjud)  Must be a valid lookup ID in the the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.PaymentMethodTypes.
    */
    'requestedPaymentMethodTypeId'?: number | null;
    /**
    * @description Benefit Paid
    */
    'benefitPaid'?: number | null;
    /**
    * @description Remaining Cost
    */
    'remainingCost'?: number | null;
    /**
    * @description Parent Claim ID. If this is an adjustment or reversal, indicates the claim which is being adjusted or reversed.   A null value indicates a regular claim. This value cannot be updated.
    */
    'parentClaimId'?: number | null;
    /**
    * @description Claim Type ID. Medical or Pharmacy (Required)   Must be a valid lookup ID in the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.ClaimTypes.
    */
    'claimTypeId'?: number | null;
    /**
    * @description Patient Last Name
    */
    'patientLastName'?: string | null;
    /**
    * @description Patient First Name
    */
    'patientFirstName'?: string | null;
    /**
    * @description Patient Date of Birth
    */
    'patientDateOfBirth'?: Moment | null;
    /**
    * @description Patient Sex
    */
    'patientSex'?: string | null;
    /**
    * @description Patient Address
    */
    'patientAddress'?: string | null;
    /**
    * @description Patient City
    */
    'patientCity'?: string | null;
    /**
    * @description Patient State
    */
    'patientState'?: string | null;
    /**
    * @description Patient ZIP Code. Has to be string of 5 digits.
    */
    'patientZip'?: string | null;
    /**
    * @description Patient Phone Number
    */
    'patientPhoneNumber'?: string | null;
    /**
    * @description Patient Relationship Type ID (e.g Spouse, Child, Employee)  Must be a valid lookup ID in the the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.RelationshipTypes
    */
    'patientRelationshipTypeId'?: number | null;
    /**
    * @description List of Patient external references.
    */
    'patientExternalReferences'?: Array<ClaimExternalReferenceModel> | null;
    /**
    * @description Date when claim was processed
    */
    'dateProcessed'?: Moment | null;
    /**
    * @description The user identifier of the original processor of the claim
    */
    'processedBy'?: number | null;
    /**
    * @description Transaction Batch Number
    */
    'transactionBatchNumber'?: string | null;
    /**
    * @description Transaction Number
    */
    'transactionNumber'?: string | null;
    /**
    * @description Processor Sponsor Number
    */
    'processorSponsorNumber'?: string | null;
    /**
    * @description Processor Group Number
    */
    'processorGroupNumber'?: string | null;
    /**
    * @description Participating Provider Client Number
    */
    'parClientNumber'?: string | null;
    /**
    * @description Location Number (not used)
    */
    'locationNumber'?: string | null;
    /**
    * @description Status of claim as provided by external processor   This does not reflect the TC Claim Status
    */
    'externalStatus'?: string | null;
    /**
    * @description NDC ID
    */
    'ndcId'?: number | null;
    /**
    * @description NDC. Acronym for National Drug Code.  The NDC is a unique 10-digit, 3-segment numeric identifier assigned to each medication listed under Section 510 of the U.S. Federal Food, Drug, and Cosmetic Act.
    */
    'ndc'?: string | null;
    /**
    * @description Claim Received Date
    */
    'claimReceivedDate'?: Moment | null;
    'claimId'?: number;
    /**
    * @description Claim Group ID. Groups an EOB for Medical Claims. Pharmacy Claims always have a unique Claim Group ID.
    */
    'claimGroupId'?: number;
    'createdBy'?: number;
    'updatedBy'?: number | null;
    'promotionGroupNumber'?: string | null;
    'paymentMethodTypeId'?: number | null;
    'conditions'?: Array<ClaimConditionModel> | null;
    /**
    * @description Is migrated flag
    */
    'isMigrated'?: boolean;
    /**
    * @description EOB ID. Used to group Medical Claims together.
    */
    'eobId'?: number;
    /**
    * @description Class Type ID.  Must be a valid lookup ID in the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.ClassTypes.
    */
    'classTypeId'?: number;
    /**
    * @description General Coverage Type ID. (e.g. Medical, Drug, Dental, Vision, etc.)  Must be a valid lookup ID in the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.GeneralCoverageTypes.
    */
    'generalCoverageTypeId'?: number;
    /**
    * @description Financial Transaction Type ID. (e.g. Refund, Void Check, Adjustment etc.)  Must be a valid lookup ID in the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.FinancialTransactionTypes.
    */
    'financialTransactionTypeId'?: number;
    /**
    * @description Transaction Type ID. Internal transaction type. (e.g. InitialLoad, Adjustment, InternalClaim etc.)  Must be a valid lookup ID in the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.TransactionTypes.
    */
    'transactionTypeId'?: number;
    /**
    * @description Billed Amount
    */
    'billedAmount'?: number | null;
    /**
    * @description Charges Discounted Amount. Used as initial cost for IMS claims.
    */
    'chargesDiscountedAmount'?: number | null;
    /**
    * @description Covered Amount
    */
    'coveredAmount'?: number | null;
    /**
    * @description Deductible Applied Amount.  For internal claims initial out-of-pocket amount is a sum of DeductibleAppliedAmount and CopayAmount.
    */
    'deductibleAppliedAmount'?: number | null;
    /**
    * @description Copay Amount. A fixed amount you pay for a covered health care service.  For internal claims initial out-of-pocket amount is a sum of DeductibleAppliedAmount and CopayAmount.
    */
    'copayAmount'?: number | null;
    /**
    * @description Coordination of Benefits Amount. Coordination of benefits allows two insurance carriers to determine their fair share of the cost for covered services.   For IMS claims this amount is used as remaining cost.
    */
    'cobAmount'?: number | null;
    /**
    * @description Paid Amount, should be same as Benefit Paid  Creation of a medical claim can set this amount differently or as null, but when claim is adjudicated the amount will be updated and equal to claim BenefitPaid
    */
    'paidAmount'?: number | null;
    /**
    * @description Cause Type ID. (e.g. Accident, General Illness, Routine Care, etc.)  Must be a valid lookup ID in the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.CauseTypes.
    */
    'causeTypeId'?: number;
    /**
    * @description Procedure Type ID known as Service Code. Procedure/Service code also known as JCODE describes the type of service a medical doctor or practitioner performed on a patient   Must be a valid lookup ID in the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.ProcedureTypes.
    */
    'procedureTypeId'?: number | null;
    /**
    * @description Modifier Type ID. Modifier codes are used to further describe an EOB.  Must be a valid lookup ID in the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.ModifierTypes.
    */
    'modifierTypeId'?: number | null;
    /**
    * @description NDC Quantity
    */
    'ndcQuantity'?: number | null;
    /**
    * @description Unit or Basis of Measurement for the NDC
    */
    'ndcUnit'?: string | null;
    /**
    * @description Assignment Flag; Indicates if payment is issued to provider
    */
    'assignmentFlag'?: boolean | null;
    /**
    * @description Service Start Date
    */
    'serviceStartDate'?: Moment | null;
    /**
    * @description Service End Date
    */
    'serviceEndDate'?: Moment | null;
    /**
    * @description Original Loss Date
    */
    'originalLossDate'?: Moment | null;
    /**
    * @description Claim Adjudicated Date
    */
    'claimAdjudicatedDate'?: Moment | null;
    /**
    * @description Place of Service Type ID. (e.g. Pharmacy, Patient Home, Urgent Care Facility, Emergency Room Hospital etc.)  Must be a valid lookup ID in the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.PlaceOfServiceTypes.
    */
    'placeOfServiceTypeId'?: number | null;
    /**
    * @description Service Type ID. (e.g. HI - Home Infusion, MC - Medical Care, HM - In-Patient Hospital/Facility Misc, HC - Home Health Care etc)  Must be a valid lookup ID in the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.ServiceTypes.
    */
    'serviceTypeId'?: number | null;
    /**
    * @description Network Flag; Network benefits applied?
    */
    'networkFlag'?: boolean | null;
    /**
    * @description Service Count
    */
    'serviceCount'?: number | null;
    /**
    * @description Unit Count
    */
    'unitCount'?: number | null;
    /**
    * @description Check Date
    */
    'checkDate'?: Moment | null;
    /**
    * @description Check Number
    */
    'checkNumber'?: number | null;
    /**
    * @description ABF EOB Number
    */
    'abfEobNumber'?: string | null;
    /**
    * @description ABF Check Number
    */
    'abfCheckNumber'?: string | null;
    /**
    * @description Bill Type provides information that identifies the place of service or the type of  bill related to the location at which a health care service was rendered provides
    */
    'ub82BillType'?: string | null;
    /**
    * @description Portion of Bill Type code
    */
    'billType'?: string | null;
    /**
    * @description Revenue codes are a classification of hospital charges in a standard grouping  that is controlled by National Uniform Billing Committee
    */
    'revenueCode'?: string | null;
    /**
    * @description Admission Date
    */
    'admissionDate'?: Moment | null;
    /**
    * @description Discharge Date
    */
    'dischargeDate'?: Moment | null;
    /**
    * @description Other insurance paid amount and is not covered
    */
    'notCoveredAmount'?: number | null;
    /**
    * @description Out of Pocket Amount
    */
    'outOfPocketAmount'?: number | null;
    /**
    * @description Amount considered not eligible or not covered
    */
    'ineligibleAmount'?: number | null;
    /**
    * @description Other insurance paid amount and is covered
    */
    'otherInsurancePaidAmount'?: number | null;
    /**
    * @description Amount allowed after copay and other insurance
    */
    'allowedAmount'?: number | null;
    /**
    * @description NDC Unit Price Amount
    */
    'ndcUnitPriceAmount'?: number | null;
    /**
    * @description The claim payor name; not related to payor codes
    */
    'payorName'?: string | null;
    /**
    * @description Diagnosis IDs. The IDs will be stored in the order provided in the collection.
    */
    'diagnosisIds'?: Array<number> | null;
    /**
    * @description Additional claim status
    */
    'substatus'?: string | null;
    /**
    * @description List of Providers
    */
    'providers'?: Array<ClaimProviderModel> | null;
    /**
    * @description Payor Details
    */
    'payors'?: Array<ClaimPayorModel> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof GatewayMedicalClaimModel>> = [
        {
            "name":     "rejectTypeIds",
            "baseName": "rejectTypeIds",
            "type":     "Array<number>"
        },
        {
            "name":     "exceptionTypeIds",
            "baseName": "exceptionTypeIds",
            "type":     "Array<number>"
        },
        {
            "name":     "overrideTypeIds",
            "baseName": "overrideTypeIds",
            "type":     "Array<number>"
        },
        {
            "name":     "eobAdjudicationCodeIds",
            "baseName": "eobAdjudicationCodeIds",
            "type":     "Array<number>"
        },
        {
            "name":     "providerIds",
            "baseName": "providerIds",
            "type":     "Array<number>"
        },
        {
            "name":     "adjudicationCodes",
            "baseName": "adjudicationCodes",
            "type":     "Array<ClaimAdjudicationCodeModel>"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "externalReferences",
            "baseName": "externalReferences",
            "type":     "Array<ClaimExternalReferenceModel>"
        },
        {
            "name":     "promotionId",
            "baseName": "promotionId",
            "type":     "number"
        },
        {
            "name":     "processorId",
            "baseName": "processorId",
            "type":     "number"
        },
        {
            "name":     "claimStatusId",
            "baseName": "claimStatusId",
            "type":     "number"
        },
        {
            "name":     "requestedPaymentMethodTypeId",
            "baseName": "requestedPaymentMethodTypeId",
            "type":     "number"
        },
        {
            "name":     "benefitPaid",
            "baseName": "benefitPaid",
            "type":     "number"
        },
        {
            "name":     "remainingCost",
            "baseName": "remainingCost",
            "type":     "number"
        },
        {
            "name":     "parentClaimId",
            "baseName": "parentClaimId",
            "type":     "number"
        },
        {
            "name":     "claimTypeId",
            "baseName": "claimTypeId",
            "type":     "number"
        },
        {
            "name":     "patientLastName",
            "baseName": "patientLastName",
            "type":     "string"
        },
        {
            "name":     "patientFirstName",
            "baseName": "patientFirstName",
            "type":     "string"
        },
        {
            "name":     "patientDateOfBirth",
            "baseName": "patientDateOfBirth",
            "type":     "Date"
        },
        {
            "name":     "patientSex",
            "baseName": "patientSex",
            "type":     "string"
        },
        {
            "name":     "patientAddress",
            "baseName": "patientAddress",
            "type":     "string"
        },
        {
            "name":     "patientCity",
            "baseName": "patientCity",
            "type":     "string"
        },
        {
            "name":     "patientState",
            "baseName": "patientState",
            "type":     "string"
        },
        {
            "name":     "patientZip",
            "baseName": "patientZip",
            "type":     "string"
        },
        {
            "name":     "patientPhoneNumber",
            "baseName": "patientPhoneNumber",
            "type":     "string"
        },
        {
            "name":     "patientRelationshipTypeId",
            "baseName": "patientRelationshipTypeId",
            "type":     "number"
        },
        {
            "name":     "patientExternalReferences",
            "baseName": "patientExternalReferences",
            "type":     "Array<ClaimExternalReferenceModel>"
        },
        {
            "name":     "dateProcessed",
            "baseName": "dateProcessed",
            "type":     "Date"
        },
        {
            "name":     "processedBy",
            "baseName": "processedBy",
            "type":     "number"
        },
        {
            "name":     "transactionBatchNumber",
            "baseName": "transactionBatchNumber",
            "type":     "string"
        },
        {
            "name":     "transactionNumber",
            "baseName": "transactionNumber",
            "type":     "string"
        },
        {
            "name":     "processorSponsorNumber",
            "baseName": "processorSponsorNumber",
            "type":     "string"
        },
        {
            "name":     "processorGroupNumber",
            "baseName": "processorGroupNumber",
            "type":     "string"
        },
        {
            "name":     "parClientNumber",
            "baseName": "parClientNumber",
            "type":     "string"
        },
        {
            "name":     "locationNumber",
            "baseName": "locationNumber",
            "type":     "string"
        },
        {
            "name":     "externalStatus",
            "baseName": "externalStatus",
            "type":     "string"
        },
        {
            "name":     "ndcId",
            "baseName": "ndcId",
            "type":     "number"
        },
        {
            "name":     "ndc",
            "baseName": "ndc",
            "type":     "string"
        },
        {
            "name":     "claimReceivedDate",
            "baseName": "claimReceivedDate",
            "type":     "Date"
        },
        {
            "name":     "claimId",
            "baseName": "claimId",
            "type":     "number"
        },
        {
            "name":     "claimGroupId",
            "baseName": "claimGroupId",
            "type":     "number"
        },
        {
            "name":     "createdBy",
            "baseName": "createdBy",
            "type":     "number"
        },
        {
            "name":     "updatedBy",
            "baseName": "updatedBy",
            "type":     "number"
        },
        {
            "name":     "promotionGroupNumber",
            "baseName": "promotionGroupNumber",
            "type":     "string"
        },
        {
            "name":     "paymentMethodTypeId",
            "baseName": "paymentMethodTypeId",
            "type":     "number"
        },
        {
            "name":     "conditions",
            "baseName": "conditions",
            "type":     "Array<ClaimConditionModel>"
        },
        {
            "name":     "isMigrated",
            "baseName": "isMigrated",
            "type":     "boolean"
        },
        {
            "name":     "eobId",
            "baseName": "eobId",
            "type":     "number"
        },
        {
            "name":     "classTypeId",
            "baseName": "classTypeId",
            "type":     "number"
        },
        {
            "name":     "generalCoverageTypeId",
            "baseName": "generalCoverageTypeId",
            "type":     "number"
        },
        {
            "name":     "financialTransactionTypeId",
            "baseName": "financialTransactionTypeId",
            "type":     "number"
        },
        {
            "name":     "transactionTypeId",
            "baseName": "transactionTypeId",
            "type":     "number"
        },
        {
            "name":     "billedAmount",
            "baseName": "billedAmount",
            "type":     "number"
        },
        {
            "name":     "chargesDiscountedAmount",
            "baseName": "chargesDiscountedAmount",
            "type":     "number"
        },
        {
            "name":     "coveredAmount",
            "baseName": "coveredAmount",
            "type":     "number"
        },
        {
            "name":     "deductibleAppliedAmount",
            "baseName": "deductibleAppliedAmount",
            "type":     "number"
        },
        {
            "name":     "copayAmount",
            "baseName": "copayAmount",
            "type":     "number"
        },
        {
            "name":     "cobAmount",
            "baseName": "cobAmount",
            "type":     "number"
        },
        {
            "name":     "paidAmount",
            "baseName": "paidAmount",
            "type":     "number"
        },
        {
            "name":     "causeTypeId",
            "baseName": "causeTypeId",
            "type":     "number"
        },
        {
            "name":     "procedureTypeId",
            "baseName": "procedureTypeId",
            "type":     "number"
        },
        {
            "name":     "modifierTypeId",
            "baseName": "modifierTypeId",
            "type":     "number"
        },
        {
            "name":     "ndcQuantity",
            "baseName": "ndcQuantity",
            "type":     "number"
        },
        {
            "name":     "ndcUnit",
            "baseName": "ndcUnit",
            "type":     "string"
        },
        {
            "name":     "assignmentFlag",
            "baseName": "assignmentFlag",
            "type":     "boolean"
        },
        {
            "name":     "serviceStartDate",
            "baseName": "serviceStartDate",
            "type":     "Date"
        },
        {
            "name":     "serviceEndDate",
            "baseName": "serviceEndDate",
            "type":     "Date"
        },
        {
            "name":     "originalLossDate",
            "baseName": "originalLossDate",
            "type":     "Date"
        },
        {
            "name":     "claimAdjudicatedDate",
            "baseName": "claimAdjudicatedDate",
            "type":     "Date"
        },
        {
            "name":     "placeOfServiceTypeId",
            "baseName": "placeOfServiceTypeId",
            "type":     "number"
        },
        {
            "name":     "serviceTypeId",
            "baseName": "serviceTypeId",
            "type":     "number"
        },
        {
            "name":     "networkFlag",
            "baseName": "networkFlag",
            "type":     "boolean"
        },
        {
            "name":     "serviceCount",
            "baseName": "serviceCount",
            "type":     "number"
        },
        {
            "name":     "unitCount",
            "baseName": "unitCount",
            "type":     "number"
        },
        {
            "name":     "checkDate",
            "baseName": "checkDate",
            "type":     "Date"
        },
        {
            "name":     "checkNumber",
            "baseName": "checkNumber",
            "type":     "number"
        },
        {
            "name":     "abfEobNumber",
            "baseName": "abfEobNumber",
            "type":     "string"
        },
        {
            "name":     "abfCheckNumber",
            "baseName": "abfCheckNumber",
            "type":     "string"
        },
        {
            "name":     "ub82BillType",
            "baseName": "ub82BillType",
            "type":     "string"
        },
        {
            "name":     "billType",
            "baseName": "billType",
            "type":     "string"
        },
        {
            "name":     "revenueCode",
            "baseName": "revenueCode",
            "type":     "string"
        },
        {
            "name":     "admissionDate",
            "baseName": "admissionDate",
            "type":     "Date"
        },
        {
            "name":     "dischargeDate",
            "baseName": "dischargeDate",
            "type":     "Date"
        },
        {
            "name":     "notCoveredAmount",
            "baseName": "notCoveredAmount",
            "type":     "number"
        },
        {
            "name":     "outOfPocketAmount",
            "baseName": "outOfPocketAmount",
            "type":     "number"
        },
        {
            "name":     "ineligibleAmount",
            "baseName": "ineligibleAmount",
            "type":     "number"
        },
        {
            "name":     "otherInsurancePaidAmount",
            "baseName": "otherInsurancePaidAmount",
            "type":     "number"
        },
        {
            "name":     "allowedAmount",
            "baseName": "allowedAmount",
            "type":     "number"
        },
        {
            "name":     "ndcUnitPriceAmount",
            "baseName": "ndcUnitPriceAmount",
            "type":     "number"
        },
        {
            "name":     "payorName",
            "baseName": "payorName",
            "type":     "string"
        },
        {
            "name":     "diagnosisIds",
            "baseName": "diagnosisIds",
            "type":     "Array<number>"
        },
        {
            "name":     "substatus",
            "baseName": "substatus",
            "type":     "string"
        },
        {
            "name":     "providers",
            "baseName": "providers",
            "type":     "Array<ClaimProviderModel>"
        },
        {
            "name":     "payors",
            "baseName": "payors",
            "type":     "Array<ClaimPayorModel>"
        },
    ];

    static getAttributeTypeMap() {
        return GatewayMedicalClaimModel.attributeTypeMap;
    }
}
