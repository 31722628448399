// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Condition Type
*/
export class EnrollmentV2ConditionTypeModel {
    /**
    * @description Condition Type Id
    */
    'caseId'?: number;
    /**
    * @description Condition Type Code
    */
    'code'?: string | null;
    /**
    * @description Condition Type Description
    */
    'description'?: string | null;
    /**
    * @description Condition Type Category
    */
    'categoryCode'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnrollmentV2ConditionTypeModel>> = [
        {
            "name":     "caseId",
            "baseName": "caseId",
            "type":     "number"
        },
        {
            "name":     "code",
            "baseName": "code",
            "type":     "string"
        },
        {
            "name":     "description",
            "baseName": "description",
            "type":     "string"
        },
        {
            "name":     "categoryCode",
            "baseName": "categoryCode",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return EnrollmentV2ConditionTypeModel.attributeTypeMap;
    }
}
