// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PharmacySpecialization } from './pharmacySpecialization.js';
import type { PharmacyType } from './pharmacyType.js';
import type { SimpleIntakeAddressModel } from './simpleIntakeAddressModel.js';
import type { SimpleIntakeContactModel } from './simpleIntakeContactModel.js';
import type { SimpleIntakePharmacyPhonesModel } from './simpleIntakePharmacyPhonesModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SimpleIntakePharmacyModel {
    'address'?: SimpleIntakeAddressModel | null;
    'contact'?: SimpleIntakeContactModel | null;
    'id'?: number;
    'nabp'?: string;
    'name'?: string;
    'npi'?: string;
    'phones'?: SimpleIntakePharmacyPhonesModel | null;
    'type'?: PharmacyType;
    'specialization'?: PharmacySpecialization;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SimpleIntakePharmacyModel>> = [
        {
            "name":     "address",
            "baseName": "address",
            "type":     "SimpleIntakeAddressModel"
        },
        {
            "name":     "contact",
            "baseName": "contact",
            "type":     "SimpleIntakeContactModel"
        },
        {
            "name":     "id",
            "baseName": "id",
            "type":     "number"
        },
        {
            "name":     "nabp",
            "baseName": "nabp",
            "type":     "string"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "phones",
            "baseName": "phones",
            "type":     "SimpleIntakePharmacyPhonesModel"
        },
        {
            "name":     "type",
            "baseName": "type",
            "type":     "PharmacyType"
        },
        {
            "name":     "specialization",
            "baseName": "specialization",
            "type":     "PharmacySpecialization"
        },
    ];

    static getAttributeTypeMap() {
        return SimpleIntakePharmacyModel.attributeTypeMap;
    }
}
