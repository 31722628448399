// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { MedicalBiDiagnosisCodeRequest } from './medicalBiDiagnosisCodeRequest.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiDiagnosis {
    'cptCodes': Array<MedicalBiDiagnosisCodeRequest>;
    'jCodes': Array<MedicalBiDiagnosisCodeRequest>;
    'primaryIcdCode'?: string;
    'primaryIcdDescription'?: string;
    'secondaryIcdCode'?: string;
    'secondaryIcdDescription'?: string;
    'treatmentDate'?: Moment;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiDiagnosis>> = [
        {
            "name":     "cptCodes",
            "baseName": "cptCodes",
            "type":     "Array<MedicalBiDiagnosisCodeRequest>"
        },
        {
            "name":     "jCodes",
            "baseName": "jCodes",
            "type":     "Array<MedicalBiDiagnosisCodeRequest>"
        },
        {
            "name":     "primaryIcdCode",
            "baseName": "primaryIcdCode",
            "type":     "string"
        },
        {
            "name":     "primaryIcdDescription",
            "baseName": "primaryIcdDescription",
            "type":     "string"
        },
        {
            "name":     "secondaryIcdCode",
            "baseName": "secondaryIcdCode",
            "type":     "string"
        },
        {
            "name":     "secondaryIcdDescription",
            "baseName": "secondaryIcdDescription",
            "type":     "string"
        },
        {
            "name":     "treatmentDate",
            "baseName": "treatmentDate",
            "type":     "Date"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiDiagnosis.attributeTypeMap;
    }
}
