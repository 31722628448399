// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BasicEnrollmentMemberNumberDetails } from './basicEnrollmentMemberNumberDetails.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class BasicEnrollmentResponse {
    'accountId': number;
    'pharmacyMemberNumber': string | null;
    'medicalMemberNumber': string | null;
    'messages': Array<string> | null;
    'maxBenefitAmount': number | null;
    'untrackedBenefit': boolean | null;
    'startDate': Moment | null;
    'endDate': Moment | null;
    'claimEligibilityDate': Moment | null;
    'reenrollmentEligibilityDate': Moment | null;
    'isOptedIn': boolean | null;
    'pharmacyMemberNumberDetails': BasicEnrollmentMemberNumberDetails | null;
    'medicalMemberNumberDetails': BasicEnrollmentMemberNumberDetails | null;
    'enrollmentId': number | null;
    'enrollmentStatus': string | null;
    'enrollmentRejectionCodes': Array<string> | null;
    'queuedProcessingDate': Moment | null;
    'copayProgramType': string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof BasicEnrollmentResponse>> = [
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "pharmacyMemberNumber",
            "baseName": "pharmacyMemberNumber",
            "type":     "string"
        },
        {
            "name":     "medicalMemberNumber",
            "baseName": "medicalMemberNumber",
            "type":     "string"
        },
        {
            "name":     "messages",
            "baseName": "messages",
            "type":     "Array<string>"
        },
        {
            "name":     "maxBenefitAmount",
            "baseName": "maxBenefitAmount",
            "type":     "number"
        },
        {
            "name":     "untrackedBenefit",
            "baseName": "untrackedBenefit",
            "type":     "boolean"
        },
        {
            "name":     "startDate",
            "baseName": "startDate",
            "type":     "Date"
        },
        {
            "name":     "endDate",
            "baseName": "endDate",
            "type":     "Date"
        },
        {
            "name":     "claimEligibilityDate",
            "baseName": "claimEligibilityDate",
            "type":     "Date"
        },
        {
            "name":     "reenrollmentEligibilityDate",
            "baseName": "reenrollmentEligibilityDate",
            "type":     "Date"
        },
        {
            "name":     "isOptedIn",
            "baseName": "isOptedIn",
            "type":     "boolean"
        },
        {
            "name":     "pharmacyMemberNumberDetails",
            "baseName": "pharmacyMemberNumberDetails",
            "type":     "BasicEnrollmentMemberNumberDetails"
        },
        {
            "name":     "medicalMemberNumberDetails",
            "baseName": "medicalMemberNumberDetails",
            "type":     "BasicEnrollmentMemberNumberDetails"
        },
        {
            "name":     "enrollmentId",
            "baseName": "enrollmentId",
            "type":     "number"
        },
        {
            "name":     "enrollmentStatus",
            "baseName": "enrollmentStatus",
            "type":     "string"
        },
        {
            "name":     "enrollmentRejectionCodes",
            "baseName": "enrollmentRejectionCodes",
            "type":     "Array<string>"
        },
        {
            "name":     "queuedProcessingDate",
            "baseName": "queuedProcessingDate",
            "type":     "Date"
        },
        {
            "name":     "copayProgramType",
            "baseName": "copayProgramType",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return BasicEnrollmentResponse.attributeTypeMap;
    }
}
