// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FinancialScreeningPatientRequest } from './financialScreeningPatientRequest.js';
import type { FinancialScreeningPrescriberRequest } from './financialScreeningPrescriberRequest.js';
import type { FinancialScreeningPrescription } from './financialScreeningPrescription.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class FinancialScreeningRequestBase {
    'patient': FinancialScreeningPatientRequest | null;
    'prescriber'?: FinancialScreeningPrescriberRequest | null;
    'prescription': FinancialScreeningPrescription | null;
    'project'?: string;
    'clientId1'?: string;
    'clientId2'?: string;
    'clientId3'?: string;
    'clientId4'?: string;
    'clientId5'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof FinancialScreeningRequestBase>> = [
        {
            "name":     "patient",
            "baseName": "patient",
            "type":     "FinancialScreeningPatientRequest"
        },
        {
            "name":     "prescriber",
            "baseName": "prescriber",
            "type":     "FinancialScreeningPrescriberRequest"
        },
        {
            "name":     "prescription",
            "baseName": "prescription",
            "type":     "FinancialScreeningPrescription"
        },
        {
            "name":     "project",
            "baseName": "project",
            "type":     "string"
        },
        {
            "name":     "clientId1",
            "baseName": "clientId1",
            "type":     "string"
        },
        {
            "name":     "clientId2",
            "baseName": "clientId2",
            "type":     "string"
        },
        {
            "name":     "clientId3",
            "baseName": "clientId3",
            "type":     "string"
        },
        {
            "name":     "clientId4",
            "baseName": "clientId4",
            "type":     "string"
        },
        {
            "name":     "clientId5",
            "baseName": "clientId5",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return FinancialScreeningRequestBase.attributeTypeMap;
    }
}
