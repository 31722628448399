// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { HhsAttestations } from './hhsAttestations.js';
import type { HhsInitiator } from './hhsInitiator.js';
import type { HhsMedicalPayor } from './hhsMedicalPayor.js';
import type { HhsMedication } from './hhsMedication.js';
import type { HhsPatient } from './hhsPatient.js';
import type { HhsPharmacyPayor } from './hhsPharmacyPayor.js';
import type { HhsPrescriber } from './hhsPrescriber.js';
import type { HhsSource } from './hhsSource.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class HhsCreateEnrollmentRequest {
    'hubInitiator': HhsInitiator;
    'source': HhsSource;
    'medication': HhsMedication | null;
    'patient': HhsPatient | null;
    'medicalPayors'?: Array<HhsMedicalPayor>;
    'pharmacyPayors'?: Array<HhsPharmacyPayor>;
    'prescriber'?: HhsPrescriber | null;
    'attestations': HhsAttestations | null;
    'thirdPartyEnrollmentId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof HhsCreateEnrollmentRequest>> = [
        {
            "name":     "hubInitiator",
            "baseName": "hubInitiator",
            "type":     "HhsInitiator"
        },
        {
            "name":     "source",
            "baseName": "source",
            "type":     "HhsSource"
        },
        {
            "name":     "medication",
            "baseName": "medication",
            "type":     "HhsMedication"
        },
        {
            "name":     "patient",
            "baseName": "patient",
            "type":     "HhsPatient"
        },
        {
            "name":     "medicalPayors",
            "baseName": "medicalPayors",
            "type":     "Array<HhsMedicalPayor>"
        },
        {
            "name":     "pharmacyPayors",
            "baseName": "pharmacyPayors",
            "type":     "Array<HhsPharmacyPayor>"
        },
        {
            "name":     "prescriber",
            "baseName": "prescriber",
            "type":     "HhsPrescriber"
        },
        {
            "name":     "attestations",
            "baseName": "attestations",
            "type":     "HhsAttestations"
        },
        {
            "name":     "thirdPartyEnrollmentId",
            "baseName": "thirdPartyEnrollmentId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return HhsCreateEnrollmentRequest.attributeTypeMap;
    }
}
