// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Provider information model
*/
export class ClaimStagingAnsi837ProviderModel {
    /**
    * @description Billing provider name
    */
    'billingProviderName'?: string | null;
    /**
    * @description Billing provider address
    */
    'billingProviderAddress'?: string | null;
    /**
    * @description Billing provider city
    */
    'billingProviderCity'?: string | null;
    /**
    * @description Billing provider state
    */
    'billingProviderState'?: string | null;
    /**
    * @description Billing provider ZIP code
    */
    'billingProviderZip'?: string | null;
    /**
    * @description Billing provider country
    */
    'billingProviderCountry'?: string | null;
    /**
    * @description Billing provider phone number (fax)
    */
    'billingProviderPhone'?: string | null;
    /**
    * @description Billing provider National Provider ID (NPI)
    */
    'npi'?: string | null;
    /**
    * @description Billing provider Tax ID
    */
    'taxId'?: string | null;
    /**
    * @description \\\"Pay-To\\\" provider name
    */
    'payToProviderName'?: string | null;
    /**
    * @description \\\"Pay-To\\\" provider address
    */
    'payToAddress'?: string | null;
    /**
    * @description \\\"Pay-To\\\" provider city
    */
    'payToCity'?: string | null;
    /**
    * @description \\\"Pay-To\\\" provider state
    */
    'payToState'?: string | null;
    /**
    * @description \\\"Pay-To\\\" provider ZIP code
    */
    'payToZip'?: string | null;
    /**
    * @description Alternate provider Id
    */
    'alternateId'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimStagingAnsi837ProviderModel>> = [
        {
            "name":     "billingProviderName",
            "baseName": "billingProviderName",
            "type":     "string"
        },
        {
            "name":     "billingProviderAddress",
            "baseName": "billingProviderAddress",
            "type":     "string"
        },
        {
            "name":     "billingProviderCity",
            "baseName": "billingProviderCity",
            "type":     "string"
        },
        {
            "name":     "billingProviderState",
            "baseName": "billingProviderState",
            "type":     "string"
        },
        {
            "name":     "billingProviderZip",
            "baseName": "billingProviderZip",
            "type":     "string"
        },
        {
            "name":     "billingProviderCountry",
            "baseName": "billingProviderCountry",
            "type":     "string"
        },
        {
            "name":     "billingProviderPhone",
            "baseName": "billingProviderPhone",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "taxId",
            "baseName": "taxId",
            "type":     "string"
        },
        {
            "name":     "payToProviderName",
            "baseName": "payToProviderName",
            "type":     "string"
        },
        {
            "name":     "payToAddress",
            "baseName": "payToAddress",
            "type":     "string"
        },
        {
            "name":     "payToCity",
            "baseName": "payToCity",
            "type":     "string"
        },
        {
            "name":     "payToState",
            "baseName": "payToState",
            "type":     "string"
        },
        {
            "name":     "payToZip",
            "baseName": "payToZip",
            "type":     "string"
        },
        {
            "name":     "alternateId",
            "baseName": "alternateId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimStagingAnsi837ProviderModel.attributeTypeMap;
    }
}
