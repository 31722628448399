// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Model for Account Sessions
*/
export class AccountSessionModel {
    /**
    * @description Session identifier
    */
    'sessionId'?: number;
    /**
    * @description Survey ID
    */
    'surveyId'?: number | null;
    /**
    * @description The date and time (using Coordinated Universal Time [UTC]) at which the Session was recorded
    */
    'sessionDate'?: Moment;
    /**
    * @description Survey Session identifier
    */
    'surveySessionId'?: number | null;
    /**
    * @description Document identifier
    */
    'documentId'?: number | null;
    /**
    * @description Entity ID
    */
    'entityId'?: number | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof AccountSessionModel>> = [
        {
            "name":     "sessionId",
            "baseName": "sessionId",
            "type":     "number"
        },
        {
            "name":     "surveyId",
            "baseName": "surveyId",
            "type":     "number"
        },
        {
            "name":     "sessionDate",
            "baseName": "sessionDate",
            "type":     "Date"
        },
        {
            "name":     "surveySessionId",
            "baseName": "surveySessionId",
            "type":     "number"
        },
        {
            "name":     "documentId",
            "baseName": "documentId",
            "type":     "number"
        },
        {
            "name":     "entityId",
            "baseName": "entityId",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return AccountSessionModel.attributeTypeMap;
    }
}
