// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Address
*/
export class CoverMyMedsInboundEnrollmentAddressModel {
    /**
    * @description Street address (required, 100 chars max)
    */
    'address1'?: string | null;
    /**
    * @description City (required, 100 chars max)
    */
    'city'?: string | null;
    /**
    * @description State (required, 2 chars)
    */
    'state'?: string | null;
    /**
    * @description 10 digit zip code (required, 10 chars max)
    */
    'zipCode'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof CoverMyMedsInboundEnrollmentAddressModel>> = [
        {
            "name":     "address1",
            "baseName": "address1",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "zipCode",
            "baseName": "zipCode",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return CoverMyMedsInboundEnrollmentAddressModel.attributeTypeMap;
    }
}
