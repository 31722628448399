// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EmailErrorCode } from './emailErrorCode.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EmailReturnResult {
    'success'?: boolean;
    'errorCode'?: EmailErrorCode;
    'errorMessage'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EmailReturnResult>> = [
        {
            "name":     "success",
            "baseName": "success",
            "type":     "boolean"
        },
        {
            "name":     "errorCode",
            "baseName": "errorCode",
            "type":     "EmailErrorCode"
        },
        {
            "name":     "errorMessage",
            "baseName": "errorMessage",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return EmailReturnResult.attributeTypeMap;
    }
}
