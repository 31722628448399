// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ClaimAdjudicationCodeModel } from './claimAdjudicationCodeModel.js';
import type { ClaimConditionModel } from './claimConditionModel.js';
import type { ClaimLookupModel } from './claimLookupModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class GatewayClaimViewModel {
    'rejectTypeIds': Array<number>;
    'adjudicationCodes': Array<ClaimAdjudicationCodeModel>;
    /**
    * @description Claim Status ID
    */
    'claimStatusId'?: number;
    /**
    * @description Claim benefit paid
    */
    'benefitPaid'?: number | null;
    /**
    * @description Remaining Cost
    */
    'remainingCost'?: number | null;
    /**
    * @description Parent Claim ID used for adjustments
    */
    'parentClaimId'?: number | null;
    /**
    * @description Date processed. Date when claim was processed
    */
    'dateProcessed'?: Moment | null;
    /**
    * @description National Drug Code
    */
    'ndc'?: string | null;
    /**
    * @description Claim ID
    */
    'claimId'?: number;
    /**
    * @description Claim Created by user ID
    */
    'createdBy'?: number;
    /**
    * @description Claim Updated by user ID
    */
    'updatedBy'?: number | null;
    /**
    * @description Claim Conditions allow for a claim to be tentatively approved usually so a third party verification can be performed at a later time.  Later a process will find the claims with related conditions, process the claim and clear the condition.
    */
    'conditions'?: Array<ClaimConditionModel> | null;
    /**
    * @description Initial OutOfPocket. Amount which wasn\\\'t covered by primary insurance.
    */
    'initialOutOfPocketAmount'?: number | null;
    /**
    * @description Medical Procedure Cause ID
    */
    'causeTypeId'?: number | null;
    /**
    * @description Medical Procedure ID
    */
    'procedureTypeId'?: number | null;
    /**
    * @description Initial Cost of medical procedure or drug
    */
    'initialCost'?: number | null;
    'claimStatus'?: ClaimLookupModel | null;
    /**
    * @description Date of service. Service Start Date for medical claims
    */
    'dateOfService'?: Moment | null;
    /**
    * @description Received Date. Date when claim was received by the system
    */
    'receivedDate'?: Moment | null;
    'causeType'?: ClaimLookupModel | null;
    'procedureType'?: ClaimLookupModel | null;
    /**
    * @description Quantity
    */
    'quantity'?: number | null;
    /**
    * @description Reject type names and descriptions
    */
    'rejectTypes'?: Array<ClaimLookupModel> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof GatewayClaimViewModel>> = [
        {
            "name":     "rejectTypeIds",
            "baseName": "rejectTypeIds",
            "type":     "Array<number>"
        },
        {
            "name":     "adjudicationCodes",
            "baseName": "adjudicationCodes",
            "type":     "Array<ClaimAdjudicationCodeModel>"
        },
        {
            "name":     "claimStatusId",
            "baseName": "claimStatusId",
            "type":     "number"
        },
        {
            "name":     "benefitPaid",
            "baseName": "benefitPaid",
            "type":     "number"
        },
        {
            "name":     "remainingCost",
            "baseName": "remainingCost",
            "type":     "number"
        },
        {
            "name":     "parentClaimId",
            "baseName": "parentClaimId",
            "type":     "number"
        },
        {
            "name":     "dateProcessed",
            "baseName": "dateProcessed",
            "type":     "Date"
        },
        {
            "name":     "ndc",
            "baseName": "ndc",
            "type":     "string"
        },
        {
            "name":     "claimId",
            "baseName": "claimId",
            "type":     "number"
        },
        {
            "name":     "createdBy",
            "baseName": "createdBy",
            "type":     "number"
        },
        {
            "name":     "updatedBy",
            "baseName": "updatedBy",
            "type":     "number"
        },
        {
            "name":     "conditions",
            "baseName": "conditions",
            "type":     "Array<ClaimConditionModel>"
        },
        {
            "name":     "initialOutOfPocketAmount",
            "baseName": "initialOutOfPocketAmount",
            "type":     "number"
        },
        {
            "name":     "causeTypeId",
            "baseName": "causeTypeId",
            "type":     "number"
        },
        {
            "name":     "procedureTypeId",
            "baseName": "procedureTypeId",
            "type":     "number"
        },
        {
            "name":     "initialCost",
            "baseName": "initialCost",
            "type":     "number"
        },
        {
            "name":     "claimStatus",
            "baseName": "claimStatus",
            "type":     "ClaimLookupModel"
        },
        {
            "name":     "dateOfService",
            "baseName": "dateOfService",
            "type":     "Date"
        },
        {
            "name":     "receivedDate",
            "baseName": "receivedDate",
            "type":     "Date"
        },
        {
            "name":     "causeType",
            "baseName": "causeType",
            "type":     "ClaimLookupModel"
        },
        {
            "name":     "procedureType",
            "baseName": "procedureType",
            "type":     "ClaimLookupModel"
        },
        {
            "name":     "quantity",
            "baseName": "quantity",
            "type":     "number"
        },
        {
            "name":     "rejectTypes",
            "baseName": "rejectTypes",
            "type":     "Array<ClaimLookupModel>"
        },
    ];

    static getAttributeTypeMap() {
        return GatewayClaimViewModel.attributeTypeMap;
    }
}
