// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BestEnrollmentType } from './bestEnrollmentType.js';
import type { EnrollmentV2CopayProgramType } from './enrollmentV2CopayProgramType.js';
import type { EnrollmentV2EligibilityModel } from './enrollmentV2EligibilityModel.js';
import type { EnrollmentV2EnrollmentFlags } from './enrollmentV2EnrollmentFlags.js';
import type { EnrollmentV2MemberNumberModel } from './enrollmentV2MemberNumberModel.js';
import type { SharedCaseCaseRelationshipsModel } from './sharedCaseCaseRelationshipsModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class BestEnrollmentV2Model {
    'bestEnrollmentType': BestEnrollmentType;
    'isTest'?: boolean;
    /**
    * @description Max Benefit Amount (decimal)
    */
    'maxBenefitAmount'?: number | null;
    /**
    * @description Untracked Benefit
    */
    'untrackedBenefit'?: boolean;
    /**
    * @description Household Size
    */
    'householdSize'?: number | null;
    /**
    * @description Start / Effective Date of the Enrollment
    */
    'startDate'?: Moment | null;
    /**
    * @description End Date
    */
    'endDate'?: Moment | null;
    /**
    * @description Claim Eligibility Date
    */
    'claimEligibilityDate'?: Moment | null;
    /**
    * @description Default Payment Type
    */
    'paymentType'?: number | null;
    /**
    * @description Is Opted In
    */
    'isOptedIn'?: boolean | null;
    /**
    * @description Benefit Period Id
    */
    'benefitPeriodId'?: number | null;
    /**
    * @description Await e-sign expiration date
    */
    'awaitESignExpirationDate'?: Moment | null;
    'copayProgramType'?: EnrollmentV2CopayProgramType;
    /**
    * @description Account Id
    */
    'accountId'?: number;
    /**
    * @description Case Id/Enrollment Id
    */
    'caseId'?: number;
    /**
    * @description List containing all Member Numbers associated with this Enrollment
    */
    'memberNumbers'?: Array<EnrollmentV2MemberNumberModel> | null;
    'linkedEntities'?: SharedCaseCaseRelationshipsModel | null;
    /**
    * @description Case Source Id
    */
    'caseSourceId'?: number;
    /**
    * @description Is the underlying case still open?
    */
    'isOpen'?: boolean;
    /**
    * @description Create date
    */
    'createDate'?: Moment;
    /**
    * @description Case Initiator Id
    */
    'caseInitiatorId'?: number | null;
    /**
    * @description Condition Type Code List to manually reject this intake
    */
    'conditionTypeCodeList'?: Array<string> | null;
    /**
    * @description Is the underlying case queued for processing at a future date?
    */
    'isQueued'?: boolean;
    /**
    * @description Case Sub Status Code
    */
    'caseSubStatusCode'?: string | null;
    /**
    * @description Case Status Code
    */
    'caseStatusCode'?: string | null;
    'status'?: EnrollmentV2EligibilityModel | null;
    /**
    * @description List of Ids of all update case for this Enrollment
    */
    'updateCaseIds'?: Array<number> | null;
    /**
    * @description One or More Days of Enrollment
    */
    'lastOneOrMoreDays'?: boolean;
    'enrollmentFlags'?: EnrollmentV2EnrollmentFlags | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof BestEnrollmentV2Model>> = [
        {
            "name":     "bestEnrollmentType",
            "baseName": "bestEnrollmentType",
            "type":     "BestEnrollmentType"
        },
        {
            "name":     "isTest",
            "baseName": "isTest",
            "type":     "boolean"
        },
        {
            "name":     "maxBenefitAmount",
            "baseName": "maxBenefitAmount",
            "type":     "number"
        },
        {
            "name":     "untrackedBenefit",
            "baseName": "untrackedBenefit",
            "type":     "boolean"
        },
        {
            "name":     "householdSize",
            "baseName": "householdSize",
            "type":     "number"
        },
        {
            "name":     "startDate",
            "baseName": "startDate",
            "type":     "Date"
        },
        {
            "name":     "endDate",
            "baseName": "endDate",
            "type":     "Date"
        },
        {
            "name":     "claimEligibilityDate",
            "baseName": "claimEligibilityDate",
            "type":     "Date"
        },
        {
            "name":     "paymentType",
            "baseName": "paymentType",
            "type":     "number"
        },
        {
            "name":     "isOptedIn",
            "baseName": "isOptedIn",
            "type":     "boolean"
        },
        {
            "name":     "benefitPeriodId",
            "baseName": "benefitPeriodId",
            "type":     "number"
        },
        {
            "name":     "awaitESignExpirationDate",
            "baseName": "awaitESignExpirationDate",
            "type":     "Date"
        },
        {
            "name":     "copayProgramType",
            "baseName": "copayProgramType",
            "type":     "EnrollmentV2CopayProgramType"
        },
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "caseId",
            "baseName": "caseId",
            "type":     "number"
        },
        {
            "name":     "memberNumbers",
            "baseName": "memberNumbers",
            "type":     "Array<EnrollmentV2MemberNumberModel>"
        },
        {
            "name":     "linkedEntities",
            "baseName": "linkedEntities",
            "type":     "SharedCaseCaseRelationshipsModel"
        },
        {
            "name":     "caseSourceId",
            "baseName": "caseSourceId",
            "type":     "number"
        },
        {
            "name":     "isOpen",
            "baseName": "isOpen",
            "type":     "boolean"
        },
        {
            "name":     "createDate",
            "baseName": "createDate",
            "type":     "Date"
        },
        {
            "name":     "caseInitiatorId",
            "baseName": "caseInitiatorId",
            "type":     "number"
        },
        {
            "name":     "conditionTypeCodeList",
            "baseName": "conditionTypeCodeList",
            "type":     "Array<string>"
        },
        {
            "name":     "isQueued",
            "baseName": "isQueued",
            "type":     "boolean"
        },
        {
            "name":     "caseSubStatusCode",
            "baseName": "caseSubStatusCode",
            "type":     "string"
        },
        {
            "name":     "caseStatusCode",
            "baseName": "caseStatusCode",
            "type":     "string"
        },
        {
            "name":     "status",
            "baseName": "status",
            "type":     "EnrollmentV2EligibilityModel"
        },
        {
            "name":     "updateCaseIds",
            "baseName": "updateCaseIds",
            "type":     "Array<number>"
        },
        {
            "name":     "lastOneOrMoreDays",
            "baseName": "lastOneOrMoreDays",
            "type":     "boolean"
        },
        {
            "name":     "enrollmentFlags",
            "baseName": "enrollmentFlags",
            "type":     "EnrollmentV2EnrollmentFlags"
        },
    ];

    static getAttributeTypeMap() {
        return BestEnrollmentV2Model.attributeTypeMap;
    }
}
