// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PharmacyCardFinderPatientResponse } from './pharmacyCardFinderPatientResponse.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyCardFinderPlan {
    'bin'?: string;
    'cardHolderId'?: string;
    'coverageOrder'?: string;
    'familyUnitNumber'?: string;
    'groupId'?: string;
    'groupName'?: string;
    'patient'?: PharmacyCardFinderPatientResponse | null;
    'pbmName'?: string;
    'pbmPhone'?: string;
    'pbmResponseMessage'?: string;
    'pcn'?: string;
    'planType'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyCardFinderPlan>> = [
        {
            "name":     "bin",
            "baseName": "bin",
            "type":     "string"
        },
        {
            "name":     "cardHolderId",
            "baseName": "cardHolderId",
            "type":     "string"
        },
        {
            "name":     "coverageOrder",
            "baseName": "coverageOrder",
            "type":     "string"
        },
        {
            "name":     "familyUnitNumber",
            "baseName": "familyUnitNumber",
            "type":     "string"
        },
        {
            "name":     "groupId",
            "baseName": "groupId",
            "type":     "string"
        },
        {
            "name":     "groupName",
            "baseName": "groupName",
            "type":     "string"
        },
        {
            "name":     "patient",
            "baseName": "patient",
            "type":     "PharmacyCardFinderPatientResponse"
        },
        {
            "name":     "pbmName",
            "baseName": "pbmName",
            "type":     "string"
        },
        {
            "name":     "pbmPhone",
            "baseName": "pbmPhone",
            "type":     "string"
        },
        {
            "name":     "pbmResponseMessage",
            "baseName": "pbmResponseMessage",
            "type":     "string"
        },
        {
            "name":     "pcn",
            "baseName": "pcn",
            "type":     "string"
        },
        {
            "name":     "planType",
            "baseName": "planType",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyCardFinderPlan.attributeTypeMap;
    }
}
