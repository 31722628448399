// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AccountExternalIdType } from './accountExternalIdType.js';
import type { Gender } from './gender.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class HubSearchAccountsRequestModel {
    'firstName'?: string;
    'lastName'?: string;
    'dateOfBirth'?: Moment;
    'city'?: string;
    'state'?: string;
    'zip'?: string;
    'gender'?: Gender;
    'externalId'?: string;
    'accountExternalIdType'?: AccountExternalIdType;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof HubSearchAccountsRequestModel>> = [
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "Date"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "zip",
            "baseName": "zip",
            "type":     "string"
        },
        {
            "name":     "gender",
            "baseName": "gender",
            "type":     "Gender"
        },
        {
            "name":     "externalId",
            "baseName": "externalId",
            "type":     "string"
        },
        {
            "name":     "accountExternalIdType",
            "baseName": "accountExternalIdType",
            "type":     "AccountExternalIdType"
        },
    ];

    static getAttributeTypeMap() {
        return HubSearchAccountsRequestModel.attributeTypeMap;
    }
}
