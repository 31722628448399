// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class IPlaceOrderResponseModel {
    'orderId'?: number;
    'orderStatusId'?: number;
    'orderStatusDescription'?: string;
    'carrier'?: string;
    'trackingNumber'?: string;
    'dateShipped'?: Moment;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof IPlaceOrderResponseModel>> = [
        {
            "name":     "orderId",
            "baseName": "orderId",
            "type":     "number"
        },
        {
            "name":     "orderStatusId",
            "baseName": "orderStatusId",
            "type":     "number"
        },
        {
            "name":     "orderStatusDescription",
            "baseName": "orderStatusDescription",
            "type":     "string"
        },
        {
            "name":     "carrier",
            "baseName": "carrier",
            "type":     "string"
        },
        {
            "name":     "trackingNumber",
            "baseName": "trackingNumber",
            "type":     "string"
        },
        {
            "name":     "dateShipped",
            "baseName": "dateShipped",
            "type":     "Date"
        },
    ];

    static getAttributeTypeMap() {
        return IPlaceOrderResponseModel.attributeTypeMap;
    }
}
