// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { NpiAddressResponseModel } from './npiAddressResponseModel.js';
import type { NpiBasicResponseModel } from './npiBasicResponseModel.js';
import type { NpiEndpointResponseModel } from './npiEndpointResponseModel.js';
import type { NpiIdentifierResponseModel } from './npiIdentifierResponseModel.js';
import type { NpiOtherNameResponseModel } from './npiOtherNameResponseModel.js';
import type { NpiPracticeLocationResponseModel } from './npiPracticeLocationResponseModel.js';
import type { NpiTaxonomyResponseModel } from './npiTaxonomyResponseModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class NpiRegistryModel {
    'created_epoch'?: string;
    'enumeration_type'?: string;
    'last_updated_epoch'?: string;
    'number'?: string;
    'addresses'?: Array<NpiAddressResponseModel>;
    'practiceLocations'?: Array<NpiPracticeLocationResponseModel>;
    'basic'?: NpiBasicResponseModel | null;
    'taxonomies'?: Array<NpiTaxonomyResponseModel>;
    'identifiers'?: Array<NpiIdentifierResponseModel>;
    'endpoints'?: Array<NpiEndpointResponseModel>;
    'other_names'?: Array<NpiOtherNameResponseModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof NpiRegistryModel>> = [
        {
            "name":     "created_epoch",
            "baseName": "created_epoch",
            "type":     "string"
        },
        {
            "name":     "enumeration_type",
            "baseName": "enumeration_type",
            "type":     "string"
        },
        {
            "name":     "last_updated_epoch",
            "baseName": "last_updated_epoch",
            "type":     "string"
        },
        {
            "name":     "number",
            "baseName": "number",
            "type":     "string"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<NpiAddressResponseModel>"
        },
        {
            "name":     "practiceLocations",
            "baseName": "practiceLocations",
            "type":     "Array<NpiPracticeLocationResponseModel>"
        },
        {
            "name":     "basic",
            "baseName": "basic",
            "type":     "NpiBasicResponseModel"
        },
        {
            "name":     "taxonomies",
            "baseName": "taxonomies",
            "type":     "Array<NpiTaxonomyResponseModel>"
        },
        {
            "name":     "identifiers",
            "baseName": "identifiers",
            "type":     "Array<NpiIdentifierResponseModel>"
        },
        {
            "name":     "endpoints",
            "baseName": "endpoints",
            "type":     "Array<NpiEndpointResponseModel>"
        },
        {
            "name":     "other_names",
            "baseName": "other_names",
            "type":     "Array<NpiOtherNameResponseModel>"
        },
    ];

    static getAttributeTypeMap() {
        return NpiRegistryModel.attributeTypeMap;
    }
}
