// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EnterpriseCoordinatorAccountServiceModelsAccountOrderRelationModel {
    'coverageOrder'?: number;
    'relatedId'?: number;
    'isPrimary'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnterpriseCoordinatorAccountServiceModelsAccountOrderRelationModel>> = [
        {
            "name":     "coverageOrder",
            "baseName": "coverageOrder",
            "type":     "number"
        },
        {
            "name":     "relatedId",
            "baseName": "relatedId",
            "type":     "number"
        },
        {
            "name":     "isPrimary",
            "baseName": "isPrimary",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return EnterpriseCoordinatorAccountServiceModelsAccountOrderRelationModel.attributeTypeMap;
    }
}
