// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Signature
*/
export class PrescriptionSignatureModel {
    'signatureId'?: number;
    'isSigned'?: boolean | null;
    'signatureDate'?: Moment | null;
    'dispenseAsWritten'?: boolean | null;
    'isSubstitutionAllowed'?: boolean | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PrescriptionSignatureModel>> = [
        {
            "name":     "signatureId",
            "baseName": "signatureId",
            "type":     "number"
        },
        {
            "name":     "isSigned",
            "baseName": "isSigned",
            "type":     "boolean"
        },
        {
            "name":     "signatureDate",
            "baseName": "signatureDate",
            "type":     "Date"
        },
        {
            "name":     "dispenseAsWritten",
            "baseName": "dispenseAsWritten",
            "type":     "boolean"
        },
        {
            "name":     "isSubstitutionAllowed",
            "baseName": "isSubstitutionAllowed",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return PrescriptionSignatureModel.attributeTypeMap;
    }
}
