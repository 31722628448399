// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PrescriberResponseModel } from './prescriberResponseModel.js';
import type { SiteModel } from './siteModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class TenantSiteAndPrescriberObjectData {
    'sites': Array<SiteModel> | null;
    'prescribers': Array<PrescriberResponseModel> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof TenantSiteAndPrescriberObjectData>> = [
        {
            "name":     "sites",
            "baseName": "sites",
            "type":     "Array<SiteModel>"
        },
        {
            "name":     "prescribers",
            "baseName": "prescribers",
            "type":     "Array<PrescriberResponseModel>"
        },
    ];

    static getAttributeTypeMap() {
        return TenantSiteAndPrescriberObjectData.attributeTypeMap;
    }
}
