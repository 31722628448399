// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiStepTherapy {
    'required'?: boolean;
    'treatment'?: string;
    'method'?: string;
    'therapySeasonStartDate'?: string;
    'therapyAvailabilityDate'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiStepTherapy>> = [
        {
            "name":     "required",
            "baseName": "required",
            "type":     "boolean"
        },
        {
            "name":     "treatment",
            "baseName": "treatment",
            "type":     "string"
        },
        {
            "name":     "method",
            "baseName": "method",
            "type":     "string"
        },
        {
            "name":     "therapySeasonStartDate",
            "baseName": "therapySeasonStartDate",
            "type":     "string"
        },
        {
            "name":     "therapyAvailabilityDate",
            "baseName": "therapyAvailabilityDate",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiStepTherapy.attributeTypeMap;
    }
}
