// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EnrollmentType } from './enrollmentType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Is Valid Member Number Model
*/
export class EnrollmentV2IsValidMemberNumberModel {
    /**
    * @description Member Number
    */
    'memberNumber'?: string | null;
    /**
    * @description Is Valid?
    */
    'isValid'?: boolean;
    /**
    * @description Member Number Validation Message
    */
    'validationMessage'?: string | null;
    /**
    * @description Is Member Number already in use?
    */
    'inUse'?: boolean;
    'memberNumberType'?: EnrollmentType;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnrollmentV2IsValidMemberNumberModel>> = [
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "isValid",
            "baseName": "isValid",
            "type":     "boolean"
        },
        {
            "name":     "validationMessage",
            "baseName": "validationMessage",
            "type":     "string"
        },
        {
            "name":     "inUse",
            "baseName": "inUse",
            "type":     "boolean"
        },
        {
            "name":     "memberNumberType",
            "baseName": "memberNumberType",
            "type":     "EnrollmentType"
        },
    ];

    static getAttributeTypeMap() {
        return EnrollmentV2IsValidMemberNumberModel.attributeTypeMap;
    }
}
