// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MangoHubGetPatientSummaryPrescriber {
    'firstName': string;
    'lastName': string;
    'siteName': string;
    'address1': string;
    'address2': string;
    'city': string;
    'state': string;
    'zipCode': string;
    'phone': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MangoHubGetPatientSummaryPrescriber>> = [
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "siteName",
            "baseName": "siteName",
            "type":     "string"
        },
        {
            "name":     "address1",
            "baseName": "address1",
            "type":     "string"
        },
        {
            "name":     "address2",
            "baseName": "address2",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "zipCode",
            "baseName": "zipCode",
            "type":     "string"
        },
        {
            "name":     "phone",
            "baseName": "phone",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MangoHubGetPatientSummaryPrescriber.attributeTypeMap;
    }
}
