// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { TCQpSignatureModelTab } from './tCQpSignatureModelTab.js';
import type { TCQpSignatureModelTabGroup } from './tCQpSignatureModelTabGroup.js';
import type { TCQpSignatureModelTabList } from './tCQpSignatureModelTabList.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class TCQpSignatureModelDocument {
    'name'?: string | null;
    'applyAnchorTabs'?: boolean;
    'transformPdfFields'?: boolean;
    'documentBase64'?: string | null;
    'checkboxTabs'?: Array<TCQpSignatureModelTab> | null;
    'dateSignedTabs'?: Array<TCQpSignatureModelTab> | null;
    'initialHereTabs'?: Array<TCQpSignatureModelTab> | null;
    'listTabs'?: Array<TCQpSignatureModelTabList> | null;
    'radioGroupTabs'?: Array<TCQpSignatureModelTabGroup> | null;
    'signHereTabs'?: Array<TCQpSignatureModelTab> | null;
    'textTabs'?: Array<TCQpSignatureModelTab> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof TCQpSignatureModelDocument>> = [
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "applyAnchorTabs",
            "baseName": "applyAnchorTabs",
            "type":     "boolean"
        },
        {
            "name":     "transformPdfFields",
            "baseName": "transformPdfFields",
            "type":     "boolean"
        },
        {
            "name":     "documentBase64",
            "baseName": "documentBase64",
            "type":     "string"
        },
        {
            "name":     "checkboxTabs",
            "baseName": "checkboxTabs",
            "type":     "Array<TCQpSignatureModelTab>"
        },
        {
            "name":     "dateSignedTabs",
            "baseName": "dateSignedTabs",
            "type":     "Array<TCQpSignatureModelTab>"
        },
        {
            "name":     "initialHereTabs",
            "baseName": "initialHereTabs",
            "type":     "Array<TCQpSignatureModelTab>"
        },
        {
            "name":     "listTabs",
            "baseName": "listTabs",
            "type":     "Array<TCQpSignatureModelTabList>"
        },
        {
            "name":     "radioGroupTabs",
            "baseName": "radioGroupTabs",
            "type":     "Array<TCQpSignatureModelTabGroup>"
        },
        {
            "name":     "signHereTabs",
            "baseName": "signHereTabs",
            "type":     "Array<TCQpSignatureModelTab>"
        },
        {
            "name":     "textTabs",
            "baseName": "textTabs",
            "type":     "Array<TCQpSignatureModelTab>"
        },
    ];

    static getAttributeTypeMap() {
        return TCQpSignatureModelDocument.attributeTypeMap;
    }
}
