// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class DebitCardRequestModel {
    'memberNumber'?: string;
    'accountNumber'?: string;
    'debitCardNumber'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof DebitCardRequestModel>> = [
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "accountNumber",
            "baseName": "accountNumber",
            "type":     "string"
        },
        {
            "name":     "debitCardNumber",
            "baseName": "debitCardNumber",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return DebitCardRequestModel.attributeTypeMap;
    }
}
