// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SearchWorkflowsRequestModel {
    'createdBefore': Moment;
    'createdAfter': Moment;
    'endBefore': Moment;
    'endAfter': Moment;
    'workflowTypes': Array<string>;
    'statusTypes': Array<string>;
    'includePayors'?: boolean;
    'includePrescribers'?: boolean;
    'includePharmacies'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SearchWorkflowsRequestModel>> = [
        {
            "name":     "createdBefore",
            "baseName": "createdBefore",
            "type":     "Date"
        },
        {
            "name":     "createdAfter",
            "baseName": "createdAfter",
            "type":     "Date"
        },
        {
            "name":     "endBefore",
            "baseName": "endBefore",
            "type":     "Date"
        },
        {
            "name":     "endAfter",
            "baseName": "endAfter",
            "type":     "Date"
        },
        {
            "name":     "workflowTypes",
            "baseName": "workflowTypes",
            "type":     "Array<string>"
        },
        {
            "name":     "statusTypes",
            "baseName": "statusTypes",
            "type":     "Array<string>"
        },
        {
            "name":     "includePayors",
            "baseName": "includePayors",
            "type":     "boolean"
        },
        {
            "name":     "includePrescribers",
            "baseName": "includePrescribers",
            "type":     "boolean"
        },
        {
            "name":     "includePharmacies",
            "baseName": "includePharmacies",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return SearchWorkflowsRequestModel.attributeTypeMap;
    }
}
