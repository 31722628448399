// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { SiteModel } from './siteModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SiteSearchResponseModel {
    'sites'?: Array<SiteModel>;
    'hasAdditionalMatches'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SiteSearchResponseModel>> = [
        {
            "name":     "sites",
            "baseName": "sites",
            "type":     "Array<SiteModel>"
        },
        {
            "name":     "hasAdditionalMatches",
            "baseName": "hasAdditionalMatches",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return SiteSearchResponseModel.attributeTypeMap;
    }
}
