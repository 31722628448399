// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MangoV1CardDetailsMedicalCard {
    'activationCode': string;
    'payorId': string;
    'group': string;
    'memberNumber': string;
    'benefitRemaining'?: number | null;
    'benefitUsed'?: number | null;
    'importantSafetyInformation': string;
    'cardImage': string;
    'usageText': string;
    'termsAndConditions': string;
    'supportPhoneNumber': string;
    'patientInstructions': string;
    'processorInstructions': string;
    'expirationDate': Moment;
    'enrollmentDate'?: Moment | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MangoV1CardDetailsMedicalCard>> = [
        {
            "name":     "activationCode",
            "baseName": "activationCode",
            "type":     "string"
        },
        {
            "name":     "payorId",
            "baseName": "payorId",
            "type":     "string"
        },
        {
            "name":     "group",
            "baseName": "group",
            "type":     "string"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "benefitRemaining",
            "baseName": "benefitRemaining",
            "type":     "number"
        },
        {
            "name":     "benefitUsed",
            "baseName": "benefitUsed",
            "type":     "number"
        },
        {
            "name":     "importantSafetyInformation",
            "baseName": "importantSafetyInformation",
            "type":     "string"
        },
        {
            "name":     "cardImage",
            "baseName": "cardImage",
            "type":     "string"
        },
        {
            "name":     "usageText",
            "baseName": "usageText",
            "type":     "string"
        },
        {
            "name":     "termsAndConditions",
            "baseName": "termsAndConditions",
            "type":     "string"
        },
        {
            "name":     "supportPhoneNumber",
            "baseName": "supportPhoneNumber",
            "type":     "string"
        },
        {
            "name":     "patientInstructions",
            "baseName": "patientInstructions",
            "type":     "string"
        },
        {
            "name":     "processorInstructions",
            "baseName": "processorInstructions",
            "type":     "string"
        },
        {
            "name":     "expirationDate",
            "baseName": "expirationDate",
            "type":     "Date"
        },
        {
            "name":     "enrollmentDate",
            "baseName": "enrollmentDate",
            "type":     "Date"
        },
    ];

    static getAttributeTypeMap() {
        return MangoV1CardDetailsMedicalCard.attributeTypeMap;
    }
}
