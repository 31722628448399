// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { TempJanssenGECheckPatient } from './tempJanssenGECheckPatient.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class JanssenGECheckRequestModel {
    'tcProgramId': number;
    'ndc': string;
    'uniqueId': string;
    'memberNumber': string;
    'patient': TempJanssenGECheckPatient | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof JanssenGECheckRequestModel>> = [
        {
            "name":     "tcProgramId",
            "baseName": "tcProgramId",
            "type":     "number"
        },
        {
            "name":     "ndc",
            "baseName": "ndc",
            "type":     "string"
        },
        {
            "name":     "uniqueId",
            "baseName": "uniqueId",
            "type":     "string"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "patient",
            "baseName": "patient",
            "type":     "TempJanssenGECheckPatient"
        },
    ];

    static getAttributeTypeMap() {
        return JanssenGECheckRequestModel.attributeTypeMap;
    }
}
