// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyBiObtainPriorAuthorization {
    'fax'?: string | null;
    'org'?: string | null;
    'phone'?: string | null;
    'requirements'?: string | null;
    'website'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyBiObtainPriorAuthorization>> = [
        {
            "name":     "fax",
            "baseName": "fax",
            "type":     "string"
        },
        {
            "name":     "org",
            "baseName": "org",
            "type":     "string"
        },
        {
            "name":     "phone",
            "baseName": "phone",
            "type":     "string"
        },
        {
            "name":     "requirements",
            "baseName": "requirements",
            "type":     "string"
        },
        {
            "name":     "website",
            "baseName": "website",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyBiObtainPriorAuthorization.attributeTypeMap;
    }
}
