// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PriorAuthorizationFollowUpDiagnosis } from './priorAuthorizationFollowUpDiagnosis.js';
import type { PriorAuthorizationFollowUpFacility } from './priorAuthorizationFollowUpFacility.js';
import type { PriorAuthorizationFollowUpInfo } from './priorAuthorizationFollowUpInfo.js';
import type { PriorAuthorizationFollowUpPatient } from './priorAuthorizationFollowUpPatient.js';
import type { PriorAuthorizationFollowUpPayor } from './priorAuthorizationFollowUpPayor.js';
import type { PriorAuthorizationFollowUpPrescriber } from './priorAuthorizationFollowUpPrescriber.js';
import type { PriorAuthorizationFollowUpPrescription } from './priorAuthorizationFollowUpPrescription.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PriorAuthorizationFollowUpRequest {
    'mock'?: boolean;
    'diagnosis': PriorAuthorizationFollowUpDiagnosis | null;
    'patient': PriorAuthorizationFollowUpPatient | null;
    'payor': PriorAuthorizationFollowUpPayor | null;
    'prescriber'?: PriorAuthorizationFollowUpPrescriber | null;
    'prescription': PriorAuthorizationFollowUpPrescription | null;
    'project'?: string | null;
    'facility': PriorAuthorizationFollowUpFacility | null;
    'priorAuthInfo': PriorAuthorizationFollowUpInfo | null;
    'clientId1'?: string | null;
    'clientId2'?: string | null;
    'clientId3'?: string | null;
    'clientId4'?: string | null;
    'clientId5'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PriorAuthorizationFollowUpRequest>> = [
        {
            "name":     "mock",
            "baseName": "mock",
            "type":     "boolean"
        },
        {
            "name":     "diagnosis",
            "baseName": "diagnosis",
            "type":     "PriorAuthorizationFollowUpDiagnosis"
        },
        {
            "name":     "patient",
            "baseName": "patient",
            "type":     "PriorAuthorizationFollowUpPatient"
        },
        {
            "name":     "payor",
            "baseName": "payor",
            "type":     "PriorAuthorizationFollowUpPayor"
        },
        {
            "name":     "prescriber",
            "baseName": "prescriber",
            "type":     "PriorAuthorizationFollowUpPrescriber"
        },
        {
            "name":     "prescription",
            "baseName": "prescription",
            "type":     "PriorAuthorizationFollowUpPrescription"
        },
        {
            "name":     "project",
            "baseName": "project",
            "type":     "string"
        },
        {
            "name":     "facility",
            "baseName": "facility",
            "type":     "PriorAuthorizationFollowUpFacility"
        },
        {
            "name":     "priorAuthInfo",
            "baseName": "priorAuthInfo",
            "type":     "PriorAuthorizationFollowUpInfo"
        },
        {
            "name":     "clientId1",
            "baseName": "clientId1",
            "type":     "string"
        },
        {
            "name":     "clientId2",
            "baseName": "clientId2",
            "type":     "string"
        },
        {
            "name":     "clientId3",
            "baseName": "clientId3",
            "type":     "string"
        },
        {
            "name":     "clientId4",
            "baseName": "clientId4",
            "type":     "string"
        },
        {
            "name":     "clientId5",
            "baseName": "clientId5",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PriorAuthorizationFollowUpRequest.attributeTypeMap;
    }
}
