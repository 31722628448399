// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as enums from '@trialcard/enums';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';


export enum ScheduleEventType {
    Success = 'S',
    Rejected = 'R',
    Timeout = 'T',
    NotSent = 'N',
    Help = 'H',
    OptOut = 'O',
    BadData = 'B'
}
