// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiFamily {
    'deductibleMet'?: number;
    'deductibleTotal'?: number;
    'oopMax'?: number;
    'oopMet'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiFamily>> = [
        {
            "name":     "deductibleMet",
            "baseName": "deductibleMet",
            "type":     "number"
        },
        {
            "name":     "deductibleTotal",
            "baseName": "deductibleTotal",
            "type":     "number"
        },
        {
            "name":     "oopMax",
            "baseName": "oopMax",
            "type":     "number"
        },
        {
            "name":     "oopMet",
            "baseName": "oopMet",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiFamily.attributeTypeMap;
    }
}
