// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CoverMyMedsInboundEnrollmentAddressModel } from './coverMyMedsInboundEnrollmentAddressModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Provider<br />  Prescriber and Site data
*/
export class CoverMyMedsInboundEnrollmentProviderModel {
    /**
    * @description Provider\\\'s first name. (required)<br />  Saved to Prescriber \\\'Prescriber Name
    */
    'firstName'?: string | null;
    /**
    * @description Provider\\\'s last name. (required)<br />  Saved to Prescriber \\\'Prescriber Name
    */
    'lastName'?: string | null;
    /**
    * @description Provider\\\'s National Pharmacy Identifier (NPI). (required)<br />  Saved to Prescriber \\\'NPI\\\'
    */
    'npi'?: string | null;
    /**
    * @description Provider\\\'s office contact<br />  Saved to Site \\\'Office Contact\\\'
    */
    'officeContact'?: string | null;
    /**
    * @description Provider\\\'s office contact fax number (Must be between 10 and 15 chars)<br />  Saved to Site Office Contact \\\'Fax\\\'
    */
    'officeContactFax'?: string | null;
    /**
    * @description Provider\\\'s office contact phone number (Must be between 10 and 15 chars)<br />  Saved to Site Office Contact \\\'Phone\\\'
    */
    'officeContactPhone'?: string | null;
    /**
    * @description Provider\\\'s best time to contact office. Allowed values are: \\\"Morning\\\", \\\"Afternoon\\\".<br />  Not displayed in QP (Saved to Prescriber BestTimeToCall)
    */
    'officeContactTimePreferences'?: Array<string> | null;
    /**
    * @description Provider\\\'s office fax number (Must be between 10 and 15 chars)<br />  Saved to Prescriber \\\'Fax Number\\\'
    */
    'officeFax'?: string | null;
    /**
    * @description Provider\\\'s office phone number. (Must be between 10 and 15 chars, required)<br />  Saved to Prescriber \\\'Phone number\\\'
    */
    'officePhone'?: string | null;
    'primaryAddress'?: CoverMyMedsInboundEnrollmentAddressModel | null;
    /**
    * @description Provider\\\'s site name<br />  Saved to Site \\\'Site Name\\\'
    */
    'siteInstitutionName'?: string | null;
    /**
    * @description Provider\\\'s state license number. (required)<br />  Saved to Prescriber \\\'State License\\\'
    */
    'stateLicenseNumber'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof CoverMyMedsInboundEnrollmentProviderModel>> = [
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "officeContact",
            "baseName": "officeContact",
            "type":     "string"
        },
        {
            "name":     "officeContactFax",
            "baseName": "officeContactFax",
            "type":     "string"
        },
        {
            "name":     "officeContactPhone",
            "baseName": "officeContactPhone",
            "type":     "string"
        },
        {
            "name":     "officeContactTimePreferences",
            "baseName": "officeContactTimePreferences",
            "type":     "Array<string>"
        },
        {
            "name":     "officeFax",
            "baseName": "officeFax",
            "type":     "string"
        },
        {
            "name":     "officePhone",
            "baseName": "officePhone",
            "type":     "string"
        },
        {
            "name":     "primaryAddress",
            "baseName": "primaryAddress",
            "type":     "CoverMyMedsInboundEnrollmentAddressModel"
        },
        {
            "name":     "siteInstitutionName",
            "baseName": "siteInstitutionName",
            "type":     "string"
        },
        {
            "name":     "stateLicenseNumber",
            "baseName": "stateLicenseNumber",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return CoverMyMedsInboundEnrollmentProviderModel.attributeTypeMap;
    }
}
