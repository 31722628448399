// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AuthenticationGetCurrentUserGetCurrentUserContactInfoResponseModel } from './authenticationGetCurrentUserGetCurrentUserContactInfoResponseModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class AuthenticationGetCurrentUserGetCurrentUserResponseModel {
    'userId': number;
    'userName': string;
    'firstName': string;
    'lastName': string;
    'phone': AuthenticationGetCurrentUserGetCurrentUserContactInfoResponseModel | null;
    'fax': AuthenticationGetCurrentUserGetCurrentUserContactInfoResponseModel | null;
    'email': AuthenticationGetCurrentUserGetCurrentUserContactInfoResponseModel | null;
    'role': string;
    'active': boolean;
    'accountId': number;
    'externalId': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof AuthenticationGetCurrentUserGetCurrentUserResponseModel>> = [
        {
            "name":     "userId",
            "baseName": "userId",
            "type":     "number"
        },
        {
            "name":     "userName",
            "baseName": "userName",
            "type":     "string"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "phone",
            "baseName": "phone",
            "type":     "AuthenticationGetCurrentUserGetCurrentUserContactInfoResponseModel"
        },
        {
            "name":     "fax",
            "baseName": "fax",
            "type":     "AuthenticationGetCurrentUserGetCurrentUserContactInfoResponseModel"
        },
        {
            "name":     "email",
            "baseName": "email",
            "type":     "AuthenticationGetCurrentUserGetCurrentUserContactInfoResponseModel"
        },
        {
            "name":     "role",
            "baseName": "role",
            "type":     "string"
        },
        {
            "name":     "active",
            "baseName": "active",
            "type":     "boolean"
        },
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "externalId",
            "baseName": "externalId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return AuthenticationGetCurrentUserGetCurrentUserResponseModel.attributeTypeMap;
    }
}
