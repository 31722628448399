// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PrescriberSiteSearchPostRequest {
    'applicationContext'?: number;
    'maxRecordCount'?: number;
    'description'?: string;
    'npi'?: string;
    'addressOne'?: string;
    'city'?: string;
    'state'?: string;
    'zip'?: string;
    'roleType'?: number;
    'externalIdType'?: number;
    'externalId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PrescriberSiteSearchPostRequest>> = [
        {
            "name":     "applicationContext",
            "baseName": "applicationContext",
            "type":     "number"
        },
        {
            "name":     "maxRecordCount",
            "baseName": "maxRecordCount",
            "type":     "number"
        },
        {
            "name":     "description",
            "baseName": "description",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "addressOne",
            "baseName": "addressOne",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "zip",
            "baseName": "zip",
            "type":     "string"
        },
        {
            "name":     "roleType",
            "baseName": "roleType",
            "type":     "number"
        },
        {
            "name":     "externalIdType",
            "baseName": "externalIdType",
            "type":     "number"
        },
        {
            "name":     "externalId",
            "baseName": "externalId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PrescriberSiteSearchPostRequest.attributeTypeMap;
    }
}
