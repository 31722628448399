// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PrescriberResponseModel {
    'prescriberId'?: number;
    'firstName'?: string;
    'middleName'?: string;
    'lastName'?: string;
    'suffix'?: string;
    'nickname'?: string;
    'npi'?: string;
    'taxId'?: string;
    'addressOne': string;
    'addressTwo': string;
    'city': string;
    'state': string;
    'zip': string;
    'phoneNumber': string;
    'phoneNumberExtension': string;
    'fax': string;
    'emailAddress': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PrescriberResponseModel>> = [
        {
            "name":     "prescriberId",
            "baseName": "prescriberId",
            "type":     "number"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "middleName",
            "baseName": "middleName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "suffix",
            "baseName": "suffix",
            "type":     "string"
        },
        {
            "name":     "nickname",
            "baseName": "nickname",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "taxId",
            "baseName": "taxId",
            "type":     "string"
        },
        {
            "name":     "addressOne",
            "baseName": "addressOne",
            "type":     "string"
        },
        {
            "name":     "addressTwo",
            "baseName": "addressTwo",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "zip",
            "baseName": "zip",
            "type":     "string"
        },
        {
            "name":     "phoneNumber",
            "baseName": "phoneNumber",
            "type":     "string"
        },
        {
            "name":     "phoneNumberExtension",
            "baseName": "phoneNumberExtension",
            "type":     "string"
        },
        {
            "name":     "fax",
            "baseName": "fax",
            "type":     "string"
        },
        {
            "name":     "emailAddress",
            "baseName": "emailAddress",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PrescriberResponseModel.attributeTypeMap;
    }
}
