// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PharmacySharedAddressUpsearchModel } from './pharmacySharedAddressUpsearchModel.js';
import type { PharmacySharedEmailAddressUpsearchModel } from './pharmacySharedEmailAddressUpsearchModel.js';
import type { PharmacySharedPhoneUpsearchModel } from './pharmacySharedPhoneUpsearchModel.js';
import type { PharmacySharedWebAddressUpsearchModel } from './pharmacySharedWebAddressUpsearchModel.js';
import type { RoleType } from './roleType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacySharedContactUpsearchModel {
    'addresses'?: Array<PharmacySharedAddressUpsearchModel>;
    'emailAddresses'?: Array<PharmacySharedEmailAddressUpsearchModel>;
    'phoneNumbers'?: Array<PharmacySharedPhoneUpsearchModel>;
    'webAddresses'?: Array<PharmacySharedWebAddressUpsearchModel>;
    'contactId'?: number;
    'contactType'?: RoleType;
    'firstName'?: string;
    'lastName'?: string;
    'occupationDescription'?: string;
    'isMainContact'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacySharedContactUpsearchModel>> = [
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<PharmacySharedAddressUpsearchModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<PharmacySharedEmailAddressUpsearchModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<PharmacySharedPhoneUpsearchModel>"
        },
        {
            "name":     "webAddresses",
            "baseName": "webAddresses",
            "type":     "Array<PharmacySharedWebAddressUpsearchModel>"
        },
        {
            "name":     "contactId",
            "baseName": "contactId",
            "type":     "number"
        },
        {
            "name":     "contactType",
            "baseName": "contactType",
            "type":     "RoleType"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "occupationDescription",
            "baseName": "occupationDescription",
            "type":     "string"
        },
        {
            "name":     "isMainContact",
            "baseName": "isMainContact",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacySharedContactUpsearchModel.attributeTypeMap;
    }
}
