// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EmailSignaturePdfRequest } from './emailSignaturePdfRequest.js';
import type { EnrollPatientRequestModel } from './enrollPatientRequestModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EnrollmentWithEmailSignaturePdfRequest {
    'enrollmentRequest': EnrollPatientRequestModel | null;
    'emailSignaturePdfRequest': EmailSignaturePdfRequest | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnrollmentWithEmailSignaturePdfRequest>> = [
        {
            "name":     "enrollmentRequest",
            "baseName": "enrollmentRequest",
            "type":     "EnrollPatientRequestModel"
        },
        {
            "name":     "emailSignaturePdfRequest",
            "baseName": "emailSignaturePdfRequest",
            "type":     "EmailSignaturePdfRequest"
        },
    ];

    static getAttributeTypeMap() {
        return EnrollmentWithEmailSignaturePdfRequest.attributeTypeMap;
    }
}
