// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class GatewayClaimAccumulatorModel {
    'memberNumbers': Array<string>;
    /**
    * @description Start Date
    */
    'startDate'?: Moment | null;
    /**
    * @description End Date
    */
    'endDate'?: Moment | null;
    /**
    * @description Accumulator ID
    */
    'accumulatorId'?: number;
    /**
    * @description Accumulator Type ID (required)  Must be a valid lookup ID in the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.AccumulatorTypes.
    */
    'accumulatorTypeId'?: number;
    /**
    * @description Secondary type ID (optional)  Must be null or a valid lookup ID in the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.AccumulatorSecondaryTypes.
    */
    'accumulatorSecondaryTypeId'?: number | null;
    /**
    * @description Primary Accumulator ID (if this is a secondary accumulator)
    */
    'primaryAccumulatorId'?: number | null;
    /**
    * @description Current balance of all processed transactions
    */
    'balance'?: number;
    /**
    * @description Available balance, including unprocessed transactions
    */
    'availableBalance'?: number;
    /**
    * @description Initial benefit of the accumulator period
    */
    'initialAmount'?: number;
    /**
    * @description Max benefit of the accumulator period
    */
    'maxBenefitAmount'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof GatewayClaimAccumulatorModel>> = [
        {
            "name":     "memberNumbers",
            "baseName": "memberNumbers",
            "type":     "Array<string>"
        },
        {
            "name":     "startDate",
            "baseName": "startDate",
            "type":     "Date"
        },
        {
            "name":     "endDate",
            "baseName": "endDate",
            "type":     "Date"
        },
        {
            "name":     "accumulatorId",
            "baseName": "accumulatorId",
            "type":     "number"
        },
        {
            "name":     "accumulatorTypeId",
            "baseName": "accumulatorTypeId",
            "type":     "number"
        },
        {
            "name":     "accumulatorSecondaryTypeId",
            "baseName": "accumulatorSecondaryTypeId",
            "type":     "number"
        },
        {
            "name":     "primaryAccumulatorId",
            "baseName": "primaryAccumulatorId",
            "type":     "number"
        },
        {
            "name":     "balance",
            "baseName": "balance",
            "type":     "number"
        },
        {
            "name":     "availableBalance",
            "baseName": "availableBalance",
            "type":     "number"
        },
        {
            "name":     "initialAmount",
            "baseName": "initialAmount",
            "type":     "number"
        },
        {
            "name":     "maxBenefitAmount",
            "baseName": "maxBenefitAmount",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return GatewayClaimAccumulatorModel.attributeTypeMap;
    }
}
