// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ClaimAdjudicationClaimLineItem } from './claimAdjudicationClaimLineItem.js';
import type { ClaimAdjudicationMedicalInsurance } from './claimAdjudicationMedicalInsurance.js';
import type { ClaimAdjudicationPatientDetails } from './claimAdjudicationPatientDetails.js';
import type { ClaimAdjudicationPharmacyInsurance } from './claimAdjudicationPharmacyInsurance.js';
import type { ClaimAdjudicationProviderDetails } from './claimAdjudicationProviderDetails.js';
import type { ClaimTypeCode } from './claimTypeCode.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ClaimAdjudicationRequest {
    'dcn'?: string;
    'outboundFileName'?: string;
    'claimId'?: string;
    'masterClaimId'?: string;
    'memberNumber'?: string;
    'tcGroupNumber'?: string;
    'receivedDate'?: string;
    'processorCode'?: string;
    'claimTypeCode'?: ClaimTypeCode;
    'dateOfService'?: string;
    'patientDetails'?: ClaimAdjudicationPatientDetails | null;
    'pharmacyInsurance'?: ClaimAdjudicationPharmacyInsurance | null;
    'medicalInsurance'?: ClaimAdjudicationMedicalInsurance | null;
    'providerDetails'?: ClaimAdjudicationProviderDetails | null;
    'claimLineItems'?: Array<ClaimAdjudicationClaimLineItem>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimAdjudicationRequest>> = [
        {
            "name":     "dcn",
            "baseName": "dcn",
            "type":     "string"
        },
        {
            "name":     "outboundFileName",
            "baseName": "outboundFileName",
            "type":     "string"
        },
        {
            "name":     "claimId",
            "baseName": "claimId",
            "type":     "string"
        },
        {
            "name":     "masterClaimId",
            "baseName": "masterClaimId",
            "type":     "string"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "tcGroupNumber",
            "baseName": "tcGroupNumber",
            "type":     "string"
        },
        {
            "name":     "receivedDate",
            "baseName": "receivedDate",
            "type":     "string"
        },
        {
            "name":     "processorCode",
            "baseName": "processorCode",
            "type":     "string"
        },
        {
            "name":     "claimTypeCode",
            "baseName": "claimTypeCode",
            "type":     "ClaimTypeCode"
        },
        {
            "name":     "dateOfService",
            "baseName": "dateOfService",
            "type":     "string"
        },
        {
            "name":     "patientDetails",
            "baseName": "patientDetails",
            "type":     "ClaimAdjudicationPatientDetails"
        },
        {
            "name":     "pharmacyInsurance",
            "baseName": "pharmacyInsurance",
            "type":     "ClaimAdjudicationPharmacyInsurance"
        },
        {
            "name":     "medicalInsurance",
            "baseName": "medicalInsurance",
            "type":     "ClaimAdjudicationMedicalInsurance"
        },
        {
            "name":     "providerDetails",
            "baseName": "providerDetails",
            "type":     "ClaimAdjudicationProviderDetails"
        },
        {
            "name":     "claimLineItems",
            "baseName": "claimLineItems",
            "type":     "Array<ClaimAdjudicationClaimLineItem>"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimAdjudicationRequest.attributeTypeMap;
    }
}
