// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ClaimConditionModel } from './claimConditionModel.js';
import type { ClaimExternalReferenceModel } from './claimExternalReferenceModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class GatewayPharmacyClaimModel {
    'rejectTypeIds': Array<number>;
    'exceptionTypeIds': Array<number>;
    'overrideTypeIds': Array<number>;
    'cobIds': Array<number>;
    /**
    * @description Member Number
    */
    'memberNumber'?: string | null;
    /**
    * @description External Reference Type IDs. (e.g PDMI Member Number, Alternate Card Number, Lash Claim ID, etc.)
    */
    'externalReferences'?: Array<ClaimExternalReferenceModel> | null;
    /**
    * @description Card Promotion ID
    */
    'promotionId'?: number | null;
    /**
    * @description Processor ID. (e.g. IMS, PDMI, TrialCard, etc.)   Must be a valid lookup ID in the the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.Processors.
    */
    'processorId'?: number;
    /**
    * @description Claim Status ID. (e.g. Rejected, Paid, Reversed, Adjusted, Unprocessed, Under Review, Approved etc)  Must be a valid lookup ID in the the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.ClaimStatuses.
    */
    'claimStatusId'?: number;
    /**
    * @description Requested Payment Method Type ID (e.g. DbCard, Chk, VirMasterCard, ThrdPartyAdjud)  Must be a valid lookup ID in the the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.PaymentMethodTypes.
    */
    'requestedPaymentMethodTypeId'?: number | null;
    /**
    * @description Benefit Paid
    */
    'benefitPaid'?: number | null;
    /**
    * @description Remaining Cost
    */
    'remainingCost'?: number | null;
    /**
    * @description Parent Claim ID. If this is an adjustment or reversal, indicates the claim which is being adjusted or reversed.   A null value indicates a regular claim. This value cannot be updated.
    */
    'parentClaimId'?: number | null;
    /**
    * @description Claim Type ID. Medical or Pharmacy (Required)   Must be a valid lookup ID in the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.ClaimTypes.
    */
    'claimTypeId'?: number | null;
    /**
    * @description Patient Last Name
    */
    'patientLastName'?: string | null;
    /**
    * @description Patient First Name
    */
    'patientFirstName'?: string | null;
    /**
    * @description Patient Date of Birth
    */
    'patientDateOfBirth'?: Moment | null;
    /**
    * @description Patient Sex
    */
    'patientSex'?: string | null;
    /**
    * @description Patient Address
    */
    'patientAddress'?: string | null;
    /**
    * @description Patient City
    */
    'patientCity'?: string | null;
    /**
    * @description Patient State
    */
    'patientState'?: string | null;
    /**
    * @description Patient ZIP Code. Has to be string of 5 digits.
    */
    'patientZip'?: string | null;
    /**
    * @description Patient Phone Number
    */
    'patientPhoneNumber'?: string | null;
    /**
    * @description Patient Relationship Type ID (e.g Spouse, Child, Employee)  Must be a valid lookup ID in the the database TC.Enterprise.Claim.Service.Public.Client.IClaimServiceClientLookupCollections.RelationshipTypes
    */
    'patientRelationshipTypeId'?: number | null;
    /**
    * @description List of Patient external references.
    */
    'patientExternalReferences'?: Array<ClaimExternalReferenceModel> | null;
    /**
    * @description Date when claim was processed
    */
    'dateProcessed'?: Moment | null;
    /**
    * @description The user identifier of the original processor of the claim
    */
    'processedBy'?: number | null;
    /**
    * @description Transaction Batch Number
    */
    'transactionBatchNumber'?: string | null;
    /**
    * @description Transaction Number
    */
    'transactionNumber'?: string | null;
    /**
    * @description Processor Sponsor Number
    */
    'processorSponsorNumber'?: string | null;
    /**
    * @description Processor Group Number
    */
    'processorGroupNumber'?: string | null;
    /**
    * @description Participating Provider Client Number
    */
    'parClientNumber'?: string | null;
    /**
    * @description Location Number (not used)
    */
    'locationNumber'?: string | null;
    /**
    * @description Status of claim as provided by external processor   This does not reflect the TC Claim Status
    */
    'externalStatus'?: string | null;
    /**
    * @description Identifier for NDC record
    */
    'ndcId'?: number | null;
    /**
    * @description National Drug Code
    */
    'ndc'?: string | null;
    /**
    * @description Drug Name
    */
    'drugName'?: string | null;
    /**
    * @description Pharmacy NABP Number
    */
    'pharmacyNabpNumber'?: string | null;
    /**
    * @description Pharmacy NPI (unique identification number for covered health care providers
    */
    'pharmacyNpi'?: string | null;
    /**
    * @description Pharmacy Name
    */
    'pharmacyName'?: string | null;
    /**
    * @description Pharmacy Address One
    */
    'pharmacyAddressOne'?: string | null;
    /**
    * @description Pharmacy Address Two
    */
    'pharmacyAddressTwo'?: string | null;
    /**
    * @description Pharmacy City
    */
    'pharmacyCity'?: string | null;
    /**
    * @description Pharmacy State
    */
    'pharmacyState'?: string | null;
    /**
    * @description Pharmacy ZIP Code
    */
    'pharmacyZip'?: string | null;
    /**
    * @description Pharmacy Chain Number
    */
    'pharmacyChainNumber'?: string | null;
    /**
    * @description Pharmacy Chain Name
    */
    'pharmacyChainName'?: string | null;
    /**
    * @description Rx Number
    */
    'rxNumber'?: string | null;
    /**
    * @description Service Date
    */
    'serviceDate'?: Moment | null;
    /**
    * @description Group Number
    */
    'groupNumber'?: string | null;
    /**
    * @description Group Name
    */
    'groupName'?: string | null;
    /**
    * @description Quantity Dispensed
    */
    'quantityDispensed'?: number | null;
    /**
    * @description Days Supply
    */
    'daysSupply'?: number | null;
    /**
    * @description Dispensed as Written Code
    */
    'dispensedAsWrittenCode'?: string | null;
    /**
    * @description Is new refill?
    */
    'isNewRefill'?: boolean | null;
    /**
    * @description Prescriber First Name
    */
    'prescriberFirstName'?: string | null;
    /**
    * @description Prescriber Last Name
    */
    'prescriberLastName'?: string | null;
    /**
    * @description Submitted Usual and Customary Cost
    */
    'submittedUsualAndCustomaryCost'?: number | null;
    /**
    * @description Submitted Ingredient Cost
    */
    'submittedIngredientCost'?: number | null;
    /**
    * @description Submitted Dispensing Fee
    */
    'submittedDispensingFee'?: number | null;
    /**
    * @description Submitted Sales Tax
    */
    'submittedSalesTax'?: number | null;
    /**
    * @description Submitted Gross Amount
    */
    'submittedGrossAmount'?: number | null;
    /**
    * @description Prescriber DEA
    */
    'prescriberDea'?: string | null;
    /**
    * @description Prescriber NPI
    */
    'prescriberNpi'?: string | null;
    /**
    * @description Submitted Copay Amount
    */
    'submittedCopayAmount'?: number | null;
    /**
    * @description Calculated Average Wholesale Price
    */
    'calculatedAverageWholesalePrice'?: number | null;
    /**
    * @description Rx Written Date
    */
    'rxWrittenDate'?: Moment | null;
    /**
    * @description Other Coverage Code
    */
    'otherCoverageCode'?: string | null;
    /**
    * @description Other Payer Amount
    */
    'otherPayerAmount'?: number | null;
    /**
    * @description Is Refill Authorized?
    */
    'isRefillAuthorized'?: boolean | null;
    /**
    * @description Refill Number
    */
    'refillNumber'?: number | null;
    /**
    * @description Prior Authorization Number
    */
    'priorAuthorizationNumber'?: string | null;
    /**
    * @description Claim Received Date
    */
    'claimReceivedDate'?: Moment | null;
    /**
    * @description Eob Status ID (Optional, will be calculated if not provided)
    */
    'eobStatusId'?: number | null;
    /**
    * @description Processor Eob Value (Optional)
    */
    'processorEobValue'?: string | null;
    /**
    * @description Claim Group External Reference Type IDs
    */
    'eobExternalReferences'?: Array<ClaimExternalReferenceModel> | null;
    'claimId'?: number;
    'claimGroupId'?: number;
    'createdBy'?: number;
    'updatedBy'?: number | null;
    'promotionGroupNumber'?: string | null;
    'paymentMethodTypeId'?: number | null;
    'conditions'?: Array<ClaimConditionModel> | null;
    'isMigrated'?: boolean;
    /**
    * @description Initial Out of Pocket Amount (pre-benefit cost)
    */
    'initialOutOfPocketAmount'?: number | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof GatewayPharmacyClaimModel>> = [
        {
            "name":     "rejectTypeIds",
            "baseName": "rejectTypeIds",
            "type":     "Array<number>"
        },
        {
            "name":     "exceptionTypeIds",
            "baseName": "exceptionTypeIds",
            "type":     "Array<number>"
        },
        {
            "name":     "overrideTypeIds",
            "baseName": "overrideTypeIds",
            "type":     "Array<number>"
        },
        {
            "name":     "cobIds",
            "baseName": "cobIds",
            "type":     "Array<number>"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "externalReferences",
            "baseName": "externalReferences",
            "type":     "Array<ClaimExternalReferenceModel>"
        },
        {
            "name":     "promotionId",
            "baseName": "promotionId",
            "type":     "number"
        },
        {
            "name":     "processorId",
            "baseName": "processorId",
            "type":     "number"
        },
        {
            "name":     "claimStatusId",
            "baseName": "claimStatusId",
            "type":     "number"
        },
        {
            "name":     "requestedPaymentMethodTypeId",
            "baseName": "requestedPaymentMethodTypeId",
            "type":     "number"
        },
        {
            "name":     "benefitPaid",
            "baseName": "benefitPaid",
            "type":     "number"
        },
        {
            "name":     "remainingCost",
            "baseName": "remainingCost",
            "type":     "number"
        },
        {
            "name":     "parentClaimId",
            "baseName": "parentClaimId",
            "type":     "number"
        },
        {
            "name":     "claimTypeId",
            "baseName": "claimTypeId",
            "type":     "number"
        },
        {
            "name":     "patientLastName",
            "baseName": "patientLastName",
            "type":     "string"
        },
        {
            "name":     "patientFirstName",
            "baseName": "patientFirstName",
            "type":     "string"
        },
        {
            "name":     "patientDateOfBirth",
            "baseName": "patientDateOfBirth",
            "type":     "Date"
        },
        {
            "name":     "patientSex",
            "baseName": "patientSex",
            "type":     "string"
        },
        {
            "name":     "patientAddress",
            "baseName": "patientAddress",
            "type":     "string"
        },
        {
            "name":     "patientCity",
            "baseName": "patientCity",
            "type":     "string"
        },
        {
            "name":     "patientState",
            "baseName": "patientState",
            "type":     "string"
        },
        {
            "name":     "patientZip",
            "baseName": "patientZip",
            "type":     "string"
        },
        {
            "name":     "patientPhoneNumber",
            "baseName": "patientPhoneNumber",
            "type":     "string"
        },
        {
            "name":     "patientRelationshipTypeId",
            "baseName": "patientRelationshipTypeId",
            "type":     "number"
        },
        {
            "name":     "patientExternalReferences",
            "baseName": "patientExternalReferences",
            "type":     "Array<ClaimExternalReferenceModel>"
        },
        {
            "name":     "dateProcessed",
            "baseName": "dateProcessed",
            "type":     "Date"
        },
        {
            "name":     "processedBy",
            "baseName": "processedBy",
            "type":     "number"
        },
        {
            "name":     "transactionBatchNumber",
            "baseName": "transactionBatchNumber",
            "type":     "string"
        },
        {
            "name":     "transactionNumber",
            "baseName": "transactionNumber",
            "type":     "string"
        },
        {
            "name":     "processorSponsorNumber",
            "baseName": "processorSponsorNumber",
            "type":     "string"
        },
        {
            "name":     "processorGroupNumber",
            "baseName": "processorGroupNumber",
            "type":     "string"
        },
        {
            "name":     "parClientNumber",
            "baseName": "parClientNumber",
            "type":     "string"
        },
        {
            "name":     "locationNumber",
            "baseName": "locationNumber",
            "type":     "string"
        },
        {
            "name":     "externalStatus",
            "baseName": "externalStatus",
            "type":     "string"
        },
        {
            "name":     "ndcId",
            "baseName": "ndcId",
            "type":     "number"
        },
        {
            "name":     "ndc",
            "baseName": "ndc",
            "type":     "string"
        },
        {
            "name":     "drugName",
            "baseName": "drugName",
            "type":     "string"
        },
        {
            "name":     "pharmacyNabpNumber",
            "baseName": "pharmacyNabpNumber",
            "type":     "string"
        },
        {
            "name":     "pharmacyNpi",
            "baseName": "pharmacyNpi",
            "type":     "string"
        },
        {
            "name":     "pharmacyName",
            "baseName": "pharmacyName",
            "type":     "string"
        },
        {
            "name":     "pharmacyAddressOne",
            "baseName": "pharmacyAddressOne",
            "type":     "string"
        },
        {
            "name":     "pharmacyAddressTwo",
            "baseName": "pharmacyAddressTwo",
            "type":     "string"
        },
        {
            "name":     "pharmacyCity",
            "baseName": "pharmacyCity",
            "type":     "string"
        },
        {
            "name":     "pharmacyState",
            "baseName": "pharmacyState",
            "type":     "string"
        },
        {
            "name":     "pharmacyZip",
            "baseName": "pharmacyZip",
            "type":     "string"
        },
        {
            "name":     "pharmacyChainNumber",
            "baseName": "pharmacyChainNumber",
            "type":     "string"
        },
        {
            "name":     "pharmacyChainName",
            "baseName": "pharmacyChainName",
            "type":     "string"
        },
        {
            "name":     "rxNumber",
            "baseName": "rxNumber",
            "type":     "string"
        },
        {
            "name":     "serviceDate",
            "baseName": "serviceDate",
            "type":     "Date"
        },
        {
            "name":     "groupNumber",
            "baseName": "groupNumber",
            "type":     "string"
        },
        {
            "name":     "groupName",
            "baseName": "groupName",
            "type":     "string"
        },
        {
            "name":     "quantityDispensed",
            "baseName": "quantityDispensed",
            "type":     "number"
        },
        {
            "name":     "daysSupply",
            "baseName": "daysSupply",
            "type":     "number"
        },
        {
            "name":     "dispensedAsWrittenCode",
            "baseName": "dispensedAsWrittenCode",
            "type":     "string"
        },
        {
            "name":     "isNewRefill",
            "baseName": "isNewRefill",
            "type":     "boolean"
        },
        {
            "name":     "prescriberFirstName",
            "baseName": "prescriberFirstName",
            "type":     "string"
        },
        {
            "name":     "prescriberLastName",
            "baseName": "prescriberLastName",
            "type":     "string"
        },
        {
            "name":     "submittedUsualAndCustomaryCost",
            "baseName": "submittedUsualAndCustomaryCost",
            "type":     "number"
        },
        {
            "name":     "submittedIngredientCost",
            "baseName": "submittedIngredientCost",
            "type":     "number"
        },
        {
            "name":     "submittedDispensingFee",
            "baseName": "submittedDispensingFee",
            "type":     "number"
        },
        {
            "name":     "submittedSalesTax",
            "baseName": "submittedSalesTax",
            "type":     "number"
        },
        {
            "name":     "submittedGrossAmount",
            "baseName": "submittedGrossAmount",
            "type":     "number"
        },
        {
            "name":     "prescriberDea",
            "baseName": "prescriberDea",
            "type":     "string"
        },
        {
            "name":     "prescriberNpi",
            "baseName": "prescriberNpi",
            "type":     "string"
        },
        {
            "name":     "submittedCopayAmount",
            "baseName": "submittedCopayAmount",
            "type":     "number"
        },
        {
            "name":     "calculatedAverageWholesalePrice",
            "baseName": "calculatedAverageWholesalePrice",
            "type":     "number"
        },
        {
            "name":     "rxWrittenDate",
            "baseName": "rxWrittenDate",
            "type":     "Date"
        },
        {
            "name":     "otherCoverageCode",
            "baseName": "otherCoverageCode",
            "type":     "string"
        },
        {
            "name":     "otherPayerAmount",
            "baseName": "otherPayerAmount",
            "type":     "number"
        },
        {
            "name":     "isRefillAuthorized",
            "baseName": "isRefillAuthorized",
            "type":     "boolean"
        },
        {
            "name":     "refillNumber",
            "baseName": "refillNumber",
            "type":     "number"
        },
        {
            "name":     "priorAuthorizationNumber",
            "baseName": "priorAuthorizationNumber",
            "type":     "string"
        },
        {
            "name":     "claimReceivedDate",
            "baseName": "claimReceivedDate",
            "type":     "Date"
        },
        {
            "name":     "eobStatusId",
            "baseName": "eobStatusId",
            "type":     "number"
        },
        {
            "name":     "processorEobValue",
            "baseName": "processorEobValue",
            "type":     "string"
        },
        {
            "name":     "eobExternalReferences",
            "baseName": "eobExternalReferences",
            "type":     "Array<ClaimExternalReferenceModel>"
        },
        {
            "name":     "claimId",
            "baseName": "claimId",
            "type":     "number"
        },
        {
            "name":     "claimGroupId",
            "baseName": "claimGroupId",
            "type":     "number"
        },
        {
            "name":     "createdBy",
            "baseName": "createdBy",
            "type":     "number"
        },
        {
            "name":     "updatedBy",
            "baseName": "updatedBy",
            "type":     "number"
        },
        {
            "name":     "promotionGroupNumber",
            "baseName": "promotionGroupNumber",
            "type":     "string"
        },
        {
            "name":     "paymentMethodTypeId",
            "baseName": "paymentMethodTypeId",
            "type":     "number"
        },
        {
            "name":     "conditions",
            "baseName": "conditions",
            "type":     "Array<ClaimConditionModel>"
        },
        {
            "name":     "isMigrated",
            "baseName": "isMigrated",
            "type":     "boolean"
        },
        {
            "name":     "initialOutOfPocketAmount",
            "baseName": "initialOutOfPocketAmount",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return GatewayPharmacyClaimModel.attributeTypeMap;
    }
}
