// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PartyExternalIdType } from './partyExternalIdType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SiteSharedExternalIdUpsearchModel {
    'id'?: number;
    'externalIdType'?: PartyExternalIdType;
    'externalId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SiteSharedExternalIdUpsearchModel>> = [
        {
            "name":     "id",
            "baseName": "id",
            "type":     "number"
        },
        {
            "name":     "externalIdType",
            "baseName": "externalIdType",
            "type":     "PartyExternalIdType"
        },
        {
            "name":     "externalId",
            "baseName": "externalId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return SiteSharedExternalIdUpsearchModel.attributeTypeMap;
    }
}
