// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RoleType } from './roleType.js';
import type { SimpleIntakeAccountContactPhonesModel } from './simpleIntakeAccountContactPhonesModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SimpleIntakeAccountContactModel {
    'firstName'?: string;
    'lastName'?: string;
    'phones'?: SimpleIntakeAccountContactPhonesModel | null;
    'type'?: RoleType;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SimpleIntakeAccountContactModel>> = [
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "phones",
            "baseName": "phones",
            "type":     "SimpleIntakeAccountContactPhonesModel"
        },
        {
            "name":     "type",
            "baseName": "type",
            "type":     "RoleType"
        },
    ];

    static getAttributeTypeMap() {
        return SimpleIntakeAccountContactModel.attributeTypeMap;
    }
}
