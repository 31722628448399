// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PrescriberLicenseUpsearchModel } from './prescriberLicenseUpsearchModel.js';
import type { PrescriberSharedAddressUpsearchModel } from './prescriberSharedAddressUpsearchModel.js';
import type { PrescriberSharedEmailAddressUpsearchModel } from './prescriberSharedEmailAddressUpsearchModel.js';
import type { PrescriberSharedExternalIdUpsearchModel } from './prescriberSharedExternalIdUpsearchModel.js';
import type { PrescriberSharedPhoneUpsearchModel } from './prescriberSharedPhoneUpsearchModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PrescriberUpsearchModel {
    'prescriberId'?: number;
    'prescriberLicenses'?: Array<PrescriberLicenseUpsearchModel>;
    'externalIds'?: Array<PrescriberSharedExternalIdUpsearchModel>;
    'addresses'?: Array<PrescriberSharedAddressUpsearchModel>;
    'phoneNumbers'?: Array<PrescriberSharedPhoneUpsearchModel>;
    'emailAddresses'?: Array<PrescriberSharedEmailAddressUpsearchModel>;
    'firstName'?: string;
    'middleName'?: string;
    'lastName'?: string;
    'suffix'?: string;
    'nickname'?: string;
    'npi'?: string;
    'taxId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PrescriberUpsearchModel>> = [
        {
            "name":     "prescriberId",
            "baseName": "prescriberId",
            "type":     "number"
        },
        {
            "name":     "prescriberLicenses",
            "baseName": "prescriberLicenses",
            "type":     "Array<PrescriberLicenseUpsearchModel>"
        },
        {
            "name":     "externalIds",
            "baseName": "externalIds",
            "type":     "Array<PrescriberSharedExternalIdUpsearchModel>"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<PrescriberSharedAddressUpsearchModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<PrescriberSharedPhoneUpsearchModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<PrescriberSharedEmailAddressUpsearchModel>"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "middleName",
            "baseName": "middleName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "suffix",
            "baseName": "suffix",
            "type":     "string"
        },
        {
            "name":     "nickname",
            "baseName": "nickname",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "taxId",
            "baseName": "taxId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PrescriberUpsearchModel.attributeTypeMap;
    }
}
