// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EnterpriseCoordinatorAccountUpsearchRequestModel } from './enterpriseCoordinatorAccountUpsearchRequestModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PatientIntakeRequestModel {
    'patientAccountUpsearch': EnterpriseCoordinatorAccountUpsearchRequestModel | null;
    'closeIntake'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PatientIntakeRequestModel>> = [
        {
            "name":     "patientAccountUpsearch",
            "baseName": "patientAccountUpsearch",
            "type":     "EnterpriseCoordinatorAccountUpsearchRequestModel"
        },
        {
            "name":     "closeIntake",
            "baseName": "closeIntake",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return PatientIntakeRequestModel.attributeTypeMap;
    }
}
