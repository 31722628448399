// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as enums from '@trialcard/enums';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';


export enum EnrollmentScenario {
    Undetermined = 0,
    NewEnrollment = 1,
    Update = 2,
    KeyUpdate = 3,
    ReEnrollment = 4,
    DisEnrollment = 5
}
