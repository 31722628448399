// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Model for Payor Details
*/
export class ClaimPayorModel {
    /**
    * @description Payor Identifier
    */
    'payorId'?: number;
    /**
    * @description Payor Description
    */
    'payorDescription'?: string | null;
    /**
    * @description Participant Group Identifier
    */
    'participantGroupId'?: string | null;
    /**
    * @description Participant Plan Name
    */
    'participantPlanName'?: string | null;
    /**
    * @description Participant Coverage Effective Date
    */
    'participantCoverageEffDate'?: Moment | null;
    /**
    * @description Participant Coverage Termination Date
    */
    'participantCoverageTermDate'?: Moment | null;
    /**
    * @description Coverage Order
    */
    'coverageOrder'?: number | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimPayorModel>> = [
        {
            "name":     "payorId",
            "baseName": "payorId",
            "type":     "number"
        },
        {
            "name":     "payorDescription",
            "baseName": "payorDescription",
            "type":     "string"
        },
        {
            "name":     "participantGroupId",
            "baseName": "participantGroupId",
            "type":     "string"
        },
        {
            "name":     "participantPlanName",
            "baseName": "participantPlanName",
            "type":     "string"
        },
        {
            "name":     "participantCoverageEffDate",
            "baseName": "participantCoverageEffDate",
            "type":     "Date"
        },
        {
            "name":     "participantCoverageTermDate",
            "baseName": "participantCoverageTermDate",
            "type":     "Date"
        },
        {
            "name":     "coverageOrder",
            "baseName": "coverageOrder",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimPayorModel.attributeTypeMap;
    }
}
