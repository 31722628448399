// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  https://openapi-generator.tech  Do not edit the class manually.
*/
export class ExtEnrollmentPatientLookupRequestModel {
    'firstName'?: string;
    'lastName'?: string;
    'gender'?: string;
    'dateOfBirth'?: string;
    'postalCode'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtEnrollmentPatientLookupRequestModel>> = [
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "gender",
            "baseName": "gender",
            "type":     "string"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "string"
        },
        {
            "name":     "postalCode",
            "baseName": "postalCode",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ExtEnrollmentPatientLookupRequestModel.attributeTypeMap;
    }
}
