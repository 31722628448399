// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { NoteConditionRelationshipModel } from './noteConditionRelationshipModel.js';
import type { NoteTypeCategory } from './noteTypeCategory.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class NoteTaskModel {
    'id'?: number;
    'closedBy'?: number;
    'createdBy'?: number;
    'createdDate'?: Moment;
    'updatedBy'?: number;
    'updatedDate'?: Moment;
    'typeCategory'?: NoteTypeCategory;
    'typeCode'?: string;
    'typeDescription'?: string;
    'conditions'?: Array<NoteConditionRelationshipModel>;
    'accountId'?: number;
    'caseId'?: number;
    'assignedUserId'?: number;
    'dueDate'?: Moment;
    'completionDate'?: Moment;
    'isCompleted'?: boolean;
    'resolution'?: string;
    'documentId'?: number;
    'typeId'?: number;
    'subject'?: string;
    'body'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof NoteTaskModel>> = [
        {
            "name":     "id",
            "baseName": "id",
            "type":     "number"
        },
        {
            "name":     "closedBy",
            "baseName": "closedBy",
            "type":     "number"
        },
        {
            "name":     "createdBy",
            "baseName": "createdBy",
            "type":     "number"
        },
        {
            "name":     "createdDate",
            "baseName": "createdDate",
            "type":     "Date"
        },
        {
            "name":     "updatedBy",
            "baseName": "updatedBy",
            "type":     "number"
        },
        {
            "name":     "updatedDate",
            "baseName": "updatedDate",
            "type":     "Date"
        },
        {
            "name":     "typeCategory",
            "baseName": "typeCategory",
            "type":     "NoteTypeCategory"
        },
        {
            "name":     "typeCode",
            "baseName": "typeCode",
            "type":     "string"
        },
        {
            "name":     "typeDescription",
            "baseName": "typeDescription",
            "type":     "string"
        },
        {
            "name":     "conditions",
            "baseName": "conditions",
            "type":     "Array<NoteConditionRelationshipModel>"
        },
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "caseId",
            "baseName": "caseId",
            "type":     "number"
        },
        {
            "name":     "assignedUserId",
            "baseName": "assignedUserId",
            "type":     "number"
        },
        {
            "name":     "dueDate",
            "baseName": "dueDate",
            "type":     "Date"
        },
        {
            "name":     "completionDate",
            "baseName": "completionDate",
            "type":     "Date"
        },
        {
            "name":     "isCompleted",
            "baseName": "isCompleted",
            "type":     "boolean"
        },
        {
            "name":     "resolution",
            "baseName": "resolution",
            "type":     "string"
        },
        {
            "name":     "documentId",
            "baseName": "documentId",
            "type":     "number"
        },
        {
            "name":     "typeId",
            "baseName": "typeId",
            "type":     "number"
        },
        {
            "name":     "subject",
            "baseName": "subject",
            "type":     "string"
        },
        {
            "name":     "body",
            "baseName": "body",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return NoteTaskModel.attributeTypeMap;
    }
}
