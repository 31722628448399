// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class UnsubscribeBySourceNameRequestModel {
    'email': string;
    'sourceName': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof UnsubscribeBySourceNameRequestModel>> = [
        {
            "name":     "email",
            "baseName": "email",
            "type":     "string"
        },
        {
            "name":     "sourceName",
            "baseName": "sourceName",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return UnsubscribeBySourceNameRequestModel.attributeTypeMap;
    }
}
