// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as enums from '@trialcard/enums';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';


export enum EnrollmentStatus {
    Approved = 0,
    Rejected = 1,
    Review_Required = 2,
    Review_Processing = 3,
    Intake_Processing = 4,
    Disenrolled = 5,
    Enrolled = 6,
    Canceled = 7,
    Open = 8,
    Unknown = -1,
    EXP = 9
}
