// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EnrollmentTcsWrapperModelsAccumulatorModel } from './enrollmentTcsWrapperModelsAccumulatorModel.js';
import type { ExtEnrollmentAlternateCardId } from './extEnrollmentAlternateCardId.js';
import type { TCSCommunicationType } from './tCSCommunicationType.js';
import type { TCSEligibilityStatus } from './tCSEligibilityStatus.js';
import type { TCSInsuranceType } from './tCSInsuranceType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ExtEnrollmentPatientSearchRecordModel {
    'firstName'?: string;
    'lastName'?: string;
    'dateOfBirth'?: Moment;
    'address1'?: string;
    'city'?: string;
    'state'?: string;
    'postalCode'?: string;
    'country'?: string;
    'memberNumber'?: string;
    'cardStartDate'?: Moment;
    'cardEndDate'?: Moment;
    'cardActivationDate'?: Moment;
    'eligibilityStatusCode'?: TCSEligibilityStatus;
    'eligibilityStatusDescription'?: string;
    'eligibilityDate'?: string;
    'bin'?: string;
    'sponsorNumber'?: string;
    'alternateCardIds': Array<ExtEnrollmentAlternateCardId>;
    'email'?: string;
    'phone'?: string;
    'groupNumber'?: string;
    'totalBenefitAmount'?: number;
    'remainingBenefitAmount'?: number;
    'totalPaidAmount'?: number;
    'accumulators': Array<EnrollmentTcsWrapperModelsAccumulatorModel>;
    'communicationTypes'?: TCSCommunicationType;
    'insuranceType'?: TCSInsuranceType;
    'payerName'?: string;
    'payerPhone'?: string;
    'patientInsuranceBin'?: string;
    'patientInsurancePcn'?: string;
    'payerMedicalId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtEnrollmentPatientSearchRecordModel>> = [
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "Date"
        },
        {
            "name":     "address1",
            "baseName": "address1",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "postalCode",
            "baseName": "postalCode",
            "type":     "string"
        },
        {
            "name":     "country",
            "baseName": "country",
            "type":     "string"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "cardStartDate",
            "baseName": "cardStartDate",
            "type":     "Date"
        },
        {
            "name":     "cardEndDate",
            "baseName": "cardEndDate",
            "type":     "Date"
        },
        {
            "name":     "cardActivationDate",
            "baseName": "cardActivationDate",
            "type":     "Date"
        },
        {
            "name":     "eligibilityStatusCode",
            "baseName": "eligibilityStatusCode",
            "type":     "TCSEligibilityStatus"
        },
        {
            "name":     "eligibilityStatusDescription",
            "baseName": "eligibilityStatusDescription",
            "type":     "string"
        },
        {
            "name":     "eligibilityDate",
            "baseName": "eligibilityDate",
            "type":     "string"
        },
        {
            "name":     "bin",
            "baseName": "bin",
            "type":     "string"
        },
        {
            "name":     "sponsorNumber",
            "baseName": "sponsorNumber",
            "type":     "string"
        },
        {
            "name":     "alternateCardIds",
            "baseName": "alternateCardIds",
            "type":     "Array<ExtEnrollmentAlternateCardId>"
        },
        {
            "name":     "email",
            "baseName": "email",
            "type":     "string"
        },
        {
            "name":     "phone",
            "baseName": "phone",
            "type":     "string"
        },
        {
            "name":     "groupNumber",
            "baseName": "groupNumber",
            "type":     "string"
        },
        {
            "name":     "totalBenefitAmount",
            "baseName": "totalBenefitAmount",
            "type":     "number"
        },
        {
            "name":     "remainingBenefitAmount",
            "baseName": "remainingBenefitAmount",
            "type":     "number"
        },
        {
            "name":     "totalPaidAmount",
            "baseName": "totalPaidAmount",
            "type":     "number"
        },
        {
            "name":     "accumulators",
            "baseName": "accumulators",
            "type":     "Array<EnrollmentTcsWrapperModelsAccumulatorModel>"
        },
        {
            "name":     "communicationTypes",
            "baseName": "communicationTypes",
            "type":     "TCSCommunicationType"
        },
        {
            "name":     "insuranceType",
            "baseName": "insuranceType",
            "type":     "TCSInsuranceType"
        },
        {
            "name":     "payerName",
            "baseName": "payerName",
            "type":     "string"
        },
        {
            "name":     "payerPhone",
            "baseName": "payerPhone",
            "type":     "string"
        },
        {
            "name":     "patientInsuranceBin",
            "baseName": "patientInsuranceBin",
            "type":     "string"
        },
        {
            "name":     "patientInsurancePcn",
            "baseName": "patientInsurancePcn",
            "type":     "string"
        },
        {
            "name":     "payerMedicalId",
            "baseName": "payerMedicalId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ExtEnrollmentPatientSearchRecordModel.attributeTypeMap;
    }
}
