// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiPayorRequest {
    'groupId'?: string;
    'groupName'?: string;
    'id': string;
    'memberId': string;
    'name'?: string;
    'otherInsuranceStatus'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiPayorRequest>> = [
        {
            "name":     "groupId",
            "baseName": "groupId",
            "type":     "string"
        },
        {
            "name":     "groupName",
            "baseName": "groupName",
            "type":     "string"
        },
        {
            "name":     "id",
            "baseName": "id",
            "type":     "string"
        },
        {
            "name":     "memberId",
            "baseName": "memberId",
            "type":     "string"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "otherInsuranceStatus",
            "baseName": "otherInsuranceStatus",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiPayorRequest.attributeTypeMap;
    }
}
