// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { HhsAddress } from './hhsAddress.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class HhsPrescriber {
    'firstName': string;
    'lastName': string;
    'middleName'?: string;
    'suffix'?: string;
    'npi'?: string;
    'taxId'?: string;
    'deaNumber'?: string;
    'stateLicenseId'?: string;
    'ptan'?: string;
    'facilityPhoneNumber': string;
    'facilityFaxNumber'?: string;
    'facilityAddress': HhsAddress | null;
    'facilityName'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof HhsPrescriber>> = [
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "middleName",
            "baseName": "middleName",
            "type":     "string"
        },
        {
            "name":     "suffix",
            "baseName": "suffix",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "taxId",
            "baseName": "taxId",
            "type":     "string"
        },
        {
            "name":     "deaNumber",
            "baseName": "deaNumber",
            "type":     "string"
        },
        {
            "name":     "stateLicenseId",
            "baseName": "stateLicenseId",
            "type":     "string"
        },
        {
            "name":     "ptan",
            "baseName": "ptan",
            "type":     "string"
        },
        {
            "name":     "facilityPhoneNumber",
            "baseName": "facilityPhoneNumber",
            "type":     "string"
        },
        {
            "name":     "facilityFaxNumber",
            "baseName": "facilityFaxNumber",
            "type":     "string"
        },
        {
            "name":     "facilityAddress",
            "baseName": "facilityAddress",
            "type":     "HhsAddress"
        },
        {
            "name":     "facilityName",
            "baseName": "facilityName",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return HhsPrescriber.attributeTypeMap;
    }
}
