// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  https://openapi-generator.tech  Do not edit the class manually.
*/
export class ExtEnrollmentMoveMemberNumberBySurveyQuestionResultModel {
    'memberNumber'?: string;
    'sourceGroup'?: string;
    'sourceOfferType'?: number;
    'sourceBatchId'?: number;
    'sourceTcProgramId'?: number;
    'destinationGroup'?: string;
    'destinationOfferType'?: number;
    'destinationBatchId'?: number;
    'destinationTcProgramId'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtEnrollmentMoveMemberNumberBySurveyQuestionResultModel>> = [
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "sourceGroup",
            "baseName": "sourceGroup",
            "type":     "string"
        },
        {
            "name":     "sourceOfferType",
            "baseName": "sourceOfferType",
            "type":     "number"
        },
        {
            "name":     "sourceBatchId",
            "baseName": "sourceBatchId",
            "type":     "number"
        },
        {
            "name":     "sourceTcProgramId",
            "baseName": "sourceTcProgramId",
            "type":     "number"
        },
        {
            "name":     "destinationGroup",
            "baseName": "destinationGroup",
            "type":     "string"
        },
        {
            "name":     "destinationOfferType",
            "baseName": "destinationOfferType",
            "type":     "number"
        },
        {
            "name":     "destinationBatchId",
            "baseName": "destinationBatchId",
            "type":     "number"
        },
        {
            "name":     "destinationTcProgramId",
            "baseName": "destinationTcProgramId",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return ExtEnrollmentMoveMemberNumberBySurveyQuestionResultModel.attributeTypeMap;
    }
}
