// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { SiteModel } from './siteModel.js';
import type { SitePointOfContactModel } from './sitePointOfContactModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SiteAccountGetCaseGetCaseSiteResponseModel {
    'siteData': SiteModel | null;
    'pointOfContactData': Array<SitePointOfContactModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SiteAccountGetCaseGetCaseSiteResponseModel>> = [
        {
            "name":     "siteData",
            "baseName": "siteData",
            "type":     "SiteModel"
        },
        {
            "name":     "pointOfContactData",
            "baseName": "pointOfContactData",
            "type":     "Array<SitePointOfContactModel>"
        },
    ];

    static getAttributeTypeMap() {
        return SiteAccountGetCaseGetCaseSiteResponseModel.attributeTypeMap;
    }
}
