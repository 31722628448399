// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiAppeal {
    'available'?: string;
    'contactFax'?: string;
    'contactOrg'?: string;
    'contactPhone'?: string;
    'numberAvailable'?: string;
    'requiredDocuments'?: string;
    'submissionDeadline'?: string;
    'turnaroundTime'?: string;
    'contactWebsite'?: string;
    'priorAuthContactFax'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiAppeal>> = [
        {
            "name":     "available",
            "baseName": "available",
            "type":     "string"
        },
        {
            "name":     "contactFax",
            "baseName": "contactFax",
            "type":     "string"
        },
        {
            "name":     "contactOrg",
            "baseName": "contactOrg",
            "type":     "string"
        },
        {
            "name":     "contactPhone",
            "baseName": "contactPhone",
            "type":     "string"
        },
        {
            "name":     "numberAvailable",
            "baseName": "numberAvailable",
            "type":     "string"
        },
        {
            "name":     "requiredDocuments",
            "baseName": "requiredDocuments",
            "type":     "string"
        },
        {
            "name":     "submissionDeadline",
            "baseName": "submissionDeadline",
            "type":     "string"
        },
        {
            "name":     "turnaroundTime",
            "baseName": "turnaroundTime",
            "type":     "string"
        },
        {
            "name":     "contactWebsite",
            "baseName": "contactWebsite",
            "type":     "string"
        },
        {
            "name":     "priorAuthContactFax",
            "baseName": "priorAuthContactFax",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiAppeal.attributeTypeMap;
    }
}
