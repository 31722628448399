// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiPriorAuthorization {
    'approvalDate'?: Moment;
    'approvalNumber'?: string;
    'approvedQuantity'?: string;
    'approvedQuantityUsed'?: string;
    'endDate'?: Moment;
    'onFile'?: boolean;
    'renewalProcessExists'?: boolean;
    'required'?: boolean;
    'requiredCodes'?: string;
    'responsibleOrg'?: string;
    'startDate'?: Moment;
    'status'?: string;
    'turnaroundTime'?: string;
    'submissionMethods'?: Array<string>;
    'notificationMethods'?: Array<string>;
    'obtainPriorAuthFormLink'?: string;
    'obtainPriorAuthAddress'?: string;
    'approvedTreatments'?: string;
    'approvedTreatmentsUsed'?: string;
    'providerNameOnFile'?: string;
    'addressOnFile'?: string;
    'exceptionReason'?: string;
    'completionDate'?: string;
    'submissionDate'?: string;
    'type'?: string;
    'onFileApplies'?: string;
    'initiationDate'?: string;
    'timelyFilingPeriod'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiPriorAuthorization>> = [
        {
            "name":     "approvalDate",
            "baseName": "approvalDate",
            "type":     "Date"
        },
        {
            "name":     "approvalNumber",
            "baseName": "approvalNumber",
            "type":     "string"
        },
        {
            "name":     "approvedQuantity",
            "baseName": "approvedQuantity",
            "type":     "string"
        },
        {
            "name":     "approvedQuantityUsed",
            "baseName": "approvedQuantityUsed",
            "type":     "string"
        },
        {
            "name":     "endDate",
            "baseName": "endDate",
            "type":     "Date"
        },
        {
            "name":     "onFile",
            "baseName": "onFile",
            "type":     "boolean"
        },
        {
            "name":     "renewalProcessExists",
            "baseName": "renewalProcessExists",
            "type":     "boolean"
        },
        {
            "name":     "required",
            "baseName": "required",
            "type":     "boolean"
        },
        {
            "name":     "requiredCodes",
            "baseName": "requiredCodes",
            "type":     "string"
        },
        {
            "name":     "responsibleOrg",
            "baseName": "responsibleOrg",
            "type":     "string"
        },
        {
            "name":     "startDate",
            "baseName": "startDate",
            "type":     "Date"
        },
        {
            "name":     "status",
            "baseName": "status",
            "type":     "string"
        },
        {
            "name":     "turnaroundTime",
            "baseName": "turnaroundTime",
            "type":     "string"
        },
        {
            "name":     "submissionMethods",
            "baseName": "submissionMethods",
            "type":     "Array<string>"
        },
        {
            "name":     "notificationMethods",
            "baseName": "notificationMethods",
            "type":     "Array<string>"
        },
        {
            "name":     "obtainPriorAuthFormLink",
            "baseName": "obtainPriorAuthFormLink",
            "type":     "string"
        },
        {
            "name":     "obtainPriorAuthAddress",
            "baseName": "obtainPriorAuthAddress",
            "type":     "string"
        },
        {
            "name":     "approvedTreatments",
            "baseName": "approvedTreatments",
            "type":     "string"
        },
        {
            "name":     "approvedTreatmentsUsed",
            "baseName": "approvedTreatmentsUsed",
            "type":     "string"
        },
        {
            "name":     "providerNameOnFile",
            "baseName": "providerNameOnFile",
            "type":     "string"
        },
        {
            "name":     "addressOnFile",
            "baseName": "addressOnFile",
            "type":     "string"
        },
        {
            "name":     "exceptionReason",
            "baseName": "exceptionReason",
            "type":     "string"
        },
        {
            "name":     "completionDate",
            "baseName": "completionDate",
            "type":     "string"
        },
        {
            "name":     "submissionDate",
            "baseName": "submissionDate",
            "type":     "string"
        },
        {
            "name":     "type",
            "baseName": "type",
            "type":     "string"
        },
        {
            "name":     "onFileApplies",
            "baseName": "onFileApplies",
            "type":     "string"
        },
        {
            "name":     "initiationDate",
            "baseName": "initiationDate",
            "type":     "string"
        },
        {
            "name":     "timelyFilingPeriod",
            "baseName": "timelyFilingPeriod",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiPriorAuthorization.attributeTypeMap;
    }
}
