// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ExtEnrollmentGuardianInfoModel } from './extEnrollmentGuardianInfoModel.js';
import type { ExtEnrollmentHybridClientIdentifiersModel } from './extEnrollmentHybridClientIdentifiersModel.js';
import type { ExtEnrollmentHybridEnrollmentHistoryModel } from './extEnrollmentHybridEnrollmentHistoryModel.js';
import type { ExtEnrollmentHybridPatientSearchDemographicsModel } from './extEnrollmentHybridPatientSearchDemographicsModel.js';
import type { ExtEnrollmentMedBenInsuranceVerificationModel } from './extEnrollmentMedBenInsuranceVerificationModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ExtEnrollmentHybridPatientSearchResultModel {
    'clientIdentifiers'?: ExtEnrollmentHybridClientIdentifiersModel | null;
    'demographics'?: ExtEnrollmentHybridPatientSearchDemographicsModel | null;
    'guardianInfo'?: ExtEnrollmentGuardianInfoModel | null;
    'insuranceVerification'?: ExtEnrollmentMedBenInsuranceVerificationModel | null;
    'enrollments': Array<ExtEnrollmentHybridEnrollmentHistoryModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtEnrollmentHybridPatientSearchResultModel>> = [
        {
            "name":     "clientIdentifiers",
            "baseName": "clientIdentifiers",
            "type":     "ExtEnrollmentHybridClientIdentifiersModel"
        },
        {
            "name":     "demographics",
            "baseName": "demographics",
            "type":     "ExtEnrollmentHybridPatientSearchDemographicsModel"
        },
        {
            "name":     "guardianInfo",
            "baseName": "guardianInfo",
            "type":     "ExtEnrollmentGuardianInfoModel"
        },
        {
            "name":     "insuranceVerification",
            "baseName": "insuranceVerification",
            "type":     "ExtEnrollmentMedBenInsuranceVerificationModel"
        },
        {
            "name":     "enrollments",
            "baseName": "enrollments",
            "type":     "Array<ExtEnrollmentHybridEnrollmentHistoryModel>"
        },
    ];

    static getAttributeTypeMap() {
        return ExtEnrollmentHybridPatientSearchResultModel.attributeTypeMap;
    }
}
