// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PharmacyBiResponseDetail } from './pharmacyBiResponseDetail.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiPbm {
    'exists'?: boolean;
    'name'?: string;
    'phone'?: string;
    'plans'?: Array<PharmacyBiResponseDetail>;
    'payerId'?: string;
    'policyId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiPbm>> = [
        {
            "name":     "exists",
            "baseName": "exists",
            "type":     "boolean"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "phone",
            "baseName": "phone",
            "type":     "string"
        },
        {
            "name":     "plans",
            "baseName": "plans",
            "type":     "Array<PharmacyBiResponseDetail>"
        },
        {
            "name":     "payerId",
            "baseName": "payerId",
            "type":     "string"
        },
        {
            "name":     "policyId",
            "baseName": "policyId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiPbm.attributeTypeMap;
    }
}
