// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description What does the new Enrollment cover?
*/
export class EnrollmentV2ProcessingBenefitModel {
    /**
    * @description Start Date
    */
    'startDate'?: Moment | null;
    /**
    * @description End Date
    */
    'endDate'?: Moment | null;
    /**
    * @description Claim Eligibility Date
    */
    'claimEligibilityDate'?: Moment | null;
    /**
    * @description This value represents the calculated configured Maximum Benefit amount.  This does not take into account an accumulator\\\'s actual balance.
    */
    'maxBenefit'?: number | null;
    /**
    * @description Benefit Amount not limited by Accumulator :)
    */
    'hasUntrackedAccumulator'?: boolean;
    /**
    * @description Represents the date we would queue the enrollment to be processed on, null  if we would not queue it
    */
    'queuedProcessingDate'?: Moment | null;
    /**
    * @description Represents the reason why we would queue the enrollment to be processed later  null if we would not queue it
    */
    'queuedProcessingReason'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnrollmentV2ProcessingBenefitModel>> = [
        {
            "name":     "startDate",
            "baseName": "startDate",
            "type":     "Date"
        },
        {
            "name":     "endDate",
            "baseName": "endDate",
            "type":     "Date"
        },
        {
            "name":     "claimEligibilityDate",
            "baseName": "claimEligibilityDate",
            "type":     "Date"
        },
        {
            "name":     "maxBenefit",
            "baseName": "maxBenefit",
            "type":     "number"
        },
        {
            "name":     "hasUntrackedAccumulator",
            "baseName": "hasUntrackedAccumulator",
            "type":     "boolean"
        },
        {
            "name":     "queuedProcessingDate",
            "baseName": "queuedProcessingDate",
            "type":     "Date"
        },
        {
            "name":     "queuedProcessingReason",
            "baseName": "queuedProcessingReason",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return EnrollmentV2ProcessingBenefitModel.attributeTypeMap;
    }
}
