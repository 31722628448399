// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class UpdateTaskRequestModel {
    'taskId': number;
    'subject'?: string;
    'body'?: string;
    'dueDate'?: Moment | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof UpdateTaskRequestModel>> = [
        {
            "name":     "taskId",
            "baseName": "taskId",
            "type":     "number"
        },
        {
            "name":     "subject",
            "baseName": "subject",
            "type":     "string"
        },
        {
            "name":     "body",
            "baseName": "body",
            "type":     "string"
        },
        {
            "name":     "dueDate",
            "baseName": "dueDate",
            "type":     "Date"
        },
    ];

    static getAttributeTypeMap() {
        return UpdateTaskRequestModel.attributeTypeMap;
    }
}
