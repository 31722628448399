// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ContactInfoType } from './contactInfoType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Model for Account Site Contact
*/
export class SitePointOfContactModel {
    /**
    * @description First Name
    */
    'firstName'?: string | null;
    /**
    * @description Last Name
    */
    'lastName'?: string | null;
    /**
    * @description Job Title
    */
    'jobTitle'?: string | null;
    /**
    * @description Phone Number
    */
    'phoneNumber'?: string | null;
    /**
    * @description Phone Number Extension
    */
    'phoneNumberExtension'?: string | null;
    /**
    * @description Fax Number
    */
    'faxNumber'?: string | null;
    /**
    * @description E-mail Address
    */
    'emailAddress'?: string | null;
    'preferredContactMethod'?: ContactInfoType;
    /**
    * @description Procurement Method
    */
    'procurementMethodTypeId'?: number | null;
    /**
    * @description Office Contact Note
    */
    'officeContactNote'?: string | null;
    /**
    * @description Preferred Contact Methods
    */
    'preferredContactMethods'?: Array<ContactInfoType> | null;
    /**
    * @description Account Site Contact identifier
    */
    'id'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SitePointOfContactModel>> = [
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "jobTitle",
            "baseName": "jobTitle",
            "type":     "string"
        },
        {
            "name":     "phoneNumber",
            "baseName": "phoneNumber",
            "type":     "string"
        },
        {
            "name":     "phoneNumberExtension",
            "baseName": "phoneNumberExtension",
            "type":     "string"
        },
        {
            "name":     "faxNumber",
            "baseName": "faxNumber",
            "type":     "string"
        },
        {
            "name":     "emailAddress",
            "baseName": "emailAddress",
            "type":     "string"
        },
        {
            "name":     "preferredContactMethod",
            "baseName": "preferredContactMethod",
            "type":     "ContactInfoType"
        },
        {
            "name":     "procurementMethodTypeId",
            "baseName": "procurementMethodTypeId",
            "type":     "number"
        },
        {
            "name":     "officeContactNote",
            "baseName": "officeContactNote",
            "type":     "string"
        },
        {
            "name":     "preferredContactMethods",
            "baseName": "preferredContactMethods",
            "type":     "Array<ContactInfoType>"
        },
        {
            "name":     "id",
            "baseName": "id",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return SitePointOfContactModel.attributeTypeMap;
    }
}
