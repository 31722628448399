// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AddressType } from './addressType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class TrialcardPatientV3ModelsSharedAddressModel {
    'addressId'?: number;
    'addressType'?: AddressType;
    'addressOne'?: string | null;
    'addressTwo'?: string | null;
    'city'?: string | null;
    'state'?: string | null;
    'zip'?: string | null;
    'country'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof TrialcardPatientV3ModelsSharedAddressModel>> = [
        {
            "name":     "addressId",
            "baseName": "addressId",
            "type":     "number"
        },
        {
            "name":     "addressType",
            "baseName": "addressType",
            "type":     "AddressType"
        },
        {
            "name":     "addressOne",
            "baseName": "addressOne",
            "type":     "string"
        },
        {
            "name":     "addressTwo",
            "baseName": "addressTwo",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "zip",
            "baseName": "zip",
            "type":     "string"
        },
        {
            "name":     "country",
            "baseName": "country",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return TrialcardPatientV3ModelsSharedAddressModel.attributeTypeMap;
    }
}
