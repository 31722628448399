// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RoleType } from './roleType.js';
import type { SiteProcurementMethodModel } from './siteProcurementMethodModel.js';
import type { SiteSharedAddressModel } from './siteSharedAddressModel.js';
import type { SiteSharedContactPreferenceModel } from './siteSharedContactPreferenceModel.js';
import type { SiteSharedEmailAddressModel } from './siteSharedEmailAddressModel.js';
import type { SiteSharedExternalIdModel } from './siteSharedExternalIdModel.js';
import type { SiteSharedGlobalIdModel } from './siteSharedGlobalIdModel.js';
import type { SiteSharedPhoneModel } from './siteSharedPhoneModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SiteModel {
    'siteId'?: number;
    'externalIds'?: Array<SiteSharedExternalIdModel>;
    'addresses'?: Array<SiteSharedAddressModel>;
    'phoneNumbers'?: Array<SiteSharedPhoneModel>;
    'emailAddresses'?: Array<SiteSharedEmailAddressModel>;
    'contactPreferences'?: Array<SiteSharedContactPreferenceModel>;
    'procurementMethods'?: Array<SiteProcurementMethodModel>;
    'globalIds'?: Array<SiteSharedGlobalIdModel>;
    'siteRoleType'?: RoleType;
    'description'?: string;
    'nickName'?: string;
    'businessStartTime'?: Moment;
    'businessEndTime'?: Moment;
    'siteNote'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SiteModel>> = [
        {
            "name":     "siteId",
            "baseName": "siteId",
            "type":     "number"
        },
        {
            "name":     "externalIds",
            "baseName": "externalIds",
            "type":     "Array<SiteSharedExternalIdModel>"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<SiteSharedAddressModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<SiteSharedPhoneModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<SiteSharedEmailAddressModel>"
        },
        {
            "name":     "contactPreferences",
            "baseName": "contactPreferences",
            "type":     "Array<SiteSharedContactPreferenceModel>"
        },
        {
            "name":     "procurementMethods",
            "baseName": "procurementMethods",
            "type":     "Array<SiteProcurementMethodModel>"
        },
        {
            "name":     "globalIds",
            "baseName": "globalIds",
            "type":     "Array<SiteSharedGlobalIdModel>"
        },
        {
            "name":     "siteRoleType",
            "baseName": "siteRoleType",
            "type":     "RoleType"
        },
        {
            "name":     "description",
            "baseName": "description",
            "type":     "string"
        },
        {
            "name":     "nickName",
            "baseName": "nickName",
            "type":     "string"
        },
        {
            "name":     "businessStartTime",
            "baseName": "businessStartTime",
            "type":     "Date"
        },
        {
            "name":     "businessEndTime",
            "baseName": "businessEndTime",
            "type":     "Date"
        },
        {
            "name":     "siteNote",
            "baseName": "siteNote",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return SiteModel.attributeTypeMap;
    }
}
