// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EnterpriseCoordinatorPharmacyServiceModelsPharmacyPointOfContactUpsearchModel } from './enterpriseCoordinatorPharmacyServiceModelsPharmacyPointOfContactUpsearchModel.js';
import type { EnterpriseCoordinatorSharedAddressUpsearchModel } from './enterpriseCoordinatorSharedAddressUpsearchModel.js';
import type { EnterpriseCoordinatorSharedContactUpsearchModel } from './enterpriseCoordinatorSharedContactUpsearchModel.js';
import type { EnterpriseCoordinatorSharedEmailAddressUpsearchModel } from './enterpriseCoordinatorSharedEmailAddressUpsearchModel.js';
import type { EnterpriseCoordinatorSharedPhoneUpsearchModel } from './enterpriseCoordinatorSharedPhoneUpsearchModel.js';
import type { EnterpriseCoordinatorSharedWebAddressUpsearchModel } from './enterpriseCoordinatorSharedWebAddressUpsearchModel.js';
import type { PharmacySpecialization } from './pharmacySpecialization.js';
import type { PharmacyType } from './pharmacyType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EnterpriseCoordinatorPharmacyServiceModelsPharmacyUpsearchModel {
    'pharmacyId'?: number;
    'pharmacyName'?: string;
    'nabp'?: string;
    'npi'?: string;
    'pharmacyType'?: PharmacyType;
    'pharmacySpecialization'?: PharmacySpecialization;
    'accountRelationshipIsPrimary'?: boolean;
    'addresses'?: Array<EnterpriseCoordinatorSharedAddressUpsearchModel>;
    'phoneNumbers'?: Array<EnterpriseCoordinatorSharedPhoneUpsearchModel>;
    'emailAddresses'?: Array<EnterpriseCoordinatorSharedEmailAddressUpsearchModel>;
    'webAddresses'?: Array<EnterpriseCoordinatorSharedWebAddressUpsearchModel>;
    'contacts'?: Array<EnterpriseCoordinatorSharedContactUpsearchModel>;
    'pointOfContacts'?: Array<EnterpriseCoordinatorPharmacyServiceModelsPharmacyPointOfContactUpsearchModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnterpriseCoordinatorPharmacyServiceModelsPharmacyUpsearchModel>> = [
        {
            "name":     "pharmacyId",
            "baseName": "pharmacyId",
            "type":     "number"
        },
        {
            "name":     "pharmacyName",
            "baseName": "pharmacyName",
            "type":     "string"
        },
        {
            "name":     "nabp",
            "baseName": "nabp",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "pharmacyType",
            "baseName": "pharmacyType",
            "type":     "PharmacyType"
        },
        {
            "name":     "pharmacySpecialization",
            "baseName": "pharmacySpecialization",
            "type":     "PharmacySpecialization"
        },
        {
            "name":     "accountRelationshipIsPrimary",
            "baseName": "accountRelationshipIsPrimary",
            "type":     "boolean"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<EnterpriseCoordinatorSharedAddressUpsearchModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<EnterpriseCoordinatorSharedPhoneUpsearchModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<EnterpriseCoordinatorSharedEmailAddressUpsearchModel>"
        },
        {
            "name":     "webAddresses",
            "baseName": "webAddresses",
            "type":     "Array<EnterpriseCoordinatorSharedWebAddressUpsearchModel>"
        },
        {
            "name":     "contacts",
            "baseName": "contacts",
            "type":     "Array<EnterpriseCoordinatorSharedContactUpsearchModel>"
        },
        {
            "name":     "pointOfContacts",
            "baseName": "pointOfContacts",
            "type":     "Array<EnterpriseCoordinatorPharmacyServiceModelsPharmacyPointOfContactUpsearchModel>"
        },
    ];

    static getAttributeTypeMap() {
        return EnterpriseCoordinatorPharmacyServiceModelsPharmacyUpsearchModel.attributeTypeMap;
    }
}
