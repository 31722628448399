// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Gender } from './gender.js';
import type { PatientAccountPatientRegistrationIPatientRegistrationUserContactInfoRequestModel } from './patientAccountPatientRegistrationIPatientRegistrationUserContactInfoRequestModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class IPatientRegistrationRequestModel {
    'userName': string;
    'firstName': string;
    'lastName': string;
    'phone': PatientAccountPatientRegistrationIPatientRegistrationUserContactInfoRequestModel | null;
    'fax': PatientAccountPatientRegistrationIPatientRegistrationUserContactInfoRequestModel | null;
    'email': PatientAccountPatientRegistrationIPatientRegistrationUserContactInfoRequestModel | null;
    'dob': Moment;
    'gender': Gender;
    'zip': string;
    'password': string;
    'sendEmail': boolean;
    'externalId': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof IPatientRegistrationRequestModel>> = [
        {
            "name":     "userName",
            "baseName": "userName",
            "type":     "string"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "phone",
            "baseName": "phone",
            "type":     "PatientAccountPatientRegistrationIPatientRegistrationUserContactInfoRequestModel"
        },
        {
            "name":     "fax",
            "baseName": "fax",
            "type":     "PatientAccountPatientRegistrationIPatientRegistrationUserContactInfoRequestModel"
        },
        {
            "name":     "email",
            "baseName": "email",
            "type":     "PatientAccountPatientRegistrationIPatientRegistrationUserContactInfoRequestModel"
        },
        {
            "name":     "dob",
            "baseName": "dob",
            "type":     "Date"
        },
        {
            "name":     "gender",
            "baseName": "gender",
            "type":     "Gender"
        },
        {
            "name":     "zip",
            "baseName": "zip",
            "type":     "string"
        },
        {
            "name":     "password",
            "baseName": "password",
            "type":     "string"
        },
        {
            "name":     "sendEmail",
            "baseName": "sendEmail",
            "type":     "boolean"
        },
        {
            "name":     "externalId",
            "baseName": "externalId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return IPatientRegistrationRequestModel.attributeTypeMap;
    }
}
