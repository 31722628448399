// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { DebitCardMapStatus } from './debitCardMapStatus.js';
import type { DebitCardVmcUsageRegion } from './debitCardVmcUsageRegion.js';
import type { InternalDebitCardStatus } from './internalDebitCardStatus.js';
import type { ProcessorType } from './processorType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class DebitCardVmcEncryptedPciInfoResponseModel {
    'vmcValidThroughDate'?: string;
    'vmcNumber'?: string;
    'debitCardMapStatus'?: DebitCardMapStatus;
    'thirdPartyProcessorType'?: ProcessorType;
    'thirdPartyCardStatus'?: string;
    'thirdPartyCardStatusDescription'?: string;
    'internalDebitCardStatus'?: InternalDebitCardStatus;
    'effectiveDate'?: Moment;
    'expirationDate'?: Moment;
    'balance'?: number;
    'vmcNumberOfUses'?: number;
    'vmcCvc'?: string;
    'vmcDollarAmount'?: number;
    'vmcUsageRegion'?: DebitCardVmcUsageRegion;
    'vmcExactMatch'?: boolean;
    'vmcUsedCount'?: number;
    'vmcMcmgs'?: Array<string>;
    'vmcVendorAccountNumber'?: string;
    'cardPromotionId'?: number;
    'cardPromotion'?: string;
    'vmcCardCts'?: string;
    'firstName'?: string;
    'lastName'?: string;
    'middleName'?: string;
    'zipCode'?: string;
    'address'?: string;
    'country'?: string;
    'state'?: string;
    'city'?: string;
    'accountNumber'?: string;
    'last4'?: string;
    'tcMemberNumber'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof DebitCardVmcEncryptedPciInfoResponseModel>> = [
        {
            "name":     "vmcValidThroughDate",
            "baseName": "vmcValidThroughDate",
            "type":     "string"
        },
        {
            "name":     "vmcNumber",
            "baseName": "vmcNumber",
            "type":     "string"
        },
        {
            "name":     "debitCardMapStatus",
            "baseName": "debitCardMapStatus",
            "type":     "DebitCardMapStatus"
        },
        {
            "name":     "thirdPartyProcessorType",
            "baseName": "thirdPartyProcessorType",
            "type":     "ProcessorType"
        },
        {
            "name":     "thirdPartyCardStatus",
            "baseName": "thirdPartyCardStatus",
            "type":     "string"
        },
        {
            "name":     "thirdPartyCardStatusDescription",
            "baseName": "thirdPartyCardStatusDescription",
            "type":     "string"
        },
        {
            "name":     "internalDebitCardStatus",
            "baseName": "internalDebitCardStatus",
            "type":     "InternalDebitCardStatus"
        },
        {
            "name":     "effectiveDate",
            "baseName": "effectiveDate",
            "type":     "Date"
        },
        {
            "name":     "expirationDate",
            "baseName": "expirationDate",
            "type":     "Date"
        },
        {
            "name":     "balance",
            "baseName": "balance",
            "type":     "number"
        },
        {
            "name":     "vmcNumberOfUses",
            "baseName": "vmcNumberOfUses",
            "type":     "number"
        },
        {
            "name":     "vmcCvc",
            "baseName": "vmcCvc",
            "type":     "string"
        },
        {
            "name":     "vmcDollarAmount",
            "baseName": "vmcDollarAmount",
            "type":     "number"
        },
        {
            "name":     "vmcUsageRegion",
            "baseName": "vmcUsageRegion",
            "type":     "DebitCardVmcUsageRegion"
        },
        {
            "name":     "vmcExactMatch",
            "baseName": "vmcExactMatch",
            "type":     "boolean"
        },
        {
            "name":     "vmcUsedCount",
            "baseName": "vmcUsedCount",
            "type":     "number"
        },
        {
            "name":     "vmcMcmgs",
            "baseName": "vmcMcmgs",
            "type":     "Array<string>"
        },
        {
            "name":     "vmcVendorAccountNumber",
            "baseName": "vmcVendorAccountNumber",
            "type":     "string"
        },
        {
            "name":     "cardPromotionId",
            "baseName": "cardPromotionId",
            "type":     "number"
        },
        {
            "name":     "cardPromotion",
            "baseName": "cardPromotion",
            "type":     "string"
        },
        {
            "name":     "vmcCardCts",
            "baseName": "vmcCardCts",
            "type":     "string"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "middleName",
            "baseName": "middleName",
            "type":     "string"
        },
        {
            "name":     "zipCode",
            "baseName": "zipCode",
            "type":     "string"
        },
        {
            "name":     "address",
            "baseName": "address",
            "type":     "string"
        },
        {
            "name":     "country",
            "baseName": "country",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "accountNumber",
            "baseName": "accountNumber",
            "type":     "string"
        },
        {
            "name":     "last4",
            "baseName": "last4",
            "type":     "string"
        },
        {
            "name":     "tcMemberNumber",
            "baseName": "tcMemberNumber",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return DebitCardVmcEncryptedPciInfoResponseModel.attributeTypeMap;
    }
}
