// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description External Reference Model
*/
export class ClaimExternalReferenceModel {
    /**
    * @description External Reference Id
    */
    'externalReferenceTypeId'?: number;
    /**
    * @description External Reference Value
    */
    'externalValue'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimExternalReferenceModel>> = [
        {
            "name":     "externalReferenceTypeId",
            "baseName": "externalReferenceTypeId",
            "type":     "number"
        },
        {
            "name":     "externalValue",
            "baseName": "externalValue",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimExternalReferenceModel.attributeTypeMap;
    }
}
