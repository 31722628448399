// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { TCQpSignatureModelAttestation } from './tCQpSignatureModelAttestation.js';
import type { TCQpSignatureModelDocument } from './tCQpSignatureModelDocument.js';
import type { TCQpSignatureModelField } from './tCQpSignatureModelField.js';
import type { TCQpSignatureModelRecipient } from './tCQpSignatureModelRecipient.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EmailSignaturePdfRequest {
    'brandId': string | null;
    'senderId': string | null;
    'document': TCQpSignatureModelDocument | null;
    'carbonCopies': Array<TCQpSignatureModelRecipient>;
    'recipients': Array<TCQpSignatureModelRecipient>;
    'emailSubject': string;
    'emailBody': string;
    'fields': Array<TCQpSignatureModelField> | null;
    'documentType': string;
    'attestations': Array<TCQpSignatureModelAttestation> | null;
    'expireInDays': number | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EmailSignaturePdfRequest>> = [
        {
            "name":     "brandId",
            "baseName": "brandId",
            "type":     "string"
        },
        {
            "name":     "senderId",
            "baseName": "senderId",
            "type":     "string"
        },
        {
            "name":     "document",
            "baseName": "document",
            "type":     "TCQpSignatureModelDocument"
        },
        {
            "name":     "carbonCopies",
            "baseName": "carbonCopies",
            "type":     "Array<TCQpSignatureModelRecipient>"
        },
        {
            "name":     "recipients",
            "baseName": "recipients",
            "type":     "Array<TCQpSignatureModelRecipient>"
        },
        {
            "name":     "emailSubject",
            "baseName": "emailSubject",
            "type":     "string"
        },
        {
            "name":     "emailBody",
            "baseName": "emailBody",
            "type":     "string"
        },
        {
            "name":     "fields",
            "baseName": "fields",
            "type":     "Array<TCQpSignatureModelField>"
        },
        {
            "name":     "documentType",
            "baseName": "documentType",
            "type":     "string"
        },
        {
            "name":     "attestations",
            "baseName": "attestations",
            "type":     "Array<TCQpSignatureModelAttestation>"
        },
        {
            "name":     "expireInDays",
            "baseName": "expireInDays",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return EmailSignaturePdfRequest.attributeTypeMap;
    }
}
