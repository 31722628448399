// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BasicEnrollmentAddress } from './basicEnrollmentAddress.js';
import type { RoleType } from './roleType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class BasicEnrollmentPharmacyPayor {
    'bin'?: string;
    'pcn'?: string;
    'name'?: string;
    'phoneNumber'?: string;
    'phoneNumberExtension'?: string;
    'faxNumber'?: string;
    'faxNumberExtension'?: string;
    'address'?: BasicEnrollmentAddress | null;
    'groupNumber'?: string;
    'policyNumber'?: string;
    'planEffectiveDate'?: Moment;
    'planExpirationDate'?: Moment;
    'planTerminationDate'?: Moment;
    'policyName'?: string;
    'policyHolderFirstName'?: string;
    'policyHolderLastName'?: string;
    'relationshipToPatient'?: RoleType;
    'isPlaceholder'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof BasicEnrollmentPharmacyPayor>> = [
        {
            "name":     "bin",
            "baseName": "bin",
            "type":     "string"
        },
        {
            "name":     "pcn",
            "baseName": "pcn",
            "type":     "string"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "phoneNumber",
            "baseName": "phoneNumber",
            "type":     "string"
        },
        {
            "name":     "phoneNumberExtension",
            "baseName": "phoneNumberExtension",
            "type":     "string"
        },
        {
            "name":     "faxNumber",
            "baseName": "faxNumber",
            "type":     "string"
        },
        {
            "name":     "faxNumberExtension",
            "baseName": "faxNumberExtension",
            "type":     "string"
        },
        {
            "name":     "address",
            "baseName": "address",
            "type":     "BasicEnrollmentAddress"
        },
        {
            "name":     "groupNumber",
            "baseName": "groupNumber",
            "type":     "string"
        },
        {
            "name":     "policyNumber",
            "baseName": "policyNumber",
            "type":     "string"
        },
        {
            "name":     "planEffectiveDate",
            "baseName": "planEffectiveDate",
            "type":     "Date"
        },
        {
            "name":     "planExpirationDate",
            "baseName": "planExpirationDate",
            "type":     "Date"
        },
        {
            "name":     "planTerminationDate",
            "baseName": "planTerminationDate",
            "type":     "Date"
        },
        {
            "name":     "policyName",
            "baseName": "policyName",
            "type":     "string"
        },
        {
            "name":     "policyHolderFirstName",
            "baseName": "policyHolderFirstName",
            "type":     "string"
        },
        {
            "name":     "policyHolderLastName",
            "baseName": "policyHolderLastName",
            "type":     "string"
        },
        {
            "name":     "relationshipToPatient",
            "baseName": "relationshipToPatient",
            "type":     "RoleType"
        },
        {
            "name":     "isPlaceholder",
            "baseName": "isPlaceholder",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return BasicEnrollmentPharmacyPayor.attributeTypeMap;
    }
}
