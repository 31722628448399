// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PayorSharedAddressUpsearchModel } from './payorSharedAddressUpsearchModel.js';
import type { PayorSharedContactUpsearchModel } from './payorSharedContactUpsearchModel.js';
import type { PayorSharedEmailAddressUpsearchModel } from './payorSharedEmailAddressUpsearchModel.js';
import type { PayorSharedPhoneUpsearchModel } from './payorSharedPhoneUpsearchModel.js';
import type { PayorSharedWebAddressUpsearchModel } from './payorSharedWebAddressUpsearchModel.js';
import type { PayorType } from './payorType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PayorUpsearchModel {
    'payorId'?: number;
    'addresses'?: Array<PayorSharedAddressUpsearchModel>;
    'contacts'?: Array<PayorSharedContactUpsearchModel>;
    'emailAddresses'?: Array<PayorSharedEmailAddressUpsearchModel>;
    'phoneNumbers'?: Array<PayorSharedPhoneUpsearchModel>;
    'webAddresses'?: Array<PayorSharedWebAddressUpsearchModel>;
    'payorName'?: string;
    'payorType'?: PayorType;
    'payorBin'?: string;
    'payorPcn'?: string;
    'payorGroup'?: string;
    'isPlaceholder'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PayorUpsearchModel>> = [
        {
            "name":     "payorId",
            "baseName": "payorId",
            "type":     "number"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<PayorSharedAddressUpsearchModel>"
        },
        {
            "name":     "contacts",
            "baseName": "contacts",
            "type":     "Array<PayorSharedContactUpsearchModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<PayorSharedEmailAddressUpsearchModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<PayorSharedPhoneUpsearchModel>"
        },
        {
            "name":     "webAddresses",
            "baseName": "webAddresses",
            "type":     "Array<PayorSharedWebAddressUpsearchModel>"
        },
        {
            "name":     "payorName",
            "baseName": "payorName",
            "type":     "string"
        },
        {
            "name":     "payorType",
            "baseName": "payorType",
            "type":     "PayorType"
        },
        {
            "name":     "payorBin",
            "baseName": "payorBin",
            "type":     "string"
        },
        {
            "name":     "payorPcn",
            "baseName": "payorPcn",
            "type":     "string"
        },
        {
            "name":     "payorGroup",
            "baseName": "payorGroup",
            "type":     "string"
        },
        {
            "name":     "isPlaceholder",
            "baseName": "isPlaceholder",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return PayorUpsearchModel.attributeTypeMap;
    }
}
