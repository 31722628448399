// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { MedicalBiRequestSearchBase } from './medicalBiRequestSearchBase.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiRequestSearchResponse {
    'requests': Array<MedicalBiRequestSearchBase>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiRequestSearchResponse>> = [
        {
            "name":     "requests",
            "baseName": "requests",
            "type":     "Array<MedicalBiRequestSearchBase>"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiRequestSearchResponse.attributeTypeMap;
    }
}
