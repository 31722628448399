// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AccountOrderRelationModel } from './accountOrderRelationModel.js';
import type { RoleType } from './roleType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Model for Account-to-Payor Type Relations
*/
export class AccountPayorRelationModel {
    'roleType'?: RoleType;
    /**
    * @description List of Account Relation Model objects
    */
    'accountRelations'?: Array<AccountOrderRelationModel> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof AccountPayorRelationModel>> = [
        {
            "name":     "roleType",
            "baseName": "roleType",
            "type":     "RoleType"
        },
        {
            "name":     "accountRelations",
            "baseName": "accountRelations",
            "type":     "Array<AccountOrderRelationModel>"
        },
    ];

    static getAttributeTypeMap() {
        return AccountPayorRelationModel.attributeTypeMap;
    }
}
