// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { DigitalpaymentBankAccountDetail } from './digitalpaymentBankAccountDetail.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description External account information
*/
export class DigitalpaymentExternalAccountDetail {
    'bankAccount'?: DigitalpaymentBankAccountDetail | null;
    'externalAccountAliasId'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof DigitalpaymentExternalAccountDetail>> = [
        {
            "name":     "bankAccount",
            "baseName": "bankAccount",
            "type":     "DigitalpaymentBankAccountDetail"
        },
        {
            "name":     "externalAccountAliasId",
            "baseName": "externalAccountAliasId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return DigitalpaymentExternalAccountDetail.attributeTypeMap;
    }
}
