// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Prior Authorization data, used to update Prior Authorization status  This is inbound object so it uses CMM naming convention
*/
export class CoverMyMedsInboundPriorAuthorizationProcessStatusRequestModel {
    /**
    * @description The patient’s last name
    */
    'patient_last_name'?: string | null;
    /**
    * @description CoverMyMeds prior authorization status code TC.Enterprise.CoverMyMeds.Service.Public.PriorAuthorizationStatus
    */
    'pa_status'?: string | null;
    /**
    * @description CoverMyMeds case key or secure patient ID for TC initiated
    */
    'patient_identifier'?: string | null;
    /**
    * @description CoverMyMeds\\\' unique request key; Will be saved as TC case external ID (CMM PAKey)
    */
    'pa_key'?: string | null;
    /**
    * @description Name of drug
    */
    'drug_name'?: string | null;
    /**
    * @description Quantity of drug
    */
    'quantity'?: string | null;
    /**
    * @description Days supply of drug
    */
    'days_supply'?: string | null;
    /**
    * @description Date of PA expiration (when available)
    */
    'pa_expiration_date'?: string | null;
    /**
    * @description Indicates whether this PA is an appeal of a previous PA, indicated by PA key presence in this field; If null, this PA is not an appeal of another PA  Example: \\\"ABC123\\\" when this PA is an appeal or null when it is not an appeal
    */
    'appeal_of'?: string | null;
    /**
    * @description A list of all PA keys that are appeals of this PA
    */
    'appeals'?: Array<string> | null;
    /**
    * @description Indicates whether CMM has exhausted outreach to an HCP or patient
    */
    'is_outreach_exhausted'?: boolean | null;
    /**
    * @description External PA Identifier; Null when CMM initiated, CaseDisplayId for TC initiated ones.
    */
    'external_pa_identifier'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof CoverMyMedsInboundPriorAuthorizationProcessStatusRequestModel>> = [
        {
            "name":     "patient_last_name",
            "baseName": "patient_last_name",
            "type":     "string"
        },
        {
            "name":     "pa_status",
            "baseName": "pa_status",
            "type":     "string"
        },
        {
            "name":     "patient_identifier",
            "baseName": "patient_identifier",
            "type":     "string"
        },
        {
            "name":     "pa_key",
            "baseName": "pa_key",
            "type":     "string"
        },
        {
            "name":     "drug_name",
            "baseName": "drug_name",
            "type":     "string"
        },
        {
            "name":     "quantity",
            "baseName": "quantity",
            "type":     "string"
        },
        {
            "name":     "days_supply",
            "baseName": "days_supply",
            "type":     "string"
        },
        {
            "name":     "pa_expiration_date",
            "baseName": "pa_expiration_date",
            "type":     "string"
        },
        {
            "name":     "appeal_of",
            "baseName": "appeal_of",
            "type":     "string"
        },
        {
            "name":     "appeals",
            "baseName": "appeals",
            "type":     "Array<string>"
        },
        {
            "name":     "is_outreach_exhausted",
            "baseName": "is_outreach_exhausted",
            "type":     "boolean"
        },
        {
            "name":     "external_pa_identifier",
            "baseName": "external_pa_identifier",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return CoverMyMedsInboundPriorAuthorizationProcessStatusRequestModel.attributeTypeMap;
    }
}
