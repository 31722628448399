// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiPlan {
    'gracePeriod'?: string;
    'hasGracePeriod'?: boolean;
    'isSet'?: boolean;
    'paidThroughDate'?: Moment;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiPlan>> = [
        {
            "name":     "gracePeriod",
            "baseName": "gracePeriod",
            "type":     "string"
        },
        {
            "name":     "hasGracePeriod",
            "baseName": "hasGracePeriod",
            "type":     "boolean"
        },
        {
            "name":     "isSet",
            "baseName": "isSet",
            "type":     "boolean"
        },
        {
            "name":     "paidThroughDate",
            "baseName": "paidThroughDate",
            "type":     "Date"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiPlan.attributeTypeMap;
    }
}
