// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ContactInfoType } from './contactInfoType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EnterpriseCoordinatorAccountServiceModelsAccountPhoneUpsearchModel {
    'id'?: number;
    'phoneType'?: ContactInfoType;
    'phoneNumber'?: string;
    'extension'?: string;
    'isVoicemailOptedIn'?: boolean;
    'isPrimary'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnterpriseCoordinatorAccountServiceModelsAccountPhoneUpsearchModel>> = [
        {
            "name":     "id",
            "baseName": "id",
            "type":     "number"
        },
        {
            "name":     "phoneType",
            "baseName": "phoneType",
            "type":     "ContactInfoType"
        },
        {
            "name":     "phoneNumber",
            "baseName": "phoneNumber",
            "type":     "string"
        },
        {
            "name":     "extension",
            "baseName": "extension",
            "type":     "string"
        },
        {
            "name":     "isVoicemailOptedIn",
            "baseName": "isVoicemailOptedIn",
            "type":     "boolean"
        },
        {
            "name":     "isPrimary",
            "baseName": "isPrimary",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return EnterpriseCoordinatorAccountServiceModelsAccountPhoneUpsearchModel.attributeTypeMap;
    }
}
