// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PorticoPortalGroupModel {
    'id'?: string;
    'name'?: string;
    'createdBy'?: number;
    'createdOn'?: Moment;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PorticoPortalGroupModel>> = [
        {
            "name":     "id",
            "baseName": "id",
            "type":     "string"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "createdBy",
            "baseName": "createdBy",
            "type":     "number"
        },
        {
            "name":     "createdOn",
            "baseName": "createdOn",
            "type":     "Date"
        },
    ];

    static getAttributeTypeMap() {
        return PorticoPortalGroupModel.attributeTypeMap;
    }
}
