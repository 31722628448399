// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class BasicEnrollmentMemberNumberDetails {
    'bin'?: string;
    'groupNumber'?: string;
    'pcn'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof BasicEnrollmentMemberNumberDetails>> = [
        {
            "name":     "bin",
            "baseName": "bin",
            "type":     "string"
        },
        {
            "name":     "groupNumber",
            "baseName": "groupNumber",
            "type":     "string"
        },
        {
            "name":     "pcn",
            "baseName": "pcn",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return BasicEnrollmentMemberNumberDetails.attributeTypeMap;
    }
}
