// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class NpiPracticeLocationResponseModel {
    'country_code'?: string;
    'country_name'?: string;
    'address_purpose'?: string;
    'address_type'?: string;
    'address_1'?: string;
    'address_2'?: string;
    'city'?: string;
    'state'?: string;
    'postal_code'?: string;
    'telephone_number'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof NpiPracticeLocationResponseModel>> = [
        {
            "name":     "country_code",
            "baseName": "country_code",
            "type":     "string"
        },
        {
            "name":     "country_name",
            "baseName": "country_name",
            "type":     "string"
        },
        {
            "name":     "address_purpose",
            "baseName": "address_purpose",
            "type":     "string"
        },
        {
            "name":     "address_type",
            "baseName": "address_type",
            "type":     "string"
        },
        {
            "name":     "address_1",
            "baseName": "address_1",
            "type":     "string"
        },
        {
            "name":     "address_2",
            "baseName": "address_2",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "postal_code",
            "baseName": "postal_code",
            "type":     "string"
        },
        {
            "name":     "telephone_number",
            "baseName": "telephone_number",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return NpiPracticeLocationResponseModel.attributeTypeMap;
    }
}
