// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MangoHubPhone {
    'phoneTypeDescription': string;
    'phoneNumber': string;
    'extension': string;
    'bestDaysToCall': string;
    'bestTimeToCall': string;
    'language': string;
    'timeZone': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MangoHubPhone>> = [
        {
            "name":     "phoneTypeDescription",
            "baseName": "phoneTypeDescription",
            "type":     "string"
        },
        {
            "name":     "phoneNumber",
            "baseName": "phoneNumber",
            "type":     "string"
        },
        {
            "name":     "extension",
            "baseName": "extension",
            "type":     "string"
        },
        {
            "name":     "bestDaysToCall",
            "baseName": "bestDaysToCall",
            "type":     "string"
        },
        {
            "name":     "bestTimeToCall",
            "baseName": "bestTimeToCall",
            "type":     "string"
        },
        {
            "name":     "language",
            "baseName": "language",
            "type":     "string"
        },
        {
            "name":     "timeZone",
            "baseName": "timeZone",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MangoHubPhone.attributeTypeMap;
    }
}
