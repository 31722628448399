// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { LocationValidAddressModel } from './locationValidAddressModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class LocationValidAddressResponseModel {
    'addresses'?: Array<LocationValidAddressModel>;
    'hasMatches'?: boolean;
    'hasMultipleMatches'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof LocationValidAddressResponseModel>> = [
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<LocationValidAddressModel>"
        },
        {
            "name":     "hasMatches",
            "baseName": "hasMatches",
            "type":     "boolean"
        },
        {
            "name":     "hasMultipleMatches",
            "baseName": "hasMultipleMatches",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return LocationValidAddressResponseModel.attributeTypeMap;
    }
}
