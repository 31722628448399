// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiPayorResult {
    'agentName'?: string;
    'hasSecondaryInsurance'?: boolean;
    'hasStandardPlanLetter'?: boolean;
    'inNetworkConsideration'?: string;
    'isAccumulatorPlan'?: boolean;
    'isMaximizerPlan'?: boolean;
    'newPlanAvailable'?: boolean;
    'newPlanEffectiveDate'?: string;
    'newPlanSubscriberId'?: string;
    'phone'?: string;
    'planEffectiveDate'?: Moment;
    'planFundType'?: string;
    'planName'?: string;
    'planPriority'?: number;
    'planRenewalMonth'?: string;
    'planRenewalType'?: string;
    'planTerminationDate'?: Moment;
    'planType'?: string;
    'policyType'?: string;
    'referenceId'?: string;
    'secondaryInsuranceName'?: string;
    'standardPlanLetter'?: string;
    'willCoverIfPrimaryDenies'?: string;
    'willCoverPartBDeductible'?: string;
    'planCode'?: string;
    'isDeductibleMetBeforeCob'?: string;
    'isOopMetBeforeCob'?: string;
    'groupId'?: string;
    'groupName'?: string;
    'id': string;
    'memberId': string;
    'name'?: string;
    'otherInsuranceStatus'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiPayorResult>> = [
        {
            "name":     "agentName",
            "baseName": "agentName",
            "type":     "string"
        },
        {
            "name":     "hasSecondaryInsurance",
            "baseName": "hasSecondaryInsurance",
            "type":     "boolean"
        },
        {
            "name":     "hasStandardPlanLetter",
            "baseName": "hasStandardPlanLetter",
            "type":     "boolean"
        },
        {
            "name":     "inNetworkConsideration",
            "baseName": "inNetworkConsideration",
            "type":     "string"
        },
        {
            "name":     "isAccumulatorPlan",
            "baseName": "isAccumulatorPlan",
            "type":     "boolean"
        },
        {
            "name":     "isMaximizerPlan",
            "baseName": "isMaximizerPlan",
            "type":     "boolean"
        },
        {
            "name":     "newPlanAvailable",
            "baseName": "newPlanAvailable",
            "type":     "boolean"
        },
        {
            "name":     "newPlanEffectiveDate",
            "baseName": "newPlanEffectiveDate",
            "type":     "string"
        },
        {
            "name":     "newPlanSubscriberId",
            "baseName": "newPlanSubscriberId",
            "type":     "string"
        },
        {
            "name":     "phone",
            "baseName": "phone",
            "type":     "string"
        },
        {
            "name":     "planEffectiveDate",
            "baseName": "planEffectiveDate",
            "type":     "Date"
        },
        {
            "name":     "planFundType",
            "baseName": "planFundType",
            "type":     "string"
        },
        {
            "name":     "planName",
            "baseName": "planName",
            "type":     "string"
        },
        {
            "name":     "planPriority",
            "baseName": "planPriority",
            "type":     "number"
        },
        {
            "name":     "planRenewalMonth",
            "baseName": "planRenewalMonth",
            "type":     "string"
        },
        {
            "name":     "planRenewalType",
            "baseName": "planRenewalType",
            "type":     "string"
        },
        {
            "name":     "planTerminationDate",
            "baseName": "planTerminationDate",
            "type":     "Date"
        },
        {
            "name":     "planType",
            "baseName": "planType",
            "type":     "string"
        },
        {
            "name":     "policyType",
            "baseName": "policyType",
            "type":     "string"
        },
        {
            "name":     "referenceId",
            "baseName": "referenceId",
            "type":     "string"
        },
        {
            "name":     "secondaryInsuranceName",
            "baseName": "secondaryInsuranceName",
            "type":     "string"
        },
        {
            "name":     "standardPlanLetter",
            "baseName": "standardPlanLetter",
            "type":     "string"
        },
        {
            "name":     "willCoverIfPrimaryDenies",
            "baseName": "willCoverIfPrimaryDenies",
            "type":     "string"
        },
        {
            "name":     "willCoverPartBDeductible",
            "baseName": "willCoverPartBDeductible",
            "type":     "string"
        },
        {
            "name":     "planCode",
            "baseName": "planCode",
            "type":     "string"
        },
        {
            "name":     "isDeductibleMetBeforeCob",
            "baseName": "isDeductibleMetBeforeCob",
            "type":     "string"
        },
        {
            "name":     "isOopMetBeforeCob",
            "baseName": "isOopMetBeforeCob",
            "type":     "string"
        },
        {
            "name":     "groupId",
            "baseName": "groupId",
            "type":     "string"
        },
        {
            "name":     "groupName",
            "baseName": "groupName",
            "type":     "string"
        },
        {
            "name":     "id",
            "baseName": "id",
            "type":     "string"
        },
        {
            "name":     "memberId",
            "baseName": "memberId",
            "type":     "string"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "otherInsuranceStatus",
            "baseName": "otherInsuranceStatus",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiPayorResult.attributeTypeMap;
    }
}
