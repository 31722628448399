// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { TCSCommunicationType } from './tCSCommunicationType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ExtEnrollmentCommTypeUpdateRequestModel {
    'memberNumber'?: string;
    'communicationTypes'?: TCSCommunicationType;
    'marketingCommunicationTypes'?: TCSCommunicationType;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtEnrollmentCommTypeUpdateRequestModel>> = [
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "communicationTypes",
            "baseName": "communicationTypes",
            "type":     "TCSCommunicationType"
        },
        {
            "name":     "marketingCommunicationTypes",
            "baseName": "marketingCommunicationTypes",
            "type":     "TCSCommunicationType"
        },
    ];

    static getAttributeTypeMap() {
        return ExtEnrollmentCommTypeUpdateRequestModel.attributeTypeMap;
    }
}
