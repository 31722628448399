// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { HhsProduct } from './hhsProduct.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class HhsMedication {
    'productName': HhsProduct;
    'hasValidPrescription': boolean;
    'mailOrderOptIn'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof HhsMedication>> = [
        {
            "name":     "productName",
            "baseName": "productName",
            "type":     "HhsProduct"
        },
        {
            "name":     "hasValidPrescription",
            "baseName": "hasValidPrescription",
            "type":     "boolean"
        },
        {
            "name":     "mailOrderOptIn",
            "baseName": "mailOrderOptIn",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return HhsMedication.attributeTypeMap;
    }
}
