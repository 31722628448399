// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SimpleIntakeDiagnosisModel {
    'ids': Array<number>;
    'codes': Array<string>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SimpleIntakeDiagnosisModel>> = [
        {
            "name":     "ids",
            "baseName": "ids",
            "type":     "Array<number>"
        },
        {
            "name":     "codes",
            "baseName": "codes",
            "type":     "Array<string>"
        },
    ];

    static getAttributeTypeMap() {
        return SimpleIntakeDiagnosisModel.attributeTypeMap;
    }
}
