// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SendEnrollmentResponseModel {
    'groupId': string;
    'memberId': string;
    'bin': string;
    'pcn': string;
    'dob': string;
    'email'?: string;
    'firstName': string;
    'lastName': string;
    'phoneNumber'?: string;
    'zipcode': string;
    'textOptIn'?: boolean;
    'accountId': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SendEnrollmentResponseModel>> = [
        {
            "name":     "groupId",
            "baseName": "groupId",
            "type":     "string"
        },
        {
            "name":     "memberId",
            "baseName": "memberId",
            "type":     "string"
        },
        {
            "name":     "bin",
            "baseName": "bin",
            "type":     "string"
        },
        {
            "name":     "pcn",
            "baseName": "pcn",
            "type":     "string"
        },
        {
            "name":     "dob",
            "baseName": "dob",
            "type":     "string"
        },
        {
            "name":     "email",
            "baseName": "email",
            "type":     "string"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "phoneNumber",
            "baseName": "phoneNumber",
            "type":     "string"
        },
        {
            "name":     "zipcode",
            "baseName": "zipcode",
            "type":     "string"
        },
        {
            "name":     "textOptIn",
            "baseName": "textOptIn",
            "type":     "boolean"
        },
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return SendEnrollmentResponseModel.attributeTypeMap;
    }
}
