// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ClaimLookupModel } from './claimLookupModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ClaimDetailsResponseModel {
    'paymentMethodDetail': ClaimLookupModel | null;
    'claimStatusDetail': ClaimLookupModel | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimDetailsResponseModel>> = [
        {
            "name":     "paymentMethodDetail",
            "baseName": "paymentMethodDetail",
            "type":     "ClaimLookupModel"
        },
        {
            "name":     "claimStatusDetail",
            "baseName": "claimStatusDetail",
            "type":     "ClaimLookupModel"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimDetailsResponseModel.attributeTypeMap;
    }
}
