// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as enums from '@trialcard/enums';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  https://openapi-generator.tech  Do not edit the class manually.
*/

export enum CaseContactInformationPurposeType {
    UNKNOWN = 0,
    MAIN = 1,
    MARKETING = 2,
    SALES = 3,
    CUSTOMERSERVICE = 4,
    HOME = 5,
    WORK = 6,
    SEASONAL = 7,
    OTHER = 8
}
