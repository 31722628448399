// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { SimpleIntakeAccountContactModel } from './simpleIntakeAccountContactModel.js';
import type { SimpleIntakeDiagnosisModel } from './simpleIntakeDiagnosisModel.js';
import type { SimpleIntakePatientModel } from './simpleIntakePatientModel.js';
import type { SimpleIntakePayorsModel } from './simpleIntakePayorsModel.js';
import type { SimpleIntakePharmacyModel } from './simpleIntakePharmacyModel.js';
import type { SimpleIntakePrescriberModel } from './simpleIntakePrescriberModel.js';
import type { SimpleIntakePrescriptionModel } from './simpleIntakePrescriptionModel.js';
import type { SimpleIntakeSiteModel } from './simpleIntakeSiteModel.js';
import type { SimpleIntakeSurveyModel } from './simpleIntakeSurveyModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SimpleIntakeRequestModel {
    'assignmentOfBenefits'?: boolean;
    'autoReenroll'?: boolean;
    'contacts'?: Array<SimpleIntakeAccountContactModel>;
    'diagnosis'?: SimpleIntakeDiagnosisModel | null;
    'patient': SimpleIntakePatientModel | null;
    'payors'?: SimpleIntakePayorsModel | null;
    'pharmacies'?: Array<SimpleIntakePharmacyModel>;
    'prescribers'?: Array<SimpleIntakePrescriberModel>;
    'prescriptions'?: Array<SimpleIntakePrescriptionModel>;
    'sites'?: Array<SimpleIntakeSiteModel>;
    'sourceId'?: number;
    'surveys'?: Array<SimpleIntakeSurveyModel>;
    'thirdPartyId'?: string;
    'workflows'?: Array<string>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SimpleIntakeRequestModel>> = [
        {
            "name":     "assignmentOfBenefits",
            "baseName": "assignmentOfBenefits",
            "type":     "boolean"
        },
        {
            "name":     "autoReenroll",
            "baseName": "autoReenroll",
            "type":     "boolean"
        },
        {
            "name":     "contacts",
            "baseName": "contacts",
            "type":     "Array<SimpleIntakeAccountContactModel>"
        },
        {
            "name":     "diagnosis",
            "baseName": "diagnosis",
            "type":     "SimpleIntakeDiagnosisModel"
        },
        {
            "name":     "patient",
            "baseName": "patient",
            "type":     "SimpleIntakePatientModel"
        },
        {
            "name":     "payors",
            "baseName": "payors",
            "type":     "SimpleIntakePayorsModel"
        },
        {
            "name":     "pharmacies",
            "baseName": "pharmacies",
            "type":     "Array<SimpleIntakePharmacyModel>"
        },
        {
            "name":     "prescribers",
            "baseName": "prescribers",
            "type":     "Array<SimpleIntakePrescriberModel>"
        },
        {
            "name":     "prescriptions",
            "baseName": "prescriptions",
            "type":     "Array<SimpleIntakePrescriptionModel>"
        },
        {
            "name":     "sites",
            "baseName": "sites",
            "type":     "Array<SimpleIntakeSiteModel>"
        },
        {
            "name":     "sourceId",
            "baseName": "sourceId",
            "type":     "number"
        },
        {
            "name":     "surveys",
            "baseName": "surveys",
            "type":     "Array<SimpleIntakeSurveyModel>"
        },
        {
            "name":     "thirdPartyId",
            "baseName": "thirdPartyId",
            "type":     "string"
        },
        {
            "name":     "workflows",
            "baseName": "workflows",
            "type":     "Array<string>"
        },
    ];

    static getAttributeTypeMap() {
        return SimpleIntakeRequestModel.attributeTypeMap;
    }
}
