// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyBiPriorAuthorization {
    'appealsContactFax'?: string | null;
    'approvalDate'?: string | null;
    'approvalNeededToGetBenefits'?: string | null;
    'approvalNumber'?: string | null;
    'denialReason'?: string | null;
    'endDate'?: string | null;
    'expirationDate'?: string | null;
    'initiationDate'?: string | null;
    'notificationMethod'?: string | null;
    'onFile'?: string | null;
    'onFileForDose'?: string | null;
    'required'?: string | null;
    'separatePriorAuthRequired'?: string | null;
    'startDate'?: string | null;
    'status'?: string | null;
    'statusDateTime'?: string | null;
    'submissionDate'?: string | null;
    'transactionID'?: string | null;
    'turnaroundTime'?: string | null;
    'type'?: string | null;
    'url'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyBiPriorAuthorization>> = [
        {
            "name":     "appealsContactFax",
            "baseName": "appealsContactFax",
            "type":     "string"
        },
        {
            "name":     "approvalDate",
            "baseName": "approvalDate",
            "type":     "string"
        },
        {
            "name":     "approvalNeededToGetBenefits",
            "baseName": "approvalNeededToGetBenefits",
            "type":     "string"
        },
        {
            "name":     "approvalNumber",
            "baseName": "approvalNumber",
            "type":     "string"
        },
        {
            "name":     "denialReason",
            "baseName": "denialReason",
            "type":     "string"
        },
        {
            "name":     "endDate",
            "baseName": "endDate",
            "type":     "string"
        },
        {
            "name":     "expirationDate",
            "baseName": "expirationDate",
            "type":     "string"
        },
        {
            "name":     "initiationDate",
            "baseName": "initiationDate",
            "type":     "string"
        },
        {
            "name":     "notificationMethod",
            "baseName": "notificationMethod",
            "type":     "string"
        },
        {
            "name":     "onFile",
            "baseName": "onFile",
            "type":     "string"
        },
        {
            "name":     "onFileForDose",
            "baseName": "onFileForDose",
            "type":     "string"
        },
        {
            "name":     "required",
            "baseName": "required",
            "type":     "string"
        },
        {
            "name":     "separatePriorAuthRequired",
            "baseName": "separatePriorAuthRequired",
            "type":     "string"
        },
        {
            "name":     "startDate",
            "baseName": "startDate",
            "type":     "string"
        },
        {
            "name":     "status",
            "baseName": "status",
            "type":     "string"
        },
        {
            "name":     "statusDateTime",
            "baseName": "statusDateTime",
            "type":     "string"
        },
        {
            "name":     "submissionDate",
            "baseName": "submissionDate",
            "type":     "string"
        },
        {
            "name":     "transactionID",
            "baseName": "transactionID",
            "type":     "string"
        },
        {
            "name":     "turnaroundTime",
            "baseName": "turnaroundTime",
            "type":     "string"
        },
        {
            "name":     "type",
            "baseName": "type",
            "type":     "string"
        },
        {
            "name":     "url",
            "baseName": "url",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyBiPriorAuthorization.attributeTypeMap;
    }
}
