// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RoleType } from './roleType.js';
import type { SimpleIntakeAddressModel } from './simpleIntakeAddressModel.js';
import type { SimpleIntakeContactModel } from './simpleIntakeContactModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SimpleIntakeSiteModel {
    'address'?: SimpleIntakeAddressModel | null;
    'contact'?: SimpleIntakeContactModel | null;
    'id'?: number;
    'name'?: string;
    'npi'?: string;
    'taxId'?: string;
    'type'?: RoleType;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SimpleIntakeSiteModel>> = [
        {
            "name":     "address",
            "baseName": "address",
            "type":     "SimpleIntakeAddressModel"
        },
        {
            "name":     "contact",
            "baseName": "contact",
            "type":     "SimpleIntakeContactModel"
        },
        {
            "name":     "id",
            "baseName": "id",
            "type":     "number"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "taxId",
            "baseName": "taxId",
            "type":     "string"
        },
        {
            "name":     "type",
            "baseName": "type",
            "type":     "RoleType"
        },
    ];

    static getAttributeTypeMap() {
        return SimpleIntakeSiteModel.attributeTypeMap;
    }
}
