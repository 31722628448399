// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Patient information model
*/
export class ClaimStagingAnsi837PatientModel {
    /**
    * @description The “PatientAccountNumber” is the identifier assigned by the provider (3rd party ID)
    */
    'patientAccountNumber'?: string | null;
    /**
    * @description Portal patient ID
    */
    'portalPatientId'?: string | null;
    /**
    * @description Relationship to patient
    */
    'relationshipToPatient'?: string | null;
    /**
    * @description Name of the patient
    */
    'name'?: string | null;
    /**
    * @description Patient last name
    */
    'lastName'?: string | null;
    /**
    * @description Patient first name
    */
    'firstName'?: string | null;
    /**
    * @description Patient middle name
    */
    'middleName'?: string | null;
    /**
    * @description Patient address
    */
    'address'?: string | null;
    /**
    * @description Patient address city
    */
    'city'?: string | null;
    /**
    * @description Patient address state
    */
    'state'?: string | null;
    /**
    * @description Patient address country
    */
    'country'?: string | null;
    /**
    * @description Patient address ZIP code
    */
    'zip'?: string | null;
    /**
    * @description Patient date of birth
    */
    'dateOfBirth'?: Moment;
    /**
    * @description Patient sex
    */
    'sex'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimStagingAnsi837PatientModel>> = [
        {
            "name":     "patientAccountNumber",
            "baseName": "patientAccountNumber",
            "type":     "string"
        },
        {
            "name":     "portalPatientId",
            "baseName": "portalPatientId",
            "type":     "string"
        },
        {
            "name":     "relationshipToPatient",
            "baseName": "relationshipToPatient",
            "type":     "string"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "middleName",
            "baseName": "middleName",
            "type":     "string"
        },
        {
            "name":     "address",
            "baseName": "address",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "country",
            "baseName": "country",
            "type":     "string"
        },
        {
            "name":     "zip",
            "baseName": "zip",
            "type":     "string"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "Date"
        },
        {
            "name":     "sex",
            "baseName": "sex",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimStagingAnsi837PatientModel.attributeTypeMap;
    }
}
