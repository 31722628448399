// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class CaseProgramCaseTypeModel {
    'programCaseTypeId'?: number;
    'caseTypeId'?: number;
    'caseTypeCode'?: string;
    'caseTypeDescription'?: string;
    'caseTypeCategoryId'?: number;
    'caseTypeCategoryCode'?: string;
    'caseTypeCategoryDescription'?: string;
    'createdFromIntake'?: boolean;
    'isWorkflowCase'?: boolean;
    'isWorkflow'?: boolean;
    'expirationPeriod'?: number;
    'isHidden'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof CaseProgramCaseTypeModel>> = [
        {
            "name":     "programCaseTypeId",
            "baseName": "programCaseTypeId",
            "type":     "number"
        },
        {
            "name":     "caseTypeId",
            "baseName": "caseTypeId",
            "type":     "number"
        },
        {
            "name":     "caseTypeCode",
            "baseName": "caseTypeCode",
            "type":     "string"
        },
        {
            "name":     "caseTypeDescription",
            "baseName": "caseTypeDescription",
            "type":     "string"
        },
        {
            "name":     "caseTypeCategoryId",
            "baseName": "caseTypeCategoryId",
            "type":     "number"
        },
        {
            "name":     "caseTypeCategoryCode",
            "baseName": "caseTypeCategoryCode",
            "type":     "string"
        },
        {
            "name":     "caseTypeCategoryDescription",
            "baseName": "caseTypeCategoryDescription",
            "type":     "string"
        },
        {
            "name":     "createdFromIntake",
            "baseName": "createdFromIntake",
            "type":     "boolean"
        },
        {
            "name":     "isWorkflowCase",
            "baseName": "isWorkflowCase",
            "type":     "boolean"
        },
        {
            "name":     "isWorkflow",
            "baseName": "isWorkflow",
            "type":     "boolean"
        },
        {
            "name":     "expirationPeriod",
            "baseName": "expirationPeriod",
            "type":     "number"
        },
        {
            "name":     "isHidden",
            "baseName": "isHidden",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return CaseProgramCaseTypeModel.attributeTypeMap;
    }
}
