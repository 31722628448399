// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PriorAuthorizationResultUpdateRequest {
    'mock'?: boolean;
    'transactionCorrelationId': string | null;
    'externalId'?: string | null;
    'paStatus': string | null;
    'paMessage'?: string | null;
    'paStatusDateTime': string | null;
    'ssoUrl'?: string | null;
    'providerPIN'?: string | null;
    'paDueDate'?: string | null;
    'paAuthorizationNumber'?: string | null;
    'authExpirationDate'?: string | null;
    'authStartDate'?: string | null;
    'transactionId'?: string | null;
    'transactionMessage'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PriorAuthorizationResultUpdateRequest>> = [
        {
            "name":     "mock",
            "baseName": "mock",
            "type":     "boolean"
        },
        {
            "name":     "transactionCorrelationId",
            "baseName": "transactionCorrelationId",
            "type":     "string"
        },
        {
            "name":     "externalId",
            "baseName": "externalId",
            "type":     "string"
        },
        {
            "name":     "paStatus",
            "baseName": "paStatus",
            "type":     "string"
        },
        {
            "name":     "paMessage",
            "baseName": "paMessage",
            "type":     "string"
        },
        {
            "name":     "paStatusDateTime",
            "baseName": "paStatusDateTime",
            "type":     "string"
        },
        {
            "name":     "ssoUrl",
            "baseName": "ssoUrl",
            "type":     "string"
        },
        {
            "name":     "providerPIN",
            "baseName": "providerPIN",
            "type":     "string"
        },
        {
            "name":     "paDueDate",
            "baseName": "paDueDate",
            "type":     "string"
        },
        {
            "name":     "paAuthorizationNumber",
            "baseName": "paAuthorizationNumber",
            "type":     "string"
        },
        {
            "name":     "authExpirationDate",
            "baseName": "authExpirationDate",
            "type":     "string"
        },
        {
            "name":     "authStartDate",
            "baseName": "authStartDate",
            "type":     "string"
        },
        {
            "name":     "transactionId",
            "baseName": "transactionId",
            "type":     "string"
        },
        {
            "name":     "transactionMessage",
            "baseName": "transactionMessage",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PriorAuthorizationResultUpdateRequest.attributeTypeMap;
    }
}
