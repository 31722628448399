// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ClaimAdjudicationProviderDetails {
    'providerName'?: string;
    'providerAddress1'?: string;
    'providerAddress2'?: string;
    'providerCity'?: string;
    'providerState'?: string;
    'providerZipCode'?: string;
    'providerFederalEin'?: string;
    'providerFax'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimAdjudicationProviderDetails>> = [
        {
            "name":     "providerName",
            "baseName": "providerName",
            "type":     "string"
        },
        {
            "name":     "providerAddress1",
            "baseName": "providerAddress1",
            "type":     "string"
        },
        {
            "name":     "providerAddress2",
            "baseName": "providerAddress2",
            "type":     "string"
        },
        {
            "name":     "providerCity",
            "baseName": "providerCity",
            "type":     "string"
        },
        {
            "name":     "providerState",
            "baseName": "providerState",
            "type":     "string"
        },
        {
            "name":     "providerZipCode",
            "baseName": "providerZipCode",
            "type":     "string"
        },
        {
            "name":     "providerFederalEin",
            "baseName": "providerFederalEin",
            "type":     "string"
        },
        {
            "name":     "providerFax",
            "baseName": "providerFax",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimAdjudicationProviderDetails.attributeTypeMap;
    }
}
