// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EnterpriseCoordinatorAccountServiceModelsAccountOrderRelationModel } from './enterpriseCoordinatorAccountServiceModelsAccountOrderRelationModel.js';
import type { RoleType } from './roleType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EnterpriseCoordinatorAccountServiceModelsAccountPayorRelationModel {
    'roleType'?: RoleType;
    'accountRelations'?: Array<EnterpriseCoordinatorAccountServiceModelsAccountOrderRelationModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnterpriseCoordinatorAccountServiceModelsAccountPayorRelationModel>> = [
        {
            "name":     "roleType",
            "baseName": "roleType",
            "type":     "RoleType"
        },
        {
            "name":     "accountRelations",
            "baseName": "accountRelations",
            "type":     "Array<EnterpriseCoordinatorAccountServiceModelsAccountOrderRelationModel>"
        },
    ];

    static getAttributeTypeMap() {
        return EnterpriseCoordinatorAccountServiceModelsAccountPayorRelationModel.attributeTypeMap;
    }
}
