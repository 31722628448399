// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Model for getting an existing National Drug Code with Program information included
*/
export class PrescriptionProgramNdcModel {
    'programPromotionXrefId'?: number;
    'programNdcXrefId'?: number;
    'id'?: number;
    'name'?: string | null;
    'drugNumber'?: string | null;
    'strengthDescription'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PrescriptionProgramNdcModel>> = [
        {
            "name":     "programPromotionXrefId",
            "baseName": "programPromotionXrefId",
            "type":     "number"
        },
        {
            "name":     "programNdcXrefId",
            "baseName": "programNdcXrefId",
            "type":     "number"
        },
        {
            "name":     "id",
            "baseName": "id",
            "type":     "number"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "drugNumber",
            "baseName": "drugNumber",
            "type":     "string"
        },
        {
            "name":     "strengthDescription",
            "baseName": "strengthDescription",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PrescriptionProgramNdcModel.attributeTypeMap;
    }
}
