// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { SiteLicenseType } from './siteLicenseType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Model for Site License
*/
export class SiteLicenseModel {
    'licenseType'?: SiteLicenseType;
    /**
    * @description LicenseNumber
    */
    'licenseNumber'?: string | null;
    /**
    * @description License State Code
    */
    'licenseStateCd'?: string | null;
    /**
    * @description License Expiration Date
    */
    'licenseExpirationDt'?: Moment | null;
    /**
    * @description SiteId
    */
    'siteLicenseId'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SiteLicenseModel>> = [
        {
            "name":     "licenseType",
            "baseName": "licenseType",
            "type":     "SiteLicenseType"
        },
        {
            "name":     "licenseNumber",
            "baseName": "licenseNumber",
            "type":     "string"
        },
        {
            "name":     "licenseStateCd",
            "baseName": "licenseStateCd",
            "type":     "string"
        },
        {
            "name":     "licenseExpirationDt",
            "baseName": "licenseExpirationDt",
            "type":     "Date"
        },
        {
            "name":     "siteLicenseId",
            "baseName": "siteLicenseId",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return SiteLicenseModel.attributeTypeMap;
    }
}
