// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyBiDenial {
    'date'?: string | null;
    'notes'?: string | null;
    'number'?: string | null;
    'reason'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyBiDenial>> = [
        {
            "name":     "date",
            "baseName": "date",
            "type":     "string"
        },
        {
            "name":     "notes",
            "baseName": "notes",
            "type":     "string"
        },
        {
            "name":     "number",
            "baseName": "number",
            "type":     "string"
        },
        {
            "name":     "reason",
            "baseName": "reason",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyBiDenial.attributeTypeMap;
    }
}
