// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ContactInfoPurposeType } from './contactInfoPurposeType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PrescriberSharedEmailAddressUpsearchModel {
    'emailAddressId'?: number;
    'emailAddressType'?: ContactInfoPurposeType;
    'address'?: string;
    'language'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PrescriberSharedEmailAddressUpsearchModel>> = [
        {
            "name":     "emailAddressId",
            "baseName": "emailAddressId",
            "type":     "number"
        },
        {
            "name":     "emailAddressType",
            "baseName": "emailAddressType",
            "type":     "ContactInfoPurposeType"
        },
        {
            "name":     "address",
            "baseName": "address",
            "type":     "string"
        },
        {
            "name":     "language",
            "baseName": "language",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PrescriberSharedEmailAddressUpsearchModel.attributeTypeMap;
    }
}
