// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PriorAuthorizationPrescriberAddress } from './priorAuthorizationPrescriberAddress.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PriorAuthorizationPrescriber {
    'address': PriorAuthorizationPrescriberAddress | null;
    'email'?: string | null;
    'fax': string | null;
    'firstName': string | null;
    'lastName': string | null;
    'middleName'?: string | null;
    'npi': string | null;
    'phone': string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PriorAuthorizationPrescriber>> = [
        {
            "name":     "address",
            "baseName": "address",
            "type":     "PriorAuthorizationPrescriberAddress"
        },
        {
            "name":     "email",
            "baseName": "email",
            "type":     "string"
        },
        {
            "name":     "fax",
            "baseName": "fax",
            "type":     "string"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "middleName",
            "baseName": "middleName",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "phone",
            "baseName": "phone",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PriorAuthorizationPrescriber.attributeTypeMap;
    }
}
