// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Model for managing the relationship between an Insurance Provider and a Pharmacy Benefit Manager (PBM) on an Account
*/
export class LinkedPayorModel {
    /**
    * @description Order of Coverage
    */
    'coverageOrder'?: number;
    /**
    * @description Payor ID of an Insurance Provider
    */
    'insurancePayorId'?: number;
    /**
    * @description Payor ID of a Pharmacy Benefits Manager (PBM)
    */
    'pbmPayorId'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof LinkedPayorModel>> = [
        {
            "name":     "coverageOrder",
            "baseName": "coverageOrder",
            "type":     "number"
        },
        {
            "name":     "insurancePayorId",
            "baseName": "insurancePayorId",
            "type":     "number"
        },
        {
            "name":     "pbmPayorId",
            "baseName": "pbmPayorId",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return LinkedPayorModel.attributeTypeMap;
    }
}
