// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BestFitPharmacyPatient } from './bestFitPharmacyPatient.js';
import type { BestFitPharmacyPayor } from './bestFitPharmacyPayor.js';
import type { BestFitPharmacyPharmacyRequest } from './bestFitPharmacyPharmacyRequest.js';
import type { BestFitPharmacyPrescriber } from './bestFitPharmacyPrescriber.js';
import type { BestFitPharmacyPrescription } from './bestFitPharmacyPrescription.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class BestFitPharmacyRequest {
    'mock'?: boolean;
    'patient': BestFitPharmacyPatient | null;
    'payor': BestFitPharmacyPayor | null;
    'pharmacy'?: BestFitPharmacyPharmacyRequest | null;
    'prescriber'?: BestFitPharmacyPrescriber | null;
    'prescription': BestFitPharmacyPrescription | null;
    'project'?: string;
    'clientId1'?: string;
    'clientId2'?: string;
    'clientId3'?: string;
    'clientId4'?: string;
    'clientId5'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof BestFitPharmacyRequest>> = [
        {
            "name":     "mock",
            "baseName": "mock",
            "type":     "boolean"
        },
        {
            "name":     "patient",
            "baseName": "patient",
            "type":     "BestFitPharmacyPatient"
        },
        {
            "name":     "payor",
            "baseName": "payor",
            "type":     "BestFitPharmacyPayor"
        },
        {
            "name":     "pharmacy",
            "baseName": "pharmacy",
            "type":     "BestFitPharmacyPharmacyRequest"
        },
        {
            "name":     "prescriber",
            "baseName": "prescriber",
            "type":     "BestFitPharmacyPrescriber"
        },
        {
            "name":     "prescription",
            "baseName": "prescription",
            "type":     "BestFitPharmacyPrescription"
        },
        {
            "name":     "project",
            "baseName": "project",
            "type":     "string"
        },
        {
            "name":     "clientId1",
            "baseName": "clientId1",
            "type":     "string"
        },
        {
            "name":     "clientId2",
            "baseName": "clientId2",
            "type":     "string"
        },
        {
            "name":     "clientId3",
            "baseName": "clientId3",
            "type":     "string"
        },
        {
            "name":     "clientId4",
            "baseName": "clientId4",
            "type":     "string"
        },
        {
            "name":     "clientId5",
            "baseName": "clientId5",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return BestFitPharmacyRequest.attributeTypeMap;
    }
}
