// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Model of a lookup value with a parent.
*/
export class ClaimParentLookupModel {
    /**
    * @description Description
    */
    'description'?: string | null;
    /**
    * @description Code
    */
    'code'?: string | null;
    /**
    * @description ID
    */
    'id'?: number;
    /**
    * @description Lookup parent identifier.
    */
    'parentId'?: number | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimParentLookupModel>> = [
        {
            "name":     "description",
            "baseName": "description",
            "type":     "string"
        },
        {
            "name":     "code",
            "baseName": "code",
            "type":     "string"
        },
        {
            "name":     "id",
            "baseName": "id",
            "type":     "number"
        },
        {
            "name":     "parentId",
            "baseName": "parentId",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimParentLookupModel.attributeTypeMap;
    }
}
