// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { StagingDocumentType } from './stagingDocumentType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class DocumentGroupUploadRequestModel {
    'documentData': string;
    'documentType': StagingDocumentType;
    'coverPageText'?: string | null;
    'thirdPartyId': string;
    'documentSequenceNumber': number;
    'totalDocumentsInGroup': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof DocumentGroupUploadRequestModel>> = [
        {
            "name":     "documentData",
            "baseName": "documentData",
            "type":     "string"
        },
        {
            "name":     "documentType",
            "baseName": "documentType",
            "type":     "StagingDocumentType"
        },
        {
            "name":     "coverPageText",
            "baseName": "coverPageText",
            "type":     "string"
        },
        {
            "name":     "thirdPartyId",
            "baseName": "thirdPartyId",
            "type":     "string"
        },
        {
            "name":     "documentSequenceNumber",
            "baseName": "documentSequenceNumber",
            "type":     "number"
        },
        {
            "name":     "totalDocumentsInGroup",
            "baseName": "totalDocumentsInGroup",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return DocumentGroupUploadRequestModel.attributeTypeMap;
    }
}
