// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ContactInformationType } from './contactInformationType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Account Contact Preference
*/
export class AccountContactPreferenceModel {
    'contactInfoType'?: ContactInformationType;
    /**
    * @description Set all other ContactPreferenceTypes on this Account to be IsPreferred = False, only used on Save not populated on Read
    */
    'setAllOthersToNotPreferred'?: boolean | null;
    /**
    * @description Is this communication method opted in?
    */
    'isOptedIn'?: boolean | null;
    /**
    * @description Is this the preferred communication method?
    */
    'isPreferred'?: boolean | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof AccountContactPreferenceModel>> = [
        {
            "name":     "contactInfoType",
            "baseName": "contactInfoType",
            "type":     "ContactInformationType"
        },
        {
            "name":     "setAllOthersToNotPreferred",
            "baseName": "setAllOthersToNotPreferred",
            "type":     "boolean"
        },
        {
            "name":     "isOptedIn",
            "baseName": "isOptedIn",
            "type":     "boolean"
        },
        {
            "name":     "isPreferred",
            "baseName": "isPreferred",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return AccountContactPreferenceModel.attributeTypeMap;
    }
}
