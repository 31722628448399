// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Gender } from './gender.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class GetBestEnrollmentV2RequestModel {
    'firstName': string | null;
    'lastName': string | null;
    'dateOfBirth': Moment | null;
    'zip': string | null;
    'gender': Gender | null;
    'includeQueuedTransient'?: boolean;
    'includeRejected'?: boolean;
    'removeTestPatients'?: boolean;
    'accountId'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof GetBestEnrollmentV2RequestModel>> = [
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "Date"
        },
        {
            "name":     "zip",
            "baseName": "zip",
            "type":     "string"
        },
        {
            "name":     "gender",
            "baseName": "gender",
            "type":     "Gender"
        },
        {
            "name":     "includeQueuedTransient",
            "baseName": "includeQueuedTransient",
            "type":     "boolean"
        },
        {
            "name":     "includeRejected",
            "baseName": "includeRejected",
            "type":     "boolean"
        },
        {
            "name":     "removeTestPatients",
            "baseName": "removeTestPatients",
            "type":     "boolean"
        },
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return GetBestEnrollmentV2RequestModel.attributeTypeMap;
    }
}
