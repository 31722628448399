// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiCoverageException {
    'isProcessExists'?: string;
    'obtainProcess'?: string;
    'obtainPhone'?: string;
    'obtainFax'?: string;
    'obtainWebsite'?: string;
    'obtainAddress'?: string;
    'turnaroundTime'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiCoverageException>> = [
        {
            "name":     "isProcessExists",
            "baseName": "isProcessExists",
            "type":     "string"
        },
        {
            "name":     "obtainProcess",
            "baseName": "obtainProcess",
            "type":     "string"
        },
        {
            "name":     "obtainPhone",
            "baseName": "obtainPhone",
            "type":     "string"
        },
        {
            "name":     "obtainFax",
            "baseName": "obtainFax",
            "type":     "string"
        },
        {
            "name":     "obtainWebsite",
            "baseName": "obtainWebsite",
            "type":     "string"
        },
        {
            "name":     "obtainAddress",
            "baseName": "obtainAddress",
            "type":     "string"
        },
        {
            "name":     "turnaroundTime",
            "baseName": "turnaroundTime",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiCoverageException.attributeTypeMap;
    }
}
