// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PharmacyCardFinderPlan } from './pharmacyCardFinderPlan.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyCardFinderResponse {
    'plans'?: Array<PharmacyCardFinderPlan>;
    'prescriberNpi'?: string;
    'transactionCorrelationId'?: number;
    'transactionDateTime'?: Moment;
    'transactionId'?: string;
    'transactionMessage'?: string;
    'transactionStatus'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyCardFinderResponse>> = [
        {
            "name":     "plans",
            "baseName": "plans",
            "type":     "Array<PharmacyCardFinderPlan>"
        },
        {
            "name":     "prescriberNpi",
            "baseName": "prescriberNpi",
            "type":     "string"
        },
        {
            "name":     "transactionCorrelationId",
            "baseName": "transactionCorrelationId",
            "type":     "number"
        },
        {
            "name":     "transactionDateTime",
            "baseName": "transactionDateTime",
            "type":     "Date"
        },
        {
            "name":     "transactionId",
            "baseName": "transactionId",
            "type":     "string"
        },
        {
            "name":     "transactionMessage",
            "baseName": "transactionMessage",
            "type":     "string"
        },
        {
            "name":     "transactionStatus",
            "baseName": "transactionStatus",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyCardFinderResponse.attributeTypeMap;
    }
}
