// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as enums from '@trialcard/enums';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  https://openapi-generator.tech  Do not edit the class manually.
*/

export enum CaseContactInformationType {
    UNKNOWN = 0,
    PHONE = 1,
    MOBILE = 2,
    FAX = 3,
    WEB = 4,
    EMAIL = 5,
    DONOTCALL = 6,
    FACEBOOK = 7,
    TWITTER = 8,
    LINKEDIN = 9,
    GOOGLEPLUS = 10,
    YOUTUBE = 11,
    Text = 12,
    Mail = 13,
    MANGO = 14
}
