// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { MangoHubAddress } from './mangoHubAddress.js';
import type { MangoHubPhone } from './mangoHubPhone.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MangoHubGetPatientSummaryMedicalPayor {
    'memberId': string;
    'group': string;
    'bin': string;
    'pcn': string;
    'payorName': string;
    'planTypeDescription': string;
    'phoneNumbers': Array<MangoHubPhone>;
    'addresses': Array<MangoHubAddress>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MangoHubGetPatientSummaryMedicalPayor>> = [
        {
            "name":     "memberId",
            "baseName": "memberId",
            "type":     "string"
        },
        {
            "name":     "group",
            "baseName": "group",
            "type":     "string"
        },
        {
            "name":     "bin",
            "baseName": "bin",
            "type":     "string"
        },
        {
            "name":     "pcn",
            "baseName": "pcn",
            "type":     "string"
        },
        {
            "name":     "payorName",
            "baseName": "payorName",
            "type":     "string"
        },
        {
            "name":     "planTypeDescription",
            "baseName": "planTypeDescription",
            "type":     "string"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<MangoHubPhone>"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<MangoHubAddress>"
        },
    ];

    static getAttributeTypeMap() {
        return MangoHubGetPatientSummaryMedicalPayor.attributeTypeMap;
    }
}
