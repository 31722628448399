// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalEligibilityIndividual {
    'coInsurance'?: string;
    'deductible'?: string;
    'deductibleMet'?: string;
    'deductibleRemaining'?: string;
    'deductibleOutNetwork'?: string;
    'deductibleRemainingOutNetwork'?: string;
    'oop'?: string;
    'oopMet'?: string;
    'oopRemaining'?: string;
    'oopOutNetwork'?: string;
    'oopRemainingOutNetwork'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalEligibilityIndividual>> = [
        {
            "name":     "coInsurance",
            "baseName": "coInsurance",
            "type":     "string"
        },
        {
            "name":     "deductible",
            "baseName": "deductible",
            "type":     "string"
        },
        {
            "name":     "deductibleMet",
            "baseName": "deductibleMet",
            "type":     "string"
        },
        {
            "name":     "deductibleRemaining",
            "baseName": "deductibleRemaining",
            "type":     "string"
        },
        {
            "name":     "deductibleOutNetwork",
            "baseName": "deductibleOutNetwork",
            "type":     "string"
        },
        {
            "name":     "deductibleRemainingOutNetwork",
            "baseName": "deductibleRemainingOutNetwork",
            "type":     "string"
        },
        {
            "name":     "oop",
            "baseName": "oop",
            "type":     "string"
        },
        {
            "name":     "oopMet",
            "baseName": "oopMet",
            "type":     "string"
        },
        {
            "name":     "oopRemaining",
            "baseName": "oopRemaining",
            "type":     "string"
        },
        {
            "name":     "oopOutNetwork",
            "baseName": "oopOutNetwork",
            "type":     "string"
        },
        {
            "name":     "oopRemainingOutNetwork",
            "baseName": "oopRemainingOutNetwork",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalEligibilityIndividual.attributeTypeMap;
    }
}
