// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BasicEnrollmentEthnicity } from './basicEnrollmentEthnicity.js';
import type { HhsAddress } from './hhsAddress.js';
import type { HhsAssignedGender } from './hhsAssignedGender.js';
import type { HhsBestTimeToCall } from './hhsBestTimeToCall.js';
import type { HhsGenderIdentity } from './hhsGenderIdentity.js';
import type { HhsPhoneType } from './hhsPhoneType.js';
import type { HhsRace } from './hhsRace.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class HhsPatient {
    'ssnLast4': string;
    'firstName': string;
    'lastName': string;
    'middleName'?: string;
    'suffix'?: string;
    'dateOfBirth': Moment;
    'assignedGender'?: HhsAssignedGender;
    'genderIdentity'?: HhsGenderIdentity;
    'race': HhsRace;
    'ethnicity': BasicEnrollmentEthnicity;
    'emailAddress'?: string;
    'phoneNumber': string;
    'phoneNumberType': HhsPhoneType;
    'canLeaveVoicemail': boolean;
    'bestTimeToContact': HhsBestTimeToCall;
    'address': HhsAddress | null;
    'thirdPartyId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof HhsPatient>> = [
        {
            "name":     "ssnLast4",
            "baseName": "ssnLast4",
            "type":     "string"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "middleName",
            "baseName": "middleName",
            "type":     "string"
        },
        {
            "name":     "suffix",
            "baseName": "suffix",
            "type":     "string"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "Date"
        },
        {
            "name":     "assignedGender",
            "baseName": "assignedGender",
            "type":     "HhsAssignedGender"
        },
        {
            "name":     "genderIdentity",
            "baseName": "genderIdentity",
            "type":     "HhsGenderIdentity"
        },
        {
            "name":     "race",
            "baseName": "race",
            "type":     "HhsRace"
        },
        {
            "name":     "ethnicity",
            "baseName": "ethnicity",
            "type":     "BasicEnrollmentEthnicity"
        },
        {
            "name":     "emailAddress",
            "baseName": "emailAddress",
            "type":     "string"
        },
        {
            "name":     "phoneNumber",
            "baseName": "phoneNumber",
            "type":     "string"
        },
        {
            "name":     "phoneNumberType",
            "baseName": "phoneNumberType",
            "type":     "HhsPhoneType"
        },
        {
            "name":     "canLeaveVoicemail",
            "baseName": "canLeaveVoicemail",
            "type":     "boolean"
        },
        {
            "name":     "bestTimeToContact",
            "baseName": "bestTimeToContact",
            "type":     "HhsBestTimeToCall"
        },
        {
            "name":     "address",
            "baseName": "address",
            "type":     "HhsAddress"
        },
        {
            "name":     "thirdPartyId",
            "baseName": "thirdPartyId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return HhsPatient.attributeTypeMap;
    }
}
