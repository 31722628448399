// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BasicEnrollmentSurvey } from './basicEnrollmentSurvey.js';
import type { EnrollPatientAccountAttestationAttribute } from './enrollPatientAccountAttestationAttribute.js';
import type { EnrollPatientCaseInitiator } from './enrollPatientCaseInitiator.js';
import type { EnrollPatientCaseSource } from './enrollPatientCaseSource.js';
import type { EnrollPatientRequestModelAccount } from './enrollPatientRequestModelAccount.js';
import type { EnrollmentInputModel } from './enrollmentInputModel.js';
import type { PayorCoverageEligibilityRequestModel } from './payorCoverageEligibilityRequestModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EnrollPatientRequestModel {
    'account': EnrollPatientRequestModelAccount;
    'surveys'?: Array<BasicEnrollmentSurvey>;
    'shouldVerifyEligibility'?: boolean;
    'payorCoverageAreas'?: Array<PayorCoverageEligibilityRequestModel>;
    'enrollmentInput'?: EnrollmentInputModel | null;
    'caseInitiator'?: EnrollPatientCaseInitiator;
    'caseSource'?: EnrollPatientCaseSource;
    'accountAttestations'?: Array<EnrollPatientAccountAttestationAttribute>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnrollPatientRequestModel>> = [
        {
            "name":     "account",
            "baseName": "account",
            "type":     "EnrollPatientRequestModelAccount"
        },
        {
            "name":     "surveys",
            "baseName": "surveys",
            "type":     "Array<BasicEnrollmentSurvey>"
        },
        {
            "name":     "shouldVerifyEligibility",
            "baseName": "shouldVerifyEligibility",
            "type":     "boolean"
        },
        {
            "name":     "payorCoverageAreas",
            "baseName": "payorCoverageAreas",
            "type":     "Array<PayorCoverageEligibilityRequestModel>"
        },
        {
            "name":     "enrollmentInput",
            "baseName": "enrollmentInput",
            "type":     "EnrollmentInputModel"
        },
        {
            "name":     "caseInitiator",
            "baseName": "caseInitiator",
            "type":     "EnrollPatientCaseInitiator"
        },
        {
            "name":     "caseSource",
            "baseName": "caseSource",
            "type":     "EnrollPatientCaseSource"
        },
        {
            "name":     "accountAttestations",
            "baseName": "accountAttestations",
            "type":     "Array<EnrollPatientAccountAttestationAttribute>"
        },
    ];

    static getAttributeTypeMap() {
        return EnrollPatientRequestModel.attributeTypeMap;
    }
}
