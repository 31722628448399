// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ExtEnrollmentPhoneNumberModel } from './extEnrollmentPhoneNumberModel.js';
import type { TCSSource } from './tCSSource.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ExtEnrollmentCreatePrescriberRequestModel {
    'firstName'?: string;
    'lastName'?: string;
    'phoneNumbers': Array<ExtEnrollmentPhoneNumberModel>;
    'address1'?: string;
    'address2'?: string;
    'city'?: string;
    'country'?: string;
    'state'?: string;
    'postalCode'?: string;
    'sourceId'?: TCSSource;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtEnrollmentCreatePrescriberRequestModel>> = [
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<ExtEnrollmentPhoneNumberModel>"
        },
        {
            "name":     "address1",
            "baseName": "address1",
            "type":     "string"
        },
        {
            "name":     "address2",
            "baseName": "address2",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "country",
            "baseName": "country",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "postalCode",
            "baseName": "postalCode",
            "type":     "string"
        },
        {
            "name":     "sourceId",
            "baseName": "sourceId",
            "type":     "TCSSource"
        },
    ];

    static getAttributeTypeMap() {
        return ExtEnrollmentCreatePrescriberRequestModel.attributeTypeMap;
    }
}
