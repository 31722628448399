// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BasicEnrollmentAddress } from './basicEnrollmentAddress.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class BasicEnrollmentPrescriber {
    'firstName'?: string;
    'middleName'?: string;
    'lastName'?: string;
    'suffix'?: string;
    'npi'?: string;
    'taxId'?: string;
    'deaNumber'?: string;
    'stateLicenseId'?: string;
    'ptan'?: string;
    'phoneNumber'?: string;
    'phoneNumberExtension'?: string;
    'faxNumber'?: string;
    'faxNumberExtension'?: string;
    'address'?: BasicEnrollmentAddress | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof BasicEnrollmentPrescriber>> = [
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "middleName",
            "baseName": "middleName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "suffix",
            "baseName": "suffix",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "taxId",
            "baseName": "taxId",
            "type":     "string"
        },
        {
            "name":     "deaNumber",
            "baseName": "deaNumber",
            "type":     "string"
        },
        {
            "name":     "stateLicenseId",
            "baseName": "stateLicenseId",
            "type":     "string"
        },
        {
            "name":     "ptan",
            "baseName": "ptan",
            "type":     "string"
        },
        {
            "name":     "phoneNumber",
            "baseName": "phoneNumber",
            "type":     "string"
        },
        {
            "name":     "phoneNumberExtension",
            "baseName": "phoneNumberExtension",
            "type":     "string"
        },
        {
            "name":     "faxNumber",
            "baseName": "faxNumber",
            "type":     "string"
        },
        {
            "name":     "faxNumberExtension",
            "baseName": "faxNumberExtension",
            "type":     "string"
        },
        {
            "name":     "address",
            "baseName": "address",
            "type":     "BasicEnrollmentAddress"
        },
    ];

    static getAttributeTypeMap() {
        return BasicEnrollmentPrescriber.attributeTypeMap;
    }
}
