// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { MedicalEligibilityPlan } from './medicalEligibilityPlan.js';
import type { MedicalEligibilitySecondaryInsurance } from './medicalEligibilitySecondaryInsurance.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalEligibilityPayorResult {
    'groupName'?: string;
    'insurancePolicyNumber'?: string;
    'patientRelation'?: string;
    'phone'?: string;
    'plan'?: MedicalEligibilityPlan | null;
    'priorIdentifierNumber'?: string;
    'referenceId'?: string;
    'secondaryInsurance'?: MedicalEligibilitySecondaryInsurance | null;
    'groupId'?: string;
    'id': string;
    'memberId': string;
    'name'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalEligibilityPayorResult>> = [
        {
            "name":     "groupName",
            "baseName": "groupName",
            "type":     "string"
        },
        {
            "name":     "insurancePolicyNumber",
            "baseName": "insurancePolicyNumber",
            "type":     "string"
        },
        {
            "name":     "patientRelation",
            "baseName": "patientRelation",
            "type":     "string"
        },
        {
            "name":     "phone",
            "baseName": "phone",
            "type":     "string"
        },
        {
            "name":     "plan",
            "baseName": "plan",
            "type":     "MedicalEligibilityPlan"
        },
        {
            "name":     "priorIdentifierNumber",
            "baseName": "priorIdentifierNumber",
            "type":     "string"
        },
        {
            "name":     "referenceId",
            "baseName": "referenceId",
            "type":     "string"
        },
        {
            "name":     "secondaryInsurance",
            "baseName": "secondaryInsurance",
            "type":     "MedicalEligibilitySecondaryInsurance"
        },
        {
            "name":     "groupId",
            "baseName": "groupId",
            "type":     "string"
        },
        {
            "name":     "id",
            "baseName": "id",
            "type":     "string"
        },
        {
            "name":     "memberId",
            "baseName": "memberId",
            "type":     "string"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalEligibilityPayorResult.attributeTypeMap;
    }
}
