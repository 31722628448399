// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as enums from '@trialcard/enums';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';


export enum Extauth2ValidateStatus {
    Valid = 0,
    UserFound = 1,
    Invalid = 2,
    UserNotFound = 3,
    AccountDisabled = 4,
    AccountLocked = 5,
    PasswordExpired = 6,
    ServerError = 7,
    UserMustChangePassword = 8
}
