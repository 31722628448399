// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ActualType } from './actualType.js';
import type { CaseAttestationAttributeModel } from './caseAttestationAttributeModel.js';
import type { CaseConditionRelationshipModel } from './caseConditionRelationshipModel.js';
import type { CaseContactPreferenceModel } from './caseContactPreferenceModel.js';
import type { CaseExternalIdModel } from './caseExternalIdModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class CaseGenericCaseModel {
    'id'?: number;
    'displayId'?: string;
    'actualType'?: ActualType;
    'parentCaseId'?: number;
    'previousCaseId'?: number;
    'tenantId'?: number;
    'accountId'?: number;
    'programId'?: number;
    'typeId'?: number;
    'typeCode'?: string;
    'typeDescription'?: string;
    'statusId'?: number;
    'statusCode'?: string;
    'statusDescription'?: string;
    'subStatusId'?: number;
    'subStatusCode'?: string;
    'subStatusDescription'?: string;
    'assignedUserId'?: number;
    'isOpen'?: boolean;
    'startDate'?: Moment;
    'expirationDate'?: Moment;
    'endDate'?: Moment;
    'isExpired'?: boolean;
    'createdOn'?: Moment;
    'typeCategoryCode'?: string;
    'typeCategoryId'?: number;
    'typeCategoryDescription'?: string;
    'statusCategoryCode'?: string;
    'statusCategoryId'?: number;
    'statusCategoryDescription'?: string;
    'sourceId'?: number;
    'closedBy'?: number;
    'externalIds'?: Array<CaseExternalIdModel>;
    'conditions'?: Array<CaseConditionRelationshipModel>;
    'attestations'?: Array<CaseAttestationAttributeModel>;
    'contactPreferences'?: Array<CaseContactPreferenceModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof CaseGenericCaseModel>> = [
        {
            "name":     "id",
            "baseName": "id",
            "type":     "number"
        },
        {
            "name":     "displayId",
            "baseName": "displayId",
            "type":     "string"
        },
        {
            "name":     "actualType",
            "baseName": "actualType",
            "type":     "ActualType"
        },
        {
            "name":     "parentCaseId",
            "baseName": "parentCaseId",
            "type":     "number"
        },
        {
            "name":     "previousCaseId",
            "baseName": "previousCaseId",
            "type":     "number"
        },
        {
            "name":     "tenantId",
            "baseName": "tenantId",
            "type":     "number"
        },
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "programId",
            "baseName": "programId",
            "type":     "number"
        },
        {
            "name":     "typeId",
            "baseName": "typeId",
            "type":     "number"
        },
        {
            "name":     "typeCode",
            "baseName": "typeCode",
            "type":     "string"
        },
        {
            "name":     "typeDescription",
            "baseName": "typeDescription",
            "type":     "string"
        },
        {
            "name":     "statusId",
            "baseName": "statusId",
            "type":     "number"
        },
        {
            "name":     "statusCode",
            "baseName": "statusCode",
            "type":     "string"
        },
        {
            "name":     "statusDescription",
            "baseName": "statusDescription",
            "type":     "string"
        },
        {
            "name":     "subStatusId",
            "baseName": "subStatusId",
            "type":     "number"
        },
        {
            "name":     "subStatusCode",
            "baseName": "subStatusCode",
            "type":     "string"
        },
        {
            "name":     "subStatusDescription",
            "baseName": "subStatusDescription",
            "type":     "string"
        },
        {
            "name":     "assignedUserId",
            "baseName": "assignedUserId",
            "type":     "number"
        },
        {
            "name":     "isOpen",
            "baseName": "isOpen",
            "type":     "boolean"
        },
        {
            "name":     "startDate",
            "baseName": "startDate",
            "type":     "Date"
        },
        {
            "name":     "expirationDate",
            "baseName": "expirationDate",
            "type":     "Date"
        },
        {
            "name":     "endDate",
            "baseName": "endDate",
            "type":     "Date"
        },
        {
            "name":     "isExpired",
            "baseName": "isExpired",
            "type":     "boolean"
        },
        {
            "name":     "createdOn",
            "baseName": "createdOn",
            "type":     "Date"
        },
        {
            "name":     "typeCategoryCode",
            "baseName": "typeCategoryCode",
            "type":     "string"
        },
        {
            "name":     "typeCategoryId",
            "baseName": "typeCategoryId",
            "type":     "number"
        },
        {
            "name":     "typeCategoryDescription",
            "baseName": "typeCategoryDescription",
            "type":     "string"
        },
        {
            "name":     "statusCategoryCode",
            "baseName": "statusCategoryCode",
            "type":     "string"
        },
        {
            "name":     "statusCategoryId",
            "baseName": "statusCategoryId",
            "type":     "number"
        },
        {
            "name":     "statusCategoryDescription",
            "baseName": "statusCategoryDescription",
            "type":     "string"
        },
        {
            "name":     "sourceId",
            "baseName": "sourceId",
            "type":     "number"
        },
        {
            "name":     "closedBy",
            "baseName": "closedBy",
            "type":     "number"
        },
        {
            "name":     "externalIds",
            "baseName": "externalIds",
            "type":     "Array<CaseExternalIdModel>"
        },
        {
            "name":     "conditions",
            "baseName": "conditions",
            "type":     "Array<CaseConditionRelationshipModel>"
        },
        {
            "name":     "attestations",
            "baseName": "attestations",
            "type":     "Array<CaseAttestationAttributeModel>"
        },
        {
            "name":     "contactPreferences",
            "baseName": "contactPreferences",
            "type":     "Array<CaseContactPreferenceModel>"
        },
    ];

    static getAttributeTypeMap() {
        return CaseGenericCaseModel.attributeTypeMap;
    }
}
