// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PorticoPortalChildModel } from './porticoPortalChildModel.js';
import type { PorticoPortalDataGroupModel } from './porticoPortalDataGroupModel.js';
import type { PorticoPortalGroupModel } from './porticoPortalGroupModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PorticoPortalModel {
    'id'?: string;
    'portalGroupId'?: string;
    'name'?: string;
    'url'?: string;
    'beginOn'?: Moment;
    'endOn'?: Moment;
    'settings'?: string;
    'coreVersion'?: string;
    'createdBy'?: number;
    'createdOn'?: Moment;
    'portalGroup'?: PorticoPortalGroupModel | null;
    'portalChildren': Array<PorticoPortalChildModel>;
    'portalDataGroups': Array<PorticoPortalDataGroupModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PorticoPortalModel>> = [
        {
            "name":     "id",
            "baseName": "id",
            "type":     "string"
        },
        {
            "name":     "portalGroupId",
            "baseName": "portalGroupId",
            "type":     "string"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "url",
            "baseName": "url",
            "type":     "string"
        },
        {
            "name":     "beginOn",
            "baseName": "beginOn",
            "type":     "Date"
        },
        {
            "name":     "endOn",
            "baseName": "endOn",
            "type":     "Date"
        },
        {
            "name":     "settings",
            "baseName": "settings",
            "type":     "string"
        },
        {
            "name":     "coreVersion",
            "baseName": "coreVersion",
            "type":     "string"
        },
        {
            "name":     "createdBy",
            "baseName": "createdBy",
            "type":     "number"
        },
        {
            "name":     "createdOn",
            "baseName": "createdOn",
            "type":     "Date"
        },
        {
            "name":     "portalGroup",
            "baseName": "portalGroup",
            "type":     "PorticoPortalGroupModel"
        },
        {
            "name":     "portalChildren",
            "baseName": "portalChildren",
            "type":     "Array<PorticoPortalChildModel>"
        },
        {
            "name":     "portalDataGroups",
            "baseName": "portalDataGroups",
            "type":     "Array<PorticoPortalDataGroupModel>"
        },
    ];

    static getAttributeTypeMap() {
        return PorticoPortalModel.attributeTypeMap;
    }
}
