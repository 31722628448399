// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PrescriptionExternalIdModel } from './prescriptionExternalIdModel.js';
import type { PrescriptionSignatureModel } from './prescriptionSignatureModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Model for getting a Prescription
*/
export class PrescriptionModel {
    'id'?: number;
    'accountId'?: number;
    'createdBy'?: number;
    'createdOn'?: Moment;
    'updatedBy'?: number | null;
    'updatedOn'?: Moment | null;
    'isDeleted'?: boolean;
    'signatures': Array<PrescriptionSignatureModel> | null;
    'externalIds': Array<PrescriptionExternalIdModel> | null;
    'documentId'?: number | null;
    'prescriberId'?: number | null;
    'ndcId'?: number;
    'dispenseTypeId'?: number | null;
    'writtenOn'?: Moment | null;
    'daySupply'?: number | null;
    'refills'?: number | null;
    'quantity'?: number | null;
    'directions'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PrescriptionModel>> = [
        {
            "name":     "id",
            "baseName": "id",
            "type":     "number"
        },
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "createdBy",
            "baseName": "createdBy",
            "type":     "number"
        },
        {
            "name":     "createdOn",
            "baseName": "createdOn",
            "type":     "Date"
        },
        {
            "name":     "updatedBy",
            "baseName": "updatedBy",
            "type":     "number"
        },
        {
            "name":     "updatedOn",
            "baseName": "updatedOn",
            "type":     "Date"
        },
        {
            "name":     "isDeleted",
            "baseName": "isDeleted",
            "type":     "boolean"
        },
        {
            "name":     "signatures",
            "baseName": "signatures",
            "type":     "Array<PrescriptionSignatureModel>"
        },
        {
            "name":     "externalIds",
            "baseName": "externalIds",
            "type":     "Array<PrescriptionExternalIdModel>"
        },
        {
            "name":     "documentId",
            "baseName": "documentId",
            "type":     "number"
        },
        {
            "name":     "prescriberId",
            "baseName": "prescriberId",
            "type":     "number"
        },
        {
            "name":     "ndcId",
            "baseName": "ndcId",
            "type":     "number"
        },
        {
            "name":     "dispenseTypeId",
            "baseName": "dispenseTypeId",
            "type":     "number"
        },
        {
            "name":     "writtenOn",
            "baseName": "writtenOn",
            "type":     "Date"
        },
        {
            "name":     "daySupply",
            "baseName": "daySupply",
            "type":     "number"
        },
        {
            "name":     "refills",
            "baseName": "refills",
            "type":     "number"
        },
        {
            "name":     "quantity",
            "baseName": "quantity",
            "type":     "number"
        },
        {
            "name":     "directions",
            "baseName": "directions",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PrescriptionModel.attributeTypeMap;
    }
}
