// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ClaimExternalReferenceModel } from './claimExternalReferenceModel.js';
import type { ClaimPaymentBatchDetailModel } from './claimPaymentBatchDetailModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Payment Batch Model
*/
export class ClaimPaymentBatchModel {
    /**
    * @description Parent Payment Batch ID
    */
    'parentPaymentBatchId'?: number | null;
    /**
    * @description Processor ID
    */
    'processorId'?: number;
    /**
    * @description Promotion ID
    */
    'promotionId'?: number | null;
    /**
    * @description Payment Method Type ID
    */
    'paymentMethodTypeId'?: number;
    /**
    * @description Payment Batch Status ID
    */
    'paymentBatchStatusId'?: number;
    /**
    * @description Payment Batch Start Date
    */
    'paymentBatchStartDate'?: Moment | null;
    /**
    * @description Payment Batch End Date
    */
    'paymentBatchEndDate'?: Moment | null;
    /**
    * @description Invoice Number
    */
    'invoiceNumber'?: number | null;
    /**
    * @description External Reference Type IDs
    */
    'externalReferences'?: Array<ClaimExternalReferenceModel> | null;
    /**
    * @description Payment Batch ID
    */
    'paymentBatchId'?: number;
    /**
    * @description Processor Code
    */
    'processorCode'?: string | null;
    /**
    * @description Processor Description
    */
    'processorDescription'?: string | null;
    /**
    * @description Payment Method Type Code
    */
    'paymentMethodTypeCode'?: string | null;
    /**
    * @description Payment Method Type Description
    */
    'paymentMethodTypeDescription'?: string | null;
    /**
    * @description Payment of the batch can be made through the payment controller.
    */
    'isBatchPayable'?: boolean;
    /**
    * @description Payment Batch Status Code
    */
    'paymentBatchStatusCode'?: string | null;
    /**
    * @description Payment Batch Status Description
    */
    'paymentBatchStatusDescription'?: string | null;
    /**
    * @description Total Batch Amount
    */
    'totalBatchAmount'?: number | null;
    /**
    * @description Claim Count
    */
    'claimCount'?: number;
    /**
    * @description Payment Patch Details
    */
    'paymentBatchDetails'?: Array<ClaimPaymentBatchDetailModel> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimPaymentBatchModel>> = [
        {
            "name":     "parentPaymentBatchId",
            "baseName": "parentPaymentBatchId",
            "type":     "number"
        },
        {
            "name":     "processorId",
            "baseName": "processorId",
            "type":     "number"
        },
        {
            "name":     "promotionId",
            "baseName": "promotionId",
            "type":     "number"
        },
        {
            "name":     "paymentMethodTypeId",
            "baseName": "paymentMethodTypeId",
            "type":     "number"
        },
        {
            "name":     "paymentBatchStatusId",
            "baseName": "paymentBatchStatusId",
            "type":     "number"
        },
        {
            "name":     "paymentBatchStartDate",
            "baseName": "paymentBatchStartDate",
            "type":     "Date"
        },
        {
            "name":     "paymentBatchEndDate",
            "baseName": "paymentBatchEndDate",
            "type":     "Date"
        },
        {
            "name":     "invoiceNumber",
            "baseName": "invoiceNumber",
            "type":     "number"
        },
        {
            "name":     "externalReferences",
            "baseName": "externalReferences",
            "type":     "Array<ClaimExternalReferenceModel>"
        },
        {
            "name":     "paymentBatchId",
            "baseName": "paymentBatchId",
            "type":     "number"
        },
        {
            "name":     "processorCode",
            "baseName": "processorCode",
            "type":     "string"
        },
        {
            "name":     "processorDescription",
            "baseName": "processorDescription",
            "type":     "string"
        },
        {
            "name":     "paymentMethodTypeCode",
            "baseName": "paymentMethodTypeCode",
            "type":     "string"
        },
        {
            "name":     "paymentMethodTypeDescription",
            "baseName": "paymentMethodTypeDescription",
            "type":     "string"
        },
        {
            "name":     "isBatchPayable",
            "baseName": "isBatchPayable",
            "type":     "boolean"
        },
        {
            "name":     "paymentBatchStatusCode",
            "baseName": "paymentBatchStatusCode",
            "type":     "string"
        },
        {
            "name":     "paymentBatchStatusDescription",
            "baseName": "paymentBatchStatusDescription",
            "type":     "string"
        },
        {
            "name":     "totalBatchAmount",
            "baseName": "totalBatchAmount",
            "type":     "number"
        },
        {
            "name":     "claimCount",
            "baseName": "claimCount",
            "type":     "number"
        },
        {
            "name":     "paymentBatchDetails",
            "baseName": "paymentBatchDetails",
            "type":     "Array<ClaimPaymentBatchDetailModel>"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimPaymentBatchModel.attributeTypeMap;
    }
}
