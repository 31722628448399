// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PriorAuthorizationFollowUpRequestBase } from './priorAuthorizationFollowUpRequestBase.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PriorAuthorizationFollowUpRequestSearchBase {
    'request': PriorAuthorizationFollowUpRequestBase | null;
    'transactionCorrelationId'?: number;
    'transactionDateTime'?: Moment;
    'transactionId'?: string | null;
    'transactionMessage'?: string | null;
    'transactionStatus'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PriorAuthorizationFollowUpRequestSearchBase>> = [
        {
            "name":     "request",
            "baseName": "request",
            "type":     "PriorAuthorizationFollowUpRequestBase"
        },
        {
            "name":     "transactionCorrelationId",
            "baseName": "transactionCorrelationId",
            "type":     "number"
        },
        {
            "name":     "transactionDateTime",
            "baseName": "transactionDateTime",
            "type":     "Date"
        },
        {
            "name":     "transactionId",
            "baseName": "transactionId",
            "type":     "string"
        },
        {
            "name":     "transactionMessage",
            "baseName": "transactionMessage",
            "type":     "string"
        },
        {
            "name":     "transactionStatus",
            "baseName": "transactionStatus",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return PriorAuthorizationFollowUpRequestSearchBase.attributeTypeMap;
    }
}
