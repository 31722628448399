// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class LocationCityModel {
    'cityName'?: string;
    'stateName'?: string;
    'stateAbbr'?: string;
    'cityType'?: string;
    'countyName'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof LocationCityModel>> = [
        {
            "name":     "cityName",
            "baseName": "cityName",
            "type":     "string"
        },
        {
            "name":     "stateName",
            "baseName": "stateName",
            "type":     "string"
        },
        {
            "name":     "stateAbbr",
            "baseName": "stateAbbr",
            "type":     "string"
        },
        {
            "name":     "cityType",
            "baseName": "cityType",
            "type":     "string"
        },
        {
            "name":     "countyName",
            "baseName": "countyName",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return LocationCityModel.attributeTypeMap;
    }
}
