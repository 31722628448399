// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as enums from '@trialcard/enums';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';


export enum TCSEligibilityStatus {
    Unknown = -1,
    PatientIneligible = 0,
    EligiblePending = 1,
    PendingEnrollment = 2,
    MemberNumberInvalid = 3,
    ActivationPeriodExpired = 4,
    ReadyToActivate = 5,
    TransferToTrialcard = 6
}
