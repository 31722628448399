// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Prescription information
*/
export class CoverMyMedsInboundEnrollmentPrescriptionDefinitionModel {
    /**
    * @description The number of days supply for prescription<br />  Saved to prescription \\\'Daily Supply\\\'
    */
    'daysSupply'?: number | null;
    /**
    * @description The prescription type that was selected, used to lookup Dispense Type ID<br />  Mapped to prescription \\\'Dispense Type\\\'
    */
    'name'?: string | null;
    /**
    * @description Prescription National Drug Code, used to lookup NDC ID<br />  Mapped to prescription name
    */
    'ndc'?: string | null;
    /**
    * @description Number of prescription refills allowed<br />  Saved to prescription \\\'Refill\\\'
    */
    'refills'?: number | null;
    /**
    * @description SIG field (dosing directions/instructions) for prescription, can include:<br />  how to administer, dose, dose units, route of administration (where to administer),<br />  frequency, auxiliary information (how long to take)<br />  Saved to prescription \\\'Dosing Directions\\\'
    */
    'sig'?: string | null;
    /**
    * @description The prescription quantity (decimal value as a string)<br />  Saved to prescription \\\'Quantity\\\'
    */
    'writtenQuantity'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof CoverMyMedsInboundEnrollmentPrescriptionDefinitionModel>> = [
        {
            "name":     "daysSupply",
            "baseName": "daysSupply",
            "type":     "number"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "ndc",
            "baseName": "ndc",
            "type":     "string"
        },
        {
            "name":     "refills",
            "baseName": "refills",
            "type":     "number"
        },
        {
            "name":     "sig",
            "baseName": "sig",
            "type":     "string"
        },
        {
            "name":     "writtenQuantity",
            "baseName": "writtenQuantity",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return CoverMyMedsInboundEnrollmentPrescriptionDefinitionModel.attributeTypeMap;
    }
}
