// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BestFitPharmacyGender } from './bestFitPharmacyGender.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class BestFitPharmacyPatient {
    'birthDate'?: Moment;
    'firstName'?: string;
    'gender'?: BestFitPharmacyGender;
    'lastName'?: string;
    'zipCode': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof BestFitPharmacyPatient>> = [
        {
            "name":     "birthDate",
            "baseName": "birthDate",
            "type":     "Date"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "gender",
            "baseName": "gender",
            "type":     "BestFitPharmacyGender"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "zipCode",
            "baseName": "zipCode",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return BestFitPharmacyPatient.attributeTypeMap;
    }
}
