// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class GeneratePdfFromTemplateRequestContentPoint {
    'x': number;
    'y': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof GeneratePdfFromTemplateRequestContentPoint>> = [
        {
            "name":     "x",
            "baseName": "x",
            "type":     "number"
        },
        {
            "name":     "y",
            "baseName": "y",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return GeneratePdfFromTemplateRequestContentPoint.attributeTypeMap;
    }
}
