// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Is Valid Member Number Request Model
*/
export class EnrollmentV2IsValidMemberNumberRequestModel {
    /**
    * @description Member Number
    */
    'memberNumber'?: string | null;
    /**
    * @description Checking for a MemberNumber already with patient
    */
    'haveACard'?: boolean;
    /**
    * @description PartyId/PersonId/PatientId
    */
    'partyId'?: number | null;
    /**
    * @description Obfuscated PartyId/PersonId/PatientId
    */
    'securePartyId'?: string | null;
    /**
    * @description Check for Enrollment if exists
    */
    'checkForEnrollment'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnrollmentV2IsValidMemberNumberRequestModel>> = [
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "haveACard",
            "baseName": "haveACard",
            "type":     "boolean"
        },
        {
            "name":     "partyId",
            "baseName": "partyId",
            "type":     "number"
        },
        {
            "name":     "securePartyId",
            "baseName": "securePartyId",
            "type":     "string"
        },
        {
            "name":     "checkForEnrollment",
            "baseName": "checkForEnrollment",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return EnrollmentV2IsValidMemberNumberRequestModel.attributeTypeMap;
    }
}
