// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ExtEnrollmentGuardianInfoModel } from './extEnrollmentGuardianInfoModel.js';
import type { ExtEnrollmentMedBenClientIdentifiersModel } from './extEnrollmentMedBenClientIdentifiersModel.js';
import type { ExtEnrollmentMedBenEnrollmentDemographicsModel } from './extEnrollmentMedBenEnrollmentDemographicsModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ExtEnrollmentMedBenEnrollmentUpdateRequestModel {
    'clientIdentifiers'?: ExtEnrollmentMedBenClientIdentifiersModel | null;
    'demographics'?: ExtEnrollmentMedBenEnrollmentDemographicsModel | null;
    'guardianInfo'?: ExtEnrollmentGuardianInfoModel | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtEnrollmentMedBenEnrollmentUpdateRequestModel>> = [
        {
            "name":     "clientIdentifiers",
            "baseName": "clientIdentifiers",
            "type":     "ExtEnrollmentMedBenClientIdentifiersModel"
        },
        {
            "name":     "demographics",
            "baseName": "demographics",
            "type":     "ExtEnrollmentMedBenEnrollmentDemographicsModel"
        },
        {
            "name":     "guardianInfo",
            "baseName": "guardianInfo",
            "type":     "ExtEnrollmentGuardianInfoModel"
        },
    ];

    static getAttributeTypeMap() {
        return ExtEnrollmentMedBenEnrollmentUpdateRequestModel.attributeTypeMap;
    }
}
