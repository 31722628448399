// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CaseExternalIdType } from './caseExternalIdType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class AccountsByCaseSearchRequestModel {
    'maxRecordCount'?: number;
    'typeId'?: number;
    'type'?: number;
    'statusId'?: number;
    'subStatusId'?: number;
    'status'?: string;
    'subStatus'?: string;
    'createdBefore'?: Moment;
    'createdAfter'?: Moment;
    'endBefore'?: Moment;
    'endAfter'?: Moment;
    'isAssigned'?: boolean;
    'assignedUserId'?: number;
    'caseExternalId'?: string;
    'caseExternalIdType'?: CaseExternalIdType;
    'externalTypeCategoryId'?: number;
    'conditionTypeId'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof AccountsByCaseSearchRequestModel>> = [
        {
            "name":     "maxRecordCount",
            "baseName": "maxRecordCount",
            "type":     "number"
        },
        {
            "name":     "typeId",
            "baseName": "typeId",
            "type":     "number"
        },
        {
            "name":     "type",
            "baseName": "type",
            "type":     "number"
        },
        {
            "name":     "statusId",
            "baseName": "statusId",
            "type":     "number"
        },
        {
            "name":     "subStatusId",
            "baseName": "subStatusId",
            "type":     "number"
        },
        {
            "name":     "status",
            "baseName": "status",
            "type":     "string"
        },
        {
            "name":     "subStatus",
            "baseName": "subStatus",
            "type":     "string"
        },
        {
            "name":     "createdBefore",
            "baseName": "createdBefore",
            "type":     "Date"
        },
        {
            "name":     "createdAfter",
            "baseName": "createdAfter",
            "type":     "Date"
        },
        {
            "name":     "endBefore",
            "baseName": "endBefore",
            "type":     "Date"
        },
        {
            "name":     "endAfter",
            "baseName": "endAfter",
            "type":     "Date"
        },
        {
            "name":     "isAssigned",
            "baseName": "isAssigned",
            "type":     "boolean"
        },
        {
            "name":     "assignedUserId",
            "baseName": "assignedUserId",
            "type":     "number"
        },
        {
            "name":     "caseExternalId",
            "baseName": "caseExternalId",
            "type":     "string"
        },
        {
            "name":     "caseExternalIdType",
            "baseName": "caseExternalIdType",
            "type":     "CaseExternalIdType"
        },
        {
            "name":     "externalTypeCategoryId",
            "baseName": "externalTypeCategoryId",
            "type":     "number"
        },
        {
            "name":     "conditionTypeId",
            "baseName": "conditionTypeId",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return AccountsByCaseSearchRequestModel.attributeTypeMap;
    }
}
