// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as enums from '@trialcard/enums';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  https://openapi-generator.tech  Do not edit the class manually.
*/

export enum CasePhoneContactInformationType {
    UNKNOWN = 1,
    PHONE = 2,
    MOBILE = 3
}
