// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyBiLifetimeMaximum {
    'amount'?: string | null;
    'exists'?: string | null;
    'met'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyBiLifetimeMaximum>> = [
        {
            "name":     "amount",
            "baseName": "amount",
            "type":     "string"
        },
        {
            "name":     "exists",
            "baseName": "exists",
            "type":     "string"
        },
        {
            "name":     "met",
            "baseName": "met",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyBiLifetimeMaximum.attributeTypeMap;
    }
}
