// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PorticoDataRowModel } from './porticoDataRowModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PorticoDataGroupModel {
    'id'?: string;
    'dataGroupTypeId'?: string;
    'name'?: string;
    'isPrivate'?: boolean;
    'createdBy'?: number;
    'createdOn'?: Moment;
    'dataRows': Array<PorticoDataRowModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PorticoDataGroupModel>> = [
        {
            "name":     "id",
            "baseName": "id",
            "type":     "string"
        },
        {
            "name":     "dataGroupTypeId",
            "baseName": "dataGroupTypeId",
            "type":     "string"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "isPrivate",
            "baseName": "isPrivate",
            "type":     "boolean"
        },
        {
            "name":     "createdBy",
            "baseName": "createdBy",
            "type":     "number"
        },
        {
            "name":     "createdOn",
            "baseName": "createdOn",
            "type":     "Date"
        },
        {
            "name":     "dataRows",
            "baseName": "dataRows",
            "type":     "Array<PorticoDataRowModel>"
        },
    ];

    static getAttributeTypeMap() {
        return PorticoDataGroupModel.attributeTypeMap;
    }
}
