// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { GatewayMedicalClaimSearchModel } from './gatewayMedicalClaimSearchModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ClaimV1ClaimClaimV1ClaimMedicalClaimSearchMedicalClaimSearchRequest {
    'start'?: number;
    'pageSize'?: number;
    'searchModel': GatewayMedicalClaimSearchModel | null;
    'filterByClaimGroup'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimV1ClaimClaimV1ClaimMedicalClaimSearchMedicalClaimSearchRequest>> = [
        {
            "name":     "start",
            "baseName": "start",
            "type":     "number"
        },
        {
            "name":     "pageSize",
            "baseName": "pageSize",
            "type":     "number"
        },
        {
            "name":     "searchModel",
            "baseName": "searchModel",
            "type":     "GatewayMedicalClaimSearchModel"
        },
        {
            "name":     "filterByClaimGroup",
            "baseName": "filterByClaimGroup",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimV1ClaimClaimV1ClaimMedicalClaimSearchMedicalClaimSearchRequest.attributeTypeMap;
    }
}
