// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { MedicalBiDiagnosis } from './medicalBiDiagnosis.js';
import type { MedicalBiFacility } from './medicalBiFacility.js';
import type { MedicalBiPatientRequest } from './medicalBiPatientRequest.js';
import type { MedicalBiPayorRequest } from './medicalBiPayorRequest.js';
import type { MedicalBiPractice } from './medicalBiPractice.js';
import type { MedicalBiPrescriberRequest } from './medicalBiPrescriberRequest.js';
import type { MedicalBiPrescription } from './medicalBiPrescription.js';
import type { MedicalBiSiteOfCare } from './medicalBiSiteOfCare.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiRequest {
    'mock'?: boolean;
    'chainPbm'?: boolean;
    'diagnosis': MedicalBiDiagnosis | null;
    'facility'?: MedicalBiFacility | null;
    'patient': MedicalBiPatientRequest | null;
    'payor': MedicalBiPayorRequest | null;
    'practice'?: MedicalBiPractice | null;
    'preferredSpecialtyPharmacy'?: boolean;
    'prescriber'?: MedicalBiPrescriberRequest | null;
    'prescription'?: MedicalBiPrescription | null;
    'programId'?: string;
    'project'?: string;
    'providerProgramId': string;
    'specialtyPharmacyName'?: string;
    'sitesOfCare'?: Array<MedicalBiSiteOfCare>;
    'clientId1'?: string;
    'clientId2'?: string;
    'clientId3'?: string;
    'clientId4'?: string;
    'clientId5'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiRequest>> = [
        {
            "name":     "mock",
            "baseName": "mock",
            "type":     "boolean"
        },
        {
            "name":     "chainPbm",
            "baseName": "chainPbm",
            "type":     "boolean"
        },
        {
            "name":     "diagnosis",
            "baseName": "diagnosis",
            "type":     "MedicalBiDiagnosis"
        },
        {
            "name":     "facility",
            "baseName": "facility",
            "type":     "MedicalBiFacility"
        },
        {
            "name":     "patient",
            "baseName": "patient",
            "type":     "MedicalBiPatientRequest"
        },
        {
            "name":     "payor",
            "baseName": "payor",
            "type":     "MedicalBiPayorRequest"
        },
        {
            "name":     "practice",
            "baseName": "practice",
            "type":     "MedicalBiPractice"
        },
        {
            "name":     "preferredSpecialtyPharmacy",
            "baseName": "preferredSpecialtyPharmacy",
            "type":     "boolean"
        },
        {
            "name":     "prescriber",
            "baseName": "prescriber",
            "type":     "MedicalBiPrescriberRequest"
        },
        {
            "name":     "prescription",
            "baseName": "prescription",
            "type":     "MedicalBiPrescription"
        },
        {
            "name":     "programId",
            "baseName": "programId",
            "type":     "string"
        },
        {
            "name":     "project",
            "baseName": "project",
            "type":     "string"
        },
        {
            "name":     "providerProgramId",
            "baseName": "providerProgramId",
            "type":     "string"
        },
        {
            "name":     "specialtyPharmacyName",
            "baseName": "specialtyPharmacyName",
            "type":     "string"
        },
        {
            "name":     "sitesOfCare",
            "baseName": "sitesOfCare",
            "type":     "Array<MedicalBiSiteOfCare>"
        },
        {
            "name":     "clientId1",
            "baseName": "clientId1",
            "type":     "string"
        },
        {
            "name":     "clientId2",
            "baseName": "clientId2",
            "type":     "string"
        },
        {
            "name":     "clientId3",
            "baseName": "clientId3",
            "type":     "string"
        },
        {
            "name":     "clientId4",
            "baseName": "clientId4",
            "type":     "string"
        },
        {
            "name":     "clientId5",
            "baseName": "clientId5",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiRequest.attributeTypeMap;
    }
}
