// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EnrollmentType } from './enrollmentType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Member Number Model containing the Member Number, Type, and Payment Method
*/
export class EnrollmentV2MemberNumberModel {
    /**
    * @description Member Number
    */
    'number'?: string | null;
    'type'?: EnrollmentType;
    /**
    * @description Per Member Number Payment Method
    */
    'paymentMethod'?: number | null;
    /**
    * @description This is the TC Program ID (Warren Program ID)  ***THIS IS NOT THE EDB PROGRAM ID***
    */
    'tcProgramId'?: number | null;
    /**
    * @description Offer Type Id
    */
    'offerTypeId'?: number | null;
    /**
    * @description Status Code (Warren Status Code from Card Service)  Not populated for read operations
    */
    'statusCode'?: string | null;
    /**
    * @description This is to keep track if the member number really has payment type code  The payment type id is calculated in a few places  so we don\\\'t know if the payment type id is tied to member number  from storage or by calculated.
    */
    'originalPaymentCode'?: string | null;
    /**
    * @description This is the date the member number was terminated via TC Services. Not populated for read operations. Used Internally.
    */
    'terminationDate'?: Moment | null;
    /**
    * @description This is the promotion id the member number belongs to. Not populated for read operations. Used Internally.
    */
    'promotionId'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnrollmentV2MemberNumberModel>> = [
        {
            "name":     "number",
            "baseName": "number",
            "type":     "string"
        },
        {
            "name":     "type",
            "baseName": "type",
            "type":     "EnrollmentType"
        },
        {
            "name":     "paymentMethod",
            "baseName": "paymentMethod",
            "type":     "number"
        },
        {
            "name":     "tcProgramId",
            "baseName": "tcProgramId",
            "type":     "number"
        },
        {
            "name":     "offerTypeId",
            "baseName": "offerTypeId",
            "type":     "number"
        },
        {
            "name":     "statusCode",
            "baseName": "statusCode",
            "type":     "string"
        },
        {
            "name":     "originalPaymentCode",
            "baseName": "originalPaymentCode",
            "type":     "string"
        },
        {
            "name":     "terminationDate",
            "baseName": "terminationDate",
            "type":     "Date"
        },
        {
            "name":     "promotionId",
            "baseName": "promotionId",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return EnrollmentV2MemberNumberModel.attributeTypeMap;
    }
}
