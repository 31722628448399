// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class NpiEndpointResponseModel {
    'endpointType'?: string;
    'endpointTypeDescription'?: string;
    'endpoint'?: string;
    'affiliation'?: string;
    'endpointDescription'?: string;
    'affliationName'?: string;
    'use'?: string;
    'useDescription'?: string;
    'useOtherDescription'?: string;
    'contentType'?: string;
    'contentTypeDescription'?: string;
    'contentOtherDescription'?: string;
    'address_1'?: string;
    'address_2'?: string;
    'city'?: string;
    'state'?: string;
    'country_code'?: string;
    'postal_code'?: string;
    'country_name'?: string;
    'address_type'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof NpiEndpointResponseModel>> = [
        {
            "name":     "endpointType",
            "baseName": "endpointType",
            "type":     "string"
        },
        {
            "name":     "endpointTypeDescription",
            "baseName": "endpointTypeDescription",
            "type":     "string"
        },
        {
            "name":     "endpoint",
            "baseName": "endpoint",
            "type":     "string"
        },
        {
            "name":     "affiliation",
            "baseName": "affiliation",
            "type":     "string"
        },
        {
            "name":     "endpointDescription",
            "baseName": "endpointDescription",
            "type":     "string"
        },
        {
            "name":     "affliationName",
            "baseName": "affliationName",
            "type":     "string"
        },
        {
            "name":     "use",
            "baseName": "use",
            "type":     "string"
        },
        {
            "name":     "useDescription",
            "baseName": "useDescription",
            "type":     "string"
        },
        {
            "name":     "useOtherDescription",
            "baseName": "useOtherDescription",
            "type":     "string"
        },
        {
            "name":     "contentType",
            "baseName": "contentType",
            "type":     "string"
        },
        {
            "name":     "contentTypeDescription",
            "baseName": "contentTypeDescription",
            "type":     "string"
        },
        {
            "name":     "contentOtherDescription",
            "baseName": "contentOtherDescription",
            "type":     "string"
        },
        {
            "name":     "address_1",
            "baseName": "address_1",
            "type":     "string"
        },
        {
            "name":     "address_2",
            "baseName": "address_2",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "country_code",
            "baseName": "country_code",
            "type":     "string"
        },
        {
            "name":     "postal_code",
            "baseName": "postal_code",
            "type":     "string"
        },
        {
            "name":     "country_name",
            "baseName": "country_name",
            "type":     "string"
        },
        {
            "name":     "address_type",
            "baseName": "address_type",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return NpiEndpointResponseModel.attributeTypeMap;
    }
}
