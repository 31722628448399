// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CodePriorAuthRequirementModel } from './codePriorAuthRequirementModel.js';
import type { ErrorInfoModel } from './errorInfoModel.js';
import type { PlanInfoModel } from './planInfoModel.js';
import type { PriorAuthAppealInfoModel } from './priorAuthAppealInfoModel.js';
import type { PriorAuthInfoDetailModel } from './priorAuthInfoDetailModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PriorAuthorizationFollowUpResultBase {
    'payerAgentName'?: string | null;
    'payerPhone'?: string | null;
    'referenceNumber'?: string | null;
    'additionalNotes'?: string | null;
    'taskLink'?: string | null;
    'reviewRequired'?: string | null;
    'errorInfo'?: ErrorInfoModel | null;
    'planInfo'?: PlanInfoModel | null;
    'priorAuthInfoDetails'?: Array<PriorAuthInfoDetailModel>;
    'priorAuthAppealInfo'?: PriorAuthAppealInfoModel | null;
    'cptCodePriorAuthRequirements'?: Array<CodePriorAuthRequirementModel>;
    'jCodePriorAuthRequirements'?: Array<CodePriorAuthRequirementModel>;
    'taskCompletedDate'?: string | null;
    'taskStatus'?: string | null;
    'updatedTimestamp'?: Moment | null;
    'transactionCorrelationId'?: number;
    'transactionDateTime'?: Moment;
    'transactionId'?: string | null;
    'transactionMessage'?: string | null;
    'transactionStatus'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PriorAuthorizationFollowUpResultBase>> = [
        {
            "name":     "payerAgentName",
            "baseName": "payerAgentName",
            "type":     "string"
        },
        {
            "name":     "payerPhone",
            "baseName": "payerPhone",
            "type":     "string"
        },
        {
            "name":     "referenceNumber",
            "baseName": "referenceNumber",
            "type":     "string"
        },
        {
            "name":     "additionalNotes",
            "baseName": "additionalNotes",
            "type":     "string"
        },
        {
            "name":     "taskLink",
            "baseName": "taskLink",
            "type":     "string"
        },
        {
            "name":     "reviewRequired",
            "baseName": "reviewRequired",
            "type":     "string"
        },
        {
            "name":     "errorInfo",
            "baseName": "errorInfo",
            "type":     "ErrorInfoModel"
        },
        {
            "name":     "planInfo",
            "baseName": "planInfo",
            "type":     "PlanInfoModel"
        },
        {
            "name":     "priorAuthInfoDetails",
            "baseName": "priorAuthInfoDetails",
            "type":     "Array<PriorAuthInfoDetailModel>"
        },
        {
            "name":     "priorAuthAppealInfo",
            "baseName": "priorAuthAppealInfo",
            "type":     "PriorAuthAppealInfoModel"
        },
        {
            "name":     "cptCodePriorAuthRequirements",
            "baseName": "cptCodePriorAuthRequirements",
            "type":     "Array<CodePriorAuthRequirementModel>"
        },
        {
            "name":     "jCodePriorAuthRequirements",
            "baseName": "jCodePriorAuthRequirements",
            "type":     "Array<CodePriorAuthRequirementModel>"
        },
        {
            "name":     "taskCompletedDate",
            "baseName": "taskCompletedDate",
            "type":     "string"
        },
        {
            "name":     "taskStatus",
            "baseName": "taskStatus",
            "type":     "string"
        },
        {
            "name":     "updatedTimestamp",
            "baseName": "updatedTimestamp",
            "type":     "Date"
        },
        {
            "name":     "transactionCorrelationId",
            "baseName": "transactionCorrelationId",
            "type":     "number"
        },
        {
            "name":     "transactionDateTime",
            "baseName": "transactionDateTime",
            "type":     "Date"
        },
        {
            "name":     "transactionId",
            "baseName": "transactionId",
            "type":     "string"
        },
        {
            "name":     "transactionMessage",
            "baseName": "transactionMessage",
            "type":     "string"
        },
        {
            "name":     "transactionStatus",
            "baseName": "transactionStatus",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return PriorAuthorizationFollowUpResultBase.attributeTypeMap;
    }
}
