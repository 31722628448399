// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyBiStepTherapy {
    'required'?: string | null;
    'therapySeasonStartDate'?: string | null;
    'treatment'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyBiStepTherapy>> = [
        {
            "name":     "required",
            "baseName": "required",
            "type":     "string"
        },
        {
            "name":     "therapySeasonStartDate",
            "baseName": "therapySeasonStartDate",
            "type":     "string"
        },
        {
            "name":     "treatment",
            "baseName": "treatment",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyBiStepTherapy.attributeTypeMap;
    }
}
