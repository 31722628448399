// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MangoHubRevokePatientConsentResponse {
    'hasPatientConsent': boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MangoHubRevokePatientConsentResponse>> = [
        {
            "name":     "hasPatientConsent",
            "baseName": "hasPatientConsent",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return MangoHubRevokePatientConsentResponse.attributeTypeMap;
    }
}
