// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class TCQpSignatureServiceRequestGetRecipientViewGetRecipientViewRequest {
    'programId'?: number;
    'accountId'?: number | null;
    'envelopeId'?: string | null;
    'email'?: string | null;
    'name'?: string | null;
    'returnUrl'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof TCQpSignatureServiceRequestGetRecipientViewGetRecipientViewRequest>> = [
        {
            "name":     "programId",
            "baseName": "programId",
            "type":     "number"
        },
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "envelopeId",
            "baseName": "envelopeId",
            "type":     "string"
        },
        {
            "name":     "email",
            "baseName": "email",
            "type":     "string"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "returnUrl",
            "baseName": "returnUrl",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return TCQpSignatureServiceRequestGetRecipientViewGetRecipientViewRequest.attributeTypeMap;
    }
}
