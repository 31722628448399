// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AddressContextType } from './addressContextType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SaveAddressRequestModel {
    'accountId': number;
    'addressType': AddressContextType;
    'addressLine1': string;
    'addressLine2'?: string;
    'city': string;
    'state': string;
    'zipCode': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SaveAddressRequestModel>> = [
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "addressType",
            "baseName": "addressType",
            "type":     "AddressContextType"
        },
        {
            "name":     "addressLine1",
            "baseName": "addressLine1",
            "type":     "string"
        },
        {
            "name":     "addressLine2",
            "baseName": "addressLine2",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "zipCode",
            "baseName": "zipCode",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return SaveAddressRequestModel.attributeTypeMap;
    }
}
