// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { SimpleIntakePhoneModel } from './simpleIntakePhoneModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SimpleIntakePatientPhonesModel {
    'home'?: SimpleIntakePhoneModel | null;
    'mobile'?: SimpleIntakePhoneModel | null;
    'work'?: SimpleIntakePhoneModel | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SimpleIntakePatientPhonesModel>> = [
        {
            "name":     "home",
            "baseName": "home",
            "type":     "SimpleIntakePhoneModel"
        },
        {
            "name":     "mobile",
            "baseName": "mobile",
            "type":     "SimpleIntakePhoneModel"
        },
        {
            "name":     "work",
            "baseName": "work",
            "type":     "SimpleIntakePhoneModel"
        },
    ];

    static getAttributeTypeMap() {
        return SimpleIntakePatientPhonesModel.attributeTypeMap;
    }
}
