// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Patient\\\'s caregiver<br />  Saved as patient alternate contact
*/
export class CoverMyMedsInboundEnrollmentCaregiverModel {
    /**
    * @description Caregiver\\\'s date of birth<br />  (Not Used)
    */
    'dateOfBirth'?: string | null;
    /**
    * @description Caregiver\\\'s email address (255 chars max)<br />  Saved as patient alternate contact \\\'Email\\\'
    */
    'email'?: string | null;
    /**
    * @description Caregiver\\\'s full name (255 chars max, format = \\\'firstName lastName\\\' or \\\'firstName middleName lastName\\\' )<br />  Saved as patient alternate contact \\\'Name\\\'
    */
    'fullName'?: string | null;
    /**
    * @description Caregiver\\\'s phone number (Must be between 10 and 15 chars)<br />  Saved as patient alternate contact \\\'Home Phone\\\'
    */
    'phone'?: string | null;
    /**
    * @description Caregiver\\\'s relationship to patient<br />  (Not Used)
    */
    'relationshipToPatient'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof CoverMyMedsInboundEnrollmentCaregiverModel>> = [
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "string"
        },
        {
            "name":     "email",
            "baseName": "email",
            "type":     "string"
        },
        {
            "name":     "fullName",
            "baseName": "fullName",
            "type":     "string"
        },
        {
            "name":     "phone",
            "baseName": "phone",
            "type":     "string"
        },
        {
            "name":     "relationshipToPatient",
            "baseName": "relationshipToPatient",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return CoverMyMedsInboundEnrollmentCaregiverModel.attributeTypeMap;
    }
}
