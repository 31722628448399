// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ScheduleEventType } from './scheduleEventType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class BmsScheduleAppointmentRequestModel {
    'transactionId': number;
    'tcAccountId': string;
    'dueDateTimeEasternTime': Moment;
    'patientResponse': ScheduleEventType;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof BmsScheduleAppointmentRequestModel>> = [
        {
            "name":     "transactionId",
            "baseName": "transactionId",
            "type":     "number"
        },
        {
            "name":     "tcAccountId",
            "baseName": "tcAccountId",
            "type":     "string"
        },
        {
            "name":     "dueDateTimeEasternTime",
            "baseName": "dueDateTimeEasternTime",
            "type":     "Date"
        },
        {
            "name":     "patientResponse",
            "baseName": "patientResponse",
            "type":     "ScheduleEventType"
        },
    ];

    static getAttributeTypeMap() {
        return BmsScheduleAppointmentRequestModel.attributeTypeMap;
    }
}
