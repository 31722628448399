// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiDiagnosisCodeResult {
    'coInsurance'?: string;
    'copay'?: string;
    'deductibleApplies'?: boolean;
    'notes'?: string;
    'priorAuthorizationRequired'?: boolean;
    'isValid'?: string;
    'preDeterminationRequirement'?: string;
    'priorAuthRequired'?: string;
    'priorAuthStatus'?: string;
    'reasonForNonCoverage'?: string;
    'code': string;
    'unit'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiDiagnosisCodeResult>> = [
        {
            "name":     "coInsurance",
            "baseName": "coInsurance",
            "type":     "string"
        },
        {
            "name":     "copay",
            "baseName": "copay",
            "type":     "string"
        },
        {
            "name":     "deductibleApplies",
            "baseName": "deductibleApplies",
            "type":     "boolean"
        },
        {
            "name":     "notes",
            "baseName": "notes",
            "type":     "string"
        },
        {
            "name":     "priorAuthorizationRequired",
            "baseName": "priorAuthorizationRequired",
            "type":     "boolean"
        },
        {
            "name":     "isValid",
            "baseName": "isValid",
            "type":     "string"
        },
        {
            "name":     "preDeterminationRequirement",
            "baseName": "preDeterminationRequirement",
            "type":     "string"
        },
        {
            "name":     "priorAuthRequired",
            "baseName": "priorAuthRequired",
            "type":     "string"
        },
        {
            "name":     "priorAuthStatus",
            "baseName": "priorAuthStatus",
            "type":     "string"
        },
        {
            "name":     "reasonForNonCoverage",
            "baseName": "reasonForNonCoverage",
            "type":     "string"
        },
        {
            "name":     "code",
            "baseName": "code",
            "type":     "string"
        },
        {
            "name":     "unit",
            "baseName": "unit",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiDiagnosisCodeResult.attributeTypeMap;
    }
}
