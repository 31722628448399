// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiLifetime {
    'maxAmount'?: number;
    'maximumExists'?: string;
    'maxMet'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiLifetime>> = [
        {
            "name":     "maxAmount",
            "baseName": "maxAmount",
            "type":     "number"
        },
        {
            "name":     "maximumExists",
            "baseName": "maximumExists",
            "type":     "string"
        },
        {
            "name":     "maxMet",
            "baseName": "maxMet",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiLifetime.attributeTypeMap;
    }
}
