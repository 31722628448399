// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PharmacyBiAnnual } from './pharmacyBiAnnual.js';
import type { PharmacyBiAppeal } from './pharmacyBiAppeal.js';
import type { PharmacyBiDeductible } from './pharmacyBiDeductible.js';
import type { PharmacyBiDenial } from './pharmacyBiDenial.js';
import type { PharmacyBiFamily } from './pharmacyBiFamily.js';
import type { PharmacyBiFormulary } from './pharmacyBiFormulary.js';
import type { PharmacyBiIndividual } from './pharmacyBiIndividual.js';
import type { PharmacyBiInitialCoverageLimit } from './pharmacyBiInitialCoverageLimit.js';
import type { PharmacyBiLifetimeMaximum } from './pharmacyBiLifetimeMaximum.js';
import type { PharmacyBiMailOrderPharmacy } from './pharmacyBiMailOrderPharmacy.js';
import type { PharmacyBiMedicarePartD } from './pharmacyBiMedicarePartD.js';
import type { PharmacyBiNewPlan } from './pharmacyBiNewPlan.js';
import type { PharmacyBiObtainPriorAuthorization } from './pharmacyBiObtainPriorAuthorization.js';
import type { PharmacyBiPayorResult } from './pharmacyBiPayorResult.js';
import type { PharmacyBiPbm } from './pharmacyBiPbm.js';
import type { PharmacyBiPeerToPeer } from './pharmacyBiPeerToPeer.js';
import type { PharmacyBiPharmacy } from './pharmacyBiPharmacy.js';
import type { PharmacyBiPlan } from './pharmacyBiPlan.js';
import type { PharmacyBiPolicy } from './pharmacyBiPolicy.js';
import type { PharmacyBiPrescriptionResult } from './pharmacyBiPrescriptionResult.js';
import type { PharmacyBiPricing } from './pharmacyBiPricing.js';
import type { PharmacyBiPriorAuthorization } from './pharmacyBiPriorAuthorization.js';
import type { PharmacyBiProductCoverage } from './pharmacyBiProductCoverage.js';
import type { PharmacyBiQuantity } from './pharmacyBiQuantity.js';
import type { PharmacyBiResubmission } from './pharmacyBiResubmission.js';
import type { PharmacyBiRxGroup } from './pharmacyBiRxGroup.js';
import type { PharmacyBiSpecialtyCoverage } from './pharmacyBiSpecialtyCoverage.js';
import type { PharmacyBiSpecialtyPharmacyResult } from './pharmacyBiSpecialtyPharmacyResult.js';
import type { PharmacyBiStepTherapy } from './pharmacyBiStepTherapy.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyBiResponseDetail {
    'additionalNotes'?: string | null;
    'annualBenefit'?: PharmacyBiAnnual | null;
    'appeal'?: PharmacyBiAppeal | null;
    'bin'?: string | null;
    'coordinationOfBenefits'?: string | null;
    'coPayAppliesToOop'?: string | null;
    'copayWaivedAfterOop'?: string | null;
    'daysSupplyPriced'?: string | null;
    'deductible'?: PharmacyBiDeductible | null;
    'denial'?: PharmacyBiDenial | null;
    'drugCoverageStatus'?: string | null;
    'emid'?: string | null;
    'estimatedPatientPayAmount'?: string | null;
    'family'?: PharmacyBiFamily | null;
    'formulary'?: PharmacyBiFormulary | null;
    'individual'?: PharmacyBiIndividual | null;
    'initialCoverageLimit'?: PharmacyBiInitialCoverageLimit | null;
    'isAccumulatorPlan'?: string | null;
    'isMaximizerPlan'?: string | null;
    'isOnLowIncomeSubsidy'?: string | null;
    'lifetimeMaximum'?: PharmacyBiLifetimeMaximum | null;
    'lowIncomeSubsidyLevel'?: string | null;
    'mailOrderPharmacy'?: PharmacyBiMailOrderPharmacy | null;
    'medicarePartD'?: PharmacyBiMedicarePartD | null;
    'newPlan'?: PharmacyBiNewPlan | null;
    'obtainPriorAuthorization'?: PharmacyBiObtainPriorAuthorization | null;
    'obtainTierExceptionPhone'?: string | null;
    'otherInsuranceExists'?: string | null;
    'payor'?: PharmacyBiPayorResult | null;
    'pbm'?: PharmacyBiPbm | null;
    'pcn'?: string | null;
    'peerToPeer'?: PharmacyBiPeerToPeer | null;
    'pharmacy'?: PharmacyBiPharmacy | null;
    'plan'?: PharmacyBiPlan | null;
    'policy'?: PharmacyBiPolicy | null;
    'predeterminationSubmissionDate'?: string | null;
    'preferredDrugName'?: string | null;
    'preferredDrugValue'?: string | null;
    'prescription'?: PharmacyBiPrescriptionResult | null;
    'pricing'?: PharmacyBiPricing | null;
    'priorAuthorization'?: PharmacyBiPriorAuthorization | null;
    'productCoverage'?: PharmacyBiProductCoverage | null;
    'productQuantityLimit'?: string | null;
    'productTier'?: string | null;
    'quantity'?: PharmacyBiQuantity | null;
    'rejectionCode'?: string | null;
    'resubmission'?: PharmacyBiResubmission | null;
    'reviewRequired'?: string | null;
    'rxGroup'?: PharmacyBiRxGroup | null;
    'specialtyCoverage'?: PharmacyBiSpecialtyCoverage | null;
    'specialtyPharmacy1'?: PharmacyBiSpecialtyPharmacyResult | null;
    'specialtyPharmacy2'?: PharmacyBiSpecialtyPharmacyResult | null;
    'specialtyPharmacy3'?: PharmacyBiSpecialtyPharmacyResult | null;
    'stepTherapy'?: PharmacyBiStepTherapy | null;
    'therapyAvailabilityDate'?: string | null;
    'tierExceptionProcess'?: string | null;
    'totalTier'?: string | null;
    'willCoverIfPrimaryDenies'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyBiResponseDetail>> = [
        {
            "name":     "additionalNotes",
            "baseName": "additionalNotes",
            "type":     "string"
        },
        {
            "name":     "annualBenefit",
            "baseName": "annualBenefit",
            "type":     "PharmacyBiAnnual"
        },
        {
            "name":     "appeal",
            "baseName": "appeal",
            "type":     "PharmacyBiAppeal"
        },
        {
            "name":     "bin",
            "baseName": "bin",
            "type":     "string"
        },
        {
            "name":     "coordinationOfBenefits",
            "baseName": "coordinationOfBenefits",
            "type":     "string"
        },
        {
            "name":     "coPayAppliesToOop",
            "baseName": "coPayAppliesToOop",
            "type":     "string"
        },
        {
            "name":     "copayWaivedAfterOop",
            "baseName": "copayWaivedAfterOop",
            "type":     "string"
        },
        {
            "name":     "daysSupplyPriced",
            "baseName": "daysSupplyPriced",
            "type":     "string"
        },
        {
            "name":     "deductible",
            "baseName": "deductible",
            "type":     "PharmacyBiDeductible"
        },
        {
            "name":     "denial",
            "baseName": "denial",
            "type":     "PharmacyBiDenial"
        },
        {
            "name":     "drugCoverageStatus",
            "baseName": "drugCoverageStatus",
            "type":     "string"
        },
        {
            "name":     "emid",
            "baseName": "emid",
            "type":     "string"
        },
        {
            "name":     "estimatedPatientPayAmount",
            "baseName": "estimatedPatientPayAmount",
            "type":     "string"
        },
        {
            "name":     "family",
            "baseName": "family",
            "type":     "PharmacyBiFamily"
        },
        {
            "name":     "formulary",
            "baseName": "formulary",
            "type":     "PharmacyBiFormulary"
        },
        {
            "name":     "individual",
            "baseName": "individual",
            "type":     "PharmacyBiIndividual"
        },
        {
            "name":     "initialCoverageLimit",
            "baseName": "initialCoverageLimit",
            "type":     "PharmacyBiInitialCoverageLimit"
        },
        {
            "name":     "isAccumulatorPlan",
            "baseName": "isAccumulatorPlan",
            "type":     "string"
        },
        {
            "name":     "isMaximizerPlan",
            "baseName": "isMaximizerPlan",
            "type":     "string"
        },
        {
            "name":     "isOnLowIncomeSubsidy",
            "baseName": "isOnLowIncomeSubsidy",
            "type":     "string"
        },
        {
            "name":     "lifetimeMaximum",
            "baseName": "lifetimeMaximum",
            "type":     "PharmacyBiLifetimeMaximum"
        },
        {
            "name":     "lowIncomeSubsidyLevel",
            "baseName": "lowIncomeSubsidyLevel",
            "type":     "string"
        },
        {
            "name":     "mailOrderPharmacy",
            "baseName": "mailOrderPharmacy",
            "type":     "PharmacyBiMailOrderPharmacy"
        },
        {
            "name":     "medicarePartD",
            "baseName": "medicarePartD",
            "type":     "PharmacyBiMedicarePartD"
        },
        {
            "name":     "newPlan",
            "baseName": "newPlan",
            "type":     "PharmacyBiNewPlan"
        },
        {
            "name":     "obtainPriorAuthorization",
            "baseName": "obtainPriorAuthorization",
            "type":     "PharmacyBiObtainPriorAuthorization"
        },
        {
            "name":     "obtainTierExceptionPhone",
            "baseName": "obtainTierExceptionPhone",
            "type":     "string"
        },
        {
            "name":     "otherInsuranceExists",
            "baseName": "otherInsuranceExists",
            "type":     "string"
        },
        {
            "name":     "payor",
            "baseName": "payor",
            "type":     "PharmacyBiPayorResult"
        },
        {
            "name":     "pbm",
            "baseName": "pbm",
            "type":     "PharmacyBiPbm"
        },
        {
            "name":     "pcn",
            "baseName": "pcn",
            "type":     "string"
        },
        {
            "name":     "peerToPeer",
            "baseName": "peerToPeer",
            "type":     "PharmacyBiPeerToPeer"
        },
        {
            "name":     "pharmacy",
            "baseName": "pharmacy",
            "type":     "PharmacyBiPharmacy"
        },
        {
            "name":     "plan",
            "baseName": "plan",
            "type":     "PharmacyBiPlan"
        },
        {
            "name":     "policy",
            "baseName": "policy",
            "type":     "PharmacyBiPolicy"
        },
        {
            "name":     "predeterminationSubmissionDate",
            "baseName": "predeterminationSubmissionDate",
            "type":     "string"
        },
        {
            "name":     "preferredDrugName",
            "baseName": "preferredDrugName",
            "type":     "string"
        },
        {
            "name":     "preferredDrugValue",
            "baseName": "preferredDrugValue",
            "type":     "string"
        },
        {
            "name":     "prescription",
            "baseName": "prescription",
            "type":     "PharmacyBiPrescriptionResult"
        },
        {
            "name":     "pricing",
            "baseName": "pricing",
            "type":     "PharmacyBiPricing"
        },
        {
            "name":     "priorAuthorization",
            "baseName": "priorAuthorization",
            "type":     "PharmacyBiPriorAuthorization"
        },
        {
            "name":     "productCoverage",
            "baseName": "productCoverage",
            "type":     "PharmacyBiProductCoverage"
        },
        {
            "name":     "productQuantityLimit",
            "baseName": "productQuantityLimit",
            "type":     "string"
        },
        {
            "name":     "productTier",
            "baseName": "productTier",
            "type":     "string"
        },
        {
            "name":     "quantity",
            "baseName": "quantity",
            "type":     "PharmacyBiQuantity"
        },
        {
            "name":     "rejectionCode",
            "baseName": "rejectionCode",
            "type":     "string"
        },
        {
            "name":     "resubmission",
            "baseName": "resubmission",
            "type":     "PharmacyBiResubmission"
        },
        {
            "name":     "reviewRequired",
            "baseName": "reviewRequired",
            "type":     "string"
        },
        {
            "name":     "rxGroup",
            "baseName": "rxGroup",
            "type":     "PharmacyBiRxGroup"
        },
        {
            "name":     "specialtyCoverage",
            "baseName": "specialtyCoverage",
            "type":     "PharmacyBiSpecialtyCoverage"
        },
        {
            "name":     "specialtyPharmacy1",
            "baseName": "specialtyPharmacy1",
            "type":     "PharmacyBiSpecialtyPharmacyResult"
        },
        {
            "name":     "specialtyPharmacy2",
            "baseName": "specialtyPharmacy2",
            "type":     "PharmacyBiSpecialtyPharmacyResult"
        },
        {
            "name":     "specialtyPharmacy3",
            "baseName": "specialtyPharmacy3",
            "type":     "PharmacyBiSpecialtyPharmacyResult"
        },
        {
            "name":     "stepTherapy",
            "baseName": "stepTherapy",
            "type":     "PharmacyBiStepTherapy"
        },
        {
            "name":     "therapyAvailabilityDate",
            "baseName": "therapyAvailabilityDate",
            "type":     "string"
        },
        {
            "name":     "tierExceptionProcess",
            "baseName": "tierExceptionProcess",
            "type":     "string"
        },
        {
            "name":     "totalTier",
            "baseName": "totalTier",
            "type":     "string"
        },
        {
            "name":     "willCoverIfPrimaryDenies",
            "baseName": "willCoverIfPrimaryDenies",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyBiResponseDetail.attributeTypeMap;
    }
}
