// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ClaimAdjudicationMedicalInsurance {
    'primaryInsuranceCarrier'?: string;
    'groupPlanName'?: string;
    'groupNumber'?: string;
    'policyNumber'?: string;
    'effectiveDate'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimAdjudicationMedicalInsurance>> = [
        {
            "name":     "primaryInsuranceCarrier",
            "baseName": "primaryInsuranceCarrier",
            "type":     "string"
        },
        {
            "name":     "groupPlanName",
            "baseName": "groupPlanName",
            "type":     "string"
        },
        {
            "name":     "groupNumber",
            "baseName": "groupNumber",
            "type":     "string"
        },
        {
            "name":     "policyNumber",
            "baseName": "policyNumber",
            "type":     "string"
        },
        {
            "name":     "effectiveDate",
            "baseName": "effectiveDate",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimAdjudicationMedicalInsurance.attributeTypeMap;
    }
}
