// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PayorCoverageAreaModel } from './payorCoverageAreaModel.js';
import type { PayorSharedAddressModel } from './payorSharedAddressModel.js';
import type { PayorSharedEmailAddressModel } from './payorSharedEmailAddressModel.js';
import type { PayorSharedPhoneModel } from './payorSharedPhoneModel.js';
import type { PayorSharedWebAddressModel } from './payorSharedWebAddressModel.js';
import type { PayorType } from './payorType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PayorModel {
    'payorId'?: number;
    'addresses'?: Array<PayorSharedAddressModel>;
    'phoneNumbers'?: Array<PayorSharedPhoneModel>;
    'emailAddresses'?: Array<PayorSharedEmailAddressModel>;
    'webAddresses'?: Array<PayorSharedWebAddressModel>;
    'coverageAreas'?: Array<PayorCoverageAreaModel>;
    'payorName'?: string;
    'payorType'?: PayorType;
    'payorBin'?: string;
    'payorPcn'?: string;
    'payorGroup'?: string;
    'isPlaceholder'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PayorModel>> = [
        {
            "name":     "payorId",
            "baseName": "payorId",
            "type":     "number"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<PayorSharedAddressModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<PayorSharedPhoneModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<PayorSharedEmailAddressModel>"
        },
        {
            "name":     "webAddresses",
            "baseName": "webAddresses",
            "type":     "Array<PayorSharedWebAddressModel>"
        },
        {
            "name":     "coverageAreas",
            "baseName": "coverageAreas",
            "type":     "Array<PayorCoverageAreaModel>"
        },
        {
            "name":     "payorName",
            "baseName": "payorName",
            "type":     "string"
        },
        {
            "name":     "payorType",
            "baseName": "payorType",
            "type":     "PayorType"
        },
        {
            "name":     "payorBin",
            "baseName": "payorBin",
            "type":     "string"
        },
        {
            "name":     "payorPcn",
            "baseName": "payorPcn",
            "type":     "string"
        },
        {
            "name":     "payorGroup",
            "baseName": "payorGroup",
            "type":     "string"
        },
        {
            "name":     "isPlaceholder",
            "baseName": "isPlaceholder",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return PayorModel.attributeTypeMap;
    }
}
