// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { NoteTypeCategory } from './noteTypeCategory.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class NoteTypeModel {
    'typeId'?: number;
    'typeCategory'?: NoteTypeCategory;
    'modifiedBy'?: number;
    'typeCode'?: string;
    'typeDescription'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof NoteTypeModel>> = [
        {
            "name":     "typeId",
            "baseName": "typeId",
            "type":     "number"
        },
        {
            "name":     "typeCategory",
            "baseName": "typeCategory",
            "type":     "NoteTypeCategory"
        },
        {
            "name":     "modifiedBy",
            "baseName": "modifiedBy",
            "type":     "number"
        },
        {
            "name":     "typeCode",
            "baseName": "typeCode",
            "type":     "string"
        },
        {
            "name":     "typeDescription",
            "baseName": "typeDescription",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return NoteTypeModel.attributeTypeMap;
    }
}
