// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EntlibResponseany } from './entlibResponseany.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class DocumentsV2FillablePdfGenerateFillablePdfResponse {
    'data': any | null;
    'success': boolean;
    'messages': Array<string> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof DocumentsV2FillablePdfGenerateFillablePdfResponse>> = [
        {
            "name":     "data",
            "baseName": "data",
            "type":     "any"
        },
        {
            "name":     "success",
            "baseName": "success",
            "type":     "boolean"
        },
        {
            "name":     "messages",
            "baseName": "messages",
            "type":     "Array<string>"
        },
    ];

    static getAttributeTypeMap() {
        return DocumentsV2FillablePdfGenerateFillablePdfResponse.attributeTypeMap;
    }
}
