// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EmdGender } from './emdGender.js';
import type { IvfTreatmentCycle } from './ivfTreatmentCycle.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EmdEnrollmentRequestModel {
    'iBondId': string;
    'iBondEnrollmentId': string;
    'patientFirstName': string;
    'patientLastName': string;
    'patientEmailAddress': string;
    'patientMobileNumber'?: string;
    'patientZipcode': string;
    'patientGender': EmdGender;
    'patientBirthDate': Moment;
    'isUndergoingIvfTreatment': boolean;
    'ivfTreatmentCycle'?: IvfTreatmentCycle;
    'isActiveOrRetiredMilitary': boolean;
    'prescriberName'?: string;
    'fertilityCenterName'?: string;
    'fertilityCenterEmail'?: string;
    'isUsingInsuranceForFertility': boolean;
    'annualHouseholdIncome': number;
    'householdNumber': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EmdEnrollmentRequestModel>> = [
        {
            "name":     "iBondId",
            "baseName": "iBondId",
            "type":     "string"
        },
        {
            "name":     "iBondEnrollmentId",
            "baseName": "iBondEnrollmentId",
            "type":     "string"
        },
        {
            "name":     "patientFirstName",
            "baseName": "patientFirstName",
            "type":     "string"
        },
        {
            "name":     "patientLastName",
            "baseName": "patientLastName",
            "type":     "string"
        },
        {
            "name":     "patientEmailAddress",
            "baseName": "patientEmailAddress",
            "type":     "string"
        },
        {
            "name":     "patientMobileNumber",
            "baseName": "patientMobileNumber",
            "type":     "string"
        },
        {
            "name":     "patientZipcode",
            "baseName": "patientZipcode",
            "type":     "string"
        },
        {
            "name":     "patientGender",
            "baseName": "patientGender",
            "type":     "EmdGender"
        },
        {
            "name":     "patientBirthDate",
            "baseName": "patientBirthDate",
            "type":     "Date"
        },
        {
            "name":     "isUndergoingIvfTreatment",
            "baseName": "isUndergoingIvfTreatment",
            "type":     "boolean"
        },
        {
            "name":     "ivfTreatmentCycle",
            "baseName": "ivfTreatmentCycle",
            "type":     "IvfTreatmentCycle"
        },
        {
            "name":     "isActiveOrRetiredMilitary",
            "baseName": "isActiveOrRetiredMilitary",
            "type":     "boolean"
        },
        {
            "name":     "prescriberName",
            "baseName": "prescriberName",
            "type":     "string"
        },
        {
            "name":     "fertilityCenterName",
            "baseName": "fertilityCenterName",
            "type":     "string"
        },
        {
            "name":     "fertilityCenterEmail",
            "baseName": "fertilityCenterEmail",
            "type":     "string"
        },
        {
            "name":     "isUsingInsuranceForFertility",
            "baseName": "isUsingInsuranceForFertility",
            "type":     "boolean"
        },
        {
            "name":     "annualHouseholdIncome",
            "baseName": "annualHouseholdIncome",
            "type":     "number"
        },
        {
            "name":     "householdNumber",
            "baseName": "householdNumber",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return EmdEnrollmentRequestModel.attributeTypeMap;
    }
}
