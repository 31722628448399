// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PlanType } from './planType.js';
import type { RoleType } from './roleType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PayorAccountPharmacyBenefitModel {
    'accountId'?: number;
    'payorId'?: number;
    'name'?: string;
    'isPrimary'?: boolean;
    'coverageOrder'?: number;
    'createDate'?: Moment;
    'bin'?: string;
    'pcn'?: string;
    'policyName'?: string;
    'dateOfBirth'?: Moment;
    'policyHolderFirstName'?: string;
    'policyHolderLastName'?: string;
    'relationshipToPatient'?: RoleType;
    'phone'?: string;
    'extension'?: string;
    'groupNumber'?: string;
    'policyNumber'?: string;
    'personCode'?: string;
    'planType'?: PlanType;
    'planEffectiveDate'?: Moment;
    'planExpirationDate'?: Moment;
    'planTerminationDate'?: Moment;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PayorAccountPharmacyBenefitModel>> = [
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "payorId",
            "baseName": "payorId",
            "type":     "number"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "isPrimary",
            "baseName": "isPrimary",
            "type":     "boolean"
        },
        {
            "name":     "coverageOrder",
            "baseName": "coverageOrder",
            "type":     "number"
        },
        {
            "name":     "createDate",
            "baseName": "createDate",
            "type":     "Date"
        },
        {
            "name":     "bin",
            "baseName": "bin",
            "type":     "string"
        },
        {
            "name":     "pcn",
            "baseName": "pcn",
            "type":     "string"
        },
        {
            "name":     "policyName",
            "baseName": "policyName",
            "type":     "string"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "Date"
        },
        {
            "name":     "policyHolderFirstName",
            "baseName": "policyHolderFirstName",
            "type":     "string"
        },
        {
            "name":     "policyHolderLastName",
            "baseName": "policyHolderLastName",
            "type":     "string"
        },
        {
            "name":     "relationshipToPatient",
            "baseName": "relationshipToPatient",
            "type":     "RoleType"
        },
        {
            "name":     "phone",
            "baseName": "phone",
            "type":     "string"
        },
        {
            "name":     "extension",
            "baseName": "extension",
            "type":     "string"
        },
        {
            "name":     "groupNumber",
            "baseName": "groupNumber",
            "type":     "string"
        },
        {
            "name":     "policyNumber",
            "baseName": "policyNumber",
            "type":     "string"
        },
        {
            "name":     "personCode",
            "baseName": "personCode",
            "type":     "string"
        },
        {
            "name":     "planType",
            "baseName": "planType",
            "type":     "PlanType"
        },
        {
            "name":     "planEffectiveDate",
            "baseName": "planEffectiveDate",
            "type":     "Date"
        },
        {
            "name":     "planExpirationDate",
            "baseName": "planExpirationDate",
            "type":     "Date"
        },
        {
            "name":     "planTerminationDate",
            "baseName": "planTerminationDate",
            "type":     "Date"
        },
    ];

    static getAttributeTypeMap() {
        return PayorAccountPharmacyBenefitModel.attributeTypeMap;
    }
}
