// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BasicEnrollmentGender } from './basicEnrollmentGender.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ClaimAdjudicationPatientDetails {
    'firstName'?: string;
    'lastName'?: string;
    'gender'?: BasicEnrollmentGender;
    'dateOfBirth'?: string;
    'streetAddress'?: string;
    'city'?: string;
    'state'?: string;
    'zipCode'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimAdjudicationPatientDetails>> = [
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "gender",
            "baseName": "gender",
            "type":     "BasicEnrollmentGender"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "string"
        },
        {
            "name":     "streetAddress",
            "baseName": "streetAddress",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "zipCode",
            "baseName": "zipCode",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimAdjudicationPatientDetails.attributeTypeMap;
    }
}
