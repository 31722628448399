// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ExtEnrollmentHybridEnrollmentResultModel {
    'bin'?: string;
    'sponsorNumber'?: string;
    'groupNumber'?: string;
    'totalPaidAmount'?: number;
    'pin'?: string;
    'medicalPayerId'?: string;
    'medicalMemberNumber'?: string;
    'medicalGroupNumber'?: string;
    'memberNumber'?: string;
    'patientId'?: string;
    'enrollmentId'?: string;
    'cardActivationDate'?: Moment;
    'cardEndDate'?: Moment;
    'totalBenefitAmount'?: number;
    'remainingBenefitAmount'?: number;
    'cardLastFourDigits'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtEnrollmentHybridEnrollmentResultModel>> = [
        {
            "name":     "bin",
            "baseName": "bin",
            "type":     "string"
        },
        {
            "name":     "sponsorNumber",
            "baseName": "sponsorNumber",
            "type":     "string"
        },
        {
            "name":     "groupNumber",
            "baseName": "groupNumber",
            "type":     "string"
        },
        {
            "name":     "totalPaidAmount",
            "baseName": "totalPaidAmount",
            "type":     "number"
        },
        {
            "name":     "pin",
            "baseName": "pin",
            "type":     "string"
        },
        {
            "name":     "medicalPayerId",
            "baseName": "medicalPayerId",
            "type":     "string"
        },
        {
            "name":     "medicalMemberNumber",
            "baseName": "medicalMemberNumber",
            "type":     "string"
        },
        {
            "name":     "medicalGroupNumber",
            "baseName": "medicalGroupNumber",
            "type":     "string"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "patientId",
            "baseName": "patientId",
            "type":     "string"
        },
        {
            "name":     "enrollmentId",
            "baseName": "enrollmentId",
            "type":     "string"
        },
        {
            "name":     "cardActivationDate",
            "baseName": "cardActivationDate",
            "type":     "Date"
        },
        {
            "name":     "cardEndDate",
            "baseName": "cardEndDate",
            "type":     "Date"
        },
        {
            "name":     "totalBenefitAmount",
            "baseName": "totalBenefitAmount",
            "type":     "number"
        },
        {
            "name":     "remainingBenefitAmount",
            "baseName": "remainingBenefitAmount",
            "type":     "number"
        },
        {
            "name":     "cardLastFourDigits",
            "baseName": "cardLastFourDigits",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ExtEnrollmentHybridEnrollmentResultModel.attributeTypeMap;
    }
}
