// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { TcsWrapperModelsAccumulatorModel } from './tcsWrapperModelsAccumulatorModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ExtCardActivationResultModel {
    'bin'?: string;
    'cardActivationDate'?: Moment;
    'cardEndDate'?: Moment;
    'sponsorNumber'?: string;
    'memberNumber'?: string;
    'groupNumber'?: string;
    'totalBenefitAmount'?: number;
    'remainingBenefitAmount'?: number;
    'totalPaidAmount'?: number;
    'accumulators'?: Array<TcsWrapperModelsAccumulatorModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtCardActivationResultModel>> = [
        {
            "name":     "bin",
            "baseName": "bin",
            "type":     "string"
        },
        {
            "name":     "cardActivationDate",
            "baseName": "cardActivationDate",
            "type":     "Date"
        },
        {
            "name":     "cardEndDate",
            "baseName": "cardEndDate",
            "type":     "Date"
        },
        {
            "name":     "sponsorNumber",
            "baseName": "sponsorNumber",
            "type":     "string"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "groupNumber",
            "baseName": "groupNumber",
            "type":     "string"
        },
        {
            "name":     "totalBenefitAmount",
            "baseName": "totalBenefitAmount",
            "type":     "number"
        },
        {
            "name":     "remainingBenefitAmount",
            "baseName": "remainingBenefitAmount",
            "type":     "number"
        },
        {
            "name":     "totalPaidAmount",
            "baseName": "totalPaidAmount",
            "type":     "number"
        },
        {
            "name":     "accumulators",
            "baseName": "accumulators",
            "type":     "Array<TcsWrapperModelsAccumulatorModel>"
        },
    ];

    static getAttributeTypeMap() {
        return ExtCardActivationResultModel.attributeTypeMap;
    }
}
