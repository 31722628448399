// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Model for details about a Document related to the Account.
*/
export class DocumentRelationshipModel {
    /**
    * @description Document identifier
    */
    'documentId'?: number;
    /**
    * @description Comment
    */
    'comment'?: string | null;
    /**
    * @description IsHidden from UI
    */
    'isHidden'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof DocumentRelationshipModel>> = [
        {
            "name":     "documentId",
            "baseName": "documentId",
            "type":     "number"
        },
        {
            "name":     "comment",
            "baseName": "comment",
            "type":     "string"
        },
        {
            "name":     "isHidden",
            "baseName": "isHidden",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return DocumentRelationshipModel.attributeTypeMap;
    }
}
