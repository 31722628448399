// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ContactDatePreferenceType } from './contactDatePreferenceType.js';
import type { ContactInfoPurposeType } from './contactInfoPurposeType.js';
import type { ContactInfoType } from './contactInfoType.js';
import type { ContactTimePreferenceType } from './contactTimePreferenceType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class TrialcardPatientV3ModelsSharedPhoneUpsearchModel {
    'phoneId'?: number | null;
    'phoneType'?: ContactInfoType;
    'phonePurpose'?: ContactInfoPurposeType;
    'phoneNumber'?: string | null;
    'extension'?: string | null;
    'contactDatePreferenceType'?: ContactDatePreferenceType;
    'bestDaysToCall'?: string | null;
    'contactTimePreferenceType'?: ContactTimePreferenceType;
    'bestTimeToCall'?: string | null;
    'language'?: string | null;
    'timeZone'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof TrialcardPatientV3ModelsSharedPhoneUpsearchModel>> = [
        {
            "name":     "phoneId",
            "baseName": "phoneId",
            "type":     "number"
        },
        {
            "name":     "phoneType",
            "baseName": "phoneType",
            "type":     "ContactInfoType"
        },
        {
            "name":     "phonePurpose",
            "baseName": "phonePurpose",
            "type":     "ContactInfoPurposeType"
        },
        {
            "name":     "phoneNumber",
            "baseName": "phoneNumber",
            "type":     "string"
        },
        {
            "name":     "extension",
            "baseName": "extension",
            "type":     "string"
        },
        {
            "name":     "contactDatePreferenceType",
            "baseName": "contactDatePreferenceType",
            "type":     "ContactDatePreferenceType"
        },
        {
            "name":     "bestDaysToCall",
            "baseName": "bestDaysToCall",
            "type":     "string"
        },
        {
            "name":     "contactTimePreferenceType",
            "baseName": "contactTimePreferenceType",
            "type":     "ContactTimePreferenceType"
        },
        {
            "name":     "bestTimeToCall",
            "baseName": "bestTimeToCall",
            "type":     "string"
        },
        {
            "name":     "language",
            "baseName": "language",
            "type":     "string"
        },
        {
            "name":     "timeZone",
            "baseName": "timeZone",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return TrialcardPatientV3ModelsSharedPhoneUpsearchModel.attributeTypeMap;
    }
}
