// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { DocumentType } from './documentType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class DocumentV2UploadRequestModel {
    'documentData': string;
    'documentType': DocumentType;
    'identifierText'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof DocumentV2UploadRequestModel>> = [
        {
            "name":     "documentData",
            "baseName": "documentData",
            "type":     "string"
        },
        {
            "name":     "documentType",
            "baseName": "documentType",
            "type":     "DocumentType"
        },
        {
            "name":     "identifierText",
            "baseName": "identifierText",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return DocumentV2UploadRequestModel.attributeTypeMap;
    }
}
