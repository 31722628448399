// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PriorAuthorizationDiagnosis {
    'primaryIcdCode'?: string | null;
    'primaryIcdDescription'?: string | null;
    'secondaryIcdCode'?: string | null;
    'secondaryIcdDescription'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PriorAuthorizationDiagnosis>> = [
        {
            "name":     "primaryIcdCode",
            "baseName": "primaryIcdCode",
            "type":     "string"
        },
        {
            "name":     "primaryIcdDescription",
            "baseName": "primaryIcdDescription",
            "type":     "string"
        },
        {
            "name":     "secondaryIcdCode",
            "baseName": "secondaryIcdCode",
            "type":     "string"
        },
        {
            "name":     "secondaryIcdDescription",
            "baseName": "secondaryIcdDescription",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PriorAuthorizationDiagnosis.attributeTypeMap;
    }
}
