// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PharmacyCardFinderPatientRequest } from './pharmacyCardFinderPatientRequest.js';
import type { PharmacyCardFinderPrescriber } from './pharmacyCardFinderPrescriber.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyCardFinderRequestBase {
    'daysSupply'?: string;
    'ndc': string;
    'patient': PharmacyCardFinderPatientRequest | null;
    'pharmacyNcpDpId'?: string;
    'pharmacyNpi': string;
    'prescriber'?: PharmacyCardFinderPrescriber | null;
    'project'?: string;
    'quantity'?: string;
    'clientId1'?: string;
    'clientId2'?: string;
    'clientId3'?: string;
    'clientId4'?: string;
    'clientId5'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyCardFinderRequestBase>> = [
        {
            "name":     "daysSupply",
            "baseName": "daysSupply",
            "type":     "string"
        },
        {
            "name":     "ndc",
            "baseName": "ndc",
            "type":     "string"
        },
        {
            "name":     "patient",
            "baseName": "patient",
            "type":     "PharmacyCardFinderPatientRequest"
        },
        {
            "name":     "pharmacyNcpDpId",
            "baseName": "pharmacyNcpDpId",
            "type":     "string"
        },
        {
            "name":     "pharmacyNpi",
            "baseName": "pharmacyNpi",
            "type":     "string"
        },
        {
            "name":     "prescriber",
            "baseName": "prescriber",
            "type":     "PharmacyCardFinderPrescriber"
        },
        {
            "name":     "project",
            "baseName": "project",
            "type":     "string"
        },
        {
            "name":     "quantity",
            "baseName": "quantity",
            "type":     "string"
        },
        {
            "name":     "clientId1",
            "baseName": "clientId1",
            "type":     "string"
        },
        {
            "name":     "clientId2",
            "baseName": "clientId2",
            "type":     "string"
        },
        {
            "name":     "clientId3",
            "baseName": "clientId3",
            "type":     "string"
        },
        {
            "name":     "clientId4",
            "baseName": "clientId4",
            "type":     "string"
        },
        {
            "name":     "clientId5",
            "baseName": "clientId5",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyCardFinderRequestBase.attributeTypeMap;
    }
}
