// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ExtEnrollmentHybridEnrollmentHistoryModel } from './extEnrollmentHybridEnrollmentHistoryModel.js';
import type { ExtEnrollmentHybridPatientSearchDemographicsModel } from './extEnrollmentHybridPatientSearchDemographicsModel.js';
import type { ExtEnrollmentMedBenClientIdentifiersModel } from './extEnrollmentMedBenClientIdentifiersModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PatientLookupResponseModel {
    'patientId': string;
    'memberNumber': string;
    'demographics': ExtEnrollmentHybridPatientSearchDemographicsModel | null;
    'enrollments': Array<ExtEnrollmentHybridEnrollmentHistoryModel>;
    'clientIdentifiers': ExtEnrollmentMedBenClientIdentifiersModel | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PatientLookupResponseModel>> = [
        {
            "name":     "patientId",
            "baseName": "patientId",
            "type":     "string"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "demographics",
            "baseName": "demographics",
            "type":     "ExtEnrollmentHybridPatientSearchDemographicsModel"
        },
        {
            "name":     "enrollments",
            "baseName": "enrollments",
            "type":     "Array<ExtEnrollmentHybridEnrollmentHistoryModel>"
        },
        {
            "name":     "clientIdentifiers",
            "baseName": "clientIdentifiers",
            "type":     "ExtEnrollmentMedBenClientIdentifiersModel"
        },
    ];

    static getAttributeTypeMap() {
        return PatientLookupResponseModel.attributeTypeMap;
    }
}
