// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AccountExternalIdType } from './accountExternalIdType.js';
import type { Gender } from './gender.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PatientsSearchPatientsRequestModel {
    'maxRecordCount': number;
    'firstName'?: string;
    'lastName'?: string;
    'dateOfBirth'?: Moment;
    'city'?: string;
    'state'?: string;
    'zip'?: string;
    'gender'?: Gender;
    'accountExternalIdType'?: AccountExternalIdType;
    'accountExternalId'?: string;
    'prescriberId'?: number;
    'siteId'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PatientsSearchPatientsRequestModel>> = [
        {
            "name":     "maxRecordCount",
            "baseName": "maxRecordCount",
            "type":     "number"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "Date"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "zip",
            "baseName": "zip",
            "type":     "string"
        },
        {
            "name":     "gender",
            "baseName": "gender",
            "type":     "Gender"
        },
        {
            "name":     "accountExternalIdType",
            "baseName": "accountExternalIdType",
            "type":     "AccountExternalIdType"
        },
        {
            "name":     "accountExternalId",
            "baseName": "accountExternalId",
            "type":     "string"
        },
        {
            "name":     "prescriberId",
            "baseName": "prescriberId",
            "type":     "number"
        },
        {
            "name":     "siteId",
            "baseName": "siteId",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return PatientsSearchPatientsRequestModel.attributeTypeMap;
    }
}
