// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiPreDetermination {
    'approvedQuantity'?: string;
    'approvedQuantityUsed'?: string;
    'available'?: boolean;
    'endDate'?: Moment;
    'number'?: string;
    'onFile'?: boolean;
    'renewalProcessExists'?: boolean;
    'required'?: boolean;
    'requirement'?: string;
    'startDate'?: Moment;
    'turnaroundTime'?: string;
    'receivedDate'?: string;
    'completionDate'?: string;
    'onFileApplies'?: boolean;
    'providerNameOnFile'?: string;
    'addressOnFile'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiPreDetermination>> = [
        {
            "name":     "approvedQuantity",
            "baseName": "approvedQuantity",
            "type":     "string"
        },
        {
            "name":     "approvedQuantityUsed",
            "baseName": "approvedQuantityUsed",
            "type":     "string"
        },
        {
            "name":     "available",
            "baseName": "available",
            "type":     "boolean"
        },
        {
            "name":     "endDate",
            "baseName": "endDate",
            "type":     "Date"
        },
        {
            "name":     "number",
            "baseName": "number",
            "type":     "string"
        },
        {
            "name":     "onFile",
            "baseName": "onFile",
            "type":     "boolean"
        },
        {
            "name":     "renewalProcessExists",
            "baseName": "renewalProcessExists",
            "type":     "boolean"
        },
        {
            "name":     "required",
            "baseName": "required",
            "type":     "boolean"
        },
        {
            "name":     "requirement",
            "baseName": "requirement",
            "type":     "string"
        },
        {
            "name":     "startDate",
            "baseName": "startDate",
            "type":     "Date"
        },
        {
            "name":     "turnaroundTime",
            "baseName": "turnaroundTime",
            "type":     "string"
        },
        {
            "name":     "receivedDate",
            "baseName": "receivedDate",
            "type":     "string"
        },
        {
            "name":     "completionDate",
            "baseName": "completionDate",
            "type":     "string"
        },
        {
            "name":     "onFileApplies",
            "baseName": "onFileApplies",
            "type":     "boolean"
        },
        {
            "name":     "providerNameOnFile",
            "baseName": "providerNameOnFile",
            "type":     "string"
        },
        {
            "name":     "addressOnFile",
            "baseName": "addressOnFile",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiPreDetermination.attributeTypeMap;
    }
}
