// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyCardFinderResultSearchRequest {
    'transactionCorrelationId': number;
    'mock'?: boolean;
    'clientId1'?: string;
    'clientId2'?: string;
    'clientId3'?: string;
    'clientId4'?: string;
    'clientId5'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyCardFinderResultSearchRequest>> = [
        {
            "name":     "transactionCorrelationId",
            "baseName": "transactionCorrelationId",
            "type":     "number"
        },
        {
            "name":     "mock",
            "baseName": "mock",
            "type":     "boolean"
        },
        {
            "name":     "clientId1",
            "baseName": "clientId1",
            "type":     "string"
        },
        {
            "name":     "clientId2",
            "baseName": "clientId2",
            "type":     "string"
        },
        {
            "name":     "clientId3",
            "baseName": "clientId3",
            "type":     "string"
        },
        {
            "name":     "clientId4",
            "baseName": "clientId4",
            "type":     "string"
        },
        {
            "name":     "clientId5",
            "baseName": "clientId5",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyCardFinderResultSearchRequest.attributeTypeMap;
    }
}
