// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AccountExternalIdType } from './accountExternalIdType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Account External ID Model
*/
export class AccountExternalIdModel {
    'externalIdType'?: AccountExternalIdType;
    /**
    * @description External ID Value
    */
    'externalId'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof AccountExternalIdModel>> = [
        {
            "name":     "externalIdType",
            "baseName": "externalIdType",
            "type":     "AccountExternalIdType"
        },
        {
            "name":     "externalId",
            "baseName": "externalId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return AccountExternalIdModel.attributeTypeMap;
    }
}
