// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ExtEnrollmentHybridSiteEnrollmentModel } from './extEnrollmentHybridSiteEnrollmentModel.js';
import type { TCSSource } from './tCSSource.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ExtEnrollmentHybridClientIdentifiersModel {
    'sites': Array<ExtEnrollmentHybridSiteEnrollmentModel>;
    'sourceId'?: TCSSource;
    'alternateId'?: string;
    'alternateIdType'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtEnrollmentHybridClientIdentifiersModel>> = [
        {
            "name":     "sites",
            "baseName": "sites",
            "type":     "Array<ExtEnrollmentHybridSiteEnrollmentModel>"
        },
        {
            "name":     "sourceId",
            "baseName": "sourceId",
            "type":     "TCSSource"
        },
        {
            "name":     "alternateId",
            "baseName": "alternateId",
            "type":     "string"
        },
        {
            "name":     "alternateIdType",
            "baseName": "alternateIdType",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ExtEnrollmentHybridClientIdentifiersModel.attributeTypeMap;
    }
}
