// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { HhsAuthorizedBy } from './hhsAuthorizedBy.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class HhsAttestations {
    'correspondenceOptOut'?: boolean;
    'patientInsured': boolean;
    'patientHasSecondaryCoverage': boolean;
    'signersFullName': string;
    'providerWitnessedSignature'?: boolean;
    'providerInitials'?: string;
    'policyHolderName'?: string;
    'consentAuthorizedBy': HhsAuthorizedBy;
    'authorizorRelationshipToPatient'?: string;
    'hipaaConsentAttestation': boolean;
    'textMessageOptIn'?: boolean;
    'physicalCardOptOut': boolean;
    'applicantDeclarationsAttestation': boolean;
    'providerCertifiedNecessity'?: boolean;
    'patientEligible': boolean;
    'patientNotEligibleReason'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof HhsAttestations>> = [
        {
            "name":     "correspondenceOptOut",
            "baseName": "correspondenceOptOut",
            "type":     "boolean"
        },
        {
            "name":     "patientInsured",
            "baseName": "patientInsured",
            "type":     "boolean"
        },
        {
            "name":     "patientHasSecondaryCoverage",
            "baseName": "patientHasSecondaryCoverage",
            "type":     "boolean"
        },
        {
            "name":     "signersFullName",
            "baseName": "signersFullName",
            "type":     "string"
        },
        {
            "name":     "providerWitnessedSignature",
            "baseName": "providerWitnessedSignature",
            "type":     "boolean"
        },
        {
            "name":     "providerInitials",
            "baseName": "providerInitials",
            "type":     "string"
        },
        {
            "name":     "policyHolderName",
            "baseName": "policyHolderName",
            "type":     "string"
        },
        {
            "name":     "consentAuthorizedBy",
            "baseName": "consentAuthorizedBy",
            "type":     "HhsAuthorizedBy"
        },
        {
            "name":     "authorizorRelationshipToPatient",
            "baseName": "authorizorRelationshipToPatient",
            "type":     "string"
        },
        {
            "name":     "hipaaConsentAttestation",
            "baseName": "hipaaConsentAttestation",
            "type":     "boolean"
        },
        {
            "name":     "textMessageOptIn",
            "baseName": "textMessageOptIn",
            "type":     "boolean"
        },
        {
            "name":     "physicalCardOptOut",
            "baseName": "physicalCardOptOut",
            "type":     "boolean"
        },
        {
            "name":     "applicantDeclarationsAttestation",
            "baseName": "applicantDeclarationsAttestation",
            "type":     "boolean"
        },
        {
            "name":     "providerCertifiedNecessity",
            "baseName": "providerCertifiedNecessity",
            "type":     "boolean"
        },
        {
            "name":     "patientEligible",
            "baseName": "patientEligible",
            "type":     "boolean"
        },
        {
            "name":     "patientNotEligibleReason",
            "baseName": "patientNotEligibleReason",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return HhsAttestations.attributeTypeMap;
    }
}
