// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ClaimStagingAnsi837MedicalClaimServiceLineModel } from './claimStagingAnsi837MedicalClaimServiceLineModel.js';
import type { ClaimStagingAnsi837PatientModel } from './claimStagingAnsi837PatientModel.js';
import type { ClaimStagingAnsi837PrimaryInsuranceModel } from './claimStagingAnsi837PrimaryInsuranceModel.js';
import type { ClaimStagingAnsi837ProviderModel } from './claimStagingAnsi837ProviderModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class Ansi837MedicalClaimRequestModel {
    'accountId': number;
    'documentIds'?: Array<number>;
    /**
    * @description Case Id
    */
    'caseId'?: number;
    'ansi837PatientData'?: ClaimStagingAnsi837PatientModel | null;
    'ansi837PrimaryInsuranceData'?: ClaimStagingAnsi837PrimaryInsuranceModel | null;
    'ansi837ProviderData'?: ClaimStagingAnsi837ProviderModel | null;
    /**
    * @description Type of bill
    */
    'typeOfBill'?: string | null;
    /**
    * @description Diagnosis Code
    */
    'icdIndicator'?: string | null;
    /**
    * @description Charged amount (claims lines totalled). Also known as \\\"Billed Amount\\\"
    */
    'totalCharge'?: number;
    /**
    * @description Balance due
    */
    'balanceDue'?: number;
    /**
    * @description Itemized list of medical services  TC.Enterprise.Claim.Service.Public.Models.Staging.Ansi837MedicalClaimServiceLineModel
    */
    'medicalClaimServices'?: Array<ClaimStagingAnsi837MedicalClaimServiceLineModel> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof Ansi837MedicalClaimRequestModel>> = [
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "documentIds",
            "baseName": "documentIds",
            "type":     "Array<number>"
        },
        {
            "name":     "caseId",
            "baseName": "caseId",
            "type":     "number"
        },
        {
            "name":     "ansi837PatientData",
            "baseName": "ansi837PatientData",
            "type":     "ClaimStagingAnsi837PatientModel"
        },
        {
            "name":     "ansi837PrimaryInsuranceData",
            "baseName": "ansi837PrimaryInsuranceData",
            "type":     "ClaimStagingAnsi837PrimaryInsuranceModel"
        },
        {
            "name":     "ansi837ProviderData",
            "baseName": "ansi837ProviderData",
            "type":     "ClaimStagingAnsi837ProviderModel"
        },
        {
            "name":     "typeOfBill",
            "baseName": "typeOfBill",
            "type":     "string"
        },
        {
            "name":     "icdIndicator",
            "baseName": "icdIndicator",
            "type":     "string"
        },
        {
            "name":     "totalCharge",
            "baseName": "totalCharge",
            "type":     "number"
        },
        {
            "name":     "balanceDue",
            "baseName": "balanceDue",
            "type":     "number"
        },
        {
            "name":     "medicalClaimServices",
            "baseName": "medicalClaimServices",
            "type":     "Array<ClaimStagingAnsi837MedicalClaimServiceLineModel>"
        },
    ];

    static getAttributeTypeMap() {
        return Ansi837MedicalClaimRequestModel.attributeTypeMap;
    }
}
