// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Gender } from './gender.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class IIsPatientRegisteredRequestModel {
    'firstName': string;
    'lastName': string;
    'dob': Moment;
    'gender': Gender;
    'zip': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof IIsPatientRegisteredRequestModel>> = [
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "dob",
            "baseName": "dob",
            "type":     "Date"
        },
        {
            "name":     "gender",
            "baseName": "gender",
            "type":     "Gender"
        },
        {
            "name":     "zip",
            "baseName": "zip",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return IIsPatientRegisteredRequestModel.attributeTypeMap;
    }
}
