// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EnterpriseCoordinatorPrescriberServiceModelsPrescriberLicenseUpsearchModel } from './enterpriseCoordinatorPrescriberServiceModelsPrescriberLicenseUpsearchModel.js';
import type { EnterpriseCoordinatorSharedAddressUpsearchModel } from './enterpriseCoordinatorSharedAddressUpsearchModel.js';
import type { EnterpriseCoordinatorSharedEmailAddressUpsearchModel } from './enterpriseCoordinatorSharedEmailAddressUpsearchModel.js';
import type { EnterpriseCoordinatorSharedExternalIdUpsearchModel } from './enterpriseCoordinatorSharedExternalIdUpsearchModel.js';
import type { EnterpriseCoordinatorSharedPhoneUpsearchModel } from './enterpriseCoordinatorSharedPhoneUpsearchModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel {
    'accountRelationshipIsPrimary'?: boolean;
    'prescriberId'?: number;
    'firstName'?: string;
    'middleName'?: string;
    'lastName'?: string;
    'suffix'?: string;
    'nickname'?: string;
    'npi'?: string;
    'taxId'?: string;
    'prescriberLicenses'?: Array<EnterpriseCoordinatorPrescriberServiceModelsPrescriberLicenseUpsearchModel>;
    'externalIds'?: Array<EnterpriseCoordinatorSharedExternalIdUpsearchModel>;
    'addresses'?: Array<EnterpriseCoordinatorSharedAddressUpsearchModel>;
    'phoneNumbers'?: Array<EnterpriseCoordinatorSharedPhoneUpsearchModel>;
    'emailAddresses'?: Array<EnterpriseCoordinatorSharedEmailAddressUpsearchModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel>> = [
        {
            "name":     "accountRelationshipIsPrimary",
            "baseName": "accountRelationshipIsPrimary",
            "type":     "boolean"
        },
        {
            "name":     "prescriberId",
            "baseName": "prescriberId",
            "type":     "number"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "middleName",
            "baseName": "middleName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "suffix",
            "baseName": "suffix",
            "type":     "string"
        },
        {
            "name":     "nickname",
            "baseName": "nickname",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "taxId",
            "baseName": "taxId",
            "type":     "string"
        },
        {
            "name":     "prescriberLicenses",
            "baseName": "prescriberLicenses",
            "type":     "Array<EnterpriseCoordinatorPrescriberServiceModelsPrescriberLicenseUpsearchModel>"
        },
        {
            "name":     "externalIds",
            "baseName": "externalIds",
            "type":     "Array<EnterpriseCoordinatorSharedExternalIdUpsearchModel>"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<EnterpriseCoordinatorSharedAddressUpsearchModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<EnterpriseCoordinatorSharedPhoneUpsearchModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<EnterpriseCoordinatorSharedEmailAddressUpsearchModel>"
        },
    ];

    static getAttributeTypeMap() {
        return EnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel.attributeTypeMap;
    }
}
