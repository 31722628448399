// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PriorAuthorizationFollowUpDiagnosisCodeRequest } from './priorAuthorizationFollowUpDiagnosisCodeRequest.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PriorAuthorizationFollowUpDiagnosis {
    'cptCodes'?: Array<PriorAuthorizationFollowUpDiagnosisCodeRequest>;
    'jCodes'?: Array<PriorAuthorizationFollowUpDiagnosisCodeRequest>;
    'primaryIcdCode'?: string | null;
    'primaryIcdDescription'?: string | null;
    'secondaryIcdCode'?: string | null;
    'secondaryIcdDescription'?: string | null;
    'treatmentDate': Moment | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PriorAuthorizationFollowUpDiagnosis>> = [
        {
            "name":     "cptCodes",
            "baseName": "cptCodes",
            "type":     "Array<PriorAuthorizationFollowUpDiagnosisCodeRequest>"
        },
        {
            "name":     "jCodes",
            "baseName": "jCodes",
            "type":     "Array<PriorAuthorizationFollowUpDiagnosisCodeRequest>"
        },
        {
            "name":     "primaryIcdCode",
            "baseName": "primaryIcdCode",
            "type":     "string"
        },
        {
            "name":     "primaryIcdDescription",
            "baseName": "primaryIcdDescription",
            "type":     "string"
        },
        {
            "name":     "secondaryIcdCode",
            "baseName": "secondaryIcdCode",
            "type":     "string"
        },
        {
            "name":     "secondaryIcdDescription",
            "baseName": "secondaryIcdDescription",
            "type":     "string"
        },
        {
            "name":     "treatmentDate",
            "baseName": "treatmentDate",
            "type":     "Date"
        },
    ];

    static getAttributeTypeMap() {
        return PriorAuthorizationFollowUpDiagnosis.attributeTypeMap;
    }
}
