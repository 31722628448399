// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiSpendDown {
    'exist'?: boolean;
    'met'?: number;
    'total'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiSpendDown>> = [
        {
            "name":     "exist",
            "baseName": "exist",
            "type":     "boolean"
        },
        {
            "name":     "met",
            "baseName": "met",
            "type":     "number"
        },
        {
            "name":     "total",
            "baseName": "total",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiSpendDown.attributeTypeMap;
    }
}
