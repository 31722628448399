// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyBiProductCoverage {
    'code'?: string | null;
    'covered'?: string | null;
    'mailOrderCoInsurance'?: string | null;
    'mailOrderPharmacyCoPay'?: string | null;
    'mailOrderCovered'?: string | null;
    'reasonForNonCoverage'?: string | null;
    'retailPharmacyCoInsurance'?: string | null;
    'retailPharmacyCoPay'?: string | null;
    'retailPharmacyCovered'?: string | null;
    'specialtyPharmacyCoInsurance'?: string | null;
    'specialtyPharmacyCopay'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyBiProductCoverage>> = [
        {
            "name":     "code",
            "baseName": "code",
            "type":     "string"
        },
        {
            "name":     "covered",
            "baseName": "covered",
            "type":     "string"
        },
        {
            "name":     "mailOrderCoInsurance",
            "baseName": "mailOrderCoInsurance",
            "type":     "string"
        },
        {
            "name":     "mailOrderPharmacyCoPay",
            "baseName": "mailOrderPharmacyCoPay",
            "type":     "string"
        },
        {
            "name":     "mailOrderCovered",
            "baseName": "mailOrderCovered",
            "type":     "string"
        },
        {
            "name":     "reasonForNonCoverage",
            "baseName": "reasonForNonCoverage",
            "type":     "string"
        },
        {
            "name":     "retailPharmacyCoInsurance",
            "baseName": "retailPharmacyCoInsurance",
            "type":     "string"
        },
        {
            "name":     "retailPharmacyCoPay",
            "baseName": "retailPharmacyCoPay",
            "type":     "string"
        },
        {
            "name":     "retailPharmacyCovered",
            "baseName": "retailPharmacyCovered",
            "type":     "string"
        },
        {
            "name":     "specialtyPharmacyCoInsurance",
            "baseName": "specialtyPharmacyCoInsurance",
            "type":     "string"
        },
        {
            "name":     "specialtyPharmacyCopay",
            "baseName": "specialtyPharmacyCopay",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyBiProductCoverage.attributeTypeMap;
    }
}
