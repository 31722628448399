// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { DocumentGroupSource } from './documentGroupSource.js';
import type { DocumentGroupStatus } from './documentGroupStatus.js';
import type { DocumentStatus } from './documentStatus.js';
import type { TransmissionType } from './transmissionType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class DocumentCreateModel {
    'uploadSession'?: string;
    'documentData'?: string;
    'documentGroupSource'?: DocumentGroupSource;
    'documentGroupReceivedDate'?: Moment;
    'documentOriginalFileName'?: string;
    'documentPageCount'?: number;
    'transmissionType'?: TransmissionType;
    'documentTypeId'?: number;
    'documentStatus'?: DocumentStatus;
    'documentGroupStatus'?: DocumentGroupStatus;
    'faxAni'?: string;
    'faxDns'?: string;
    'intakeLayoutId'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof DocumentCreateModel>> = [
        {
            "name":     "uploadSession",
            "baseName": "uploadSession",
            "type":     "string"
        },
        {
            "name":     "documentData",
            "baseName": "documentData",
            "type":     "string"
        },
        {
            "name":     "documentGroupSource",
            "baseName": "documentGroupSource",
            "type":     "DocumentGroupSource"
        },
        {
            "name":     "documentGroupReceivedDate",
            "baseName": "documentGroupReceivedDate",
            "type":     "Date"
        },
        {
            "name":     "documentOriginalFileName",
            "baseName": "documentOriginalFileName",
            "type":     "string"
        },
        {
            "name":     "documentPageCount",
            "baseName": "documentPageCount",
            "type":     "number"
        },
        {
            "name":     "transmissionType",
            "baseName": "transmissionType",
            "type":     "TransmissionType"
        },
        {
            "name":     "documentTypeId",
            "baseName": "documentTypeId",
            "type":     "number"
        },
        {
            "name":     "documentStatus",
            "baseName": "documentStatus",
            "type":     "DocumentStatus"
        },
        {
            "name":     "documentGroupStatus",
            "baseName": "documentGroupStatus",
            "type":     "DocumentGroupStatus"
        },
        {
            "name":     "faxAni",
            "baseName": "faxAni",
            "type":     "string"
        },
        {
            "name":     "faxDns",
            "baseName": "faxDns",
            "type":     "string"
        },
        {
            "name":     "intakeLayoutId",
            "baseName": "intakeLayoutId",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return DocumentCreateModel.attributeTypeMap;
    }
}
