// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Gender } from './gender.js';
import type { SharedAddressAccountDataModel } from './sharedAddressAccountDataModel.js';
import type { SharedAddressPatientDataModel } from './sharedAddressPatientDataModel.js';
import type { SharedContactAccountDataModel } from './sharedContactAccountDataModel.js';
import type { SharedEmailAddressPatientDataModel } from './sharedEmailAddressPatientDataModel.js';
import type { SharedPhoneDataModel } from './sharedPhoneDataModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PatientUpSearchRequestModel {
    'accountId': number;
    'firstName'?: string;
    'middleName'?: string;
    'lastName'?: string;
    'suffix'?: string;
    'nickname'?: string;
    'dateOfBirth'?: Moment;
    'gender'?: Gender;
    'primaryLanguageId'?: number;
    'isUsResident'?: boolean;
    'householdSize'?: number;
    'raceCode'?: string;
    'ethnicityCode'?: string;
    'addresses': Array<SharedAddressPatientDataModel>;
    'shippingAddresses': Array<SharedAddressAccountDataModel>;
    'emailAddresses': Array<SharedEmailAddressPatientDataModel>;
    'contacts': Array<SharedContactAccountDataModel>;
    'phoneNumbers': Array<SharedPhoneDataModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PatientUpSearchRequestModel>> = [
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "middleName",
            "baseName": "middleName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "suffix",
            "baseName": "suffix",
            "type":     "string"
        },
        {
            "name":     "nickname",
            "baseName": "nickname",
            "type":     "string"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "Date"
        },
        {
            "name":     "gender",
            "baseName": "gender",
            "type":     "Gender"
        },
        {
            "name":     "primaryLanguageId",
            "baseName": "primaryLanguageId",
            "type":     "number"
        },
        {
            "name":     "isUsResident",
            "baseName": "isUsResident",
            "type":     "boolean"
        },
        {
            "name":     "householdSize",
            "baseName": "householdSize",
            "type":     "number"
        },
        {
            "name":     "raceCode",
            "baseName": "raceCode",
            "type":     "string"
        },
        {
            "name":     "ethnicityCode",
            "baseName": "ethnicityCode",
            "type":     "string"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<SharedAddressPatientDataModel>"
        },
        {
            "name":     "shippingAddresses",
            "baseName": "shippingAddresses",
            "type":     "Array<SharedAddressAccountDataModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<SharedEmailAddressPatientDataModel>"
        },
        {
            "name":     "contacts",
            "baseName": "contacts",
            "type":     "Array<SharedContactAccountDataModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<SharedPhoneDataModel>"
        },
    ];

    static getAttributeTypeMap() {
        return PatientUpSearchRequestModel.attributeTypeMap;
    }
}
