// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RoleType } from './roleType.js';
import type { SiteSharedAddressUpsearchModel } from './siteSharedAddressUpsearchModel.js';
import type { SiteSharedContactUpsearchModel } from './siteSharedContactUpsearchModel.js';
import type { SiteSharedEmailAddressUpsearchModel } from './siteSharedEmailAddressUpsearchModel.js';
import type { SiteSharedExternalIdUpsearchModel } from './siteSharedExternalIdUpsearchModel.js';
import type { SiteSharedGlobalIdUpsearchModel } from './siteSharedGlobalIdUpsearchModel.js';
import type { SiteSharedPhoneUpsearchModel } from './siteSharedPhoneUpsearchModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SiteUpsearchModel {
    'siteId'?: number;
    'addresses'?: Array<SiteSharedAddressUpsearchModel>;
    'contacts'?: Array<SiteSharedContactUpsearchModel>;
    'emailAddresses'?: Array<SiteSharedEmailAddressUpsearchModel>;
    'externalIds'?: Array<SiteSharedExternalIdUpsearchModel>;
    'phoneNumbers'?: Array<SiteSharedPhoneUpsearchModel>;
    'globalIds'?: Array<SiteSharedGlobalIdUpsearchModel>;
    'siteRoleType'?: RoleType;
    'description'?: string;
    'nickName'?: string;
    'businessStartTime'?: Moment;
    'businessEndTime'?: Moment;
    'siteNote'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SiteUpsearchModel>> = [
        {
            "name":     "siteId",
            "baseName": "siteId",
            "type":     "number"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<SiteSharedAddressUpsearchModel>"
        },
        {
            "name":     "contacts",
            "baseName": "contacts",
            "type":     "Array<SiteSharedContactUpsearchModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<SiteSharedEmailAddressUpsearchModel>"
        },
        {
            "name":     "externalIds",
            "baseName": "externalIds",
            "type":     "Array<SiteSharedExternalIdUpsearchModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<SiteSharedPhoneUpsearchModel>"
        },
        {
            "name":     "globalIds",
            "baseName": "globalIds",
            "type":     "Array<SiteSharedGlobalIdUpsearchModel>"
        },
        {
            "name":     "siteRoleType",
            "baseName": "siteRoleType",
            "type":     "RoleType"
        },
        {
            "name":     "description",
            "baseName": "description",
            "type":     "string"
        },
        {
            "name":     "nickName",
            "baseName": "nickName",
            "type":     "string"
        },
        {
            "name":     "businessStartTime",
            "baseName": "businessStartTime",
            "type":     "Date"
        },
        {
            "name":     "businessEndTime",
            "baseName": "businessEndTime",
            "type":     "Date"
        },
        {
            "name":     "siteNote",
            "baseName": "siteNote",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return SiteUpsearchModel.attributeTypeMap;
    }
}
