// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class JanssenGECheckResponseModel {
    'patientAccountFound': boolean;
    'responseType': string;
    'isEligible'?: boolean;
    'info': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof JanssenGECheckResponseModel>> = [
        {
            "name":     "patientAccountFound",
            "baseName": "patientAccountFound",
            "type":     "boolean"
        },
        {
            "name":     "responseType",
            "baseName": "responseType",
            "type":     "string"
        },
        {
            "name":     "isEligible",
            "baseName": "isEligible",
            "type":     "boolean"
        },
        {
            "name":     "info",
            "baseName": "info",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return JanssenGECheckResponseModel.attributeTypeMap;
    }
}
