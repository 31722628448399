// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { IClaimFileModel } from './iClaimFileModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class IPlaceOrderRequestModel {
    'programId'?: number;
    'orderTypeId'?: number;
    'firstName'?: string;
    'lastName'?: string;
    'city'?: string;
    'address1'?: string;
    'address2'?: string;
    'state'?: string;
    'postalCode'?: string;
    'phone'?: string;
    'fax'?: string;
    'uploadFile'?: IClaimFileModel | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof IPlaceOrderRequestModel>> = [
        {
            "name":     "programId",
            "baseName": "programId",
            "type":     "number"
        },
        {
            "name":     "orderTypeId",
            "baseName": "orderTypeId",
            "type":     "number"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "address1",
            "baseName": "address1",
            "type":     "string"
        },
        {
            "name":     "address2",
            "baseName": "address2",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "postalCode",
            "baseName": "postalCode",
            "type":     "string"
        },
        {
            "name":     "phone",
            "baseName": "phone",
            "type":     "string"
        },
        {
            "name":     "fax",
            "baseName": "fax",
            "type":     "string"
        },
        {
            "name":     "uploadFile",
            "baseName": "uploadFile",
            "type":     "IClaimFileModel"
        },
    ];

    static getAttributeTypeMap() {
        return IPlaceOrderRequestModel.attributeTypeMap;
    }
}
