// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { DigitalpaymentCounterpartyEntityDetail } from './digitalpaymentCounterpartyEntityDetail.js';
import type { DigitalpaymentCounterpartyUserDetail } from './digitalpaymentCounterpartyUserDetail.js';
import type { DigitalpaymentExternalAccountDetail } from './digitalpaymentExternalAccountDetail.js';
import type { DigitalpaymentRejectDetails } from './digitalpaymentRejectDetails.js';
import type { DigitalpaymentReturnDetails } from './digitalpaymentReturnDetails.js';
import type { DigitalpaymentTransactionReference } from './digitalpaymentTransactionReference.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Payload within a payin payout event delivered via webhook
*/
export class DigitalpaymentTransactionWebhookPayload {
    'methodOfPaymentType'?: string | null;
    'methodOfPaymentSubType'?: string | null;
    'status'?: string | null;
    'externalAccount'?: DigitalpaymentExternalAccountDetail | null;
    'returnDetails'?: DigitalpaymentReturnDetails | null;
    'rejectDetails'?: DigitalpaymentRejectDetails | null;
    'invoicePayableNumbers': Array<string> | null;
    'confirmationNumber'?: string | null;
    'endToEndId'?: string | null;
    'valueDate'?: Moment | null;
    'createDateTime'?: Moment;
    'effectiveDate'?: Moment | null;
    'createdBy'?: string | null;
    'creditOrDebit'?: string | null;
    'currency'?: string | null;
    'paymentAmount'?: number;
    'counterpartyEntity'?: DigitalpaymentCounterpartyEntityDetail | null;
    'counterpartyUser'?: DigitalpaymentCounterpartyUserDetail | null;
    'transactionReferences': Array<DigitalpaymentTransactionReference> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof DigitalpaymentTransactionWebhookPayload>> = [
        {
            "name":     "methodOfPaymentType",
            "baseName": "methodOfPaymentType",
            "type":     "string"
        },
        {
            "name":     "methodOfPaymentSubType",
            "baseName": "methodOfPaymentSubType",
            "type":     "string"
        },
        {
            "name":     "status",
            "baseName": "status",
            "type":     "string"
        },
        {
            "name":     "externalAccount",
            "baseName": "externalAccount",
            "type":     "DigitalpaymentExternalAccountDetail"
        },
        {
            "name":     "returnDetails",
            "baseName": "returnDetails",
            "type":     "DigitalpaymentReturnDetails"
        },
        {
            "name":     "rejectDetails",
            "baseName": "rejectDetails",
            "type":     "DigitalpaymentRejectDetails"
        },
        {
            "name":     "invoicePayableNumbers",
            "baseName": "invoicePayableNumbers",
            "type":     "Array<string>"
        },
        {
            "name":     "confirmationNumber",
            "baseName": "confirmationNumber",
            "type":     "string"
        },
        {
            "name":     "endToEndId",
            "baseName": "endToEndId",
            "type":     "string"
        },
        {
            "name":     "valueDate",
            "baseName": "valueDate",
            "type":     "Date"
        },
        {
            "name":     "createDateTime",
            "baseName": "createDateTime",
            "type":     "Date"
        },
        {
            "name":     "effectiveDate",
            "baseName": "effectiveDate",
            "type":     "Date"
        },
        {
            "name":     "createdBy",
            "baseName": "createdBy",
            "type":     "string"
        },
        {
            "name":     "creditOrDebit",
            "baseName": "creditOrDebit",
            "type":     "string"
        },
        {
            "name":     "currency",
            "baseName": "currency",
            "type":     "string"
        },
        {
            "name":     "paymentAmount",
            "baseName": "paymentAmount",
            "type":     "number"
        },
        {
            "name":     "counterpartyEntity",
            "baseName": "counterpartyEntity",
            "type":     "DigitalpaymentCounterpartyEntityDetail"
        },
        {
            "name":     "counterpartyUser",
            "baseName": "counterpartyUser",
            "type":     "DigitalpaymentCounterpartyUserDetail"
        },
        {
            "name":     "transactionReferences",
            "baseName": "transactionReferences",
            "type":     "Array<DigitalpaymentTransactionReference>"
        },
    ];

    static getAttributeTypeMap() {
        return DigitalpaymentTransactionWebhookPayload.attributeTypeMap;
    }
}
