// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ExtEnrollmentPhoneNumberModel } from './extEnrollmentPhoneNumberModel.js';
import type { TCSRelationshipType } from './tCSRelationshipType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ExtEnrollmentGuardianInfoModel {
    'personId'?: number;
    'namePrefix'?: string;
    'firstName'?: string;
    'middleName'?: string;
    'lastName'?: string;
    'nameSuffix'?: string;
    'dateOfBirth'?: string;
    'relationship'?: TCSRelationshipType;
    'primaryPhoneNumber'?: ExtEnrollmentPhoneNumberModel | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtEnrollmentGuardianInfoModel>> = [
        {
            "name":     "personId",
            "baseName": "personId",
            "type":     "number"
        },
        {
            "name":     "namePrefix",
            "baseName": "namePrefix",
            "type":     "string"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "middleName",
            "baseName": "middleName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "nameSuffix",
            "baseName": "nameSuffix",
            "type":     "string"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "string"
        },
        {
            "name":     "relationship",
            "baseName": "relationship",
            "type":     "TCSRelationshipType"
        },
        {
            "name":     "primaryPhoneNumber",
            "baseName": "primaryPhoneNumber",
            "type":     "ExtEnrollmentPhoneNumberModel"
        },
    ];

    static getAttributeTypeMap() {
        return ExtEnrollmentGuardianInfoModel.attributeTypeMap;
    }
}
