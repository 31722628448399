// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AccountAddressModel } from './accountAddressModel.js';
import type { AccountAttestationAttributeModel } from './accountAttestationAttributeModel.js';
import type { AccountContactModel } from './accountContactModel.js';
import type { AccountContactPreferenceModel } from './accountContactPreferenceModel.js';
import type { AccountExternalIdModel } from './accountExternalIdModel.js';
import type { AccountOptionalServiceModel } from './accountOptionalServiceModel.js';
import type { AccountPayorRelationModel } from './accountPayorRelationModel.js';
import type { AccountPhoneModel } from './accountPhoneModel.js';
import type { AccountRelationModel } from './accountRelationModel.js';
import type { AccountServiceModel } from './accountServiceModel.js';
import type { AccountSessionModel } from './accountSessionModel.js';
import type { AccountStatus } from './accountStatus.js';
import type { AccountType } from './accountType.js';
import type { DocumentRelationshipModel } from './documentRelationshipModel.js';
import type { LinkedPayorModel } from './linkedPayorModel.js';
import type { VoicemailOptInModel } from './voicemailOptInModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Model for Accounts
*/
export class AccountModel {
    /**
    * @description The account identifier
    */
    'accountId'?: number;
    /**
    * @description The Account ID to display to users.
    */
    'displayId'?: string | null;
    'accountType'?: AccountType;
    'accountStatus'?: AccountStatus;
    /**
    * @description Party ID
    */
    'partyId'?: number;
    /**
    * @description Secure Party ID (Takes precedence over Party ID)
    */
    'securePartyId'?: string | null;
    /**
    * @description Program identifier
    */
    'programId'?: number;
    /**
    * @description Assigned User identifier
    */
    'assignedUser'?: number | null;
    /**
    * @description User identifier of the person who last modified the Account
    */
    'lastModifiedBy'?: number;
    /**
    * @description The date and time (using Coordinated Universal Time [UTC]) at which the Account was last modified
    */
    'lastModifiedTime'?: Moment;
    /**
    * @description Account Payor Relationships
    */
    'payors'?: Array<AccountPayorRelationModel> | null;
    /**
    * @description List of AccountRelationModel objects containing information about Prescribers
    */
    'prescribers'?: Array<AccountRelationModel> | null;
    /**
    * @description List of AccountRelationModel objects containing information about Sites
    */
    'sites'?: Array<AccountRelationModel> | null;
    /**
    * @description List of AccountRelationModel objects containing information about Pharmacies
    */
    'pharmacies'?: Array<AccountRelationModel> | null;
    /**
    * @description List of Account Addresses
    */
    'accountAddresses'?: Array<AccountAddressModel> | null;
    /**
    * @description List of Account Phone Numbers
    */
    'accountPhoneNumbers'?: Array<AccountPhoneModel> | null;
    /**
    * @description List of DocumentRelationshipModel objects containing information about Documents
    */
    'documents'?: Array<DocumentRelationshipModel> | null;
    /**
    * @description List of AccountNote objects
    */
    'notes'?: Array<number> | null;
    /**
    * @description List of AccountTasks objects
    */
    'tasks'?: Array<number> | null;
    /**
    * @description List of Account Sessions
    */
    'accountSessions'?: Array<AccountSessionModel> | null;
    /**
    * @description List of VoicemailOptInModel objects containing information about Voicemail Opt-ins
    */
    'voicemailOptInStatus'?: Array<VoicemailOptInModel> | null;
    /**
    * @description List of External IDs
    */
    'externalIds'?: Array<AccountExternalIdModel> | null;
    /**
    * @description Assignment Of Benefit flag
    */
    'assignmentOfBenefitFlag'?: boolean | null;
    /**
    * @description List of External IDs
    */
    'accountContacts'?: Array<AccountContactModel> | null;
    /**
    * @description Not Eligible for Mail-In Claims flag
    */
    'isIneligibleForMailInClaims'?: boolean | null;
    /**
    * @description Linked Payor Collection
    */
    'linkedPayors'?: Array<LinkedPayorModel> | null;
    /**
    * @description Eligible to AutoReEnroll during the ReEnrollment period for it\\\'s Program
    */
    'allowAutoReenroll'?: boolean | null;
    /**
    * @description Contact Preferences
    */
    'contactPreferences'?: Array<AccountContactPreferenceModel> | null;
    /**
    * @description Attestations
    */
    'attestations'?: Array<AccountAttestationAttributeModel> | null;
    /**
    * @description Optional Services
    */
    'optionalServices'?: Array<AccountOptionalServiceModel> | null;
    /**
    * @description Account Services
    */
    'accountServices'?: Array<AccountServiceModel> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof AccountModel>> = [
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "displayId",
            "baseName": "displayId",
            "type":     "string"
        },
        {
            "name":     "accountType",
            "baseName": "accountType",
            "type":     "AccountType"
        },
        {
            "name":     "accountStatus",
            "baseName": "accountStatus",
            "type":     "AccountStatus"
        },
        {
            "name":     "partyId",
            "baseName": "partyId",
            "type":     "number"
        },
        {
            "name":     "securePartyId",
            "baseName": "securePartyId",
            "type":     "string"
        },
        {
            "name":     "programId",
            "baseName": "programId",
            "type":     "number"
        },
        {
            "name":     "assignedUser",
            "baseName": "assignedUser",
            "type":     "number"
        },
        {
            "name":     "lastModifiedBy",
            "baseName": "lastModifiedBy",
            "type":     "number"
        },
        {
            "name":     "lastModifiedTime",
            "baseName": "lastModifiedTime",
            "type":     "Date"
        },
        {
            "name":     "payors",
            "baseName": "payors",
            "type":     "Array<AccountPayorRelationModel>"
        },
        {
            "name":     "prescribers",
            "baseName": "prescribers",
            "type":     "Array<AccountRelationModel>"
        },
        {
            "name":     "sites",
            "baseName": "sites",
            "type":     "Array<AccountRelationModel>"
        },
        {
            "name":     "pharmacies",
            "baseName": "pharmacies",
            "type":     "Array<AccountRelationModel>"
        },
        {
            "name":     "accountAddresses",
            "baseName": "accountAddresses",
            "type":     "Array<AccountAddressModel>"
        },
        {
            "name":     "accountPhoneNumbers",
            "baseName": "accountPhoneNumbers",
            "type":     "Array<AccountPhoneModel>"
        },
        {
            "name":     "documents",
            "baseName": "documents",
            "type":     "Array<DocumentRelationshipModel>"
        },
        {
            "name":     "notes",
            "baseName": "notes",
            "type":     "Array<number>"
        },
        {
            "name":     "tasks",
            "baseName": "tasks",
            "type":     "Array<number>"
        },
        {
            "name":     "accountSessions",
            "baseName": "accountSessions",
            "type":     "Array<AccountSessionModel>"
        },
        {
            "name":     "voicemailOptInStatus",
            "baseName": "voicemailOptInStatus",
            "type":     "Array<VoicemailOptInModel>"
        },
        {
            "name":     "externalIds",
            "baseName": "externalIds",
            "type":     "Array<AccountExternalIdModel>"
        },
        {
            "name":     "assignmentOfBenefitFlag",
            "baseName": "assignmentOfBenefitFlag",
            "type":     "boolean"
        },
        {
            "name":     "accountContacts",
            "baseName": "accountContacts",
            "type":     "Array<AccountContactModel>"
        },
        {
            "name":     "isIneligibleForMailInClaims",
            "baseName": "isIneligibleForMailInClaims",
            "type":     "boolean"
        },
        {
            "name":     "linkedPayors",
            "baseName": "linkedPayors",
            "type":     "Array<LinkedPayorModel>"
        },
        {
            "name":     "allowAutoReenroll",
            "baseName": "allowAutoReenroll",
            "type":     "boolean"
        },
        {
            "name":     "contactPreferences",
            "baseName": "contactPreferences",
            "type":     "Array<AccountContactPreferenceModel>"
        },
        {
            "name":     "attestations",
            "baseName": "attestations",
            "type":     "Array<AccountAttestationAttributeModel>"
        },
        {
            "name":     "optionalServices",
            "baseName": "optionalServices",
            "type":     "Array<AccountOptionalServiceModel>"
        },
        {
            "name":     "accountServices",
            "baseName": "accountServices",
            "type":     "Array<AccountServiceModel>"
        },
    ];

    static getAttributeTypeMap() {
        return AccountModel.attributeTypeMap;
    }
}
