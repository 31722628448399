// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PayorModel } from './payorModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PayorSearchResponseModel {
    'payors'?: Array<PayorModel>;
    'hasAdditionalMatches'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PayorSearchResponseModel>> = [
        {
            "name":     "payors",
            "baseName": "payors",
            "type":     "Array<PayorModel>"
        },
        {
            "name":     "hasAdditionalMatches",
            "baseName": "hasAdditionalMatches",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return PayorSearchResponseModel.attributeTypeMap;
    }
}
