// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { DocumentPdfFileStyleModel } from './documentPdfFileStyleModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class DocumentPdfFileModel {
    'templateName'?: string;
    'author'?: string;
    'title'?: string;
    'keywords'?: string;
    'headerMarginLeft'?: string;
    'headerMarginRight'?: string;
    'headerStyle'?: string;
    'tableStyle'?: string;
    'overWriteExisting'?: boolean;
    'tableHeaderText'?: string;
    'documentData'?: { [key: string]: string; } | null;
    'headerData'?: { [key: string]: string; } | null;
    'documentStyles'?: Array<DocumentPdfFileStyleModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof DocumentPdfFileModel>> = [
        {
            "name":     "templateName",
            "baseName": "templateName",
            "type":     "string"
        },
        {
            "name":     "author",
            "baseName": "author",
            "type":     "string"
        },
        {
            "name":     "title",
            "baseName": "title",
            "type":     "string"
        },
        {
            "name":     "keywords",
            "baseName": "keywords",
            "type":     "string"
        },
        {
            "name":     "headerMarginLeft",
            "baseName": "headerMarginLeft",
            "type":     "string"
        },
        {
            "name":     "headerMarginRight",
            "baseName": "headerMarginRight",
            "type":     "string"
        },
        {
            "name":     "headerStyle",
            "baseName": "headerStyle",
            "type":     "string"
        },
        {
            "name":     "tableStyle",
            "baseName": "tableStyle",
            "type":     "string"
        },
        {
            "name":     "overWriteExisting",
            "baseName": "overWriteExisting",
            "type":     "boolean"
        },
        {
            "name":     "tableHeaderText",
            "baseName": "tableHeaderText",
            "type":     "string"
        },
        {
            "name":     "documentData",
            "baseName": "documentData",
            "type":     "{ [key: string]: string; }"
        },
        {
            "name":     "headerData",
            "baseName": "headerData",
            "type":     "{ [key: string]: string; }"
        },
        {
            "name":     "documentStyles",
            "baseName": "documentStyles",
            "type":     "Array<DocumentPdfFileStyleModel>"
        },
    ];

    static getAttributeTypeMap() {
        return DocumentPdfFileModel.attributeTypeMap;
    }
}
