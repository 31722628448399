// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ExtCardMemberNumberStatusResultModel } from './extCardMemberNumberStatusResultModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class CouponMemberMemberNumberStatusResponse {
    'data': ExtCardMemberNumberStatusResultModel | null;
    'success': boolean;
    'messages': Array<string> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof CouponMemberMemberNumberStatusResponse>> = [
        {
            "name":     "data",
            "baseName": "data",
            "type":     "ExtCardMemberNumberStatusResultModel"
        },
        {
            "name":     "success",
            "baseName": "success",
            "type":     "boolean"
        },
        {
            "name":     "messages",
            "baseName": "messages",
            "type":     "Array<string>"
        },
    ];

    static getAttributeTypeMap() {
        return CouponMemberMemberNumberStatusResponse.attributeTypeMap;
    }
}
