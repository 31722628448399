// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ClsInboundAddress } from './clsInboundAddress.js';
import type { ClsInboundConsent } from './clsInboundConsent.js';
import type { ClsInboundDocument } from './clsInboundDocument.js';
import type { ClsInboundHco } from './clsInboundHco.js';
import type { ClsInboundPayor } from './clsInboundPayor.js';
import type { ClsInboundPrescriber } from './clsInboundPrescriber.js';
import type { ClsInboundPrescription } from './clsInboundPrescription.js';
import type { ClsInboundServiceRequested } from './clsInboundServiceRequested.js';
import type { ClsInboundSurvey } from './clsInboundSurvey.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ClsInboundEnrollment {
    'tcPatientIs'?: string;
    'pcFirstName'?: string;
    'tcMiddleName'?: string;
    'pcLastName'?: string;
    'tcOtherBrands'?: string;
    'pcDateofBirth'?: string;
    'pcGender'?: string;
    'tCsuffix'?: string;
    'tcCommunicationLanguage'?: string;
    'tcusResident'?: string;
    'pcPatientEmail'?: string;
    'pcPhone'?: string;
    'tcMobilePhone'?: string;
    'tcBestTimetoContact'?: string;
    'tcPrimaryPhone'?: string;
    'tcWorkPhone'?: string;
    'pcPreferredChannel'?: string;
    'tcVoiceMailOptIn'?: boolean;
    'pcCaregiverSalutation'?: string;
    'pcCaregiverFirstName'?: string;
    'pcCaregiverRelationshiptoPatient'?: string;
    'tcCaregiverDetails'?: string;
    'tcCaregiver2LastName'?: string;
    'tcCaregiver2Phone'?: string;
    'tcCaregiver2EmailAddress'?: string;
    'pcCaregiverLastName'?: string;
    'tcCaregiverPhone'?: string;
    'tcCaregiverEmailAddress'?: string;
    'tcCaregiver2Salutation'?: string;
    'tcCaregiver2FirstName'?: string;
    'tcCaregiver2RelationshiptoPatient'?: string;
    'tcCaregiver2Details'?: string;
    'programCode'?: string;
    'copayId'?: string;
    'patientAddresses'?: Array<ClsInboundAddress>;
    'payor'?: Array<ClsInboundPayor>;
    'prescriber'?: Array<ClsInboundPrescriber>;
    'prescription'?: Array<ClsInboundPrescription>;
    'hco'?: Array<ClsInboundHco>;
    'consent'?: Array<ClsInboundConsent>;
    'serviceRequested'?: Array<ClsInboundServiceRequested>;
    'surveys'?: Array<ClsInboundSurvey>;
    'documents'?: Array<ClsInboundDocument>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClsInboundEnrollment>> = [
        {
            "name":     "tcPatientIs",
            "baseName": "tcPatientIs",
            "type":     "string"
        },
        {
            "name":     "pcFirstName",
            "baseName": "pcFirstName",
            "type":     "string"
        },
        {
            "name":     "tcMiddleName",
            "baseName": "tcMiddleName",
            "type":     "string"
        },
        {
            "name":     "pcLastName",
            "baseName": "pcLastName",
            "type":     "string"
        },
        {
            "name":     "tcOtherBrands",
            "baseName": "tcOtherBrands",
            "type":     "string"
        },
        {
            "name":     "pcDateofBirth",
            "baseName": "pcDateofBirth",
            "type":     "string"
        },
        {
            "name":     "pcGender",
            "baseName": "pcGender",
            "type":     "string"
        },
        {
            "name":     "tCsuffix",
            "baseName": "tCsuffix",
            "type":     "string"
        },
        {
            "name":     "tcCommunicationLanguage",
            "baseName": "tcCommunicationLanguage",
            "type":     "string"
        },
        {
            "name":     "tcusResident",
            "baseName": "tcusResident",
            "type":     "string"
        },
        {
            "name":     "pcPatientEmail",
            "baseName": "pcPatientEmail",
            "type":     "string"
        },
        {
            "name":     "pcPhone",
            "baseName": "pcPhone",
            "type":     "string"
        },
        {
            "name":     "tcMobilePhone",
            "baseName": "tcMobilePhone",
            "type":     "string"
        },
        {
            "name":     "tcBestTimetoContact",
            "baseName": "tcBestTimetoContact",
            "type":     "string"
        },
        {
            "name":     "tcPrimaryPhone",
            "baseName": "tcPrimaryPhone",
            "type":     "string"
        },
        {
            "name":     "tcWorkPhone",
            "baseName": "tcWorkPhone",
            "type":     "string"
        },
        {
            "name":     "pcPreferredChannel",
            "baseName": "pcPreferredChannel",
            "type":     "string"
        },
        {
            "name":     "tcVoiceMailOptIn",
            "baseName": "tcVoiceMailOptIn",
            "type":     "boolean"
        },
        {
            "name":     "pcCaregiverSalutation",
            "baseName": "pcCaregiverSalutation",
            "type":     "string"
        },
        {
            "name":     "pcCaregiverFirstName",
            "baseName": "pcCaregiverFirstName",
            "type":     "string"
        },
        {
            "name":     "pcCaregiverRelationshiptoPatient",
            "baseName": "pcCaregiverRelationshiptoPatient",
            "type":     "string"
        },
        {
            "name":     "tcCaregiverDetails",
            "baseName": "tcCaregiverDetails",
            "type":     "string"
        },
        {
            "name":     "tcCaregiver2LastName",
            "baseName": "tcCaregiver2LastName",
            "type":     "string"
        },
        {
            "name":     "tcCaregiver2Phone",
            "baseName": "tcCaregiver2Phone",
            "type":     "string"
        },
        {
            "name":     "tcCaregiver2EmailAddress",
            "baseName": "tcCaregiver2EmailAddress",
            "type":     "string"
        },
        {
            "name":     "pcCaregiverLastName",
            "baseName": "pcCaregiverLastName",
            "type":     "string"
        },
        {
            "name":     "tcCaregiverPhone",
            "baseName": "tcCaregiverPhone",
            "type":     "string"
        },
        {
            "name":     "tcCaregiverEmailAddress",
            "baseName": "tcCaregiverEmailAddress",
            "type":     "string"
        },
        {
            "name":     "tcCaregiver2Salutation",
            "baseName": "tcCaregiver2Salutation",
            "type":     "string"
        },
        {
            "name":     "tcCaregiver2FirstName",
            "baseName": "tcCaregiver2FirstName",
            "type":     "string"
        },
        {
            "name":     "tcCaregiver2RelationshiptoPatient",
            "baseName": "tcCaregiver2RelationshiptoPatient",
            "type":     "string"
        },
        {
            "name":     "tcCaregiver2Details",
            "baseName": "tcCaregiver2Details",
            "type":     "string"
        },
        {
            "name":     "programCode",
            "baseName": "programCode",
            "type":     "string"
        },
        {
            "name":     "copayId",
            "baseName": "copayId",
            "type":     "string"
        },
        {
            "name":     "patientAddresses",
            "baseName": "patientAddresses",
            "type":     "Array<ClsInboundAddress>"
        },
        {
            "name":     "payor",
            "baseName": "payor",
            "type":     "Array<ClsInboundPayor>"
        },
        {
            "name":     "prescriber",
            "baseName": "prescriber",
            "type":     "Array<ClsInboundPrescriber>"
        },
        {
            "name":     "prescription",
            "baseName": "prescription",
            "type":     "Array<ClsInboundPrescription>"
        },
        {
            "name":     "hco",
            "baseName": "hco",
            "type":     "Array<ClsInboundHco>"
        },
        {
            "name":     "consent",
            "baseName": "consent",
            "type":     "Array<ClsInboundConsent>"
        },
        {
            "name":     "serviceRequested",
            "baseName": "serviceRequested",
            "type":     "Array<ClsInboundServiceRequested>"
        },
        {
            "name":     "surveys",
            "baseName": "surveys",
            "type":     "Array<ClsInboundSurvey>"
        },
        {
            "name":     "documents",
            "baseName": "documents",
            "type":     "Array<ClsInboundDocument>"
        },
    ];

    static getAttributeTypeMap() {
        return ClsInboundEnrollment.attributeTypeMap;
    }
}
