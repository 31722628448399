// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RoleType } from './roleType.js';
import type { SiteLicenseModel } from './siteLicenseModel.js';
import type { SiteProcurementMethodModel } from './siteProcurementMethodModel.js';
import type { TrialcardSiteV3ModelsSharedAddressModel } from './trialcardSiteV3ModelsSharedAddressModel.js';
import type { TrialcardSiteV3ModelsSharedContactPreferenceModel } from './trialcardSiteV3ModelsSharedContactPreferenceModel.js';
import type { TrialcardSiteV3ModelsSharedEmailAddressModel } from './trialcardSiteV3ModelsSharedEmailAddressModel.js';
import type { TrialcardSiteV3ModelsSharedExternalIdModel } from './trialcardSiteV3ModelsSharedExternalIdModel.js';
import type { TrialcardSiteV3ModelsSharedGlobalIdModel } from './trialcardSiteV3ModelsSharedGlobalIdModel.js';
import type { TrialcardSiteV3ModelsSharedPhoneModel } from './trialcardSiteV3ModelsSharedPhoneModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class GetSitesResponseModel {
    'siteRoleType'?: RoleType;
    /**
    * @description Site Name
    */
    'description'?: string | null;
    /**
    * @description Site NickName
    */
    'nickName'?: string | null;
    /**
    * @description Opening Time
    */
    'businessStartTime'?: Moment | null;
    /**
    * @description Closing Time
    */
    'businessEndTime'?: Moment | null;
    /**
    * @description A note for the site
    */
    'siteNote'?: string | null;
    /**
    * @description Npi identifier
    */
    'npi'?: string | null;
    /**
    * @description State tax identifier
    */
    'stateTaxId'?: string | null;
    /**
    * @description Billing code
    */
    'billingCode'?: string | null;
    /**
    * @description Is Site Payable flag
    */
    'isSitePayable'?: boolean | null;
    /**
    * @description Site status flag
    */
    'isActive'?: boolean;
    /**
    * @description Site identifier
    */
    'siteId'?: number;
    /**
    * @description List of Site Licenses
    */
    'siteLicenses'?: Array<SiteLicenseModel> | null;
    /**
    * @description Deprecated  Use GlobalIds
    */
    'externalIds'?: Array<TrialcardSiteV3ModelsSharedExternalIdModel> | null;
    /**
    * @description List of associated addresses
    */
    'addresses'?: Array<TrialcardSiteV3ModelsSharedAddressModel> | null;
    /**
    * @description List of associated phone numbers
    */
    'phoneNumbers'?: Array<TrialcardSiteV3ModelsSharedPhoneModel> | null;
    /**
    * @description List of associated email addresses
    */
    'emailAddresses'?: Array<TrialcardSiteV3ModelsSharedEmailAddressModel> | null;
    /**
    * @description List of associated email addresses
    */
    'contactPreferences'?: Array<TrialcardSiteV3ModelsSharedContactPreferenceModel> | null;
    /**
    * @description List of associated procurement methods
    */
    'procurementMethods'?: Array<SiteProcurementMethodModel> | null;
    /**
    * @description Global ids
    */
    'globalIds'?: Array<TrialcardSiteV3ModelsSharedGlobalIdModel> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof GetSitesResponseModel>> = [
        {
            "name":     "siteRoleType",
            "baseName": "siteRoleType",
            "type":     "RoleType"
        },
        {
            "name":     "description",
            "baseName": "description",
            "type":     "string"
        },
        {
            "name":     "nickName",
            "baseName": "nickName",
            "type":     "string"
        },
        {
            "name":     "businessStartTime",
            "baseName": "businessStartTime",
            "type":     "Date"
        },
        {
            "name":     "businessEndTime",
            "baseName": "businessEndTime",
            "type":     "Date"
        },
        {
            "name":     "siteNote",
            "baseName": "siteNote",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "stateTaxId",
            "baseName": "stateTaxId",
            "type":     "string"
        },
        {
            "name":     "billingCode",
            "baseName": "billingCode",
            "type":     "string"
        },
        {
            "name":     "isSitePayable",
            "baseName": "isSitePayable",
            "type":     "boolean"
        },
        {
            "name":     "isActive",
            "baseName": "isActive",
            "type":     "boolean"
        },
        {
            "name":     "siteId",
            "baseName": "siteId",
            "type":     "number"
        },
        {
            "name":     "siteLicenses",
            "baseName": "siteLicenses",
            "type":     "Array<SiteLicenseModel>"
        },
        {
            "name":     "externalIds",
            "baseName": "externalIds",
            "type":     "Array<TrialcardSiteV3ModelsSharedExternalIdModel>"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<TrialcardSiteV3ModelsSharedAddressModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<TrialcardSiteV3ModelsSharedPhoneModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<TrialcardSiteV3ModelsSharedEmailAddressModel>"
        },
        {
            "name":     "contactPreferences",
            "baseName": "contactPreferences",
            "type":     "Array<TrialcardSiteV3ModelsSharedContactPreferenceModel>"
        },
        {
            "name":     "procurementMethods",
            "baseName": "procurementMethods",
            "type":     "Array<SiteProcurementMethodModel>"
        },
        {
            "name":     "globalIds",
            "baseName": "globalIds",
            "type":     "Array<TrialcardSiteV3ModelsSharedGlobalIdModel>"
        },
    ];

    static getAttributeTypeMap() {
        return GetSitesResponseModel.attributeTypeMap;
    }
}
