// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EServicesResponses } from './eServicesResponses.js';
import type { EnrollmentV2EnrollmentModel } from './enrollmentV2EnrollmentModel.js';
import type { EnrollmentV2ProcessingBenefitModel } from './enrollmentV2ProcessingBenefitModel.js';
import type { EnrollmentV2ProcessingOutcomeModel } from './enrollmentV2ProcessingOutcomeModel.js';
import type { EnrollmentV2RulesAssignMemberNumberResultModel } from './enrollmentV2RulesAssignMemberNumberResultModel.js';
import type { EnrollmentV2RulesEligibilityRuleResultModel } from './enrollmentV2RulesEligibilityRuleResultModel.js';
import type { EnrollmentV2RulesMemberNumberBucketResult } from './enrollmentV2RulesMemberNumberBucketResult.js';
import type { EnrollmentV2RulesMetaRuleResultModel } from './enrollmentV2RulesMetaRuleResultModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EServicesEnrollmentResponseModel {
    'eServicesResponses'?: EServicesResponses | null;
    /**
    * @description Details about the eligibility for Enrollment
    */
    'eligibilityResults'?: { [key: string]: EnrollmentV2RulesEligibilityRuleResultModel; } | null;
    /**
    * @description Details about the eligibility for Enrollment
    */
    'allEligibilityResults'?: { [key: string]: Array<EnrollmentV2RulesEligibilityRuleResultModel>; } | null;
    /**
    * @description What will happen with Member Numbers?
    */
    'memberNumbersResult'?: Array<EnrollmentV2RulesAssignMemberNumberResultModel> | null;
    'evaluationResult'?: EnrollmentV2ProcessingOutcomeModel | null;
    'benefitResult'?: EnrollmentV2ProcessingBenefitModel | null;
    /**
    * @description Details of rules that were bypasses, ect. This is only for tracking purposes and not for implementation information.
    */
    'metaRuleResults'?: Array<EnrollmentV2RulesMetaRuleResultModel> | null;
    /**
    * @description Holds the Buckets that were determined to pull Member Numbers from.  This is only for tracking purposes and not for implementation information.
    */
    'memberNumberBucketResults'?: Array<EnrollmentV2RulesMemberNumberBucketResult> | null;
    'enrollment'?: EnrollmentV2EnrollmentModel | null;
    /**
    * @description Id of the created Update Case
    */
    'updateCaseId'?: number;
    /**
    * @description Related Accumulator Id for processed Enrollment
    */
    'relatedAccumulatorId'?: number | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EServicesEnrollmentResponseModel>> = [
        {
            "name":     "eServicesResponses",
            "baseName": "eServicesResponses",
            "type":     "EServicesResponses"
        },
        {
            "name":     "eligibilityResults",
            "baseName": "eligibilityResults",
            "type":     "{ [key: string]: EnrollmentV2RulesEligibilityRuleResultModel; }"
        },
        {
            "name":     "allEligibilityResults",
            "baseName": "allEligibilityResults",
            "type":     "{ [key: string]: Array<EnrollmentV2RulesEligibilityRuleResultModel>; }"
        },
        {
            "name":     "memberNumbersResult",
            "baseName": "memberNumbersResult",
            "type":     "Array<EnrollmentV2RulesAssignMemberNumberResultModel>"
        },
        {
            "name":     "evaluationResult",
            "baseName": "evaluationResult",
            "type":     "EnrollmentV2ProcessingOutcomeModel"
        },
        {
            "name":     "benefitResult",
            "baseName": "benefitResult",
            "type":     "EnrollmentV2ProcessingBenefitModel"
        },
        {
            "name":     "metaRuleResults",
            "baseName": "metaRuleResults",
            "type":     "Array<EnrollmentV2RulesMetaRuleResultModel>"
        },
        {
            "name":     "memberNumberBucketResults",
            "baseName": "memberNumberBucketResults",
            "type":     "Array<EnrollmentV2RulesMemberNumberBucketResult>"
        },
        {
            "name":     "enrollment",
            "baseName": "enrollment",
            "type":     "EnrollmentV2EnrollmentModel"
        },
        {
            "name":     "updateCaseId",
            "baseName": "updateCaseId",
            "type":     "number"
        },
        {
            "name":     "relatedAccumulatorId",
            "baseName": "relatedAccumulatorId",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return EServicesEnrollmentResponseModel.attributeTypeMap;
    }
}
