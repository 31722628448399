// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { SimpleIntakePrescriberPhonesModel } from './simpleIntakePrescriberPhonesModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SimpleIntakePrescriberModel {
    'dea'?: string;
    'firstName'?: string;
    'id'?: number;
    'lastName'?: string;
    'npi'?: string;
    'phones'?: SimpleIntakePrescriberPhonesModel | null;
    'stateLicense'?: string;
    'taxId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SimpleIntakePrescriberModel>> = [
        {
            "name":     "dea",
            "baseName": "dea",
            "type":     "string"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "id",
            "baseName": "id",
            "type":     "number"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "phones",
            "baseName": "phones",
            "type":     "SimpleIntakePrescriberPhonesModel"
        },
        {
            "name":     "stateLicense",
            "baseName": "stateLicense",
            "type":     "string"
        },
        {
            "name":     "taxId",
            "baseName": "taxId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return SimpleIntakePrescriberModel.attributeTypeMap;
    }
}
