// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { TCQpSignatureModelAttestation } from './tCQpSignatureModelAttestation.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SignatureDocumentUpload {
    'attestations': Array<TCQpSignatureModelAttestation>;
    'documentType'?: string;
    'documentData'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SignatureDocumentUpload>> = [
        {
            "name":     "attestations",
            "baseName": "attestations",
            "type":     "Array<TCQpSignatureModelAttestation>"
        },
        {
            "name":     "documentType",
            "baseName": "documentType",
            "type":     "string"
        },
        {
            "name":     "documentData",
            "baseName": "documentData",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return SignatureDocumentUpload.attributeTypeMap;
    }
}
