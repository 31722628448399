// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as enums from '@trialcard/enums';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  https://openapi-generator.tech  Do not edit the class manually.
*/

export enum CaseRoleType {
    Unknown = 0,
    Patient = 1,
    Prescriber = 2,
    InsuranceProvider = 3,
    PharmacyBenefitManager = 4,
    SecondaryContact = 5,
    Manufacturer = 6,
    EMP = 7,
    CustomerServiceRep = 8,
    PrescriberSite = 9,
    Brand = 10,
    Child = 11,
    SocialWorker = 12,
    FinancialRepresentative = 13,
    LegalRepresentative = 14,
    Corporate = 15,
    PersonalContact = 16,
    Spouse = 17,
    Parent = 18,
    HomeHealth = 19,
    AlternateContact = 20,
    AutoInjuryPlan = 21,
    CommercialPlan = 22,
    FederalEmployeeProgram = 23,
    Medicaid = 24,
    MedicareAdvantage = 25,
    MedicareB = 26,
    MedicareD = 27,
    MediGap = 28,
    VADoDTRICARE = 29,
    WorkersCompensation = 30,
    Plan = 31,
    Payer = 32,
    DurableMedicalEquiqmentPlan = 33,
    ManagedCareMedicaidPlan = 34,
    StatePharmacyAssistanceProgram = 35,
    MedicarePartABPlan = 36,
    AidsDrugAssistanceProgram = 37,
    StatePlan = 38,
    Provider = 39,
    ExclusiveProviderOrganization = 40,
    IndemnityInsurance = 41,
    HealthMaintenanceOrganization = 42,
    PointOfServicePlan = 43,
    PreferredProviderOrganization = 44,
    Self = 45,
    OfficeContact = 46,
    Pharmacy = 47,
    MedicareA = 48,
    Hospital = 49,
    HospitalOutpatient = 50,
    InfusionCenter = 51,
    RetailPharmacy = 52,
    Foundation = 53,
    SecondarySupplemental = 54,
    RegisteredNurse = 55,
    LicensedPracticalNurse = 56,
    CertifiedNursingAssistant = 57,
    MedicalAssistant = 58,
    NationalHomeHealthAgency = 59,
    IndependentHomeHealthAgency = 60,
    Practice = 61,
    PerDiemContractIndividual = 62,
    Employee = 63
}
