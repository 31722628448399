// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PharmacyBiPlanAap } from './pharmacyBiPlanAap.js';
import type { PharmacyBiPlanMaximizer } from './pharmacyBiPlanMaximizer.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyBiPlan {
    'aap'?: PharmacyBiPlanAap | null;
    'effectiveDate'?: string | null;
    'fundType'?: string | null;
    'groupNo'?: string | null;
    'groupName'?: string | null;
    'isActive'?: string | null;
    'maximizer'?: PharmacyBiPlanMaximizer | null;
    'name'?: string | null;
    'priority'?: string | null;
    'renewalMonth'?: string | null;
    'renewalType'?: string | null;
    'terminationDate'?: string | null;
    'type'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyBiPlan>> = [
        {
            "name":     "aap",
            "baseName": "aap",
            "type":     "PharmacyBiPlanAap"
        },
        {
            "name":     "effectiveDate",
            "baseName": "effectiveDate",
            "type":     "string"
        },
        {
            "name":     "fundType",
            "baseName": "fundType",
            "type":     "string"
        },
        {
            "name":     "groupNo",
            "baseName": "groupNo",
            "type":     "string"
        },
        {
            "name":     "groupName",
            "baseName": "groupName",
            "type":     "string"
        },
        {
            "name":     "isActive",
            "baseName": "isActive",
            "type":     "string"
        },
        {
            "name":     "maximizer",
            "baseName": "maximizer",
            "type":     "PharmacyBiPlanMaximizer"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "priority",
            "baseName": "priority",
            "type":     "string"
        },
        {
            "name":     "renewalMonth",
            "baseName": "renewalMonth",
            "type":     "string"
        },
        {
            "name":     "renewalType",
            "baseName": "renewalType",
            "type":     "string"
        },
        {
            "name":     "terminationDate",
            "baseName": "terminationDate",
            "type":     "string"
        },
        {
            "name":     "type",
            "baseName": "type",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyBiPlan.attributeTypeMap;
    }
}
