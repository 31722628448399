// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PayorAccountMedicalCoverageModel } from './payorAccountMedicalCoverageModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PayorAccountMedicalCoverageModels {
    'success': boolean;
    'notSuccessful'?: boolean;
    'messages': Array<string> | null;
    'data': Array<PayorAccountMedicalCoverageModel> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PayorAccountMedicalCoverageModels>> = [
        {
            "name":     "success",
            "baseName": "success",
            "type":     "boolean"
        },
        {
            "name":     "notSuccessful",
            "baseName": "notSuccessful",
            "type":     "boolean"
        },
        {
            "name":     "messages",
            "baseName": "messages",
            "type":     "Array<string>"
        },
        {
            "name":     "data",
            "baseName": "data",
            "type":     "Array<PayorAccountMedicalCoverageModel>"
        },
    ];

    static getAttributeTypeMap() {
        return PayorAccountMedicalCoverageModels.attributeTypeMap;
    }
}
