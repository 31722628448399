// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { DocumentGroupSource } from './documentGroupSource.js';
import type { DocumentGroupStatus } from './documentGroupStatus.js';
import type { DocumentLinkModel } from './documentLinkModel.js';
import type { DocumentParentLinkModel } from './documentParentLinkModel.js';
import type { DocumentStatus } from './documentStatus.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class DocumentModel {
    'documentId'?: number;
    'documentGroupId'?: number;
    'displayId'?: string;
    'documentGroupSource'?: DocumentGroupSource;
    'documentGroupReceivedDate'?: Moment;
    'documentOriginalFileName'?: string;
    'documentPageCount'?: number;
    'documentFileName'?: string;
    'documentLocation'?: string;
    'documentLayoutCode'?: string;
    'isPrimary'?: boolean;
    'createdBy'?: number;
    'createDate'?: Moment;
    'lastUpdatedBy'?: number;
    'lastUpdateDate'?: Moment;
    'childDocumentLinks'?: Array<DocumentLinkModel>;
    'parentDocumentLinks'?: Array<DocumentParentLinkModel>;
    'documentTypeId'?: number;
    'documentStatus'?: DocumentStatus;
    'documentGroupStatus'?: DocumentGroupStatus;
    'faxAni'?: string;
    'faxDns'?: string;
    'intakeLayoutId'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof DocumentModel>> = [
        {
            "name":     "documentId",
            "baseName": "documentId",
            "type":     "number"
        },
        {
            "name":     "documentGroupId",
            "baseName": "documentGroupId",
            "type":     "number"
        },
        {
            "name":     "displayId",
            "baseName": "displayId",
            "type":     "string"
        },
        {
            "name":     "documentGroupSource",
            "baseName": "documentGroupSource",
            "type":     "DocumentGroupSource"
        },
        {
            "name":     "documentGroupReceivedDate",
            "baseName": "documentGroupReceivedDate",
            "type":     "Date"
        },
        {
            "name":     "documentOriginalFileName",
            "baseName": "documentOriginalFileName",
            "type":     "string"
        },
        {
            "name":     "documentPageCount",
            "baseName": "documentPageCount",
            "type":     "number"
        },
        {
            "name":     "documentFileName",
            "baseName": "documentFileName",
            "type":     "string"
        },
        {
            "name":     "documentLocation",
            "baseName": "documentLocation",
            "type":     "string"
        },
        {
            "name":     "documentLayoutCode",
            "baseName": "documentLayoutCode",
            "type":     "string"
        },
        {
            "name":     "isPrimary",
            "baseName": "isPrimary",
            "type":     "boolean"
        },
        {
            "name":     "createdBy",
            "baseName": "createdBy",
            "type":     "number"
        },
        {
            "name":     "createDate",
            "baseName": "createDate",
            "type":     "Date"
        },
        {
            "name":     "lastUpdatedBy",
            "baseName": "lastUpdatedBy",
            "type":     "number"
        },
        {
            "name":     "lastUpdateDate",
            "baseName": "lastUpdateDate",
            "type":     "Date"
        },
        {
            "name":     "childDocumentLinks",
            "baseName": "childDocumentLinks",
            "type":     "Array<DocumentLinkModel>"
        },
        {
            "name":     "parentDocumentLinks",
            "baseName": "parentDocumentLinks",
            "type":     "Array<DocumentParentLinkModel>"
        },
        {
            "name":     "documentTypeId",
            "baseName": "documentTypeId",
            "type":     "number"
        },
        {
            "name":     "documentStatus",
            "baseName": "documentStatus",
            "type":     "DocumentStatus"
        },
        {
            "name":     "documentGroupStatus",
            "baseName": "documentGroupStatus",
            "type":     "DocumentGroupStatus"
        },
        {
            "name":     "faxAni",
            "baseName": "faxAni",
            "type":     "string"
        },
        {
            "name":     "faxDns",
            "baseName": "faxDns",
            "type":     "string"
        },
        {
            "name":     "intakeLayoutId",
            "baseName": "intakeLayoutId",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return DocumentModel.attributeTypeMap;
    }
}
