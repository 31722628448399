// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Account Attestation Attribute Model
*/
export class AccountAttestationAttributeModel {
    /**
    * @description Attestation Attribute ID
    */
    'attestationAttributeId'?: number;
    /**
    * @description Attestation source description
    */
    'attestationSourceDesc'?: string | null;
    /**
    * @description Attestation Value
    */
    'value'?: string | null;
    /**
    * @description Attestation Start Date
    */
    'attestationStartDate'?: Moment | null;
    /**
    * @description Attestation End Date
    */
    'attestationEndDate'?: Moment | null;
    /**
    * @description Document ID
    */
    'documentId'?: number | null;
    /**
    * @description Attestation source code
    */
    'attestationSourceCode'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof AccountAttestationAttributeModel>> = [
        {
            "name":     "attestationAttributeId",
            "baseName": "attestationAttributeId",
            "type":     "number"
        },
        {
            "name":     "attestationSourceDesc",
            "baseName": "attestationSourceDesc",
            "type":     "string"
        },
        {
            "name":     "value",
            "baseName": "value",
            "type":     "string"
        },
        {
            "name":     "attestationStartDate",
            "baseName": "attestationStartDate",
            "type":     "Date"
        },
        {
            "name":     "attestationEndDate",
            "baseName": "attestationEndDate",
            "type":     "Date"
        },
        {
            "name":     "documentId",
            "baseName": "documentId",
            "type":     "number"
        },
        {
            "name":     "attestationSourceCode",
            "baseName": "attestationSourceCode",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return AccountAttestationAttributeModel.attributeTypeMap;
    }
}
