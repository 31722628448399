// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  https://openapi-generator.tech  Do not edit the class manually.
*/
export class ExtEnrollmentDocumentModel {
    'documentId'?: number;
    'documentCreateDate'?: string;
    'documentType'?: string;
    'documentName'?: string;
    'documentStatus'?: string;
    'claimId'?: string;
    'claimCreateDate'?: string;
    'claimStatus'?: string;
    'dateOfService'?: string;
    'regretReasons': Array<string>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtEnrollmentDocumentModel>> = [
        {
            "name":     "documentId",
            "baseName": "documentId",
            "type":     "number"
        },
        {
            "name":     "documentCreateDate",
            "baseName": "documentCreateDate",
            "type":     "string"
        },
        {
            "name":     "documentType",
            "baseName": "documentType",
            "type":     "string"
        },
        {
            "name":     "documentName",
            "baseName": "documentName",
            "type":     "string"
        },
        {
            "name":     "documentStatus",
            "baseName": "documentStatus",
            "type":     "string"
        },
        {
            "name":     "claimId",
            "baseName": "claimId",
            "type":     "string"
        },
        {
            "name":     "claimCreateDate",
            "baseName": "claimCreateDate",
            "type":     "string"
        },
        {
            "name":     "claimStatus",
            "baseName": "claimStatus",
            "type":     "string"
        },
        {
            "name":     "dateOfService",
            "baseName": "dateOfService",
            "type":     "string"
        },
        {
            "name":     "regretReasons",
            "baseName": "regretReasons",
            "type":     "Array<string>"
        },
    ];

    static getAttributeTypeMap() {
        return ExtEnrollmentDocumentModel.attributeTypeMap;
    }
}
