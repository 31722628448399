// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Codes to communicate why claim payment is different than amount billed
*/
export class ClaimAdjudicationCodeModel {
    /**
    * @description Claim Adjustment Reason Code (CARC)  A Code to communicate why claim payment is different than amount billed
    */
    'carc'?: string | null;
    /**
    * @description Remittance Advice Remark Code (RARC)  Provide additional explanation to claim adjustment reason.  RARC Codes are defined in the Remittance Advice Remark Code List
    */
    'rarc'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimAdjudicationCodeModel>> = [
        {
            "name":     "carc",
            "baseName": "carc",
            "type":     "string"
        },
        {
            "name":     "rarc",
            "baseName": "rarc",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimAdjudicationCodeModel.attributeTypeMap;
    }
}
