// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PayorType } from './payorType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PayorSearchModel {
    'maxRecordCount'?: number;
    'payorType'?: PayorType;
    'payorName'?: string;
    'payorGroup'?: string;
    'payorBin'?: string;
    'payorPcn'?: string;
    'addressOne'?: string;
    'city'?: string;
    'state'?: string;
    'zip'?: string;
    'coverageAreaState'?: string;
    'isPlaceholder'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PayorSearchModel>> = [
        {
            "name":     "maxRecordCount",
            "baseName": "maxRecordCount",
            "type":     "number"
        },
        {
            "name":     "payorType",
            "baseName": "payorType",
            "type":     "PayorType"
        },
        {
            "name":     "payorName",
            "baseName": "payorName",
            "type":     "string"
        },
        {
            "name":     "payorGroup",
            "baseName": "payorGroup",
            "type":     "string"
        },
        {
            "name":     "payorBin",
            "baseName": "payorBin",
            "type":     "string"
        },
        {
            "name":     "payorPcn",
            "baseName": "payorPcn",
            "type":     "string"
        },
        {
            "name":     "addressOne",
            "baseName": "addressOne",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "zip",
            "baseName": "zip",
            "type":     "string"
        },
        {
            "name":     "coverageAreaState",
            "baseName": "coverageAreaState",
            "type":     "string"
        },
        {
            "name":     "isPlaceholder",
            "baseName": "isPlaceholder",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return PayorSearchModel.attributeTypeMap;
    }
}
