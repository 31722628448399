// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { MedicalEligibilityGender } from './medicalEligibilityGender.js';
import type { MedicalEligibilityPatientAddress } from './medicalEligibilityPatientAddress.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalEligibilityPatientRequest {
    'address': MedicalEligibilityPatientAddress | null;
    'birthDate': string;
    'firstName': string;
    'gender': MedicalEligibilityGender;
    'lastName': string;
    'middleName'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalEligibilityPatientRequest>> = [
        {
            "name":     "address",
            "baseName": "address",
            "type":     "MedicalEligibilityPatientAddress"
        },
        {
            "name":     "birthDate",
            "baseName": "birthDate",
            "type":     "string"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "gender",
            "baseName": "gender",
            "type":     "MedicalEligibilityGender"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "middleName",
            "baseName": "middleName",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalEligibilityPatientRequest.attributeTypeMap;
    }
}
