// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EnrollmentTcsWrapperModelsMedicationModel } from './enrollmentTcsWrapperModelsMedicationModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EnrollmentTcsWrapperModelsAccumulatorModel {
    'medications': Array<EnrollmentTcsWrapperModelsMedicationModel>;
    'medicationMaxBenefit'?: number;
    'remainingMedicationBenefit'?: number;
    'totalMedicationPaid'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnrollmentTcsWrapperModelsAccumulatorModel>> = [
        {
            "name":     "medications",
            "baseName": "medications",
            "type":     "Array<EnrollmentTcsWrapperModelsMedicationModel>"
        },
        {
            "name":     "medicationMaxBenefit",
            "baseName": "medicationMaxBenefit",
            "type":     "number"
        },
        {
            "name":     "remainingMedicationBenefit",
            "baseName": "remainingMedicationBenefit",
            "type":     "number"
        },
        {
            "name":     "totalMedicationPaid",
            "baseName": "totalMedicationPaid",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return EnrollmentTcsWrapperModelsAccumulatorModel.attributeTypeMap;
    }
}
