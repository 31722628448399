// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Gender } from './gender.js';
import type { SimpleIntakeAddressModel } from './simpleIntakeAddressModel.js';
import type { SimpleIntakePatientPhonesModel } from './simpleIntakePatientPhonesModel.js';
import type { SimpleIntakePreferencesModel } from './simpleIntakePreferencesModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SimpleIntakePatientModel {
    'address'?: SimpleIntakeAddressModel | null;
    'dateOfBirth'?: Moment;
    'email'?: string;
    'ethnicity'?: string;
    'firstName'?: string;
    'gender'?: Gender;
    'householdSize'?: number;
    'id'?: string;
    'isUsResident'?: boolean;
    'lastName'?: string;
    'middleName'?: string;
    'phones'?: SimpleIntakePatientPhonesModel | null;
    'preferences'?: SimpleIntakePreferencesModel | null;
    'primaryLanguageId'?: number;
    'race'?: string;
    'ssn'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SimpleIntakePatientModel>> = [
        {
            "name":     "address",
            "baseName": "address",
            "type":     "SimpleIntakeAddressModel"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "Date"
        },
        {
            "name":     "email",
            "baseName": "email",
            "type":     "string"
        },
        {
            "name":     "ethnicity",
            "baseName": "ethnicity",
            "type":     "string"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "gender",
            "baseName": "gender",
            "type":     "Gender"
        },
        {
            "name":     "householdSize",
            "baseName": "householdSize",
            "type":     "number"
        },
        {
            "name":     "id",
            "baseName": "id",
            "type":     "string"
        },
        {
            "name":     "isUsResident",
            "baseName": "isUsResident",
            "type":     "boolean"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "middleName",
            "baseName": "middleName",
            "type":     "string"
        },
        {
            "name":     "phones",
            "baseName": "phones",
            "type":     "SimpleIntakePatientPhonesModel"
        },
        {
            "name":     "preferences",
            "baseName": "preferences",
            "type":     "SimpleIntakePreferencesModel"
        },
        {
            "name":     "primaryLanguageId",
            "baseName": "primaryLanguageId",
            "type":     "number"
        },
        {
            "name":     "race",
            "baseName": "race",
            "type":     "string"
        },
        {
            "name":     "ssn",
            "baseName": "ssn",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return SimpleIntakePatientModel.attributeTypeMap;
    }
}
