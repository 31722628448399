// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Coordination of Benefits (COB) adjustment model
*/
export class ClaimStagingAnsi837MedicalClaimServiceLineCobAdjustmentModel {
    /**
    * @description Adjustment payment group code (category).
    */
    'groupCode'?: string | null;
    /**
    * @description Adjustment Reason Codes (CARC)
    */
    'claimAdjustmentReasonCode'?: string | null;
    /**
    * @description Adjustment amount
    */
    'amount'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimStagingAnsi837MedicalClaimServiceLineCobAdjustmentModel>> = [
        {
            "name":     "groupCode",
            "baseName": "groupCode",
            "type":     "string"
        },
        {
            "name":     "claimAdjustmentReasonCode",
            "baseName": "claimAdjustmentReasonCode",
            "type":     "string"
        },
        {
            "name":     "amount",
            "baseName": "amount",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimStagingAnsi837MedicalClaimServiceLineCobAdjustmentModel.attributeTypeMap;
    }
}
