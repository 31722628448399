// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { SystemStatus } from './systemStatus.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class CaseTypeStatusModel {
    'caseTypeStatusRelId'?: number;
    'programId'?: number;
    'caseTypeId'?: number;
    'caseStatusId'?: number;
    'caseSubStatusId'?: number;
    'programCode'?: string;
    'programDescription'?: string;
    'caseTypeCode'?: string;
    'caseTypeDescription'?: string;
    'caseStatusCode'?: string;
    'caseStatusDescription'?: string;
    'caseSubStatusCode'?: string;
    'caseSubStatusDescription'?: string;
    'systemStatusCode'?: string;
    'systemStatusDescription'?: string;
    'systemStatus'?: SystemStatus;
    'isInitial'?: boolean;
    'isFinal'?: boolean;
    'isHidden'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof CaseTypeStatusModel>> = [
        {
            "name":     "caseTypeStatusRelId",
            "baseName": "caseTypeStatusRelId",
            "type":     "number"
        },
        {
            "name":     "programId",
            "baseName": "programId",
            "type":     "number"
        },
        {
            "name":     "caseTypeId",
            "baseName": "caseTypeId",
            "type":     "number"
        },
        {
            "name":     "caseStatusId",
            "baseName": "caseStatusId",
            "type":     "number"
        },
        {
            "name":     "caseSubStatusId",
            "baseName": "caseSubStatusId",
            "type":     "number"
        },
        {
            "name":     "programCode",
            "baseName": "programCode",
            "type":     "string"
        },
        {
            "name":     "programDescription",
            "baseName": "programDescription",
            "type":     "string"
        },
        {
            "name":     "caseTypeCode",
            "baseName": "caseTypeCode",
            "type":     "string"
        },
        {
            "name":     "caseTypeDescription",
            "baseName": "caseTypeDescription",
            "type":     "string"
        },
        {
            "name":     "caseStatusCode",
            "baseName": "caseStatusCode",
            "type":     "string"
        },
        {
            "name":     "caseStatusDescription",
            "baseName": "caseStatusDescription",
            "type":     "string"
        },
        {
            "name":     "caseSubStatusCode",
            "baseName": "caseSubStatusCode",
            "type":     "string"
        },
        {
            "name":     "caseSubStatusDescription",
            "baseName": "caseSubStatusDescription",
            "type":     "string"
        },
        {
            "name":     "systemStatusCode",
            "baseName": "systemStatusCode",
            "type":     "string"
        },
        {
            "name":     "systemStatusDescription",
            "baseName": "systemStatusDescription",
            "type":     "string"
        },
        {
            "name":     "systemStatus",
            "baseName": "systemStatus",
            "type":     "SystemStatus"
        },
        {
            "name":     "isInitial",
            "baseName": "isInitial",
            "type":     "boolean"
        },
        {
            "name":     "isFinal",
            "baseName": "isFinal",
            "type":     "boolean"
        },
        {
            "name":     "isHidden",
            "baseName": "isHidden",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return CaseTypeStatusModel.attributeTypeMap;
    }
}
