// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { QueryStringMatch } from './queryStringMatch.js';
import type { TCSPromotionSearchScope } from './tCSPromotionSearchScope.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PatientSearchRequestModel {
    'programId': number;
    'maxResults': number;
    'firstName'?: string;
    'firstNameQueryType'?: QueryStringMatch;
    'lastName'?: string;
    'lastNameQueryType'?: QueryStringMatch;
    'dateOfBirth'?: Moment;
    'city'?: string;
    'state'?: string;
    'zip'?: string;
    'memberNumber'?: string;
    'email'?: string;
    'phone'?: string;
    'alternateId'?: string;
    'promotionSearchScope'?: TCSPromotionSearchScope;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PatientSearchRequestModel>> = [
        {
            "name":     "programId",
            "baseName": "programId",
            "type":     "number"
        },
        {
            "name":     "maxResults",
            "baseName": "maxResults",
            "type":     "number"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "firstNameQueryType",
            "baseName": "firstNameQueryType",
            "type":     "QueryStringMatch"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "lastNameQueryType",
            "baseName": "lastNameQueryType",
            "type":     "QueryStringMatch"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "Date"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "zip",
            "baseName": "zip",
            "type":     "string"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "email",
            "baseName": "email",
            "type":     "string"
        },
        {
            "name":     "phone",
            "baseName": "phone",
            "type":     "string"
        },
        {
            "name":     "alternateId",
            "baseName": "alternateId",
            "type":     "string"
        },
        {
            "name":     "promotionSearchScope",
            "baseName": "promotionSearchScope",
            "type":     "TCSPromotionSearchScope"
        },
    ];

    static getAttributeTypeMap() {
        return PatientSearchRequestModel.attributeTypeMap;
    }
}
