// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class TCQpSignatureServiceRequestEmailSignatureTemplateEmailSignatureTemplateResponse {
    'envelopeId'?: string | null;
    'outboundDocumentId'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof TCQpSignatureServiceRequestEmailSignatureTemplateEmailSignatureTemplateResponse>> = [
        {
            "name":     "envelopeId",
            "baseName": "envelopeId",
            "type":     "string"
        },
        {
            "name":     "outboundDocumentId",
            "baseName": "outboundDocumentId",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return TCQpSignatureServiceRequestEmailSignatureTemplateEmailSignatureTemplateResponse.attributeTypeMap;
    }
}
