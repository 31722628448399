// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PartyExternalIdType } from './partyExternalIdType.js';
import type { RoleType } from './roleType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SiteSearchRequestModel {
    'maxRecordCount'?: number;
    'description'?: string;
    'npi'?: string;
    'addressOne'?: string;
    'city'?: string;
    'state'?: string;
    'zip'?: string;
    'roleType'?: RoleType;
    'externalIdType'?: PartyExternalIdType;
    'externalId'?: string;
    'globalIdTypeCode'?: string;
    'globalId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SiteSearchRequestModel>> = [
        {
            "name":     "maxRecordCount",
            "baseName": "maxRecordCount",
            "type":     "number"
        },
        {
            "name":     "description",
            "baseName": "description",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "addressOne",
            "baseName": "addressOne",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "zip",
            "baseName": "zip",
            "type":     "string"
        },
        {
            "name":     "roleType",
            "baseName": "roleType",
            "type":     "RoleType"
        },
        {
            "name":     "externalIdType",
            "baseName": "externalIdType",
            "type":     "PartyExternalIdType"
        },
        {
            "name":     "externalId",
            "baseName": "externalId",
            "type":     "string"
        },
        {
            "name":     "globalIdTypeCode",
            "baseName": "globalIdTypeCode",
            "type":     "string"
        },
        {
            "name":     "globalId",
            "baseName": "globalId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return SiteSearchRequestModel.attributeTypeMap;
    }
}
