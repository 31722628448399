// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SimpleIntakePrescriptionModel {
    'ndcId'?: number;
    'writtenOn'?: Moment;
    'daySupply'?: number;
    'refills'?: number;
    'quantity'?: number;
    'directions'?: string;
    'dispenseTypeId'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SimpleIntakePrescriptionModel>> = [
        {
            "name":     "ndcId",
            "baseName": "ndcId",
            "type":     "number"
        },
        {
            "name":     "writtenOn",
            "baseName": "writtenOn",
            "type":     "Date"
        },
        {
            "name":     "daySupply",
            "baseName": "daySupply",
            "type":     "number"
        },
        {
            "name":     "refills",
            "baseName": "refills",
            "type":     "number"
        },
        {
            "name":     "quantity",
            "baseName": "quantity",
            "type":     "number"
        },
        {
            "name":     "directions",
            "baseName": "directions",
            "type":     "string"
        },
        {
            "name":     "dispenseTypeId",
            "baseName": "dispenseTypeId",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return SimpleIntakePrescriptionModel.attributeTypeMap;
    }
}
