// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Account Optional Service Model
*/
export class AccountOptionalServiceModel {
    /**
    * @description Optional Service ID
    */
    'optionalServiceId'?: number;
    /**
    * @description Whether or not the account is opted in to this service
    */
    'isOptedIn'?: boolean | null;
    /**
    * @description The last time a change was made to the opt-in state for this service
    */
    'lastOptInStateChangeDate'?: Moment | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof AccountOptionalServiceModel>> = [
        {
            "name":     "optionalServiceId",
            "baseName": "optionalServiceId",
            "type":     "number"
        },
        {
            "name":     "isOptedIn",
            "baseName": "isOptedIn",
            "type":     "boolean"
        },
        {
            "name":     "lastOptInStateChangeDate",
            "baseName": "lastOptInStateChangeDate",
            "type":     "Date"
        },
    ];

    static getAttributeTypeMap() {
        return AccountOptionalServiceModel.attributeTypeMap;
    }
}
