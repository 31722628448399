// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PrescriberModel } from './prescriberModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PrescriberSearchResponseModel {
    'prescribers'?: Array<PrescriberModel>;
    'hasAdditionalMatches'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PrescriberSearchResponseModel>> = [
        {
            "name":     "prescribers",
            "baseName": "prescribers",
            "type":     "Array<PrescriberModel>"
        },
        {
            "name":     "hasAdditionalMatches",
            "baseName": "hasAdditionalMatches",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return PrescriberSearchResponseModel.attributeTypeMap;
    }
}
