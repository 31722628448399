// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { MedicalBiSpecialtyPharmacyAvailable } from './medicalBiSpecialtyPharmacyAvailable.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiSpecialtyPharmacy {
    'available'?: string;
    'coInsurance'?: string;
    'copay'?: string;
    'exclusions'?: string;
    'fax'?: string;
    'name'?: string;
    'phone'?: string;
    'network'?: string;
    'hasExclusions'?: string;
    'pharmacy2Fax'?: string;
    'pharmacy2Name'?: string;
    'pharmacy2Phone'?: string;
    'pharmacy3Fax'?: string;
    'pharmacy3Name'?: string;
    'pharmacy3Phone'?: string;
    'preferredSpecialtyPharmaciesAvailable'?: Array<MedicalBiSpecialtyPharmacyAvailable>;
    'coverage'?: string;
    'deductibleApplies'?: string;
    'reasonForNonCoverage'?: string;
    'priorAuthRequired'?: string;
    'priorAuthStatus'?: string;
    'priorAuthStartDate'?: string;
    'priorAuthEndDate'?: string;
    'priorAuthApprovalNumber'?: string;
    'priorAuthApprovedQuantity'?: string;
    'priorAuthApprovedQuantityUsed'?: string;
    'priorAuthApprovedTreatments'?: string;
    'priorAuthApprovedTreatmentsUsed'?: string;
    'priorAuthSubmissionMethods'?: Array<string>;
    'priorAuthNotificationMethods'?: Array<string>;
    'obtainPriorAuthOrg'?: string;
    'obtainPriorAuthPhone'?: string;
    'obtainPriorAuthWebsite'?: string;
    'obtainPriorAuthFax'?: string;
    'obtainPriorAuthFormLink'?: string;
    'obtainPriorAuthAddress'?: string;
    'obtainPriorAuthRequirements'?: Array<string>;
    'priorAuthTurnaroundTime'?: string;
    'predeterminationRequirementType'?: string;
    'predeterminationOnFile'?: string;
    'predeterminationNumber'?: string;
    'predeterminationStartDate'?: string;
    'predeterminationEndDate'?: string;
    'predeterminationApprovedQuantity'?: string;
    'predeterminationApprovedQuantityUsed'?: string;
    'obtainPreDeterminationOrg'?: string;
    'obtainPredeterminationPhone'?: string;
    'obtainPredeterminationWebsite'?: string;
    'obtainPredeterminationFax'?: string;
    'predeterminationTurnaroundTime'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiSpecialtyPharmacy>> = [
        {
            "name":     "available",
            "baseName": "available",
            "type":     "string"
        },
        {
            "name":     "coInsurance",
            "baseName": "coInsurance",
            "type":     "string"
        },
        {
            "name":     "copay",
            "baseName": "copay",
            "type":     "string"
        },
        {
            "name":     "exclusions",
            "baseName": "exclusions",
            "type":     "string"
        },
        {
            "name":     "fax",
            "baseName": "fax",
            "type":     "string"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "phone",
            "baseName": "phone",
            "type":     "string"
        },
        {
            "name":     "network",
            "baseName": "network",
            "type":     "string"
        },
        {
            "name":     "hasExclusions",
            "baseName": "hasExclusions",
            "type":     "string"
        },
        {
            "name":     "pharmacy2Fax",
            "baseName": "pharmacy2Fax",
            "type":     "string"
        },
        {
            "name":     "pharmacy2Name",
            "baseName": "pharmacy2Name",
            "type":     "string"
        },
        {
            "name":     "pharmacy2Phone",
            "baseName": "pharmacy2Phone",
            "type":     "string"
        },
        {
            "name":     "pharmacy3Fax",
            "baseName": "pharmacy3Fax",
            "type":     "string"
        },
        {
            "name":     "pharmacy3Name",
            "baseName": "pharmacy3Name",
            "type":     "string"
        },
        {
            "name":     "pharmacy3Phone",
            "baseName": "pharmacy3Phone",
            "type":     "string"
        },
        {
            "name":     "preferredSpecialtyPharmaciesAvailable",
            "baseName": "preferredSpecialtyPharmaciesAvailable",
            "type":     "Array<MedicalBiSpecialtyPharmacyAvailable>"
        },
        {
            "name":     "coverage",
            "baseName": "coverage",
            "type":     "string"
        },
        {
            "name":     "deductibleApplies",
            "baseName": "deductibleApplies",
            "type":     "string"
        },
        {
            "name":     "reasonForNonCoverage",
            "baseName": "reasonForNonCoverage",
            "type":     "string"
        },
        {
            "name":     "priorAuthRequired",
            "baseName": "priorAuthRequired",
            "type":     "string"
        },
        {
            "name":     "priorAuthStatus",
            "baseName": "priorAuthStatus",
            "type":     "string"
        },
        {
            "name":     "priorAuthStartDate",
            "baseName": "priorAuthStartDate",
            "type":     "string"
        },
        {
            "name":     "priorAuthEndDate",
            "baseName": "priorAuthEndDate",
            "type":     "string"
        },
        {
            "name":     "priorAuthApprovalNumber",
            "baseName": "priorAuthApprovalNumber",
            "type":     "string"
        },
        {
            "name":     "priorAuthApprovedQuantity",
            "baseName": "priorAuthApprovedQuantity",
            "type":     "string"
        },
        {
            "name":     "priorAuthApprovedQuantityUsed",
            "baseName": "priorAuthApprovedQuantityUsed",
            "type":     "string"
        },
        {
            "name":     "priorAuthApprovedTreatments",
            "baseName": "priorAuthApprovedTreatments",
            "type":     "string"
        },
        {
            "name":     "priorAuthApprovedTreatmentsUsed",
            "baseName": "priorAuthApprovedTreatmentsUsed",
            "type":     "string"
        },
        {
            "name":     "priorAuthSubmissionMethods",
            "baseName": "priorAuthSubmissionMethods",
            "type":     "Array<string>"
        },
        {
            "name":     "priorAuthNotificationMethods",
            "baseName": "priorAuthNotificationMethods",
            "type":     "Array<string>"
        },
        {
            "name":     "obtainPriorAuthOrg",
            "baseName": "obtainPriorAuthOrg",
            "type":     "string"
        },
        {
            "name":     "obtainPriorAuthPhone",
            "baseName": "obtainPriorAuthPhone",
            "type":     "string"
        },
        {
            "name":     "obtainPriorAuthWebsite",
            "baseName": "obtainPriorAuthWebsite",
            "type":     "string"
        },
        {
            "name":     "obtainPriorAuthFax",
            "baseName": "obtainPriorAuthFax",
            "type":     "string"
        },
        {
            "name":     "obtainPriorAuthFormLink",
            "baseName": "obtainPriorAuthFormLink",
            "type":     "string"
        },
        {
            "name":     "obtainPriorAuthAddress",
            "baseName": "obtainPriorAuthAddress",
            "type":     "string"
        },
        {
            "name":     "obtainPriorAuthRequirements",
            "baseName": "obtainPriorAuthRequirements",
            "type":     "Array<string>"
        },
        {
            "name":     "priorAuthTurnaroundTime",
            "baseName": "priorAuthTurnaroundTime",
            "type":     "string"
        },
        {
            "name":     "predeterminationRequirementType",
            "baseName": "predeterminationRequirementType",
            "type":     "string"
        },
        {
            "name":     "predeterminationOnFile",
            "baseName": "predeterminationOnFile",
            "type":     "string"
        },
        {
            "name":     "predeterminationNumber",
            "baseName": "predeterminationNumber",
            "type":     "string"
        },
        {
            "name":     "predeterminationStartDate",
            "baseName": "predeterminationStartDate",
            "type":     "string"
        },
        {
            "name":     "predeterminationEndDate",
            "baseName": "predeterminationEndDate",
            "type":     "string"
        },
        {
            "name":     "predeterminationApprovedQuantity",
            "baseName": "predeterminationApprovedQuantity",
            "type":     "string"
        },
        {
            "name":     "predeterminationApprovedQuantityUsed",
            "baseName": "predeterminationApprovedQuantityUsed",
            "type":     "string"
        },
        {
            "name":     "obtainPreDeterminationOrg",
            "baseName": "obtainPreDeterminationOrg",
            "type":     "string"
        },
        {
            "name":     "obtainPredeterminationPhone",
            "baseName": "obtainPredeterminationPhone",
            "type":     "string"
        },
        {
            "name":     "obtainPredeterminationWebsite",
            "baseName": "obtainPredeterminationWebsite",
            "type":     "string"
        },
        {
            "name":     "obtainPredeterminationFax",
            "baseName": "obtainPredeterminationFax",
            "type":     "string"
        },
        {
            "name":     "predeterminationTurnaroundTime",
            "baseName": "predeterminationTurnaroundTime",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiSpecialtyPharmacy.attributeTypeMap;
    }
}
