// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ClaimLookupModel } from './claimLookupModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Claim Condition Model  Claim conditions provide a way to enable tentative approval pending a third-party verification, for instance government exclusion rule.   The claim can be given a status of approved but the existence of a claim condition prevents the claim from being paid   until the separate processing of that specific condition is completed and the processing either approves the claim by   clearing the condition or rejects the claim by updating the status to rejected.
*/
export class ClaimConditionModel {
    /**
    * @description Condition Type
    */
    'conditionTypeId'?: number;
    'conditionType'?: ClaimLookupModel | null;
    /**
    * @description Condition Status
    */
    'conditionStatusId'?: number;
    'conditionStatus'?: ClaimLookupModel | null;
    /**
    * @description Condition Status Category
    */
    'conditionStatusCategoryId'?: number;
    'conditionStatusCategory'?: ClaimLookupModel | null;
    /**
    * @description Is Completed
    */
    'isCompleted'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimConditionModel>> = [
        {
            "name":     "conditionTypeId",
            "baseName": "conditionTypeId",
            "type":     "number"
        },
        {
            "name":     "conditionType",
            "baseName": "conditionType",
            "type":     "ClaimLookupModel"
        },
        {
            "name":     "conditionStatusId",
            "baseName": "conditionStatusId",
            "type":     "number"
        },
        {
            "name":     "conditionStatus",
            "baseName": "conditionStatus",
            "type":     "ClaimLookupModel"
        },
        {
            "name":     "conditionStatusCategoryId",
            "baseName": "conditionStatusCategoryId",
            "type":     "number"
        },
        {
            "name":     "conditionStatusCategory",
            "baseName": "conditionStatusCategory",
            "type":     "ClaimLookupModel"
        },
        {
            "name":     "isCompleted",
            "baseName": "isCompleted",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimConditionModel.attributeTypeMap;
    }
}
