// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel } from './enterpriseCoordinatorPatientServiceModelsPatientUpsearchModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel {
    'canUpdate'?: boolean;
    'canInsert'?: boolean;
    'skipSearch'?: boolean;
    'model'?: EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel>> = [
        {
            "name":     "canUpdate",
            "baseName": "canUpdate",
            "type":     "boolean"
        },
        {
            "name":     "canInsert",
            "baseName": "canInsert",
            "type":     "boolean"
        },
        {
            "name":     "skipSearch",
            "baseName": "skipSearch",
            "type":     "boolean"
        },
        {
            "name":     "model",
            "baseName": "model",
            "type":     "EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel"
        },
    ];

    static getAttributeTypeMap() {
        return UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel.attributeTypeMap;
    }
}
