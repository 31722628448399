// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ContactInformationPurposeType } from './contactInformationPurposeType.js';
import type { PhoneContactInformationType } from './phoneContactInformationType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description A Phone Number associated with an Account
*/
export class AccountPhoneModel {
    /**
    * @description Phone Number ID
    */
    'phoneId'?: number;
    'phoneType'?: PhoneContactInformationType;
    'phonePurpose'?: ContactInformationPurposeType;
    /**
    * @description Phone Area Code and Number
    */
    'phoneNumber'?: string | null;
    /**
    * @description Phone Number Extension
    */
    'extension'?: string | null;
    /**
    * @description Is Voice-mail Opted In?
    */
    'isVoicemailOptedIn'?: boolean | null;
    /**
    * @description Is Primary?
    */
    'isPrimary'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof AccountPhoneModel>> = [
        {
            "name":     "phoneId",
            "baseName": "phoneId",
            "type":     "number"
        },
        {
            "name":     "phoneType",
            "baseName": "phoneType",
            "type":     "PhoneContactInformationType"
        },
        {
            "name":     "phonePurpose",
            "baseName": "phonePurpose",
            "type":     "ContactInformationPurposeType"
        },
        {
            "name":     "phoneNumber",
            "baseName": "phoneNumber",
            "type":     "string"
        },
        {
            "name":     "extension",
            "baseName": "extension",
            "type":     "string"
        },
        {
            "name":     "isVoicemailOptedIn",
            "baseName": "isVoicemailOptedIn",
            "type":     "boolean"
        },
        {
            "name":     "isPrimary",
            "baseName": "isPrimary",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return AccountPhoneModel.attributeTypeMap;
    }
}
