// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ContactInformationType } from './contactInformationType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class IContactPreferenceModel {
    'contactInfoType': ContactInformationType;
    'setAllOthersToNotPreferred'?: boolean;
    'isOptedIn': boolean;
    'isPreferred'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof IContactPreferenceModel>> = [
        {
            "name":     "contactInfoType",
            "baseName": "contactInfoType",
            "type":     "ContactInformationType"
        },
        {
            "name":     "setAllOthersToNotPreferred",
            "baseName": "setAllOthersToNotPreferred",
            "type":     "boolean"
        },
        {
            "name":     "isOptedIn",
            "baseName": "isOptedIn",
            "type":     "boolean"
        },
        {
            "name":     "isPreferred",
            "baseName": "isPreferred",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return IContactPreferenceModel.attributeTypeMap;
    }
}
