// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { KeyValuePairLongString } from './keyValuePairLongString.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class GatewayClaimSearchModel {
    'ids': Array<number>;
    'claimGroupIds': Array<number>;
    'parentClaimIds': Array<number>;
    'memberNumbers': Array<string>;
    'claimStatusIds': Array<number>;
    'paymentTypeIds': Array<number>;
    /**
    * @description External reference type ID/value pairs to search for
    */
    'externalReferences'?: Array<KeyValuePairLongString> | null;
    /**
    * @description Filter records base upon containment within a Payment Batch
    */
    'isInPaymentBatch'?: boolean | null;
    /**
    * @description Promotion ID to Retrieve
    */
    'promotionId'?: number | null;
    /**
    * @description Requested Payment Type ID to Retrieve
    */
    'requestedPaymentTypeId'?: number | null;
    /**
    * @description Processor ID
    */
    'processorId'?: number | null;
    /**
    * @description Return only claims with the specified Condition Type ID applied to them
    */
    'conditionTypeId'?: number | null;
    /**
    * @description If true, return only claims where the condition is open. If false, only return claims where the condition is complete  (or no condition if TC.Enterprise.Claim.Service.Public.Models.ClaimSearchModel.ConditionTypeId is null).
    */
    'conditionIsOpen'?: boolean | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof GatewayClaimSearchModel>> = [
        {
            "name":     "ids",
            "baseName": "ids",
            "type":     "Array<number>"
        },
        {
            "name":     "claimGroupIds",
            "baseName": "claimGroupIds",
            "type":     "Array<number>"
        },
        {
            "name":     "parentClaimIds",
            "baseName": "parentClaimIds",
            "type":     "Array<number>"
        },
        {
            "name":     "memberNumbers",
            "baseName": "memberNumbers",
            "type":     "Array<string>"
        },
        {
            "name":     "claimStatusIds",
            "baseName": "claimStatusIds",
            "type":     "Array<number>"
        },
        {
            "name":     "paymentTypeIds",
            "baseName": "paymentTypeIds",
            "type":     "Array<number>"
        },
        {
            "name":     "externalReferences",
            "baseName": "externalReferences",
            "type":     "Array<KeyValuePairLongString>"
        },
        {
            "name":     "isInPaymentBatch",
            "baseName": "isInPaymentBatch",
            "type":     "boolean"
        },
        {
            "name":     "promotionId",
            "baseName": "promotionId",
            "type":     "number"
        },
        {
            "name":     "requestedPaymentTypeId",
            "baseName": "requestedPaymentTypeId",
            "type":     "number"
        },
        {
            "name":     "processorId",
            "baseName": "processorId",
            "type":     "number"
        },
        {
            "name":     "conditionTypeId",
            "baseName": "conditionTypeId",
            "type":     "number"
        },
        {
            "name":     "conditionIsOpen",
            "baseName": "conditionIsOpen",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return GatewayClaimSearchModel.attributeTypeMap;
    }
}
