// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { HhsStatus } from './hhsStatus.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class HhsCreateEnrollmentResponse {
    'memberNumber': string;
    'bin': string;
    'group': string;
    'pcn': string;
    'messages': Array<string> | null;
    'maxBenefitAmount': number;
    'startDate': Moment;
    'endDate': Moment;
    'claimEligibilityDate': Moment;
    'reenrollmentEligibilityDate': Moment;
    'enrollmentStatus': HhsStatus;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof HhsCreateEnrollmentResponse>> = [
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "bin",
            "baseName": "bin",
            "type":     "string"
        },
        {
            "name":     "group",
            "baseName": "group",
            "type":     "string"
        },
        {
            "name":     "pcn",
            "baseName": "pcn",
            "type":     "string"
        },
        {
            "name":     "messages",
            "baseName": "messages",
            "type":     "Array<string>"
        },
        {
            "name":     "maxBenefitAmount",
            "baseName": "maxBenefitAmount",
            "type":     "number"
        },
        {
            "name":     "startDate",
            "baseName": "startDate",
            "type":     "Date"
        },
        {
            "name":     "endDate",
            "baseName": "endDate",
            "type":     "Date"
        },
        {
            "name":     "claimEligibilityDate",
            "baseName": "claimEligibilityDate",
            "type":     "Date"
        },
        {
            "name":     "reenrollmentEligibilityDate",
            "baseName": "reenrollmentEligibilityDate",
            "type":     "Date"
        },
        {
            "name":     "enrollmentStatus",
            "baseName": "enrollmentStatus",
            "type":     "HhsStatus"
        },
    ];

    static getAttributeTypeMap() {
        return HhsCreateEnrollmentResponse.attributeTypeMap;
    }
}
