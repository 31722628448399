// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { DigitalpaymentTransactionWebhookPayload } from './digitalpaymentTransactionWebhookPayload.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Asynchronous callback event with data returned from Concourse
*/
export class DigitalpaymentWebhook {
    'eventId'?: string | null;
    'createdTime'?: Moment;
    'type'?: string | null;
    'programId'?: string | null;
    'data'?: DigitalpaymentTransactionWebhookPayload | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof DigitalpaymentWebhook>> = [
        {
            "name":     "eventId",
            "baseName": "eventId",
            "type":     "string"
        },
        {
            "name":     "createdTime",
            "baseName": "createdTime",
            "type":     "Date"
        },
        {
            "name":     "type",
            "baseName": "type",
            "type":     "string"
        },
        {
            "name":     "programId",
            "baseName": "programId",
            "type":     "string"
        },
        {
            "name":     "data",
            "baseName": "data",
            "type":     "DigitalpaymentTransactionWebhookPayload"
        },
    ];

    static getAttributeTypeMap() {
        return DigitalpaymentWebhook.attributeTypeMap;
    }
}
