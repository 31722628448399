// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PharmacyModel } from './pharmacyModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacySearchResponseModel {
    'pharmacies'?: Array<PharmacyModel>;
    'hasAdditionalMatches'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacySearchResponseModel>> = [
        {
            "name":     "pharmacies",
            "baseName": "pharmacies",
            "type":     "Array<PharmacyModel>"
        },
        {
            "name":     "hasAdditionalMatches",
            "baseName": "hasAdditionalMatches",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacySearchResponseModel.attributeTypeMap;
    }
}
