// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ReminderType } from './reminderType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SendReminderRequestModel {
    'memberId': string;
    'groupId': string;
    'reminderType': ReminderType;
    'reminderName'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SendReminderRequestModel>> = [
        {
            "name":     "memberId",
            "baseName": "memberId",
            "type":     "string"
        },
        {
            "name":     "groupId",
            "baseName": "groupId",
            "type":     "string"
        },
        {
            "name":     "reminderType",
            "baseName": "reminderType",
            "type":     "ReminderType"
        },
        {
            "name":     "reminderName",
            "baseName": "reminderName",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return SendReminderRequestModel.attributeTypeMap;
    }
}
