// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PayorSharedAddressModel } from './payorSharedAddressModel.js';
import type { PayorSharedEmailAddressModel } from './payorSharedEmailAddressModel.js';
import type { PayorSharedPhoneModel } from './payorSharedPhoneModel.js';
import type { PayorSharedWebAddressModel } from './payorSharedWebAddressModel.js';
import type { PlanType } from './planType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PayorPlanModel {
    'payorId'?: number;
    'payorPlanId'?: number;
    'addresses'?: Array<PayorSharedAddressModel>;
    'phoneNumbers'?: Array<PayorSharedPhoneModel>;
    'emailAddresses'?: Array<PayorSharedEmailAddressModel>;
    'webAddresses'?: Array<PayorSharedWebAddressModel>;
    'payorPlanName'?: string;
    'payorPlanBin'?: string;
    'payorPlanGroup'?: string;
    'payorPlanPcn'?: string;
    'payorMedicalId'?: string;
    'isGovernmentInsurance'?: boolean;
    'isMedicalBenefit'?: boolean;
    'planType'?: PlanType;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PayorPlanModel>> = [
        {
            "name":     "payorId",
            "baseName": "payorId",
            "type":     "number"
        },
        {
            "name":     "payorPlanId",
            "baseName": "payorPlanId",
            "type":     "number"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<PayorSharedAddressModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<PayorSharedPhoneModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<PayorSharedEmailAddressModel>"
        },
        {
            "name":     "webAddresses",
            "baseName": "webAddresses",
            "type":     "Array<PayorSharedWebAddressModel>"
        },
        {
            "name":     "payorPlanName",
            "baseName": "payorPlanName",
            "type":     "string"
        },
        {
            "name":     "payorPlanBin",
            "baseName": "payorPlanBin",
            "type":     "string"
        },
        {
            "name":     "payorPlanGroup",
            "baseName": "payorPlanGroup",
            "type":     "string"
        },
        {
            "name":     "payorPlanPcn",
            "baseName": "payorPlanPcn",
            "type":     "string"
        },
        {
            "name":     "payorMedicalId",
            "baseName": "payorMedicalId",
            "type":     "string"
        },
        {
            "name":     "isGovernmentInsurance",
            "baseName": "isGovernmentInsurance",
            "type":     "boolean"
        },
        {
            "name":     "isMedicalBenefit",
            "baseName": "isMedicalBenefit",
            "type":     "boolean"
        },
        {
            "name":     "planType",
            "baseName": "planType",
            "type":     "PlanType"
        },
    ];

    static getAttributeTypeMap() {
        return PayorPlanModel.attributeTypeMap;
    }
}
