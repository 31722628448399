// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PriorAuthorizationFollowUpAddress } from './priorAuthorizationFollowUpAddress.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PriorAuthorizationFollowUpFacility {
    'address'?: PriorAuthorizationFollowUpAddress | null;
    'facilityType'?: string | null;
    'name'?: string | null;
    'networkStatus'?: string | null;
    'npi'?: string | null;
    'taxId'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PriorAuthorizationFollowUpFacility>> = [
        {
            "name":     "address",
            "baseName": "address",
            "type":     "PriorAuthorizationFollowUpAddress"
        },
        {
            "name":     "facilityType",
            "baseName": "facilityType",
            "type":     "string"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "networkStatus",
            "baseName": "networkStatus",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "taxId",
            "baseName": "taxId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PriorAuthorizationFollowUpFacility.attributeTypeMap;
    }
}
