// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { TCQpSignatureModelItem } from './tCQpSignatureModelItem.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class TCQpSignatureModelTabList {
    'name'?: string | null;
    'value'?: string | null;
    'anchorString'?: string | null;
    'anchorUnits'?: string | null;
    'anchorXOffset'?: string | null;
    'anchorYOffset'?: string | null;
    'width'?: string | null;
    'height'?: string | null;
    'pageNumber'?: string | null;
    'xPosition'?: string | null;
    'yPosition'?: string | null;
    'required'?: boolean;
    'role'?: string | null;
    'items'?: Array<TCQpSignatureModelItem> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof TCQpSignatureModelTabList>> = [
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "value",
            "baseName": "value",
            "type":     "string"
        },
        {
            "name":     "anchorString",
            "baseName": "anchorString",
            "type":     "string"
        },
        {
            "name":     "anchorUnits",
            "baseName": "anchorUnits",
            "type":     "string"
        },
        {
            "name":     "anchorXOffset",
            "baseName": "anchorXOffset",
            "type":     "string"
        },
        {
            "name":     "anchorYOffset",
            "baseName": "anchorYOffset",
            "type":     "string"
        },
        {
            "name":     "width",
            "baseName": "width",
            "type":     "string"
        },
        {
            "name":     "height",
            "baseName": "height",
            "type":     "string"
        },
        {
            "name":     "pageNumber",
            "baseName": "pageNumber",
            "type":     "string"
        },
        {
            "name":     "xPosition",
            "baseName": "xPosition",
            "type":     "string"
        },
        {
            "name":     "yPosition",
            "baseName": "yPosition",
            "type":     "string"
        },
        {
            "name":     "required",
            "baseName": "required",
            "type":     "boolean"
        },
        {
            "name":     "role",
            "baseName": "role",
            "type":     "string"
        },
        {
            "name":     "items",
            "baseName": "items",
            "type":     "Array<TCQpSignatureModelItem>"
        },
    ];

    static getAttributeTypeMap() {
        return TCQpSignatureModelTabList.attributeTypeMap;
    }
}
