// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { MedicalEligibilityPatientRequest } from './medicalEligibilityPatientRequest.js';
import type { MedicalEligibilityPayorRequest } from './medicalEligibilityPayorRequest.js';
import type { MedicalEligibilityPractice } from './medicalEligibilityPractice.js';
import type { MedicalEligibilityPrescriberRequest } from './medicalEligibilityPrescriberRequest.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalEligibilityRequest {
    'mock'?: boolean;
    'ndc': string;
    'patient': MedicalEligibilityPatientRequest | null;
    'payor': MedicalEligibilityPayorRequest | null;
    'practice'?: MedicalEligibilityPractice | null;
    'prescriber': MedicalEligibilityPrescriberRequest | null;
    'project'?: string;
    'treatmentDate'?: string;
    'clientId1'?: string;
    'clientId2'?: string;
    'clientId3'?: string;
    'clientId4'?: string;
    'clientId5'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalEligibilityRequest>> = [
        {
            "name":     "mock",
            "baseName": "mock",
            "type":     "boolean"
        },
        {
            "name":     "ndc",
            "baseName": "ndc",
            "type":     "string"
        },
        {
            "name":     "patient",
            "baseName": "patient",
            "type":     "MedicalEligibilityPatientRequest"
        },
        {
            "name":     "payor",
            "baseName": "payor",
            "type":     "MedicalEligibilityPayorRequest"
        },
        {
            "name":     "practice",
            "baseName": "practice",
            "type":     "MedicalEligibilityPractice"
        },
        {
            "name":     "prescriber",
            "baseName": "prescriber",
            "type":     "MedicalEligibilityPrescriberRequest"
        },
        {
            "name":     "project",
            "baseName": "project",
            "type":     "string"
        },
        {
            "name":     "treatmentDate",
            "baseName": "treatmentDate",
            "type":     "string"
        },
        {
            "name":     "clientId1",
            "baseName": "clientId1",
            "type":     "string"
        },
        {
            "name":     "clientId2",
            "baseName": "clientId2",
            "type":     "string"
        },
        {
            "name":     "clientId3",
            "baseName": "clientId3",
            "type":     "string"
        },
        {
            "name":     "clientId4",
            "baseName": "clientId4",
            "type":     "string"
        },
        {
            "name":     "clientId5",
            "baseName": "clientId5",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalEligibilityRequest.attributeTypeMap;
    }
}
