// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { NpiRegistryModel } from './npiRegistryModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class NpiRegistryResponseModel {
    'result_count': number;
    'results': Array<NpiRegistryModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof NpiRegistryResponseModel>> = [
        {
            "name":     "result_count",
            "baseName": "result_count",
            "type":     "number"
        },
        {
            "name":     "results",
            "baseName": "results",
            "type":     "Array<NpiRegistryModel>"
        },
    ];

    static getAttributeTypeMap() {
        return NpiRegistryResponseModel.attributeTypeMap;
    }
}
