// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CaseAddressModel } from './caseAddressModel.js';
import type { CaseAttestationAttributeModel } from './caseAttestationAttributeModel.js';
import type { CaseConditionRelationshipModel } from './caseConditionRelationshipModel.js';
import type { CaseContactPreferenceModel } from './caseContactPreferenceModel.js';
import type { CaseExternalIdModel } from './caseExternalIdModel.js';
import type { CaseHomeHealthAgencyRelationshipModel } from './caseHomeHealthAgencyRelationshipModel.js';
import type { CasePayorRelationshipModel } from './casePayorRelationshipModel.js';
import type { CasePhoneModel } from './casePhoneModel.js';
import type { CasePrescriptionRelationshipModel } from './casePrescriptionRelationshipModel.js';
import type { CaseRelationshipModel } from './caseRelationshipModel.js';
import type { CaseSessionModel } from './caseSessionModel.js';
import type { TrialcardCaseV3ModelsCaseDocumentRelationshipModel } from './trialcardCaseV3ModelsCaseDocumentRelationshipModel.js';
import type { WorkflowCaseModel } from './workflowCaseModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Model for Workflows
*/
export class CaseWorkflowModel {
    'workflowId'?: number;
    'tenantId'?: number;
    'programId'?: number;
    'accountId'?: number;
    'displayId'?: string | null;
    'typeCode'?: string | null;
    'typeDescription'?: string | null;
    'statusId'?: number;
    'statusCode'?: string | null;
    'statusDescription'?: string | null;
    'subStatusId'?: number;
    'subStatusCode'?: string | null;
    'subStatusDescription'?: string | null;
    'isOpen'?: boolean;
    'expirationDate'?: Moment | null;
    'endDate'?: Moment | null;
    'isExpired'?: boolean;
    'createdOn'?: Moment;
    'updatedOn'?: Moment | null;
    'typeCategoryCode'?: string | null;
    'typeCategoryId'?: number;
    'typeCategoryDescription'?: string | null;
    'statusCategoryCode'?: string | null;
    'statusCategoryId'?: number;
    'statusCategoryDescription'?: string | null;
    'closedBy'?: number | null;
    'addresses': Array<CaseAddressModel> | null;
    'phoneNumbers': Array<CasePhoneModel> | null;
    'workflowCases': Array<WorkflowCaseModel> | null;
    'sessions': Array<CaseSessionModel> | null;
    'prescribers': Array<CaseRelationshipModel> | null;
    'prescriptions': Array<CasePrescriptionRelationshipModel> | null;
    'sites': Array<CaseRelationshipModel> | null;
    'payors': Array<CasePayorRelationshipModel> | null;
    'pharmacies': Array<CaseRelationshipModel> | null;
    'homeHealthAgencies': Array<CaseHomeHealthAgencyRelationshipModel> | null;
    'documents': Array<TrialcardCaseV3ModelsCaseDocumentRelationshipModel> | null;
    'notes': Array<number> | null;
    'tasks': Array<number> | null;
    'externalIds': Array<CaseExternalIdModel> | null;
    'conditions': Array<CaseConditionRelationshipModel> | null;
    'attestations': Array<CaseAttestationAttributeModel> | null;
    'contactPreferences': Array<CaseContactPreferenceModel> | null;
    'typeId'?: number;
    'previousCaseId'?: number | null;
    'sourceId'?: number | null;
    'initiatorId'?: number | null;
    'startDate'?: Moment | null;
    'assignedUserId'?: number | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof CaseWorkflowModel>> = [
        {
            "name":     "workflowId",
            "baseName": "workflowId",
            "type":     "number"
        },
        {
            "name":     "tenantId",
            "baseName": "tenantId",
            "type":     "number"
        },
        {
            "name":     "programId",
            "baseName": "programId",
            "type":     "number"
        },
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "displayId",
            "baseName": "displayId",
            "type":     "string"
        },
        {
            "name":     "typeCode",
            "baseName": "typeCode",
            "type":     "string"
        },
        {
            "name":     "typeDescription",
            "baseName": "typeDescription",
            "type":     "string"
        },
        {
            "name":     "statusId",
            "baseName": "statusId",
            "type":     "number"
        },
        {
            "name":     "statusCode",
            "baseName": "statusCode",
            "type":     "string"
        },
        {
            "name":     "statusDescription",
            "baseName": "statusDescription",
            "type":     "string"
        },
        {
            "name":     "subStatusId",
            "baseName": "subStatusId",
            "type":     "number"
        },
        {
            "name":     "subStatusCode",
            "baseName": "subStatusCode",
            "type":     "string"
        },
        {
            "name":     "subStatusDescription",
            "baseName": "subStatusDescription",
            "type":     "string"
        },
        {
            "name":     "isOpen",
            "baseName": "isOpen",
            "type":     "boolean"
        },
        {
            "name":     "expirationDate",
            "baseName": "expirationDate",
            "type":     "Date"
        },
        {
            "name":     "endDate",
            "baseName": "endDate",
            "type":     "Date"
        },
        {
            "name":     "isExpired",
            "baseName": "isExpired",
            "type":     "boolean"
        },
        {
            "name":     "createdOn",
            "baseName": "createdOn",
            "type":     "Date"
        },
        {
            "name":     "updatedOn",
            "baseName": "updatedOn",
            "type":     "Date"
        },
        {
            "name":     "typeCategoryCode",
            "baseName": "typeCategoryCode",
            "type":     "string"
        },
        {
            "name":     "typeCategoryId",
            "baseName": "typeCategoryId",
            "type":     "number"
        },
        {
            "name":     "typeCategoryDescription",
            "baseName": "typeCategoryDescription",
            "type":     "string"
        },
        {
            "name":     "statusCategoryCode",
            "baseName": "statusCategoryCode",
            "type":     "string"
        },
        {
            "name":     "statusCategoryId",
            "baseName": "statusCategoryId",
            "type":     "number"
        },
        {
            "name":     "statusCategoryDescription",
            "baseName": "statusCategoryDescription",
            "type":     "string"
        },
        {
            "name":     "closedBy",
            "baseName": "closedBy",
            "type":     "number"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<CaseAddressModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<CasePhoneModel>"
        },
        {
            "name":     "workflowCases",
            "baseName": "workflowCases",
            "type":     "Array<WorkflowCaseModel>"
        },
        {
            "name":     "sessions",
            "baseName": "sessions",
            "type":     "Array<CaseSessionModel>"
        },
        {
            "name":     "prescribers",
            "baseName": "prescribers",
            "type":     "Array<CaseRelationshipModel>"
        },
        {
            "name":     "prescriptions",
            "baseName": "prescriptions",
            "type":     "Array<CasePrescriptionRelationshipModel>"
        },
        {
            "name":     "sites",
            "baseName": "sites",
            "type":     "Array<CaseRelationshipModel>"
        },
        {
            "name":     "payors",
            "baseName": "payors",
            "type":     "Array<CasePayorRelationshipModel>"
        },
        {
            "name":     "pharmacies",
            "baseName": "pharmacies",
            "type":     "Array<CaseRelationshipModel>"
        },
        {
            "name":     "homeHealthAgencies",
            "baseName": "homeHealthAgencies",
            "type":     "Array<CaseHomeHealthAgencyRelationshipModel>"
        },
        {
            "name":     "documents",
            "baseName": "documents",
            "type":     "Array<TrialcardCaseV3ModelsCaseDocumentRelationshipModel>"
        },
        {
            "name":     "notes",
            "baseName": "notes",
            "type":     "Array<number>"
        },
        {
            "name":     "tasks",
            "baseName": "tasks",
            "type":     "Array<number>"
        },
        {
            "name":     "externalIds",
            "baseName": "externalIds",
            "type":     "Array<CaseExternalIdModel>"
        },
        {
            "name":     "conditions",
            "baseName": "conditions",
            "type":     "Array<CaseConditionRelationshipModel>"
        },
        {
            "name":     "attestations",
            "baseName": "attestations",
            "type":     "Array<CaseAttestationAttributeModel>"
        },
        {
            "name":     "contactPreferences",
            "baseName": "contactPreferences",
            "type":     "Array<CaseContactPreferenceModel>"
        },
        {
            "name":     "typeId",
            "baseName": "typeId",
            "type":     "number"
        },
        {
            "name":     "previousCaseId",
            "baseName": "previousCaseId",
            "type":     "number"
        },
        {
            "name":     "sourceId",
            "baseName": "sourceId",
            "type":     "number"
        },
        {
            "name":     "initiatorId",
            "baseName": "initiatorId",
            "type":     "number"
        },
        {
            "name":     "startDate",
            "baseName": "startDate",
            "type":     "Date"
        },
        {
            "name":     "assignedUserId",
            "baseName": "assignedUserId",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return CaseWorkflowModel.attributeTypeMap;
    }
}
