// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ClaimProviderLocationModel } from './claimProviderLocationModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Provider Model
*/
export class ClaimProviderModel {
    /**
    * @description Provider Type ID
    */
    'typeId'?: number;
    /**
    * @description Provider Name Type ID
    */
    'nameTypeId'?: number;
    /**
    * @description Provider Name
    */
    'name'?: string | null;
    /**
    * @description Provider Tax Id Number
    */
    'taxIdNum'?: string | null;
    /**
    * @description Provider\\\'s National Identifier
    */
    'npi'?: number | null;
    /**
    * @description Provider\\\'s National Identifier
    */
    'providerNpi'?: string | null;
    /**
    * @description Provider UnDuplicator Number
    */
    'unDuplicatorNumber'?: number | null;
    /**
    * @description Provider UnDuplicator Location Number
    */
    'unDuplicatorLocationNumber'?: number | null;
    /**
    * @description Provider ID
    */
    'providerId'?: number;
    /**
    * @description Provider Type Code
    */
    'typeCode'?: string | null;
    /**
    * @description Provider Name Type Code
    */
    'nameTypeCode'?: string | null;
    /**
    * @description List of Locations
    */
    'locations'?: Array<ClaimProviderLocationModel> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimProviderModel>> = [
        {
            "name":     "typeId",
            "baseName": "typeId",
            "type":     "number"
        },
        {
            "name":     "nameTypeId",
            "baseName": "nameTypeId",
            "type":     "number"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "taxIdNum",
            "baseName": "taxIdNum",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "number"
        },
        {
            "name":     "providerNpi",
            "baseName": "providerNpi",
            "type":     "string"
        },
        {
            "name":     "unDuplicatorNumber",
            "baseName": "unDuplicatorNumber",
            "type":     "number"
        },
        {
            "name":     "unDuplicatorLocationNumber",
            "baseName": "unDuplicatorLocationNumber",
            "type":     "number"
        },
        {
            "name":     "providerId",
            "baseName": "providerId",
            "type":     "number"
        },
        {
            "name":     "typeCode",
            "baseName": "typeCode",
            "type":     "string"
        },
        {
            "name":     "nameTypeCode",
            "baseName": "nameTypeCode",
            "type":     "string"
        },
        {
            "name":     "locations",
            "baseName": "locations",
            "type":     "Array<ClaimProviderLocationModel>"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimProviderModel.attributeTypeMap;
    }
}
