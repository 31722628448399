// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { DocumentUploadResponseModelTransactionId } from './documentUploadResponseModelTransactionId.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class DocumentUploadResponseModel {
    'transactionId': DocumentUploadResponseModelTransactionId | null;
    'messages': Array<string>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof DocumentUploadResponseModel>> = [
        {
            "name":     "transactionId",
            "baseName": "transactionId",
            "type":     "DocumentUploadResponseModelTransactionId"
        },
        {
            "name":     "messages",
            "baseName": "messages",
            "type":     "Array<string>"
        },
    ];

    static getAttributeTypeMap() {
        return DocumentUploadResponseModel.attributeTypeMap;
    }
}
