// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class NewNoteRequest {
    'subject'?: string;
    'body': string;
    'accountId': number;
    'caseId'?: number;
    'documentId'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof NewNoteRequest>> = [
        {
            "name":     "subject",
            "baseName": "subject",
            "type":     "string"
        },
        {
            "name":     "body",
            "baseName": "body",
            "type":     "string"
        },
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "caseId",
            "baseName": "caseId",
            "type":     "number"
        },
        {
            "name":     "documentId",
            "baseName": "documentId",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return NewNoteRequest.attributeTypeMap;
    }
}
