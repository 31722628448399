// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PriorAuthorizationResponse {
    'authorizationNumber'?: string | null;
    'statusDateTime'?: Moment | null;
    'dueDate'?: Moment | null;
    'expirationDate'?: Moment | null;
    'message'?: string | null;
    'providerPin'?: string | null;
    'startDate'?: Moment | null;
    'status'?: string | null;
    'externalId'?: string | null;
    'ssoUrl'?: string | null;
    'transactionCorrelationId'?: number;
    'transactionDateTime'?: Moment;
    'transactionId'?: string | null;
    'transactionMessage'?: string | null;
    'transactionStatus'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PriorAuthorizationResponse>> = [
        {
            "name":     "authorizationNumber",
            "baseName": "authorizationNumber",
            "type":     "string"
        },
        {
            "name":     "statusDateTime",
            "baseName": "statusDateTime",
            "type":     "Date"
        },
        {
            "name":     "dueDate",
            "baseName": "dueDate",
            "type":     "Date"
        },
        {
            "name":     "expirationDate",
            "baseName": "expirationDate",
            "type":     "Date"
        },
        {
            "name":     "message",
            "baseName": "message",
            "type":     "string"
        },
        {
            "name":     "providerPin",
            "baseName": "providerPin",
            "type":     "string"
        },
        {
            "name":     "startDate",
            "baseName": "startDate",
            "type":     "Date"
        },
        {
            "name":     "status",
            "baseName": "status",
            "type":     "string"
        },
        {
            "name":     "externalId",
            "baseName": "externalId",
            "type":     "string"
        },
        {
            "name":     "ssoUrl",
            "baseName": "ssoUrl",
            "type":     "string"
        },
        {
            "name":     "transactionCorrelationId",
            "baseName": "transactionCorrelationId",
            "type":     "number"
        },
        {
            "name":     "transactionDateTime",
            "baseName": "transactionDateTime",
            "type":     "Date"
        },
        {
            "name":     "transactionId",
            "baseName": "transactionId",
            "type":     "string"
        },
        {
            "name":     "transactionMessage",
            "baseName": "transactionMessage",
            "type":     "string"
        },
        {
            "name":     "transactionStatus",
            "baseName": "transactionStatus",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return PriorAuthorizationResponse.attributeTypeMap;
    }
}
