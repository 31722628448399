// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ClsInboundAddress } from './clsInboundAddress.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ClsInboundHco {
    'hcoName'?: string;
    'status'?: string;
    'accountType'?: string;
    'subType'?: string;
    'npi'?: string;
    'ncpcd'?: string;
    'hcoAddress'?: Array<ClsInboundAddress>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClsInboundHco>> = [
        {
            "name":     "hcoName",
            "baseName": "hcoName",
            "type":     "string"
        },
        {
            "name":     "status",
            "baseName": "status",
            "type":     "string"
        },
        {
            "name":     "accountType",
            "baseName": "accountType",
            "type":     "string"
        },
        {
            "name":     "subType",
            "baseName": "subType",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "ncpcd",
            "baseName": "ncpcd",
            "type":     "string"
        },
        {
            "name":     "hcoAddress",
            "baseName": "hcoAddress",
            "type":     "Array<ClsInboundAddress>"
        },
    ];

    static getAttributeTypeMap() {
        return ClsInboundHco.attributeTypeMap;
    }
}
