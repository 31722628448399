// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ClaimAdjudicationClaimLineItem {
    'claimLineId'?: string;
    'serviceCode'?: string;
    'ndcNumber'?: string;
    'ndcQuantity'?: number;
    'ndcUnit'?: string;
    'ndcMeasurement'?: string;
    'ndcDosage'?: string;
    'ndcUnitPrice'?: number;
    'daysSupply'?: number;
    'patientResponsibilityAmount'?: number;
    'coinsurance'?: number;
    'majorMedicalDeductible'?: number;
    'claimAdjustmentDate'?: string;
    'amountCharged'?: number;
    'expectedPaymentAmount'?: number;
    'ineligibleAmount'?: number;
    'allowedAmount'?: number;
    'otherInsurancePaidAmount'?: number;
    'copayAmount'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimAdjudicationClaimLineItem>> = [
        {
            "name":     "claimLineId",
            "baseName": "claimLineId",
            "type":     "string"
        },
        {
            "name":     "serviceCode",
            "baseName": "serviceCode",
            "type":     "string"
        },
        {
            "name":     "ndcNumber",
            "baseName": "ndcNumber",
            "type":     "string"
        },
        {
            "name":     "ndcQuantity",
            "baseName": "ndcQuantity",
            "type":     "number"
        },
        {
            "name":     "ndcUnit",
            "baseName": "ndcUnit",
            "type":     "string"
        },
        {
            "name":     "ndcMeasurement",
            "baseName": "ndcMeasurement",
            "type":     "string"
        },
        {
            "name":     "ndcDosage",
            "baseName": "ndcDosage",
            "type":     "string"
        },
        {
            "name":     "ndcUnitPrice",
            "baseName": "ndcUnitPrice",
            "type":     "number"
        },
        {
            "name":     "daysSupply",
            "baseName": "daysSupply",
            "type":     "number"
        },
        {
            "name":     "patientResponsibilityAmount",
            "baseName": "patientResponsibilityAmount",
            "type":     "number"
        },
        {
            "name":     "coinsurance",
            "baseName": "coinsurance",
            "type":     "number"
        },
        {
            "name":     "majorMedicalDeductible",
            "baseName": "majorMedicalDeductible",
            "type":     "number"
        },
        {
            "name":     "claimAdjustmentDate",
            "baseName": "claimAdjustmentDate",
            "type":     "string"
        },
        {
            "name":     "amountCharged",
            "baseName": "amountCharged",
            "type":     "number"
        },
        {
            "name":     "expectedPaymentAmount",
            "baseName": "expectedPaymentAmount",
            "type":     "number"
        },
        {
            "name":     "ineligibleAmount",
            "baseName": "ineligibleAmount",
            "type":     "number"
        },
        {
            "name":     "allowedAmount",
            "baseName": "allowedAmount",
            "type":     "number"
        },
        {
            "name":     "otherInsurancePaidAmount",
            "baseName": "otherInsurancePaidAmount",
            "type":     "number"
        },
        {
            "name":     "copayAmount",
            "baseName": "copayAmount",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimAdjudicationClaimLineItem.attributeTypeMap;
    }
}
