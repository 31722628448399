// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PartyExternalIdType } from './partyExternalIdType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SitesAndPrescribersByTenantRequestModel {
    'tenantId': number;
    'description'?: string;
    'state'?: string | null;
    'city'?: string | null;
    'zip'?: string | null;
    'maxRecordCount'?: number;
    /**
    * @description First Name
    */
    'firstName'?: string | null;
    /**
    * @description Last Name
    */
    'lastName'?: string | null;
    /**
    * @description Drug Enforcement Agency (DEA) identifier
    */
    'dea'?: string | null;
    /**
    * @description National Provider Identifier (NPI)
    */
    'npi'?: string | null;
    /**
    * @description Medicaid identifier
    */
    'medicaidId'?: string | null;
    /**
    * @description State-wise Tax identifier
    */
    'stateTaxId'?: string | null;
    /**
    * @description State License identifier
    */
    'stateLicenseId'?: string | null;
    /**
    * @description Prescriber Tax identifier
    */
    'prescriberTaxId'?: string | null;
    /**
    * @description First line of Address  <remarks>String from 0 to 40 characters in length.</remarks>
    */
    'addressOne'?: string | null;
    'externalIdType'?: PartyExternalIdType;
    /**
    * @description External ID. If empty string, matches anything (including no external IDs)
    */
    'externalId'?: string | null;
    /**
    * @description GlobalIdTypeCode. If null, matches anything (including no global IDs)
    */
    'globalIdTypeCode'?: string | null;
    /**
    * @description Global ID. If empty string, matches anything (including no global IDs)
    */
    'globalId'?: string | null;
    /**
    * @description Is Active status. If null, matches anything (including null status)
    */
    'isActive'?: boolean | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SitesAndPrescribersByTenantRequestModel>> = [
        {
            "name":     "tenantId",
            "baseName": "tenantId",
            "type":     "number"
        },
        {
            "name":     "description",
            "baseName": "description",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "zip",
            "baseName": "zip",
            "type":     "string"
        },
        {
            "name":     "maxRecordCount",
            "baseName": "maxRecordCount",
            "type":     "number"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "dea",
            "baseName": "dea",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "medicaidId",
            "baseName": "medicaidId",
            "type":     "string"
        },
        {
            "name":     "stateTaxId",
            "baseName": "stateTaxId",
            "type":     "string"
        },
        {
            "name":     "stateLicenseId",
            "baseName": "stateLicenseId",
            "type":     "string"
        },
        {
            "name":     "prescriberTaxId",
            "baseName": "prescriberTaxId",
            "type":     "string"
        },
        {
            "name":     "addressOne",
            "baseName": "addressOne",
            "type":     "string"
        },
        {
            "name":     "externalIdType",
            "baseName": "externalIdType",
            "type":     "PartyExternalIdType"
        },
        {
            "name":     "externalId",
            "baseName": "externalId",
            "type":     "string"
        },
        {
            "name":     "globalIdTypeCode",
            "baseName": "globalIdTypeCode",
            "type":     "string"
        },
        {
            "name":     "globalId",
            "baseName": "globalId",
            "type":     "string"
        },
        {
            "name":     "isActive",
            "baseName": "isActive",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return SitesAndPrescribersByTenantRequestModel.attributeTypeMap;
    }
}
