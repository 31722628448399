// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { TCSInsuranceType } from './tCSInsuranceType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ExtEnrollmentInsuranceVerificationModel {
    'insuranceType'?: TCSInsuranceType;
    'payerName'?: string;
    'payerPhone'?: string;
    'bin'?: string;
    'pcn'?: string;
    'payerMedicalId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtEnrollmentInsuranceVerificationModel>> = [
        {
            "name":     "insuranceType",
            "baseName": "insuranceType",
            "type":     "TCSInsuranceType"
        },
        {
            "name":     "payerName",
            "baseName": "payerName",
            "type":     "string"
        },
        {
            "name":     "payerPhone",
            "baseName": "payerPhone",
            "type":     "string"
        },
        {
            "name":     "bin",
            "baseName": "bin",
            "type":     "string"
        },
        {
            "name":     "pcn",
            "baseName": "pcn",
            "type":     "string"
        },
        {
            "name":     "payerMedicalId",
            "baseName": "payerMedicalId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ExtEnrollmentInsuranceVerificationModel.attributeTypeMap;
    }
}
