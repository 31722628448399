// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class DocumentPdfFileStyleModel {
    'styleName'?: string;
    'fontName'?: string;
    'fontSize'?: number;
    'colorFromCmyk'?: string;
    'color'?: string;
    'bold'?: boolean;
    'pageBreakBefore'?: boolean;
    'spaceAfter'?: number;
    'tabStop'?: string;
    'tabStopAlignment'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof DocumentPdfFileStyleModel>> = [
        {
            "name":     "styleName",
            "baseName": "styleName",
            "type":     "string"
        },
        {
            "name":     "fontName",
            "baseName": "fontName",
            "type":     "string"
        },
        {
            "name":     "fontSize",
            "baseName": "fontSize",
            "type":     "number"
        },
        {
            "name":     "colorFromCmyk",
            "baseName": "colorFromCmyk",
            "type":     "string"
        },
        {
            "name":     "color",
            "baseName": "color",
            "type":     "string"
        },
        {
            "name":     "bold",
            "baseName": "bold",
            "type":     "boolean"
        },
        {
            "name":     "pageBreakBefore",
            "baseName": "pageBreakBefore",
            "type":     "boolean"
        },
        {
            "name":     "spaceAfter",
            "baseName": "spaceAfter",
            "type":     "number"
        },
        {
            "name":     "tabStop",
            "baseName": "tabStop",
            "type":     "string"
        },
        {
            "name":     "tabStopAlignment",
            "baseName": "tabStopAlignment",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return DocumentPdfFileStyleModel.attributeTypeMap;
    }
}
