// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ClaimExternalReferenceModel } from './claimExternalReferenceModel.js';
import type { ClaimPaymentBatchDetailTransactionModel } from './claimPaymentBatchDetailTransactionModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Payment Batch Detail Model
*/
export class ClaimPaymentBatchDetailModel {
    /**
    * @description Payment Batch Detail Identifier
    */
    'paymentBatchDetailId'?: number;
    /**
    * @description Claim Id
    */
    'claimIds'?: Array<number> | null;
    /**
    * @description Claim Group ID
    */
    'claimGroupId'?: number;
    /**
    * @description Member Number
    */
    'memberNumber'?: string | null;
    /**
    * @description First Name
    */
    'firstName'?: string | null;
    /**
    * @description Last Name
    */
    'lastName'?: string | null;
    /**
    * @description Expected Amount
    */
    'expectedAmount'?: number;
    /**
    * @description Actual Amount Paid. Will differ from Expected Amount in the case of a partial payment.
    */
    'actualAmount'?: number | null;
    /**
    * @description Payment Batch Detail Status
    */
    'paymentBatchDetailStatus'?: string | null;
    /**
    * @description Payment Batch Detail Status ID
    */
    'paymentBatchDetailStatusId'?: number;
    /**
    * @description External Reference Type IDs
    */
    'externalReferences'?: Array<ClaimExternalReferenceModel> | null;
    /**
    * @description Check Number for this Payment Batch Detail
    */
    'checkNumber'?: string | null;
    /**
    * @description Processing error for payment batch detail if it exists.
    */
    'errorMessage'?: string | null;
    /**
    * @description Payment history made on card or via check
    */
    'transactions'?: Array<ClaimPaymentBatchDetailTransactionModel> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimPaymentBatchDetailModel>> = [
        {
            "name":     "paymentBatchDetailId",
            "baseName": "paymentBatchDetailId",
            "type":     "number"
        },
        {
            "name":     "claimIds",
            "baseName": "claimIds",
            "type":     "Array<number>"
        },
        {
            "name":     "claimGroupId",
            "baseName": "claimGroupId",
            "type":     "number"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "expectedAmount",
            "baseName": "expectedAmount",
            "type":     "number"
        },
        {
            "name":     "actualAmount",
            "baseName": "actualAmount",
            "type":     "number"
        },
        {
            "name":     "paymentBatchDetailStatus",
            "baseName": "paymentBatchDetailStatus",
            "type":     "string"
        },
        {
            "name":     "paymentBatchDetailStatusId",
            "baseName": "paymentBatchDetailStatusId",
            "type":     "number"
        },
        {
            "name":     "externalReferences",
            "baseName": "externalReferences",
            "type":     "Array<ClaimExternalReferenceModel>"
        },
        {
            "name":     "checkNumber",
            "baseName": "checkNumber",
            "type":     "string"
        },
        {
            "name":     "errorMessage",
            "baseName": "errorMessage",
            "type":     "string"
        },
        {
            "name":     "transactions",
            "baseName": "transactions",
            "type":     "Array<ClaimPaymentBatchDetailTransactionModel>"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimPaymentBatchDetailModel.attributeTypeMap;
    }
}
