// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyBiSpecialtyCoverage {
    'carveout'?: string | null;
    'carveoutEntity'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyBiSpecialtyCoverage>> = [
        {
            "name":     "carveout",
            "baseName": "carveout",
            "type":     "string"
        },
        {
            "name":     "carveoutEntity",
            "baseName": "carveoutEntity",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyBiSpecialtyCoverage.attributeTypeMap;
    }
}
