// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PharmacyBiResponseDetail } from './pharmacyBiResponseDetail.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyBiResponse {
    'customerId'?: string;
    'plans'?: Array<PharmacyBiResponseDetail>;
    'status'?: boolean;
    'taskCompletedDate'?: Moment;
    'taskCreatedDate'?: Moment;
    'taskStatus'?: string;
    'transactionCorrelationId': number;
    'transactionDateTime': Moment;
    'transactionId': string;
    'transactionMessage': string;
    'transactionStatus': boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyBiResponse>> = [
        {
            "name":     "customerId",
            "baseName": "customerId",
            "type":     "string"
        },
        {
            "name":     "plans",
            "baseName": "plans",
            "type":     "Array<PharmacyBiResponseDetail>"
        },
        {
            "name":     "status",
            "baseName": "status",
            "type":     "boolean"
        },
        {
            "name":     "taskCompletedDate",
            "baseName": "taskCompletedDate",
            "type":     "Date"
        },
        {
            "name":     "taskCreatedDate",
            "baseName": "taskCreatedDate",
            "type":     "Date"
        },
        {
            "name":     "taskStatus",
            "baseName": "taskStatus",
            "type":     "string"
        },
        {
            "name":     "transactionCorrelationId",
            "baseName": "transactionCorrelationId",
            "type":     "number"
        },
        {
            "name":     "transactionDateTime",
            "baseName": "transactionDateTime",
            "type":     "Date"
        },
        {
            "name":     "transactionId",
            "baseName": "transactionId",
            "type":     "string"
        },
        {
            "name":     "transactionMessage",
            "baseName": "transactionMessage",
            "type":     "string"
        },
        {
            "name":     "transactionStatus",
            "baseName": "transactionStatus",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyBiResponse.attributeTypeMap;
    }
}
