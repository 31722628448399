// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { MedicalEligibilityAddress } from './medicalEligibilityAddress.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalEligibilityPractice {
    'additionalId'?: string;
    'address'?: MedicalEligibilityAddress | null;
    'name'?: string;
    'npi'?: string;
    'phone'?: string;
    'taxId'?: string;
    'type'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalEligibilityPractice>> = [
        {
            "name":     "additionalId",
            "baseName": "additionalId",
            "type":     "string"
        },
        {
            "name":     "address",
            "baseName": "address",
            "type":     "MedicalEligibilityAddress"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "phone",
            "baseName": "phone",
            "type":     "string"
        },
        {
            "name":     "taxId",
            "baseName": "taxId",
            "type":     "string"
        },
        {
            "name":     "type",
            "baseName": "type",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalEligibilityPractice.attributeTypeMap;
    }
}
