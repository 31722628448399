// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AccountModel } from './accountModel.js';
import type { CaseModel } from './caseModel.js';
import type { DiagnosisModel } from './diagnosisModel.js';
import type { PatientModel } from './patientModel.js';
import type { PrescriberModel } from './prescriberModel.js';
import type { PrescriptionModel } from './prescriptionModel.js';
import type { SiteAccountGetCaseGetCasePayorResponseModel } from './siteAccountGetCaseGetCasePayorResponseModel.js';
import type { SiteAccountGetCaseGetCasePharmacyResponseModel } from './siteAccountGetCaseGetCasePharmacyResponseModel.js';
import type { SiteAccountGetCaseGetCaseSiteResponseModel } from './siteAccountGetCaseGetCaseSiteResponseModel.js';
import type { SiteAccountGetCaseGetCaseSurveyResponseModel } from './siteAccountGetCaseGetCaseSurveyResponseModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SiteAccountGetCaseGetCaseResponseModel {
    'accountData': AccountModel | null;
    'caseData': CaseModel | null;
    'patientData': PatientModel | null;
    'diagnosisData': Array<DiagnosisModel>;
    'payorData': Array<SiteAccountGetCaseGetCasePayorResponseModel>;
    'pharmacyData': Array<SiteAccountGetCaseGetCasePharmacyResponseModel>;
    'prescriberData': Array<PrescriberModel>;
    'prescriptionData': Array<PrescriptionModel>;
    'siteData': Array<SiteAccountGetCaseGetCaseSiteResponseModel>;
    'surveyData': Array<SiteAccountGetCaseGetCaseSurveyResponseModel>;
    'ssn': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SiteAccountGetCaseGetCaseResponseModel>> = [
        {
            "name":     "accountData",
            "baseName": "accountData",
            "type":     "AccountModel"
        },
        {
            "name":     "caseData",
            "baseName": "caseData",
            "type":     "CaseModel"
        },
        {
            "name":     "patientData",
            "baseName": "patientData",
            "type":     "PatientModel"
        },
        {
            "name":     "diagnosisData",
            "baseName": "diagnosisData",
            "type":     "Array<DiagnosisModel>"
        },
        {
            "name":     "payorData",
            "baseName": "payorData",
            "type":     "Array<SiteAccountGetCaseGetCasePayorResponseModel>"
        },
        {
            "name":     "pharmacyData",
            "baseName": "pharmacyData",
            "type":     "Array<SiteAccountGetCaseGetCasePharmacyResponseModel>"
        },
        {
            "name":     "prescriberData",
            "baseName": "prescriberData",
            "type":     "Array<PrescriberModel>"
        },
        {
            "name":     "prescriptionData",
            "baseName": "prescriptionData",
            "type":     "Array<PrescriptionModel>"
        },
        {
            "name":     "siteData",
            "baseName": "siteData",
            "type":     "Array<SiteAccountGetCaseGetCaseSiteResponseModel>"
        },
        {
            "name":     "surveyData",
            "baseName": "surveyData",
            "type":     "Array<SiteAccountGetCaseGetCaseSurveyResponseModel>"
        },
        {
            "name":     "ssn",
            "baseName": "ssn",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return SiteAccountGetCaseGetCaseResponseModel.attributeTypeMap;
    }
}
