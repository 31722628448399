// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class CompleteTaskRequestModel {
    'taskId': number;
    'resolution': string;
    'completionDate'?: Moment | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof CompleteTaskRequestModel>> = [
        {
            "name":     "taskId",
            "baseName": "taskId",
            "type":     "number"
        },
        {
            "name":     "resolution",
            "baseName": "resolution",
            "type":     "string"
        },
        {
            "name":     "completionDate",
            "baseName": "completionDate",
            "type":     "Date"
        },
    ];

    static getAttributeTypeMap() {
        return CompleteTaskRequestModel.attributeTypeMap;
    }
}
