// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EmailSignatureTemplateRequest } from './emailSignatureTemplateRequest.js';
import type { EnrollPatientRequestModel } from './enrollPatientRequestModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EnrollmentWithEmailSignatureTemplateRequest {
    'enrollmentRequest': EnrollPatientRequestModel | null;
    'emailSignatureTemplateRequest': EmailSignatureTemplateRequest | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnrollmentWithEmailSignatureTemplateRequest>> = [
        {
            "name":     "enrollmentRequest",
            "baseName": "enrollmentRequest",
            "type":     "EnrollPatientRequestModel"
        },
        {
            "name":     "emailSignatureTemplateRequest",
            "baseName": "emailSignatureTemplateRequest",
            "type":     "EmailSignatureTemplateRequest"
        },
    ];

    static getAttributeTypeMap() {
        return EnrollmentWithEmailSignatureTemplateRequest.attributeTypeMap;
    }
}
