// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ExtClaimFileOutputModel } from './extClaimFileOutputModel.js';
import type { TCSDocumentType } from './tCSDocumentType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ExtClaimSubmitClaimResultModel {
    'memberNumber'?: string;
    'channelId'?: string;
    'alternatePatientId'?: string;
    'docType'?: TCSDocumentType;
    'uploadFilesList': Array<ExtClaimFileOutputModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtClaimSubmitClaimResultModel>> = [
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "channelId",
            "baseName": "channelId",
            "type":     "string"
        },
        {
            "name":     "alternatePatientId",
            "baseName": "alternatePatientId",
            "type":     "string"
        },
        {
            "name":     "docType",
            "baseName": "docType",
            "type":     "TCSDocumentType"
        },
        {
            "name":     "uploadFilesList",
            "baseName": "uploadFilesList",
            "type":     "Array<ExtClaimFileOutputModel>"
        },
    ];

    static getAttributeTypeMap() {
        return ExtClaimSubmitClaimResultModel.attributeTypeMap;
    }
}
