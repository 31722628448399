// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PharmacySharedAddressModel } from './pharmacySharedAddressModel.js';
import type { PharmacySharedEmailAddressModel } from './pharmacySharedEmailAddressModel.js';
import type { PharmacySharedPhoneModel } from './pharmacySharedPhoneModel.js';
import type { PharmacySharedWebAddressModel } from './pharmacySharedWebAddressModel.js';
import type { PharmacySpecialization } from './pharmacySpecialization.js';
import type { PharmacyType } from './pharmacyType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyModel {
    'pharmacyId'?: number;
    'pharmacyName'?: string;
    'nabp'?: string;
    'npi'?: string;
    'pharmacyType'?: PharmacyType;
    'pharmacySpecialization'?: PharmacySpecialization;
    'addresses'?: Array<PharmacySharedAddressModel>;
    'phoneNumbers'?: Array<PharmacySharedPhoneModel>;
    'emailAddresses'?: Array<PharmacySharedEmailAddressModel>;
    'webAddresses'?: Array<PharmacySharedWebAddressModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyModel>> = [
        {
            "name":     "pharmacyId",
            "baseName": "pharmacyId",
            "type":     "number"
        },
        {
            "name":     "pharmacyName",
            "baseName": "pharmacyName",
            "type":     "string"
        },
        {
            "name":     "nabp",
            "baseName": "nabp",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "pharmacyType",
            "baseName": "pharmacyType",
            "type":     "PharmacyType"
        },
        {
            "name":     "pharmacySpecialization",
            "baseName": "pharmacySpecialization",
            "type":     "PharmacySpecialization"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<PharmacySharedAddressModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<PharmacySharedPhoneModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<PharmacySharedEmailAddressModel>"
        },
        {
            "name":     "webAddresses",
            "baseName": "webAddresses",
            "type":     "Array<PharmacySharedWebAddressModel>"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyModel.attributeTypeMap;
    }
}
