// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AddressType } from './addressType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EnterpriseCoordinatorAccountServiceModelsAccountAddressUpsearchModel {
    'id'?: number;
    'receiverFirstName'?: string;
    'receiverLastName'?: string;
    'addressType'?: AddressType;
    'addressOne'?: string;
    'addressTwo'?: string;
    'city'?: string;
    'state'?: string;
    'zip'?: string;
    'country'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnterpriseCoordinatorAccountServiceModelsAccountAddressUpsearchModel>> = [
        {
            "name":     "id",
            "baseName": "id",
            "type":     "number"
        },
        {
            "name":     "receiverFirstName",
            "baseName": "receiverFirstName",
            "type":     "string"
        },
        {
            "name":     "receiverLastName",
            "baseName": "receiverLastName",
            "type":     "string"
        },
        {
            "name":     "addressType",
            "baseName": "addressType",
            "type":     "AddressType"
        },
        {
            "name":     "addressOne",
            "baseName": "addressOne",
            "type":     "string"
        },
        {
            "name":     "addressTwo",
            "baseName": "addressTwo",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "zip",
            "baseName": "zip",
            "type":     "string"
        },
        {
            "name":     "country",
            "baseName": "country",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return EnterpriseCoordinatorAccountServiceModelsAccountAddressUpsearchModel.attributeTypeMap;
    }
}
