// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EnrollmentType } from './enrollmentType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description What will the Enrollment Service do as far as Member Numbers are concerned?  A list of objects of this type will tell you!
*/
export class EnrollmentV2RulesAssignMemberNumberResultModel {
    /**
    * @description Card Source Identifier (either an Offer Type or a TC-Services Program Id).
    */
    'cardSourceId'?: number | null;
    /**
    * @description Re-use a previously assigned Member Number
    */
    'existingMemberNumber'?: string | null;
    'memberNumberType'?: EnrollmentType;
    /**
    * @description Order a Physical Debit Card
    */
    'orderPdc'?: boolean | null;
    /**
    * @description Order a Virtual Debit Card
    */
    'orderVdc'?: boolean | null;
    /**
    * @description Specific Payment Method Id
    */
    'paymentMethodId'?: number | null;
    /**
    * @description This used to be the TC-Program Id. Now it can contain Offer Type Ids, depending on configuration.
    */
    'tcProgramId'?: number | null;
    /**
    * @description Status Code (Warren Status Code from Card Service)  Not populated for read operations
    */
    'statusCode'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnrollmentV2RulesAssignMemberNumberResultModel>> = [
        {
            "name":     "cardSourceId",
            "baseName": "cardSourceId",
            "type":     "number"
        },
        {
            "name":     "existingMemberNumber",
            "baseName": "existingMemberNumber",
            "type":     "string"
        },
        {
            "name":     "memberNumberType",
            "baseName": "memberNumberType",
            "type":     "EnrollmentType"
        },
        {
            "name":     "orderPdc",
            "baseName": "orderPdc",
            "type":     "boolean"
        },
        {
            "name":     "orderVdc",
            "baseName": "orderVdc",
            "type":     "boolean"
        },
        {
            "name":     "paymentMethodId",
            "baseName": "paymentMethodId",
            "type":     "number"
        },
        {
            "name":     "tcProgramId",
            "baseName": "tcProgramId",
            "type":     "number"
        },
        {
            "name":     "statusCode",
            "baseName": "statusCode",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return EnrollmentV2RulesAssignMemberNumberResultModel.attributeTypeMap;
    }
}
