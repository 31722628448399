// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RoleType } from './roleType.js';
import type { TrialcardSiteV3ModelsSharedAddressUpsearchModel } from './trialcardSiteV3ModelsSharedAddressUpsearchModel.js';
import type { TrialcardSiteV3ModelsSharedEmailAddressUpsearchModel } from './trialcardSiteV3ModelsSharedEmailAddressUpsearchModel.js';
import type { TrialcardSiteV3ModelsSharedPhoneUpsearchModel } from './trialcardSiteV3ModelsSharedPhoneUpsearchModel.js';
import type { TrialcardSiteV3ModelsSharedWebAddressUpsearchModel } from './trialcardSiteV3ModelsSharedWebAddressUpsearchModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class TrialcardSiteV3ModelsSharedContactUpsearchModel {
    'contactType'?: RoleType;
    'firstName'?: string | null;
    'lastName'?: string | null;
    'occupationDescription'?: string | null;
    'isMainContact'?: boolean;
    'contactId'?: number | null;
    'addresses'?: Array<TrialcardSiteV3ModelsSharedAddressUpsearchModel> | null;
    'emailAddresses'?: Array<TrialcardSiteV3ModelsSharedEmailAddressUpsearchModel> | null;
    'phoneNumbers'?: Array<TrialcardSiteV3ModelsSharedPhoneUpsearchModel> | null;
    'webAddresses'?: Array<TrialcardSiteV3ModelsSharedWebAddressUpsearchModel> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof TrialcardSiteV3ModelsSharedContactUpsearchModel>> = [
        {
            "name":     "contactType",
            "baseName": "contactType",
            "type":     "RoleType"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "occupationDescription",
            "baseName": "occupationDescription",
            "type":     "string"
        },
        {
            "name":     "isMainContact",
            "baseName": "isMainContact",
            "type":     "boolean"
        },
        {
            "name":     "contactId",
            "baseName": "contactId",
            "type":     "number"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<TrialcardSiteV3ModelsSharedAddressUpsearchModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<TrialcardSiteV3ModelsSharedEmailAddressUpsearchModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<TrialcardSiteV3ModelsSharedPhoneUpsearchModel>"
        },
        {
            "name":     "webAddresses",
            "baseName": "webAddresses",
            "type":     "Array<TrialcardSiteV3ModelsSharedWebAddressUpsearchModel>"
        },
    ];

    static getAttributeTypeMap() {
        return TrialcardSiteV3ModelsSharedContactUpsearchModel.attributeTypeMap;
    }
}
