// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyBiIndividual {
    'deductibleExists'?: string | null;
    'deductibleMet'?: string | null;
    'deductibleTotal'?: string | null;
    'oopMaximum'?: string | null;
    'oopMaximumExists'?: string | null;
    'oopMet'?: string | null;
    'oopMetUnavailable'?: string | null;
    'oopRemaining'?: string | null;
    'outOfPocketApplied'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyBiIndividual>> = [
        {
            "name":     "deductibleExists",
            "baseName": "deductibleExists",
            "type":     "string"
        },
        {
            "name":     "deductibleMet",
            "baseName": "deductibleMet",
            "type":     "string"
        },
        {
            "name":     "deductibleTotal",
            "baseName": "deductibleTotal",
            "type":     "string"
        },
        {
            "name":     "oopMaximum",
            "baseName": "oopMaximum",
            "type":     "string"
        },
        {
            "name":     "oopMaximumExists",
            "baseName": "oopMaximumExists",
            "type":     "string"
        },
        {
            "name":     "oopMet",
            "baseName": "oopMet",
            "type":     "string"
        },
        {
            "name":     "oopMetUnavailable",
            "baseName": "oopMetUnavailable",
            "type":     "string"
        },
        {
            "name":     "oopRemaining",
            "baseName": "oopRemaining",
            "type":     "string"
        },
        {
            "name":     "outOfPocketApplied",
            "baseName": "outOfPocketApplied",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyBiIndividual.attributeTypeMap;
    }
}
