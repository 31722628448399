// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CaseAddressModel } from './caseAddressModel.js';
import type { CaseAttestationAttributeModel } from './caseAttestationAttributeModel.js';
import type { CaseConditionRelationshipModel } from './caseConditionRelationshipModel.js';
import type { CaseContactPreferenceModel } from './caseContactPreferenceModel.js';
import type { CaseDocumentRelationshipModel } from './caseDocumentRelationshipModel.js';
import type { CaseExternalIdModel } from './caseExternalIdModel.js';
import type { CaseHomeHealthAgencyRelationshipModel } from './caseHomeHealthAgencyRelationshipModel.js';
import type { CasePayorRelationshipModel } from './casePayorRelationshipModel.js';
import type { CasePhoneModel } from './casePhoneModel.js';
import type { CasePrescriptionRelationshipModel } from './casePrescriptionRelationshipModel.js';
import type { CaseRelationshipModel } from './caseRelationshipModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class CaseModel {
    'caseId'?: number;
    'tenantId'?: number;
    'accountId'?: number;
    'programId'?: number;
    'displayId'?: string;
    'typeCode'?: string;
    'typeDescription'?: string;
    'statusId'?: number;
    'statusCode'?: string;
    'statusDescription'?: string;
    'subStatusId'?: number;
    'subStatusCode'?: string;
    'subStatusDescription'?: string;
    'isOpen'?: boolean;
    'expirationDate'?: Moment;
    'endDate'?: Moment;
    'isExpired'?: boolean;
    'createdOn'?: Moment;
    'typeCategoryCode'?: string;
    'typeCategoryId'?: number;
    'typeCategoryDescription'?: string;
    'statusCategoryCode'?: string;
    'statusCategoryId'?: number;
    'statusCategoryDescription'?: string;
    'closedBy'?: number;
    'conditions'?: Array<CaseConditionRelationshipModel>;
    'attestations'?: Array<CaseAttestationAttributeModel>;
    'contactPreferences'?: Array<CaseContactPreferenceModel>;
    'addresses'?: Array<CaseAddressModel>;
    'phoneNumbers'?: Array<CasePhoneModel>;
    'externalIds'?: Array<CaseExternalIdModel>;
    'typeId'?: number;
    'assignedUserId'?: number;
    'prescribers'?: Array<CaseRelationshipModel>;
    'prescriptions'?: Array<CasePrescriptionRelationshipModel>;
    'sites'?: Array<CaseRelationshipModel>;
    'payors'?: Array<CasePayorRelationshipModel>;
    'pharmacies'?: Array<CaseRelationshipModel>;
    'homeHealthAgencies'?: Array<CaseHomeHealthAgencyRelationshipModel>;
    'documents'?: Array<CaseDocumentRelationshipModel>;
    'notes'?: Array<number>;
    'tasks'?: Array<number>;
    'sourceId'?: number;
    'previousCaseId'?: number;
    'startDate'?: Moment;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof CaseModel>> = [
        {
            "name":     "caseId",
            "baseName": "caseId",
            "type":     "number"
        },
        {
            "name":     "tenantId",
            "baseName": "tenantId",
            "type":     "number"
        },
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "programId",
            "baseName": "programId",
            "type":     "number"
        },
        {
            "name":     "displayId",
            "baseName": "displayId",
            "type":     "string"
        },
        {
            "name":     "typeCode",
            "baseName": "typeCode",
            "type":     "string"
        },
        {
            "name":     "typeDescription",
            "baseName": "typeDescription",
            "type":     "string"
        },
        {
            "name":     "statusId",
            "baseName": "statusId",
            "type":     "number"
        },
        {
            "name":     "statusCode",
            "baseName": "statusCode",
            "type":     "string"
        },
        {
            "name":     "statusDescription",
            "baseName": "statusDescription",
            "type":     "string"
        },
        {
            "name":     "subStatusId",
            "baseName": "subStatusId",
            "type":     "number"
        },
        {
            "name":     "subStatusCode",
            "baseName": "subStatusCode",
            "type":     "string"
        },
        {
            "name":     "subStatusDescription",
            "baseName": "subStatusDescription",
            "type":     "string"
        },
        {
            "name":     "isOpen",
            "baseName": "isOpen",
            "type":     "boolean"
        },
        {
            "name":     "expirationDate",
            "baseName": "expirationDate",
            "type":     "Date"
        },
        {
            "name":     "endDate",
            "baseName": "endDate",
            "type":     "Date"
        },
        {
            "name":     "isExpired",
            "baseName": "isExpired",
            "type":     "boolean"
        },
        {
            "name":     "createdOn",
            "baseName": "createdOn",
            "type":     "Date"
        },
        {
            "name":     "typeCategoryCode",
            "baseName": "typeCategoryCode",
            "type":     "string"
        },
        {
            "name":     "typeCategoryId",
            "baseName": "typeCategoryId",
            "type":     "number"
        },
        {
            "name":     "typeCategoryDescription",
            "baseName": "typeCategoryDescription",
            "type":     "string"
        },
        {
            "name":     "statusCategoryCode",
            "baseName": "statusCategoryCode",
            "type":     "string"
        },
        {
            "name":     "statusCategoryId",
            "baseName": "statusCategoryId",
            "type":     "number"
        },
        {
            "name":     "statusCategoryDescription",
            "baseName": "statusCategoryDescription",
            "type":     "string"
        },
        {
            "name":     "closedBy",
            "baseName": "closedBy",
            "type":     "number"
        },
        {
            "name":     "conditions",
            "baseName": "conditions",
            "type":     "Array<CaseConditionRelationshipModel>"
        },
        {
            "name":     "attestations",
            "baseName": "attestations",
            "type":     "Array<CaseAttestationAttributeModel>"
        },
        {
            "name":     "contactPreferences",
            "baseName": "contactPreferences",
            "type":     "Array<CaseContactPreferenceModel>"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<CaseAddressModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<CasePhoneModel>"
        },
        {
            "name":     "externalIds",
            "baseName": "externalIds",
            "type":     "Array<CaseExternalIdModel>"
        },
        {
            "name":     "typeId",
            "baseName": "typeId",
            "type":     "number"
        },
        {
            "name":     "assignedUserId",
            "baseName": "assignedUserId",
            "type":     "number"
        },
        {
            "name":     "prescribers",
            "baseName": "prescribers",
            "type":     "Array<CaseRelationshipModel>"
        },
        {
            "name":     "prescriptions",
            "baseName": "prescriptions",
            "type":     "Array<CasePrescriptionRelationshipModel>"
        },
        {
            "name":     "sites",
            "baseName": "sites",
            "type":     "Array<CaseRelationshipModel>"
        },
        {
            "name":     "payors",
            "baseName": "payors",
            "type":     "Array<CasePayorRelationshipModel>"
        },
        {
            "name":     "pharmacies",
            "baseName": "pharmacies",
            "type":     "Array<CaseRelationshipModel>"
        },
        {
            "name":     "homeHealthAgencies",
            "baseName": "homeHealthAgencies",
            "type":     "Array<CaseHomeHealthAgencyRelationshipModel>"
        },
        {
            "name":     "documents",
            "baseName": "documents",
            "type":     "Array<CaseDocumentRelationshipModel>"
        },
        {
            "name":     "notes",
            "baseName": "notes",
            "type":     "Array<number>"
        },
        {
            "name":     "tasks",
            "baseName": "tasks",
            "type":     "Array<number>"
        },
        {
            "name":     "sourceId",
            "baseName": "sourceId",
            "type":     "number"
        },
        {
            "name":     "previousCaseId",
            "baseName": "previousCaseId",
            "type":     "number"
        },
        {
            "name":     "startDate",
            "baseName": "startDate",
            "type":     "Date"
        },
    ];

    static getAttributeTypeMap() {
        return CaseModel.attributeTypeMap;
    }
}
