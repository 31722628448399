// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EnterpriseCoordinatorSharedAddressUpsearchModel } from './enterpriseCoordinatorSharedAddressUpsearchModel.js';
import type { EnterpriseCoordinatorSharedContactUpsearchModel } from './enterpriseCoordinatorSharedContactUpsearchModel.js';
import type { EnterpriseCoordinatorSharedEmailAddressUpsearchModel } from './enterpriseCoordinatorSharedEmailAddressUpsearchModel.js';
import type { EnterpriseCoordinatorSharedExternalIdUpsearchModel } from './enterpriseCoordinatorSharedExternalIdUpsearchModel.js';
import type { EnterpriseCoordinatorSharedGlobalIdUpsearchModel } from './enterpriseCoordinatorSharedGlobalIdUpsearchModel.js';
import type { EnterpriseCoordinatorSharedPhoneUpsearchModel } from './enterpriseCoordinatorSharedPhoneUpsearchModel.js';
import type { EnterpriseCoordinatorSiteServiceModelsSitePointOfContactUpsearchModel } from './enterpriseCoordinatorSiteServiceModelsSitePointOfContactUpsearchModel.js';
import type { RoleType } from './roleType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EnrollPatientRequestModelAccountAllOfSiteDataInner {
    'accountRelationshipIsPrimary'?: boolean;
    'pointOfContacts'?: Array<EnterpriseCoordinatorSiteServiceModelsSitePointOfContactUpsearchModel>;
    'siteId'?: number;
    'siteRoleType'?: RoleType;
    'description'?: string;
    'nickName'?: string;
    'businessStartTime'?: Moment;
    'businessEndTime'?: Moment;
    'addresses'?: Array<EnterpriseCoordinatorSharedAddressUpsearchModel>;
    'contacts'?: Array<EnterpriseCoordinatorSharedContactUpsearchModel>;
    'emailAddresses'?: Array<EnterpriseCoordinatorSharedEmailAddressUpsearchModel>;
    'externalIds'?: Array<EnterpriseCoordinatorSharedExternalIdUpsearchModel>;
    'phoneNumbers'?: Array<EnterpriseCoordinatorSharedPhoneUpsearchModel>;
    'globalIds'?: Array<EnterpriseCoordinatorSharedGlobalIdUpsearchModel>;
    'stateTaxId'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnrollPatientRequestModelAccountAllOfSiteDataInner>> = [
        {
            "name":     "accountRelationshipIsPrimary",
            "baseName": "accountRelationshipIsPrimary",
            "type":     "boolean"
        },
        {
            "name":     "pointOfContacts",
            "baseName": "pointOfContacts",
            "type":     "Array<EnterpriseCoordinatorSiteServiceModelsSitePointOfContactUpsearchModel>"
        },
        {
            "name":     "siteId",
            "baseName": "siteId",
            "type":     "number"
        },
        {
            "name":     "siteRoleType",
            "baseName": "siteRoleType",
            "type":     "RoleType"
        },
        {
            "name":     "description",
            "baseName": "description",
            "type":     "string"
        },
        {
            "name":     "nickName",
            "baseName": "nickName",
            "type":     "string"
        },
        {
            "name":     "businessStartTime",
            "baseName": "businessStartTime",
            "type":     "Date"
        },
        {
            "name":     "businessEndTime",
            "baseName": "businessEndTime",
            "type":     "Date"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<EnterpriseCoordinatorSharedAddressUpsearchModel>"
        },
        {
            "name":     "contacts",
            "baseName": "contacts",
            "type":     "Array<EnterpriseCoordinatorSharedContactUpsearchModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<EnterpriseCoordinatorSharedEmailAddressUpsearchModel>"
        },
        {
            "name":     "externalIds",
            "baseName": "externalIds",
            "type":     "Array<EnterpriseCoordinatorSharedExternalIdUpsearchModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<EnterpriseCoordinatorSharedPhoneUpsearchModel>"
        },
        {
            "name":     "globalIds",
            "baseName": "globalIds",
            "type":     "Array<EnterpriseCoordinatorSharedGlobalIdUpsearchModel>"
        },
        {
            "name":     "stateTaxId",
            "baseName": "stateTaxId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return EnrollPatientRequestModelAccountAllOfSiteDataInner.attributeTypeMap;
    }
}
