// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CoverMyMedsInboundEnrollmentAddressModel } from './coverMyMedsInboundEnrollmentAddressModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Patient to enroll
*/
export class CoverMyMedsInboundEnrollmentPatientModel {
    /**
    * @description Patient\\\'s cell phone number (required, Must be between 10 and 15 chars)<br />  Saved as patient\\\'s \\\'Cell Phone\\\'
    */
    'cellPhone'?: string | null;
    /**
    * @description Patient\\\'s date of birth. (required)<br />  Saved as patient\\\'s \\\'Patient DOB\\\'
    */
    'dateOfBirth'?: string;
    /**
    * @description Patient\\\'s email. (required, 255 chars max)<br />  Saved as patient\\\'s \\\'Patient Email\\\'
    */
    'email'?: string | null;
    /**
    * @description Patient\\\'s first name. (required, 100 chars max)<br />  Saved as patient\\\'s \\\'Patient Name\\\'
    */
    'firstName'?: string | null;
    /**
    * @description Patient\\\'s gender, (1 char)<br />  (Not Used)
    */
    'gender'?: string | null;
    /**
    * @description Patient\\\'s home phone number (Must be between 10 and 15 chars)<br />  Saved as patient\\\'s \\\'Home Phone\\\'
    */
    'homePhone'?: string | null;
    /**
    * @description Patient\\\'s last name. (required, 100 chars max)<br />  Saved as patient\\\'s \\\'Patient Name\\\'
    */
    'lastName'?: string | null;
    /**
    * @description Patient\\\'s middle name (100 chars max)<br />  Saved as patient\\\'s \\\'Patient Name\\\'
    */
    'middleName'?: string | null;
    /**
    * @description Patients preferred contact methods (255 chars max for each contact method)<br />  String can contain \\\'Cell Phone\\\' or \\\'Home Phone\\\'<br />  Used to determine which phone is preferred contact method
    */
    'patientContactMethods'?: Array<string> | null;
    'primaryAddress'?: CoverMyMedsInboundEnrollmentAddressModel | null;
    /**
    * @description Does the patient wish to receive voicemails<br />  Mapped to patient \\\'Authorized to leave a message\\\' flag for primary contact phone
    */
    'voicemailConsent'?: boolean | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof CoverMyMedsInboundEnrollmentPatientModel>> = [
        {
            "name":     "cellPhone",
            "baseName": "cellPhone",
            "type":     "string"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "string"
        },
        {
            "name":     "email",
            "baseName": "email",
            "type":     "string"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "gender",
            "baseName": "gender",
            "type":     "string"
        },
        {
            "name":     "homePhone",
            "baseName": "homePhone",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "middleName",
            "baseName": "middleName",
            "type":     "string"
        },
        {
            "name":     "patientContactMethods",
            "baseName": "patientContactMethods",
            "type":     "Array<string>"
        },
        {
            "name":     "primaryAddress",
            "baseName": "primaryAddress",
            "type":     "CoverMyMedsInboundEnrollmentAddressModel"
        },
        {
            "name":     "voicemailConsent",
            "baseName": "voicemailConsent",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return CoverMyMedsInboundEnrollmentPatientModel.attributeTypeMap;
    }
}
