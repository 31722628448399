// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { TCSCommunicationOption } from './tCSCommunicationOption.js';
import type { TCSCommunicationType } from './tCSCommunicationType.js';
import type { TCSSiteType } from './tCSSiteType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ExtEnrollmentSiteEnrollmentModel {
    'siteId'?: string;
    'siteType'?: TCSSiteType;
    'siteCommunicationTypes': Array<TCSCommunicationType>;
    'siteCommunicationOption'?: TCSCommunicationOption;
    'sitePayable'?: boolean;
    'aobReceived'?: boolean;
    'prescriberId'?: string;
    'prescriberCommunicationTypes': Array<TCSCommunicationType>;
    'prescriberCommunicationOption'?: TCSCommunicationOption;
    'siteEmail'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtEnrollmentSiteEnrollmentModel>> = [
        {
            "name":     "siteId",
            "baseName": "siteId",
            "type":     "string"
        },
        {
            "name":     "siteType",
            "baseName": "siteType",
            "type":     "TCSSiteType"
        },
        {
            "name":     "siteCommunicationTypes",
            "baseName": "siteCommunicationTypes",
            "type":     "Array<TCSCommunicationType>"
        },
        {
            "name":     "siteCommunicationOption",
            "baseName": "siteCommunicationOption",
            "type":     "TCSCommunicationOption"
        },
        {
            "name":     "sitePayable",
            "baseName": "sitePayable",
            "type":     "boolean"
        },
        {
            "name":     "aobReceived",
            "baseName": "aobReceived",
            "type":     "boolean"
        },
        {
            "name":     "prescriberId",
            "baseName": "prescriberId",
            "type":     "string"
        },
        {
            "name":     "prescriberCommunicationTypes",
            "baseName": "prescriberCommunicationTypes",
            "type":     "Array<TCSCommunicationType>"
        },
        {
            "name":     "prescriberCommunicationOption",
            "baseName": "prescriberCommunicationOption",
            "type":     "TCSCommunicationOption"
        },
        {
            "name":     "siteEmail",
            "baseName": "siteEmail",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ExtEnrollmentSiteEnrollmentModel.attributeTypeMap;
    }
}
