// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { DiagnosisType } from './diagnosisType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class DiagnosisSearchRequestModel {
    'programId'?: number;
    'diagnosisName'?: string;
    'diagnosisDescription'?: string;
    'diagnosisCode'?: string;
    'diagnosisType'?: DiagnosisType;
    'maxRecordCount'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof DiagnosisSearchRequestModel>> = [
        {
            "name":     "programId",
            "baseName": "programId",
            "type":     "number"
        },
        {
            "name":     "diagnosisName",
            "baseName": "diagnosisName",
            "type":     "string"
        },
        {
            "name":     "diagnosisDescription",
            "baseName": "diagnosisDescription",
            "type":     "string"
        },
        {
            "name":     "diagnosisCode",
            "baseName": "diagnosisCode",
            "type":     "string"
        },
        {
            "name":     "diagnosisType",
            "baseName": "diagnosisType",
            "type":     "DiagnosisType"
        },
        {
            "name":     "maxRecordCount",
            "baseName": "maxRecordCount",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return DiagnosisSearchRequestModel.attributeTypeMap;
    }
}
