// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class CreateDataRequest {
    'contentType'?: string;
    'data'?: any | null;
    'systemCode': string;
    'systemId': string;
    'purposeCode': string;
    'purposeId': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof CreateDataRequest>> = [
        {
            "name":     "contentType",
            "baseName": "contentType",
            "type":     "string"
        },
        {
            "name":     "data",
            "baseName": "data",
            "type":     "any"
        },
        {
            "name":     "systemCode",
            "baseName": "systemCode",
            "type":     "string"
        },
        {
            "name":     "systemId",
            "baseName": "systemId",
            "type":     "string"
        },
        {
            "name":     "purposeCode",
            "baseName": "purposeCode",
            "type":     "string"
        },
        {
            "name":     "purposeId",
            "baseName": "purposeId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return CreateDataRequest.attributeTypeMap;
    }
}
