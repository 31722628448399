// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ExtEnrollmentPhoneNumberModel } from './extEnrollmentPhoneNumberModel.js';
import type { TCSCommunicationOption } from './tCSCommunicationOption.js';
import type { TCSCommunicationType } from './tCSCommunicationType.js';
import type { TCSPaymentType } from './tCSPaymentType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ExtEnrollmentHybridPatientSearchDemographicsModel {
    'patientId'?: string;
    'firstName'?: string;
    'lastName'?: string;
    'gender'?: string;
    'dateOfBirth'?: string;
    'email'?: string;
    'phoneNumbers': Array<ExtEnrollmentPhoneNumberModel>;
    'address1'?: string;
    'address2'?: string;
    'city'?: string;
    'state'?: string;
    'postalCode'?: string;
    'country'?: string;
    'memberNumber'?: string;
    'communicationTypes': Array<TCSCommunicationType>;
    'communicationOption'?: TCSCommunicationOption;
    'paymentType'?: TCSPaymentType;
    'householdSize'?: number;
    'medicalPayerId'?: string;
    'medicalMemberNumber'?: string;
    'medicalGroupNumber'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtEnrollmentHybridPatientSearchDemographicsModel>> = [
        {
            "name":     "patientId",
            "baseName": "patientId",
            "type":     "string"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "gender",
            "baseName": "gender",
            "type":     "string"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "string"
        },
        {
            "name":     "email",
            "baseName": "email",
            "type":     "string"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<ExtEnrollmentPhoneNumberModel>"
        },
        {
            "name":     "address1",
            "baseName": "address1",
            "type":     "string"
        },
        {
            "name":     "address2",
            "baseName": "address2",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "postalCode",
            "baseName": "postalCode",
            "type":     "string"
        },
        {
            "name":     "country",
            "baseName": "country",
            "type":     "string"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "communicationTypes",
            "baseName": "communicationTypes",
            "type":     "Array<TCSCommunicationType>"
        },
        {
            "name":     "communicationOption",
            "baseName": "communicationOption",
            "type":     "TCSCommunicationOption"
        },
        {
            "name":     "paymentType",
            "baseName": "paymentType",
            "type":     "TCSPaymentType"
        },
        {
            "name":     "householdSize",
            "baseName": "householdSize",
            "type":     "number"
        },
        {
            "name":     "medicalPayerId",
            "baseName": "medicalPayerId",
            "type":     "string"
        },
        {
            "name":     "medicalMemberNumber",
            "baseName": "medicalMemberNumber",
            "type":     "string"
        },
        {
            "name":     "medicalGroupNumber",
            "baseName": "medicalGroupNumber",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ExtEnrollmentHybridPatientSearchDemographicsModel.attributeTypeMap;
    }
}
