// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EnterpriseCoordinatorDiagnosisServiceModelsDiagnosisSessionModel } from './enterpriseCoordinatorDiagnosisServiceModelsDiagnosisSessionModel.js';
import type { EnterpriseCoordinatorPrescriptionServiceModelsPrescriptionModel } from './enterpriseCoordinatorPrescriptionServiceModelsPrescriptionModel.js';
import type { UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel } from './upsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel.js';
import type { UpsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel } from './upsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel.js';
import type { UpsearchModelEnterpriseCoordinatorPharmacyServiceModelsPharmacyUpsearchModel } from './upsearchModelEnterpriseCoordinatorPharmacyServiceModelsPharmacyUpsearchModel.js';
import type { UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel } from './upsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel.js';
import type { UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchBaseModel } from './upsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchBaseModel.js';
import type { UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel } from './upsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EnterpriseCoordinatorSiteAccountUpsearchRequestModel {
    'partyData'?: UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchBaseModel | null;
    'accountData'?: UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel | null;
    'diagnosisSessionData'?: EnterpriseCoordinatorDiagnosisServiceModelsDiagnosisSessionModel | null;
    'payorData'?: Array<UpsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel>;
    'pharmacyData'?: Array<UpsearchModelEnterpriseCoordinatorPharmacyServiceModelsPharmacyUpsearchModel>;
    'prescriberData'?: Array<UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel>;
    'prescriptionData'?: Array<EnterpriseCoordinatorPrescriptionServiceModelsPrescriptionModel>;
    'siteData'?: Array<UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel>;
    'surveySessionData'?: Array<number>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnterpriseCoordinatorSiteAccountUpsearchRequestModel>> = [
        {
            "name":     "partyData",
            "baseName": "partyData",
            "type":     "UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchBaseModel"
        },
        {
            "name":     "accountData",
            "baseName": "accountData",
            "type":     "UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel"
        },
        {
            "name":     "diagnosisSessionData",
            "baseName": "diagnosisSessionData",
            "type":     "EnterpriseCoordinatorDiagnosisServiceModelsDiagnosisSessionModel"
        },
        {
            "name":     "payorData",
            "baseName": "payorData",
            "type":     "Array<UpsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel>"
        },
        {
            "name":     "pharmacyData",
            "baseName": "pharmacyData",
            "type":     "Array<UpsearchModelEnterpriseCoordinatorPharmacyServiceModelsPharmacyUpsearchModel>"
        },
        {
            "name":     "prescriberData",
            "baseName": "prescriberData",
            "type":     "Array<UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel>"
        },
        {
            "name":     "prescriptionData",
            "baseName": "prescriptionData",
            "type":     "Array<EnterpriseCoordinatorPrescriptionServiceModelsPrescriptionModel>"
        },
        {
            "name":     "siteData",
            "baseName": "siteData",
            "type":     "Array<UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel>"
        },
        {
            "name":     "surveySessionData",
            "baseName": "surveySessionData",
            "type":     "Array<number>"
        },
    ];

    static getAttributeTypeMap() {
        return EnterpriseCoordinatorSiteAccountUpsearchRequestModel.attributeTypeMap;
    }
}
