// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PriorAuthAppealInfoModel {
    'available'?: string | null;
    'contactOrg'?: string | null;
    'contactPhone'?: string | null;
    'requiredDocuments'?: Array<string> | null;
    'submissionDeadline'?: string | null;
    'turnaroundTime'?: string | null;
    'notificationMethods'?: Array<string> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PriorAuthAppealInfoModel>> = [
        {
            "name":     "available",
            "baseName": "available",
            "type":     "string"
        },
        {
            "name":     "contactOrg",
            "baseName": "contactOrg",
            "type":     "string"
        },
        {
            "name":     "contactPhone",
            "baseName": "contactPhone",
            "type":     "string"
        },
        {
            "name":     "requiredDocuments",
            "baseName": "requiredDocuments",
            "type":     "Array<string>"
        },
        {
            "name":     "submissionDeadline",
            "baseName": "submissionDeadline",
            "type":     "string"
        },
        {
            "name":     "turnaroundTime",
            "baseName": "turnaroundTime",
            "type":     "string"
        },
        {
            "name":     "notificationMethods",
            "baseName": "notificationMethods",
            "type":     "Array<string>"
        },
    ];

    static getAttributeTypeMap() {
        return PriorAuthAppealInfoModel.attributeTypeMap;
    }
}
