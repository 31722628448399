// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class BestFitPharmacyPrescription {
    'daysSupply'?: string;
    'ndc': string;
    'quantity'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof BestFitPharmacyPrescription>> = [
        {
            "name":     "daysSupply",
            "baseName": "daysSupply",
            "type":     "string"
        },
        {
            "name":     "ndc",
            "baseName": "ndc",
            "type":     "string"
        },
        {
            "name":     "quantity",
            "baseName": "quantity",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return BestFitPharmacyPrescription.attributeTypeMap;
    }
}
