// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ExtCardDrugModel } from './extCardDrugModel.js';
import type { TCSCommunicationType } from './tCSCommunicationType.js';
import type { TcsWrapperModelsAccumulatorModel } from './tcsWrapperModelsAccumulatorModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ExtCardInternalStatusResultModel {
    'promotionId'?: number;
    'tcServicePrgId'?: number;
    'offerTypeId'?: number;
    'batchId'?: number;
    'tcServicePrgDesc'?: string;
    'memberNumberStatusCode'?: string;
    'effectiveDate'?: Moment;
    'ndcs'?: Array<ExtCardDrugModel>;
    'communicationTypes'?: TCSCommunicationType;
    'cap'?: boolean;
    'maximizer'?: boolean;
    'redemptions'?: number;
    'memberNumberStatus'?: string;
    'memberNumberStatusId'?: string;
    'memberNumberType'?: string;
    'pcn'?: string;
    'bin'?: string;
    'cardActivationDate'?: Moment;
    'cardEndDate'?: Moment;
    'sponsorNumber'?: string;
    'memberNumber'?: string;
    'groupNumber'?: string;
    'totalBenefitAmount'?: number;
    'remainingBenefitAmount'?: number;
    'totalPaidAmount'?: number;
    'accumulators'?: Array<TcsWrapperModelsAccumulatorModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtCardInternalStatusResultModel>> = [
        {
            "name":     "promotionId",
            "baseName": "promotionId",
            "type":     "number"
        },
        {
            "name":     "tcServicePrgId",
            "baseName": "tcServicePrgId",
            "type":     "number"
        },
        {
            "name":     "offerTypeId",
            "baseName": "offerTypeId",
            "type":     "number"
        },
        {
            "name":     "batchId",
            "baseName": "batchId",
            "type":     "number"
        },
        {
            "name":     "tcServicePrgDesc",
            "baseName": "tcServicePrgDesc",
            "type":     "string"
        },
        {
            "name":     "memberNumberStatusCode",
            "baseName": "memberNumberStatusCode",
            "type":     "string"
        },
        {
            "name":     "effectiveDate",
            "baseName": "effectiveDate",
            "type":     "Date"
        },
        {
            "name":     "ndcs",
            "baseName": "ndcs",
            "type":     "Array<ExtCardDrugModel>"
        },
        {
            "name":     "communicationTypes",
            "baseName": "communicationTypes",
            "type":     "TCSCommunicationType"
        },
        {
            "name":     "cap",
            "baseName": "cap",
            "type":     "boolean"
        },
        {
            "name":     "maximizer",
            "baseName": "maximizer",
            "type":     "boolean"
        },
        {
            "name":     "redemptions",
            "baseName": "redemptions",
            "type":     "number"
        },
        {
            "name":     "memberNumberStatus",
            "baseName": "memberNumberStatus",
            "type":     "string"
        },
        {
            "name":     "memberNumberStatusId",
            "baseName": "memberNumberStatusId",
            "type":     "string"
        },
        {
            "name":     "memberNumberType",
            "baseName": "memberNumberType",
            "type":     "string"
        },
        {
            "name":     "pcn",
            "baseName": "pcn",
            "type":     "string"
        },
        {
            "name":     "bin",
            "baseName": "bin",
            "type":     "string"
        },
        {
            "name":     "cardActivationDate",
            "baseName": "cardActivationDate",
            "type":     "Date"
        },
        {
            "name":     "cardEndDate",
            "baseName": "cardEndDate",
            "type":     "Date"
        },
        {
            "name":     "sponsorNumber",
            "baseName": "sponsorNumber",
            "type":     "string"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "groupNumber",
            "baseName": "groupNumber",
            "type":     "string"
        },
        {
            "name":     "totalBenefitAmount",
            "baseName": "totalBenefitAmount",
            "type":     "number"
        },
        {
            "name":     "remainingBenefitAmount",
            "baseName": "remainingBenefitAmount",
            "type":     "number"
        },
        {
            "name":     "totalPaidAmount",
            "baseName": "totalPaidAmount",
            "type":     "number"
        },
        {
            "name":     "accumulators",
            "baseName": "accumulators",
            "type":     "Array<TcsWrapperModelsAccumulatorModel>"
        },
    ];

    static getAttributeTypeMap() {
        return ExtCardInternalStatusResultModel.attributeTypeMap;
    }
}
