// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CaseExternalIdType } from './caseExternalIdType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class CaseSearchModel {
    'maximumNumberOfResults'?: number;
    'typeId'?: number;
    'statusId'?: number;
    'subStatusId'?: number;
    'createdAfter'?: Moment;
    'createdBefore'?: Moment;
    'endBefore'?: Moment;
    'endAfter'?: Moment;
    'isAssigned'?: boolean;
    'assignedUserId'?: number;
    'externalId'?: string;
    'externalIdType'?: CaseExternalIdType;
    'externalTypeCategoryId'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof CaseSearchModel>> = [
        {
            "name":     "maximumNumberOfResults",
            "baseName": "maximumNumberOfResults",
            "type":     "number"
        },
        {
            "name":     "typeId",
            "baseName": "typeId",
            "type":     "number"
        },
        {
            "name":     "statusId",
            "baseName": "statusId",
            "type":     "number"
        },
        {
            "name":     "subStatusId",
            "baseName": "subStatusId",
            "type":     "number"
        },
        {
            "name":     "createdAfter",
            "baseName": "createdAfter",
            "type":     "Date"
        },
        {
            "name":     "createdBefore",
            "baseName": "createdBefore",
            "type":     "Date"
        },
        {
            "name":     "endBefore",
            "baseName": "endBefore",
            "type":     "Date"
        },
        {
            "name":     "endAfter",
            "baseName": "endAfter",
            "type":     "Date"
        },
        {
            "name":     "isAssigned",
            "baseName": "isAssigned",
            "type":     "boolean"
        },
        {
            "name":     "assignedUserId",
            "baseName": "assignedUserId",
            "type":     "number"
        },
        {
            "name":     "externalId",
            "baseName": "externalId",
            "type":     "string"
        },
        {
            "name":     "externalIdType",
            "baseName": "externalIdType",
            "type":     "CaseExternalIdType"
        },
        {
            "name":     "externalTypeCategoryId",
            "baseName": "externalTypeCategoryId",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return CaseSearchModel.attributeTypeMap;
    }
}
