// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AccountStatus } from './accountStatus.js';
import type { AccountType } from './accountType.js';
import type { EnterpriseCoordinatorAccountServiceModelsAccountAddressUpsearchModel } from './enterpriseCoordinatorAccountServiceModelsAccountAddressUpsearchModel.js';
import type { EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel } from './enterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel.js';
import type { EnterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel } from './enterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel.js';
import type { EnterpriseCoordinatorAccountServiceModelsAccountExternalIdModel } from './enterpriseCoordinatorAccountServiceModelsAccountExternalIdModel.js';
import type { EnterpriseCoordinatorAccountServiceModelsAccountPayorRelationModel } from './enterpriseCoordinatorAccountServiceModelsAccountPayorRelationModel.js';
import type { EnterpriseCoordinatorAccountServiceModelsAccountPhoneUpsearchModel } from './enterpriseCoordinatorAccountServiceModelsAccountPhoneUpsearchModel.js';
import type { EnterpriseCoordinatorAccountServiceModelsAccountRelationModel } from './enterpriseCoordinatorAccountServiceModelsAccountRelationModel.js';
import type { EnterpriseCoordinatorAccountServiceModelsVoicemailOptInModel } from './enterpriseCoordinatorAccountServiceModelsVoicemailOptInModel.js';
import type { EnterpriseCoordinatorCaseDocumentRelationshipModel } from './enterpriseCoordinatorCaseDocumentRelationshipModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel {
    'accountId'?: number;
    'accountType'?: AccountType;
    'accountStatus'?: AccountStatus;
    'assignedUser'?: number;
    'payors'?: Array<EnterpriseCoordinatorAccountServiceModelsAccountPayorRelationModel>;
    'prescribers'?: Array<EnterpriseCoordinatorAccountServiceModelsAccountRelationModel>;
    'sites'?: Array<EnterpriseCoordinatorAccountServiceModelsAccountRelationModel>;
    'pharmacies'?: Array<EnterpriseCoordinatorAccountServiceModelsAccountRelationModel>;
    'addresses'?: Array<EnterpriseCoordinatorAccountServiceModelsAccountAddressUpsearchModel>;
    'phoneNumbers'?: Array<EnterpriseCoordinatorAccountServiceModelsAccountPhoneUpsearchModel>;
    'documents'?: Array<EnterpriseCoordinatorCaseDocumentRelationshipModel>;
    'notes'?: Array<number>;
    'tasks'?: Array<number>;
    'voicemailOptInStatus'?: Array<EnterpriseCoordinatorAccountServiceModelsVoicemailOptInModel>;
    'contacts'?: Array<EnterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel>;
    'contactPreferences'?: Array<EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel>;
    'externalIds'?: Array<EnterpriseCoordinatorAccountServiceModelsAccountExternalIdModel>;
    'expireExistingAccountRelationships'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel>> = [
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "accountType",
            "baseName": "accountType",
            "type":     "AccountType"
        },
        {
            "name":     "accountStatus",
            "baseName": "accountStatus",
            "type":     "AccountStatus"
        },
        {
            "name":     "assignedUser",
            "baseName": "assignedUser",
            "type":     "number"
        },
        {
            "name":     "payors",
            "baseName": "payors",
            "type":     "Array<EnterpriseCoordinatorAccountServiceModelsAccountPayorRelationModel>"
        },
        {
            "name":     "prescribers",
            "baseName": "prescribers",
            "type":     "Array<EnterpriseCoordinatorAccountServiceModelsAccountRelationModel>"
        },
        {
            "name":     "sites",
            "baseName": "sites",
            "type":     "Array<EnterpriseCoordinatorAccountServiceModelsAccountRelationModel>"
        },
        {
            "name":     "pharmacies",
            "baseName": "pharmacies",
            "type":     "Array<EnterpriseCoordinatorAccountServiceModelsAccountRelationModel>"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<EnterpriseCoordinatorAccountServiceModelsAccountAddressUpsearchModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<EnterpriseCoordinatorAccountServiceModelsAccountPhoneUpsearchModel>"
        },
        {
            "name":     "documents",
            "baseName": "documents",
            "type":     "Array<EnterpriseCoordinatorCaseDocumentRelationshipModel>"
        },
        {
            "name":     "notes",
            "baseName": "notes",
            "type":     "Array<number>"
        },
        {
            "name":     "tasks",
            "baseName": "tasks",
            "type":     "Array<number>"
        },
        {
            "name":     "voicemailOptInStatus",
            "baseName": "voicemailOptInStatus",
            "type":     "Array<EnterpriseCoordinatorAccountServiceModelsVoicemailOptInModel>"
        },
        {
            "name":     "contacts",
            "baseName": "contacts",
            "type":     "Array<EnterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel>"
        },
        {
            "name":     "contactPreferences",
            "baseName": "contactPreferences",
            "type":     "Array<EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel>"
        },
        {
            "name":     "externalIds",
            "baseName": "externalIds",
            "type":     "Array<EnterpriseCoordinatorAccountServiceModelsAccountExternalIdModel>"
        },
        {
            "name":     "expireExistingAccountRelationships",
            "baseName": "expireExistingAccountRelationships",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return EnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel.attributeTypeMap;
    }
}
