// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ContactInfoType } from './contactInfoType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ExtEnrollmentPhoneNumberModel {
    'phoneNumberId'?: number;
    'countryCode'?: string;
    'areaCode'?: string;
    'number'?: string;
    'primaryPhoneNumber'?: boolean;
    'phoneType'?: ContactInfoType;
    'extension'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtEnrollmentPhoneNumberModel>> = [
        {
            "name":     "phoneNumberId",
            "baseName": "phoneNumberId",
            "type":     "number"
        },
        {
            "name":     "countryCode",
            "baseName": "countryCode",
            "type":     "string"
        },
        {
            "name":     "areaCode",
            "baseName": "areaCode",
            "type":     "string"
        },
        {
            "name":     "number",
            "baseName": "number",
            "type":     "string"
        },
        {
            "name":     "primaryPhoneNumber",
            "baseName": "primaryPhoneNumber",
            "type":     "boolean"
        },
        {
            "name":     "phoneType",
            "baseName": "phoneType",
            "type":     "ContactInfoType"
        },
        {
            "name":     "extension",
            "baseName": "extension",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ExtEnrollmentPhoneNumberModel.attributeTypeMap;
    }
}
