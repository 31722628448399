// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AccountPayorRelationModel } from './accountPayorRelationModel.js';
import type { AccountRelationModel } from './accountRelationModel.js';
import type { AccountStatus } from './accountStatus.js';
import type { AccountType } from './accountType.js';
import type { DocumentRelationshipModel } from './documentRelationshipModel.js';
import type { VoicemailOptInModel } from './voicemailOptInModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Request model for saving Accounts
*/
export class AccountSaveModel {
    'accountType'?: AccountType;
    'accountStatus'?: AccountStatus;
    /**
    * @description Party ID
    */
    'partyId'?: number;
    /**
    * @description Secure Party ID (Takes precedence over Party ID)
    */
    'securePartyId'?: string | null;
    /**
    * @description Assigned User identifier
    */
    'assignedUser'?: number | null;
    /**
    * @description Account Payor Relationships
    */
    'payors'?: Array<AccountPayorRelationModel> | null;
    /**
    * @description List of AccountRelationModel objects containing information about Prescribers
    */
    'prescribers'?: Array<AccountRelationModel> | null;
    /**
    * @description List of AccountRelationModel objects containing information about Sites
    */
    'sites'?: Array<AccountRelationModel> | null;
    /**
    * @description List of AccountRelationModel objects containing information about Pharmacies
    */
    'pharmacies'?: Array<AccountRelationModel> | null;
    /**
    * @description List of DocumentRelationshipModel objects containing information about Documents
    */
    'documents'?: Array<DocumentRelationshipModel> | null;
    /**
    * @description List of AccountNote objects
    */
    'notes'?: Array<number> | null;
    /**
    * @description List of AccountTasks objects
    */
    'tasks'?: Array<number> | null;
    /**
    * @description List of VoicemailOptInModel objects containing information about Voicemail Opt-ins
    */
    'voicemailOptInStatus'?: Array<VoicemailOptInModel> | null;
    /**
    * @description Assignment Of Benefit flag
    */
    'assignmentOfBenefitFlag'?: boolean | null;
    /**
    * @description Not Eligible for Mail-In Claims flag
    */
    'isIneligibleForMailInClaims'?: boolean | null;
    /**
    * @description Eligible to AutoReEnroll during the ReEnrollment period for it\\\'s Program
    */
    'allowAutoReenroll'?: boolean | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof AccountSaveModel>> = [
        {
            "name":     "accountType",
            "baseName": "accountType",
            "type":     "AccountType"
        },
        {
            "name":     "accountStatus",
            "baseName": "accountStatus",
            "type":     "AccountStatus"
        },
        {
            "name":     "partyId",
            "baseName": "partyId",
            "type":     "number"
        },
        {
            "name":     "securePartyId",
            "baseName": "securePartyId",
            "type":     "string"
        },
        {
            "name":     "assignedUser",
            "baseName": "assignedUser",
            "type":     "number"
        },
        {
            "name":     "payors",
            "baseName": "payors",
            "type":     "Array<AccountPayorRelationModel>"
        },
        {
            "name":     "prescribers",
            "baseName": "prescribers",
            "type":     "Array<AccountRelationModel>"
        },
        {
            "name":     "sites",
            "baseName": "sites",
            "type":     "Array<AccountRelationModel>"
        },
        {
            "name":     "pharmacies",
            "baseName": "pharmacies",
            "type":     "Array<AccountRelationModel>"
        },
        {
            "name":     "documents",
            "baseName": "documents",
            "type":     "Array<DocumentRelationshipModel>"
        },
        {
            "name":     "notes",
            "baseName": "notes",
            "type":     "Array<number>"
        },
        {
            "name":     "tasks",
            "baseName": "tasks",
            "type":     "Array<number>"
        },
        {
            "name":     "voicemailOptInStatus",
            "baseName": "voicemailOptInStatus",
            "type":     "Array<VoicemailOptInModel>"
        },
        {
            "name":     "assignmentOfBenefitFlag",
            "baseName": "assignmentOfBenefitFlag",
            "type":     "boolean"
        },
        {
            "name":     "isIneligibleForMailInClaims",
            "baseName": "isIneligibleForMailInClaims",
            "type":     "boolean"
        },
        {
            "name":     "allowAutoReenroll",
            "baseName": "allowAutoReenroll",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return AccountSaveModel.attributeTypeMap;
    }
}
