import { Action, Mutation } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { Resolve } from 'vue-di';
import { ClaimV1Api, EdgeEnrollmentApi } from '@trialcard/apigateway.client';
import {
    EnrollmentIsValidMemberNumberRequestModel,

    ClaimAccumulatorModels,
} from '@trialcard/apigateway.models';
import { AxiosResponse } from 'axios';
import { Settings } from '~/services/settings';

@InjectModule({ stateFactory: true }, module)
export default class ActivationModule extends InjectVuexModule {
    @Resolve
    public claimApi!: ClaimV1Api;

    @Resolve
    public edgeEnrollmentApi!: EdgeEnrollmentApi;

    @Resolve
    public settings!: Settings;

    public memberNumber = '';

    @Mutation
    updateMemberNumber(newMemberNumber: string) {
        this.memberNumber = newMemberNumber;
    }

    @Action({})
    checkAccumulator(memberNumber: string): Promise<AxiosResponse<ClaimAccumulatorModels>> {
        return this.claimApi.getByMemberNumber(memberNumber, undefined);
    }

    @Action({})
    checkMemberNumber(memberNumber: string): Promise<AxiosResponse<any>> {
        const requestModel: EnrollmentIsValidMemberNumberRequestModel = {
            memberNumber,
            haveACard: true,
            partyId: 1, // TODO: ask about this
            checkForEnrollment: true,
        };
        return this.edgeEnrollmentApi.enrollmentCheckMemberNumber(requestModel, this.settings.programId);
    }
}
