// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { MedicalBiAppeal } from './medicalBiAppeal.js';
import type { MedicalBiCopay } from './medicalBiCopay.js';
import type { MedicalBiCoverageException } from './medicalBiCoverageException.js';
import type { MedicalBiCptPriorAuth } from './medicalBiCptPriorAuth.js';
import type { MedicalBiDenial } from './medicalBiDenial.js';
import type { MedicalBiDiagnosisCodeResult } from './medicalBiDiagnosisCodeResult.js';
import type { MedicalBiFacility } from './medicalBiFacility.js';
import type { MedicalBiFamily } from './medicalBiFamily.js';
import type { MedicalBiHomeHealth } from './medicalBiHomeHealth.js';
import type { MedicalBiIndividual } from './medicalBiIndividual.js';
import type { MedicalBiLifetime } from './medicalBiLifetime.js';
import type { MedicalBiMedical } from './medicalBiMedical.js';
import type { MedicalBiObtainPreDetermination } from './medicalBiObtainPreDetermination.js';
import type { MedicalBiObtainPriorAuthorization } from './medicalBiObtainPriorAuthorization.js';
import type { MedicalBiPatientResult } from './medicalBiPatientResult.js';
import type { MedicalBiPayorResult } from './medicalBiPayorResult.js';
import type { MedicalBiPbm } from './medicalBiPbm.js';
import type { MedicalBiPcp } from './medicalBiPcp.js';
import type { MedicalBiPeerToPeer } from './medicalBiPeerToPeer.js';
import type { MedicalBiPlan } from './medicalBiPlan.js';
import type { MedicalBiPolicyHolder } from './medicalBiPolicyHolder.js';
import type { MedicalBiPractice } from './medicalBiPractice.js';
import type { MedicalBiPreDetermination } from './medicalBiPreDetermination.js';
import type { MedicalBiPreferredSpecialty } from './medicalBiPreferredSpecialty.js';
import type { MedicalBiPrescriberResult } from './medicalBiPrescriberResult.js';
import type { MedicalBiPrescription } from './medicalBiPrescription.js';
import type { MedicalBiPriorAuthorization } from './medicalBiPriorAuthorization.js';
import type { MedicalBiQuantityLimit } from './medicalBiQuantityLimit.js';
import type { MedicalBiReferral } from './medicalBiReferral.js';
import type { MedicalBiSiteOfCareResult } from './medicalBiSiteOfCareResult.js';
import type { MedicalBiSpecialtyPharmacy } from './medicalBiSpecialtyPharmacy.js';
import type { MedicalBiSpendDown } from './medicalBiSpendDown.js';
import type { MedicalBiStepTherapy } from './medicalBiStepTherapy.js';
import type { MedicalBiTransportationService } from './medicalBiTransportationService.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiResultBase {
    'additionalNotes'?: string;
    'annualCap'?: string;
    'annualCapExist'?: boolean;
    'annualCapMetAmount'?: number;
    'appeal'?: MedicalBiAppeal | null;
    'benefitNetworkStatus'?: string;
    'benefitsNotes'?: string;
    'billingNotes'?: string;
    'buyAndBillAvailable'?: string;
    'claimAddress'?: string;
    'cobraPlan'?: MedicalBiPlan | null;
    'coInsurance'?: string;
    'coordinationOfBenefits'?: string;
    'copay'?: MedicalBiCopay | null;
    'coverageException'?: MedicalBiCoverageException | null;
    'coverageRestrictions'?: string;
    'cptCodes'?: Array<MedicalBiDiagnosisCodeResult>;
    'cptPriorAuth'?: MedicalBiCptPriorAuth | null;
    'createdTimestamp'?: Moment;
    'deductibleApplies'?: boolean;
    'deductibleIncludedInOop'?: boolean;
    'denial'?: MedicalBiDenial | null;
    'facility'?: MedicalBiFacility | null;
    'family'?: MedicalBiFamily | null;
    'followsMedicareGuidelines'?: string;
    'healthExchangePlan'?: MedicalBiPlan | null;
    'homeHealth'?: MedicalBiHomeHealth | null;
    'individual'?: MedicalBiIndividual | null;
    'jCodes'?: Array<MedicalBiDiagnosisCodeResult>;
    'lifetime'?: MedicalBiLifetime | null;
    'medical'?: MedicalBiMedical | null;
    'multipleCopay'?: boolean;
    'obtainPreDetermination'?: MedicalBiObtainPreDetermination | null;
    'obtainPriorAuthorization'?: MedicalBiObtainPriorAuthorization | null;
    'patient'?: MedicalBiPatientResult | null;
    'payor'?: MedicalBiPayorResult | null;
    'pbm'?: MedicalBiPbm | null;
    'pcp'?: MedicalBiPcp | null;
    'peerToPeer'?: MedicalBiPeerToPeer | null;
    'policyHolder'?: MedicalBiPolicyHolder | null;
    'practice'?: MedicalBiPractice | null;
    'preDetermination'?: MedicalBiPreDetermination | null;
    'preferredSpecialty'?: MedicalBiPreferredSpecialty | null;
    'prescriber'?: MedicalBiPrescriberResult | null;
    'prescription'?: MedicalBiPrescription | null;
    'priorAuthorization'?: MedicalBiPriorAuthorization | null;
    'quantityLimit'?: MedicalBiQuantityLimit | null;
    'reasonForNonCoverage'?: string;
    'referral'?: MedicalBiReferral | null;
    'reviewRequired'?: boolean;
    'specialtyPharmacy'?: MedicalBiSpecialtyPharmacy | null;
    'spendDown'?: MedicalBiSpendDown | null;
    'stepTherapy'?: MedicalBiStepTherapy | null;
    'transportationService'?: MedicalBiTransportationService | null;
    'taskStatus'?: string;
    'updatedTimestamp'?: Moment;
    'sitesOfCare'?: Array<MedicalBiSiteOfCareResult>;
    'transactionCorrelationId': number;
    'transactionDateTime': Moment;
    'transactionId': string;
    'transactionMessage': string;
    'transactionStatus': boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiResultBase>> = [
        {
            "name":     "additionalNotes",
            "baseName": "additionalNotes",
            "type":     "string"
        },
        {
            "name":     "annualCap",
            "baseName": "annualCap",
            "type":     "string"
        },
        {
            "name":     "annualCapExist",
            "baseName": "annualCapExist",
            "type":     "boolean"
        },
        {
            "name":     "annualCapMetAmount",
            "baseName": "annualCapMetAmount",
            "type":     "number"
        },
        {
            "name":     "appeal",
            "baseName": "appeal",
            "type":     "MedicalBiAppeal"
        },
        {
            "name":     "benefitNetworkStatus",
            "baseName": "benefitNetworkStatus",
            "type":     "string"
        },
        {
            "name":     "benefitsNotes",
            "baseName": "benefitsNotes",
            "type":     "string"
        },
        {
            "name":     "billingNotes",
            "baseName": "billingNotes",
            "type":     "string"
        },
        {
            "name":     "buyAndBillAvailable",
            "baseName": "buyAndBillAvailable",
            "type":     "string"
        },
        {
            "name":     "claimAddress",
            "baseName": "claimAddress",
            "type":     "string"
        },
        {
            "name":     "cobraPlan",
            "baseName": "cobraPlan",
            "type":     "MedicalBiPlan"
        },
        {
            "name":     "coInsurance",
            "baseName": "coInsurance",
            "type":     "string"
        },
        {
            "name":     "coordinationOfBenefits",
            "baseName": "coordinationOfBenefits",
            "type":     "string"
        },
        {
            "name":     "copay",
            "baseName": "copay",
            "type":     "MedicalBiCopay"
        },
        {
            "name":     "coverageException",
            "baseName": "coverageException",
            "type":     "MedicalBiCoverageException"
        },
        {
            "name":     "coverageRestrictions",
            "baseName": "coverageRestrictions",
            "type":     "string"
        },
        {
            "name":     "cptCodes",
            "baseName": "cptCodes",
            "type":     "Array<MedicalBiDiagnosisCodeResult>"
        },
        {
            "name":     "cptPriorAuth",
            "baseName": "cptPriorAuth",
            "type":     "MedicalBiCptPriorAuth"
        },
        {
            "name":     "createdTimestamp",
            "baseName": "createdTimestamp",
            "type":     "Date"
        },
        {
            "name":     "deductibleApplies",
            "baseName": "deductibleApplies",
            "type":     "boolean"
        },
        {
            "name":     "deductibleIncludedInOop",
            "baseName": "deductibleIncludedInOop",
            "type":     "boolean"
        },
        {
            "name":     "denial",
            "baseName": "denial",
            "type":     "MedicalBiDenial"
        },
        {
            "name":     "facility",
            "baseName": "facility",
            "type":     "MedicalBiFacility"
        },
        {
            "name":     "family",
            "baseName": "family",
            "type":     "MedicalBiFamily"
        },
        {
            "name":     "followsMedicareGuidelines",
            "baseName": "followsMedicareGuidelines",
            "type":     "string"
        },
        {
            "name":     "healthExchangePlan",
            "baseName": "healthExchangePlan",
            "type":     "MedicalBiPlan"
        },
        {
            "name":     "homeHealth",
            "baseName": "homeHealth",
            "type":     "MedicalBiHomeHealth"
        },
        {
            "name":     "individual",
            "baseName": "individual",
            "type":     "MedicalBiIndividual"
        },
        {
            "name":     "jCodes",
            "baseName": "jCodes",
            "type":     "Array<MedicalBiDiagnosisCodeResult>"
        },
        {
            "name":     "lifetime",
            "baseName": "lifetime",
            "type":     "MedicalBiLifetime"
        },
        {
            "name":     "medical",
            "baseName": "medical",
            "type":     "MedicalBiMedical"
        },
        {
            "name":     "multipleCopay",
            "baseName": "multipleCopay",
            "type":     "boolean"
        },
        {
            "name":     "obtainPreDetermination",
            "baseName": "obtainPreDetermination",
            "type":     "MedicalBiObtainPreDetermination"
        },
        {
            "name":     "obtainPriorAuthorization",
            "baseName": "obtainPriorAuthorization",
            "type":     "MedicalBiObtainPriorAuthorization"
        },
        {
            "name":     "patient",
            "baseName": "patient",
            "type":     "MedicalBiPatientResult"
        },
        {
            "name":     "payor",
            "baseName": "payor",
            "type":     "MedicalBiPayorResult"
        },
        {
            "name":     "pbm",
            "baseName": "pbm",
            "type":     "MedicalBiPbm"
        },
        {
            "name":     "pcp",
            "baseName": "pcp",
            "type":     "MedicalBiPcp"
        },
        {
            "name":     "peerToPeer",
            "baseName": "peerToPeer",
            "type":     "MedicalBiPeerToPeer"
        },
        {
            "name":     "policyHolder",
            "baseName": "policyHolder",
            "type":     "MedicalBiPolicyHolder"
        },
        {
            "name":     "practice",
            "baseName": "practice",
            "type":     "MedicalBiPractice"
        },
        {
            "name":     "preDetermination",
            "baseName": "preDetermination",
            "type":     "MedicalBiPreDetermination"
        },
        {
            "name":     "preferredSpecialty",
            "baseName": "preferredSpecialty",
            "type":     "MedicalBiPreferredSpecialty"
        },
        {
            "name":     "prescriber",
            "baseName": "prescriber",
            "type":     "MedicalBiPrescriberResult"
        },
        {
            "name":     "prescription",
            "baseName": "prescription",
            "type":     "MedicalBiPrescription"
        },
        {
            "name":     "priorAuthorization",
            "baseName": "priorAuthorization",
            "type":     "MedicalBiPriorAuthorization"
        },
        {
            "name":     "quantityLimit",
            "baseName": "quantityLimit",
            "type":     "MedicalBiQuantityLimit"
        },
        {
            "name":     "reasonForNonCoverage",
            "baseName": "reasonForNonCoverage",
            "type":     "string"
        },
        {
            "name":     "referral",
            "baseName": "referral",
            "type":     "MedicalBiReferral"
        },
        {
            "name":     "reviewRequired",
            "baseName": "reviewRequired",
            "type":     "boolean"
        },
        {
            "name":     "specialtyPharmacy",
            "baseName": "specialtyPharmacy",
            "type":     "MedicalBiSpecialtyPharmacy"
        },
        {
            "name":     "spendDown",
            "baseName": "spendDown",
            "type":     "MedicalBiSpendDown"
        },
        {
            "name":     "stepTherapy",
            "baseName": "stepTherapy",
            "type":     "MedicalBiStepTherapy"
        },
        {
            "name":     "transportationService",
            "baseName": "transportationService",
            "type":     "MedicalBiTransportationService"
        },
        {
            "name":     "taskStatus",
            "baseName": "taskStatus",
            "type":     "string"
        },
        {
            "name":     "updatedTimestamp",
            "baseName": "updatedTimestamp",
            "type":     "Date"
        },
        {
            "name":     "sitesOfCare",
            "baseName": "sitesOfCare",
            "type":     "Array<MedicalBiSiteOfCareResult>"
        },
        {
            "name":     "transactionCorrelationId",
            "baseName": "transactionCorrelationId",
            "type":     "number"
        },
        {
            "name":     "transactionDateTime",
            "baseName": "transactionDateTime",
            "type":     "Date"
        },
        {
            "name":     "transactionId",
            "baseName": "transactionId",
            "type":     "string"
        },
        {
            "name":     "transactionMessage",
            "baseName": "transactionMessage",
            "type":     "string"
        },
        {
            "name":     "transactionStatus",
            "baseName": "transactionStatus",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiResultBase.attributeTypeMap;
    }
}
