// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PrescriberLicenseModel } from './prescriberLicenseModel.js';
import type { PrescriberSharedAddressModel } from './prescriberSharedAddressModel.js';
import type { PrescriberSharedEmailAddressModel } from './prescriberSharedEmailAddressModel.js';
import type { PrescriberSharedExternalIdModel } from './prescriberSharedExternalIdModel.js';
import type { PrescriberSharedPhoneModel } from './prescriberSharedPhoneModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PrescriberModel {
    'prescriberId'?: number;
    'firstName'?: string;
    'middleName'?: string;
    'lastName'?: string;
    'suffix'?: string;
    'nickname'?: string;
    'npi'?: string;
    'taxId'?: string;
    'prescriberLicenses'?: Array<PrescriberLicenseModel>;
    'externalIds'?: Array<PrescriberSharedExternalIdModel>;
    'addresses'?: Array<PrescriberSharedAddressModel>;
    'phoneNumbers'?: Array<PrescriberSharedPhoneModel>;
    'emailAddresses'?: Array<PrescriberSharedEmailAddressModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PrescriberModel>> = [
        {
            "name":     "prescriberId",
            "baseName": "prescriberId",
            "type":     "number"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "middleName",
            "baseName": "middleName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "suffix",
            "baseName": "suffix",
            "type":     "string"
        },
        {
            "name":     "nickname",
            "baseName": "nickname",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "taxId",
            "baseName": "taxId",
            "type":     "string"
        },
        {
            "name":     "prescriberLicenses",
            "baseName": "prescriberLicenses",
            "type":     "Array<PrescriberLicenseModel>"
        },
        {
            "name":     "externalIds",
            "baseName": "externalIds",
            "type":     "Array<PrescriberSharedExternalIdModel>"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<PrescriberSharedAddressModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<PrescriberSharedPhoneModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<PrescriberSharedEmailAddressModel>"
        },
    ];

    static getAttributeTypeMap() {
        return PrescriberModel.attributeTypeMap;
    }
}
