// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EnrollmentTcsWrapperModelsAccumulatorModel } from './enrollmentTcsWrapperModelsAccumulatorModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ExtEnrollmentResultModel {
    'bin'?: string;
    'cardActivationDate'?: Moment;
    'cardEffectiveDate'?: Moment;
    'cardEndDate'?: Moment;
    'sponsorNumber'?: string;
    'memberNumber'?: string;
    'groupNumber'?: string;
    'totalBenefitAmount'?: number;
    'remainingBenefitAmount'?: number;
    'totalPaidAmount'?: number;
    'accumulators': Array<EnrollmentTcsWrapperModelsAccumulatorModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtEnrollmentResultModel>> = [
        {
            "name":     "bin",
            "baseName": "bin",
            "type":     "string"
        },
        {
            "name":     "cardActivationDate",
            "baseName": "cardActivationDate",
            "type":     "Date"
        },
        {
            "name":     "cardEffectiveDate",
            "baseName": "cardEffectiveDate",
            "type":     "Date"
        },
        {
            "name":     "cardEndDate",
            "baseName": "cardEndDate",
            "type":     "Date"
        },
        {
            "name":     "sponsorNumber",
            "baseName": "sponsorNumber",
            "type":     "string"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "groupNumber",
            "baseName": "groupNumber",
            "type":     "string"
        },
        {
            "name":     "totalBenefitAmount",
            "baseName": "totalBenefitAmount",
            "type":     "number"
        },
        {
            "name":     "remainingBenefitAmount",
            "baseName": "remainingBenefitAmount",
            "type":     "number"
        },
        {
            "name":     "totalPaidAmount",
            "baseName": "totalPaidAmount",
            "type":     "number"
        },
        {
            "name":     "accumulators",
            "baseName": "accumulators",
            "type":     "Array<EnrollmentTcsWrapperModelsAccumulatorModel>"
        },
    ];

    static getAttributeTypeMap() {
        return ExtEnrollmentResultModel.attributeTypeMap;
    }
}
