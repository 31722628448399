// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EnterpriseCoordinatorSiteAccountUpsearchRequestModel } from './enterpriseCoordinatorSiteAccountUpsearchRequestModel.js';
import type { SiteAccountSiteRegistrationISiteRegistrationUserContactInfoRequestModel } from './siteAccountSiteRegistrationISiteRegistrationUserContactInfoRequestModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ISiteRegistrationRequestModel {
    'userName': string;
    'firstName': string;
    'lastName': string;
    'phone': SiteAccountSiteRegistrationISiteRegistrationUserContactInfoRequestModel | null;
    'fax': SiteAccountSiteRegistrationISiteRegistrationUserContactInfoRequestModel | null;
    'email': SiteAccountSiteRegistrationISiteRegistrationUserContactInfoRequestModel | null;
    'siteAccountUpsearch': EnterpriseCoordinatorSiteAccountUpsearchRequestModel | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ISiteRegistrationRequestModel>> = [
        {
            "name":     "userName",
            "baseName": "userName",
            "type":     "string"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "phone",
            "baseName": "phone",
            "type":     "SiteAccountSiteRegistrationISiteRegistrationUserContactInfoRequestModel"
        },
        {
            "name":     "fax",
            "baseName": "fax",
            "type":     "SiteAccountSiteRegistrationISiteRegistrationUserContactInfoRequestModel"
        },
        {
            "name":     "email",
            "baseName": "email",
            "type":     "SiteAccountSiteRegistrationISiteRegistrationUserContactInfoRequestModel"
        },
        {
            "name":     "siteAccountUpsearch",
            "baseName": "siteAccountUpsearch",
            "type":     "EnterpriseCoordinatorSiteAccountUpsearchRequestModel"
        },
    ];

    static getAttributeTypeMap() {
        return ISiteRegistrationRequestModel.attributeTypeMap;
    }
}
