// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { MedicalEligibilityAnnual } from './medicalEligibilityAnnual.js';
import type { MedicalEligibilityCopay } from './medicalEligibilityCopay.js';
import type { MedicalEligibilityFamily } from './medicalEligibilityFamily.js';
import type { MedicalEligibilityIndividual } from './medicalEligibilityIndividual.js';
import type { MedicalEligibilityLifetime } from './medicalEligibilityLifetime.js';
import type { MedicalEligibilityPatientResult } from './medicalEligibilityPatientResult.js';
import type { MedicalEligibilityPayorResult } from './medicalEligibilityPayorResult.js';
import type { MedicalEligibilityPbm } from './medicalEligibilityPbm.js';
import type { MedicalEligibilityPreferredSpecialty } from './medicalEligibilityPreferredSpecialty.js';
import type { MedicalEligibilityPrescriberResult } from './medicalEligibilityPrescriberResult.js';
import type { MedicalEligibilitySpecialtyPharmacy } from './medicalEligibilitySpecialtyPharmacy.js';
import type { MedicalEligibilitySpendDown } from './medicalEligibilitySpendDown.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalEligibilityResultBase {
    'annual'?: MedicalEligibilityAnnual | null;
    'copay'?: MedicalEligibilityCopay | null;
    'family'?: MedicalEligibilityFamily | null;
    'individual'?: MedicalEligibilityIndividual | null;
    'lifetime'?: MedicalEligibilityLifetime | null;
    'patient'?: MedicalEligibilityPatientResult | null;
    'payor'?: MedicalEligibilityPayorResult | null;
    'pbm'?: MedicalEligibilityPbm | null;
    'preferredSpecialty'?: MedicalEligibilityPreferredSpecialty | null;
    'prescriber'?: MedicalEligibilityPrescriberResult | null;
    'specialtyPharmacy'?: MedicalEligibilitySpecialtyPharmacy | null;
    'spendDown'?: MedicalEligibilitySpendDown | null;
    'taskStatus'?: string;
    'transactionCorrelationId': number;
    'transactionDateTime': Moment;
    'transactionId': string;
    'transactionMessage': string;
    'transactionStatus': boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalEligibilityResultBase>> = [
        {
            "name":     "annual",
            "baseName": "annual",
            "type":     "MedicalEligibilityAnnual"
        },
        {
            "name":     "copay",
            "baseName": "copay",
            "type":     "MedicalEligibilityCopay"
        },
        {
            "name":     "family",
            "baseName": "family",
            "type":     "MedicalEligibilityFamily"
        },
        {
            "name":     "individual",
            "baseName": "individual",
            "type":     "MedicalEligibilityIndividual"
        },
        {
            "name":     "lifetime",
            "baseName": "lifetime",
            "type":     "MedicalEligibilityLifetime"
        },
        {
            "name":     "patient",
            "baseName": "patient",
            "type":     "MedicalEligibilityPatientResult"
        },
        {
            "name":     "payor",
            "baseName": "payor",
            "type":     "MedicalEligibilityPayorResult"
        },
        {
            "name":     "pbm",
            "baseName": "pbm",
            "type":     "MedicalEligibilityPbm"
        },
        {
            "name":     "preferredSpecialty",
            "baseName": "preferredSpecialty",
            "type":     "MedicalEligibilityPreferredSpecialty"
        },
        {
            "name":     "prescriber",
            "baseName": "prescriber",
            "type":     "MedicalEligibilityPrescriberResult"
        },
        {
            "name":     "specialtyPharmacy",
            "baseName": "specialtyPharmacy",
            "type":     "MedicalEligibilitySpecialtyPharmacy"
        },
        {
            "name":     "spendDown",
            "baseName": "spendDown",
            "type":     "MedicalEligibilitySpendDown"
        },
        {
            "name":     "taskStatus",
            "baseName": "taskStatus",
            "type":     "string"
        },
        {
            "name":     "transactionCorrelationId",
            "baseName": "transactionCorrelationId",
            "type":     "number"
        },
        {
            "name":     "transactionDateTime",
            "baseName": "transactionDateTime",
            "type":     "Date"
        },
        {
            "name":     "transactionId",
            "baseName": "transactionId",
            "type":     "string"
        },
        {
            "name":     "transactionMessage",
            "baseName": "transactionMessage",
            "type":     "string"
        },
        {
            "name":     "transactionStatus",
            "baseName": "transactionStatus",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalEligibilityResultBase.attributeTypeMap;
    }
}
