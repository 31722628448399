// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Patient benefits information model
*/
export class ClaimStagingAnsi837PrimaryInsuranceModel {
    /**
    * @description Policy member number
    */
    'memberNumber'?: string | null;
    /**
    * @description Policy group number, alphanumeric identifier for the health, auto, or other insurance plan coverage
    */
    'groupNumber'?: string | null;
    /**
    * @description Policyholder name
    */
    'policyholderName'?: string | null;
    /**
    * @description Policyholder last name
    */
    'policyholderLastName'?: string | null;
    /**
    * @description Policyholder first name
    */
    'policyholderFirstName'?: string | null;
    /**
    * @description Policyholder middle name
    */
    'policyholderMiddleName'?: string | null;
    /**
    * @description Payer name
    */
    'payerName'?: string | null;
    /**
    * @description Policy number
    */
    'policyNumber'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimStagingAnsi837PrimaryInsuranceModel>> = [
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "groupNumber",
            "baseName": "groupNumber",
            "type":     "string"
        },
        {
            "name":     "policyholderName",
            "baseName": "policyholderName",
            "type":     "string"
        },
        {
            "name":     "policyholderLastName",
            "baseName": "policyholderLastName",
            "type":     "string"
        },
        {
            "name":     "policyholderFirstName",
            "baseName": "policyholderFirstName",
            "type":     "string"
        },
        {
            "name":     "policyholderMiddleName",
            "baseName": "policyholderMiddleName",
            "type":     "string"
        },
        {
            "name":     "payerName",
            "baseName": "payerName",
            "type":     "string"
        },
        {
            "name":     "policyNumber",
            "baseName": "policyNumber",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimStagingAnsi837PrimaryInsuranceModel.attributeTypeMap;
    }
}
