// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class GeneratePdfFromHtmlRequest {
    'filename': string;
    'html': string;
    'css': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof GeneratePdfFromHtmlRequest>> = [
        {
            "name":     "filename",
            "baseName": "filename",
            "type":     "string"
        },
        {
            "name":     "html",
            "baseName": "html",
            "type":     "string"
        },
        {
            "name":     "css",
            "baseName": "css",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return GeneratePdfFromHtmlRequest.attributeTypeMap;
    }
}
