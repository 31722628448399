// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CaseWorkflowModel } from './caseWorkflowModel.js';
import type { PatientModel } from './patientModel.js';
import type { PayorModel } from './payorModel.js';
import type { PharmacyModel } from './pharmacyModel.js';
import type { PrescriberModel } from './prescriberModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SearchWorkflowsResponseModel {
    'workflow': CaseWorkflowModel | null;
    'patient': PatientModel | null;
    'payors': Array<PayorModel>;
    'prescribers': Array<PrescriberModel>;
    'pharmacies': Array<PharmacyModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SearchWorkflowsResponseModel>> = [
        {
            "name":     "workflow",
            "baseName": "workflow",
            "type":     "CaseWorkflowModel"
        },
        {
            "name":     "patient",
            "baseName": "patient",
            "type":     "PatientModel"
        },
        {
            "name":     "payors",
            "baseName": "payors",
            "type":     "Array<PayorModel>"
        },
        {
            "name":     "prescribers",
            "baseName": "prescribers",
            "type":     "Array<PrescriberModel>"
        },
        {
            "name":     "pharmacies",
            "baseName": "pharmacies",
            "type":     "Array<PharmacyModel>"
        },
    ];

    static getAttributeTypeMap() {
        return SearchWorkflowsResponseModel.attributeTypeMap;
    }
}
