// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Gender } from './gender.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class UpdatePatientDemographicsRequest {
    'accountId': number;
    'firstName'?: string;
    'middleName'?: string;
    'lastName'?: string;
    'suffix'?: string;
    'nickname'?: string;
    'dateOfBirth'?: Moment;
    'gender'?: Gender;
    'primaryLanguageId'?: number;
    'isUsResident'?: boolean;
    'householdSize'?: number;
    'raceCode'?: string;
    'ethnicityCode'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof UpdatePatientDemographicsRequest>> = [
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "middleName",
            "baseName": "middleName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "suffix",
            "baseName": "suffix",
            "type":     "string"
        },
        {
            "name":     "nickname",
            "baseName": "nickname",
            "type":     "string"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "Date"
        },
        {
            "name":     "gender",
            "baseName": "gender",
            "type":     "Gender"
        },
        {
            "name":     "primaryLanguageId",
            "baseName": "primaryLanguageId",
            "type":     "number"
        },
        {
            "name":     "isUsResident",
            "baseName": "isUsResident",
            "type":     "boolean"
        },
        {
            "name":     "householdSize",
            "baseName": "householdSize",
            "type":     "number"
        },
        {
            "name":     "raceCode",
            "baseName": "raceCode",
            "type":     "string"
        },
        {
            "name":     "ethnicityCode",
            "baseName": "ethnicityCode",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return UpdatePatientDemographicsRequest.attributeTypeMap;
    }
}
