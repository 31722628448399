// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Gender } from './gender.js';
import type { PatientSharedAddressUpsearchModel } from './patientSharedAddressUpsearchModel.js';
import type { PatientSharedEmailAddressUpsearchModel } from './patientSharedEmailAddressUpsearchModel.js';
import type { PatientSharedPhoneUpsearchModel } from './patientSharedPhoneUpsearchModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PatientUpsearchModel {
    'patientId'?: string;
    'firstName'?: string;
    'middleName'?: string;
    'lastName'?: string;
    'suffix'?: string;
    'nickname'?: string;
    'dateOfBirth'?: Moment;
    'gender'?: Gender;
    'primaryLanguageId'?: number;
    'isUsResident'?: boolean;
    'householdSize'?: number;
    'masterPatientId'?: string;
    'isDuplicate'?: boolean;
    'isTest'?: boolean;
    'isPlaceholder'?: boolean;
    'socialSecurityNumber'?: string;
    'addresses'?: Array<PatientSharedAddressUpsearchModel>;
    'emailAddresses'?: Array<PatientSharedEmailAddressUpsearchModel>;
    'phoneNumbers'?: Array<PatientSharedPhoneUpsearchModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PatientUpsearchModel>> = [
        {
            "name":     "patientId",
            "baseName": "patientId",
            "type":     "string"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "middleName",
            "baseName": "middleName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "suffix",
            "baseName": "suffix",
            "type":     "string"
        },
        {
            "name":     "nickname",
            "baseName": "nickname",
            "type":     "string"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "Date"
        },
        {
            "name":     "gender",
            "baseName": "gender",
            "type":     "Gender"
        },
        {
            "name":     "primaryLanguageId",
            "baseName": "primaryLanguageId",
            "type":     "number"
        },
        {
            "name":     "isUsResident",
            "baseName": "isUsResident",
            "type":     "boolean"
        },
        {
            "name":     "householdSize",
            "baseName": "householdSize",
            "type":     "number"
        },
        {
            "name":     "masterPatientId",
            "baseName": "masterPatientId",
            "type":     "string"
        },
        {
            "name":     "isDuplicate",
            "baseName": "isDuplicate",
            "type":     "boolean"
        },
        {
            "name":     "isTest",
            "baseName": "isTest",
            "type":     "boolean"
        },
        {
            "name":     "isPlaceholder",
            "baseName": "isPlaceholder",
            "type":     "boolean"
        },
        {
            "name":     "socialSecurityNumber",
            "baseName": "socialSecurityNumber",
            "type":     "string"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<PatientSharedAddressUpsearchModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<PatientSharedEmailAddressUpsearchModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<PatientSharedPhoneUpsearchModel>"
        },
    ];

    static getAttributeTypeMap() {
        return PatientUpsearchModel.attributeTypeMap;
    }
}
