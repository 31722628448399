// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ContactInfoPurposeType } from './contactInfoPurposeType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class TrialcardEscoordV1ModelsSharedWebAddressUpsearchModel {
    'webAddressId'?: number;
    'webAddressType'?: ContactInfoPurposeType;
    'url'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof TrialcardEscoordV1ModelsSharedWebAddressUpsearchModel>> = [
        {
            "name":     "webAddressId",
            "baseName": "webAddressId",
            "type":     "number"
        },
        {
            "name":     "webAddressType",
            "baseName": "webAddressType",
            "type":     "ContactInfoPurposeType"
        },
        {
            "name":     "url",
            "baseName": "url",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return TrialcardEscoordV1ModelsSharedWebAddressUpsearchModel.attributeTypeMap;
    }
}
