// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as enums from '@trialcard/enums';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';


export enum BasicEnrollmentRace {
    Asian = 'Asian',
    Black = 'Black',
    Multiple = 'Multiple',
    NativeAmerican = 'NativeAmerican',
    NativePacificIsles = 'NativePacificIsles',
    Unknown = 'Unknown',
    White = 'White'
}
