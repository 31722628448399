// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PriorAuthorizationRequestSearchBase } from './priorAuthorizationRequestSearchBase.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PriorAuthorizationRequestSearchResponse {
    'requests': Array<PriorAuthorizationRequestSearchBase>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PriorAuthorizationRequestSearchResponse>> = [
        {
            "name":     "requests",
            "baseName": "requests",
            "type":     "Array<PriorAuthorizationRequestSearchBase>"
        },
    ];

    static getAttributeTypeMap() {
        return PriorAuthorizationRequestSearchResponse.attributeTypeMap;
    }
}
