// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { SimpleIntakePayorModel } from './simpleIntakePayorModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SimpleIntakePayorsModel {
    'medical'?: Array<SimpleIntakePayorModel>;
    'pharmacy'?: Array<SimpleIntakePayorModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SimpleIntakePayorsModel>> = [
        {
            "name":     "medical",
            "baseName": "medical",
            "type":     "Array<SimpleIntakePayorModel>"
        },
        {
            "name":     "pharmacy",
            "baseName": "pharmacy",
            "type":     "Array<SimpleIntakePayorModel>"
        },
    ];

    static getAttributeTypeMap() {
        return SimpleIntakePayorsModel.attributeTypeMap;
    }
}
