import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _111cc916 = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _2b1c0b14 = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages_rejection" */))
const _64a24fa7 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _4a37a656 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _4a45bdd7 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _4a61ecd9 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _4a70045a = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _7f1de197 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _98a90582 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _111cc916,
    name: "error"
  }, {
    path: "/rejection",
    component: _2b1c0b14,
    name: "rejection"
  }, {
    path: "/unlock",
    component: _64a24fa7,
    name: "unlock"
  }, {
    path: "/errors/400",
    component: _4a37a656,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _4a45bdd7,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _4a61ecd9,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _4a70045a,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _7f1de197,
    name: "errors-500"
  }, {
    path: "/",
    component: _98a90582,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
