// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class TrialcardPayorV4ModelsSharedGlobalIdModel {
    'globalIdTypeCode'?: string | null;
    'globalId'?: string | null;
    'id'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof TrialcardPayorV4ModelsSharedGlobalIdModel>> = [
        {
            "name":     "globalIdTypeCode",
            "baseName": "globalIdTypeCode",
            "type":     "string"
        },
        {
            "name":     "globalId",
            "baseName": "globalId",
            "type":     "string"
        },
        {
            "name":     "id",
            "baseName": "id",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return TrialcardPayorV4ModelsSharedGlobalIdModel.attributeTypeMap;
    }
}
