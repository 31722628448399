// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PriorAuthorizationDiagnosis } from './priorAuthorizationDiagnosis.js';
import type { PriorAuthorizationPatient } from './priorAuthorizationPatient.js';
import type { PriorAuthorizationPayor } from './priorAuthorizationPayor.js';
import type { PriorAuthorizationPrescriber } from './priorAuthorizationPrescriber.js';
import type { PriorAuthorizationPrescription } from './priorAuthorizationPrescription.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PriorAuthorizationRequestBase {
    'diagnosis'?: PriorAuthorizationDiagnosis | null;
    'patient': PriorAuthorizationPatient | null;
    'payor': PriorAuthorizationPayor | null;
    'prescriber': PriorAuthorizationPrescriber | null;
    'prescription': PriorAuthorizationPrescription | null;
    'project'?: string | null;
    'clientId1'?: string | null;
    'clientId2'?: string | null;
    'clientId3'?: string | null;
    'clientId4'?: string | null;
    'clientId5'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PriorAuthorizationRequestBase>> = [
        {
            "name":     "diagnosis",
            "baseName": "diagnosis",
            "type":     "PriorAuthorizationDiagnosis"
        },
        {
            "name":     "patient",
            "baseName": "patient",
            "type":     "PriorAuthorizationPatient"
        },
        {
            "name":     "payor",
            "baseName": "payor",
            "type":     "PriorAuthorizationPayor"
        },
        {
            "name":     "prescriber",
            "baseName": "prescriber",
            "type":     "PriorAuthorizationPrescriber"
        },
        {
            "name":     "prescription",
            "baseName": "prescription",
            "type":     "PriorAuthorizationPrescription"
        },
        {
            "name":     "project",
            "baseName": "project",
            "type":     "string"
        },
        {
            "name":     "clientId1",
            "baseName": "clientId1",
            "type":     "string"
        },
        {
            "name":     "clientId2",
            "baseName": "clientId2",
            "type":     "string"
        },
        {
            "name":     "clientId3",
            "baseName": "clientId3",
            "type":     "string"
        },
        {
            "name":     "clientId4",
            "baseName": "clientId4",
            "type":     "string"
        },
        {
            "name":     "clientId5",
            "baseName": "clientId5",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PriorAuthorizationRequestBase.attributeTypeMap;
    }
}
