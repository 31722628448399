// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { HhsAddress } from './hhsAddress.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class HhsPharmacyPayor {
    'bin'?: string;
    'pcn'?: string;
    'name': string;
    'phoneNumber'?: string;
    'faxNumber'?: string;
    'address'?: HhsAddress | null;
    'groupNumber'?: string;
    'policyNumber': string;
    'planEffectiveDate'?: Moment;
    'planTerminationDate'?: Moment;
    'policyName'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof HhsPharmacyPayor>> = [
        {
            "name":     "bin",
            "baseName": "bin",
            "type":     "string"
        },
        {
            "name":     "pcn",
            "baseName": "pcn",
            "type":     "string"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "phoneNumber",
            "baseName": "phoneNumber",
            "type":     "string"
        },
        {
            "name":     "faxNumber",
            "baseName": "faxNumber",
            "type":     "string"
        },
        {
            "name":     "address",
            "baseName": "address",
            "type":     "HhsAddress"
        },
        {
            "name":     "groupNumber",
            "baseName": "groupNumber",
            "type":     "string"
        },
        {
            "name":     "policyNumber",
            "baseName": "policyNumber",
            "type":     "string"
        },
        {
            "name":     "planEffectiveDate",
            "baseName": "planEffectiveDate",
            "type":     "Date"
        },
        {
            "name":     "planTerminationDate",
            "baseName": "planTerminationDate",
            "type":     "Date"
        },
        {
            "name":     "policyName",
            "baseName": "policyName",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return HhsPharmacyPayor.attributeTypeMap;
    }
}
