// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RoleType } from './roleType.js';
import type { SiteSharedAddressModel } from './siteSharedAddressModel.js';
import type { SiteSharedEmailAddressModel } from './siteSharedEmailAddressModel.js';
import type { SiteSharedPhoneModel } from './siteSharedPhoneModel.js';
import type { SiteSharedWebAddressModel } from './siteSharedWebAddressModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SiteSharedContactModel {
    'contactId'?: number;
    'isActive'?: boolean;
    'addresses'?: Array<SiteSharedAddressModel>;
    'phoneNumbers'?: Array<SiteSharedPhoneModel>;
    'emailAddresses'?: Array<SiteSharedEmailAddressModel>;
    'webAddresses'?: Array<SiteSharedWebAddressModel>;
    'contactType'?: RoleType;
    'firstName'?: string;
    'lastName'?: string;
    'occupationDescription'?: string;
    'isMainContact'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SiteSharedContactModel>> = [
        {
            "name":     "contactId",
            "baseName": "contactId",
            "type":     "number"
        },
        {
            "name":     "isActive",
            "baseName": "isActive",
            "type":     "boolean"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<SiteSharedAddressModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<SiteSharedPhoneModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<SiteSharedEmailAddressModel>"
        },
        {
            "name":     "webAddresses",
            "baseName": "webAddresses",
            "type":     "Array<SiteSharedWebAddressModel>"
        },
        {
            "name":     "contactType",
            "baseName": "contactType",
            "type":     "RoleType"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "occupationDescription",
            "baseName": "occupationDescription",
            "type":     "string"
        },
        {
            "name":     "isMainContact",
            "baseName": "isMainContact",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return SiteSharedContactModel.attributeTypeMap;
    }
}
