// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { SharedCaseCaseRelationshipModel } from './sharedCaseCaseRelationshipModel.js';
import type { SharedCaseCaseRoleTypeRelationshipModel } from './sharedCaseCaseRoleTypeRelationshipModel.js';
import type { SharedCaseDocumentRelationshipModel } from './sharedCaseDocumentRelationshipModel.js';
import type { SharedCasePrescriptionRelationshipModel } from './sharedCasePrescriptionRelationshipModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SharedCaseCaseRelationshipsModel {
    'prescribers'?: Array<SharedCaseCaseRelationshipModel> | null;
    'prescriptions'?: Array<SharedCasePrescriptionRelationshipModel> | null;
    'sites'?: Array<SharedCaseCaseRelationshipModel> | null;
    'payors'?: Array<SharedCaseCaseRoleTypeRelationshipModel> | null;
    'pharmacies'?: Array<SharedCaseCaseRelationshipModel> | null;
    'documents'?: Array<SharedCaseDocumentRelationshipModel> | null;
    'notes'?: Array<number> | null;
    'tasks'?: Array<number> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SharedCaseCaseRelationshipsModel>> = [
        {
            "name":     "prescribers",
            "baseName": "prescribers",
            "type":     "Array<SharedCaseCaseRelationshipModel>"
        },
        {
            "name":     "prescriptions",
            "baseName": "prescriptions",
            "type":     "Array<SharedCasePrescriptionRelationshipModel>"
        },
        {
            "name":     "sites",
            "baseName": "sites",
            "type":     "Array<SharedCaseCaseRelationshipModel>"
        },
        {
            "name":     "payors",
            "baseName": "payors",
            "type":     "Array<SharedCaseCaseRoleTypeRelationshipModel>"
        },
        {
            "name":     "pharmacies",
            "baseName": "pharmacies",
            "type":     "Array<SharedCaseCaseRelationshipModel>"
        },
        {
            "name":     "documents",
            "baseName": "documents",
            "type":     "Array<SharedCaseDocumentRelationshipModel>"
        },
        {
            "name":     "notes",
            "baseName": "notes",
            "type":     "Array<number>"
        },
        {
            "name":     "tasks",
            "baseName": "tasks",
            "type":     "Array<number>"
        },
    ];

    static getAttributeTypeMap() {
        return SharedCaseCaseRelationshipsModel.attributeTypeMap;
    }
}
