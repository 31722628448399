// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CoverMyMedsInboundEnrollmentHubEnrollmentModel } from './coverMyMedsInboundEnrollmentHubEnrollmentModel.js';
import type { CoverMyMedsInboundEnrollmentWorkflowModel } from './coverMyMedsInboundEnrollmentWorkflowModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Inbound enrollment (intake) request model
*/
export class CoverMyMedsInboundEnrollmentRequestModel {
    /**
    * @description Cover My Meds Case Key. (required, 8 chars max)<br />  Saved as account external ID type: \\\'CMMCaseKey\\\'<br />  and case external ID type: \\\'ThirdPartyCaseId\\\'
    */
    'caseKey'?: string | null;
    'hubEnrollment'?: CoverMyMedsInboundEnrollmentHubEnrollmentModel | null;
    'workflow'?: CoverMyMedsInboundEnrollmentWorkflowModel | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof CoverMyMedsInboundEnrollmentRequestModel>> = [
        {
            "name":     "caseKey",
            "baseName": "caseKey",
            "type":     "string"
        },
        {
            "name":     "hubEnrollment",
            "baseName": "hubEnrollment",
            "type":     "CoverMyMedsInboundEnrollmentHubEnrollmentModel"
        },
        {
            "name":     "workflow",
            "baseName": "workflow",
            "type":     "CoverMyMedsInboundEnrollmentWorkflowModel"
        },
    ];

    static getAttributeTypeMap() {
        return CoverMyMedsInboundEnrollmentRequestModel.attributeTypeMap;
    }
}
