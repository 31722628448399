// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BestEnrollmentV2Model } from './bestEnrollmentV2Model.js';
import {  } from "./models.js";
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export type EnrollmentV1BasicEnrollmentArray = Array<BestEnrollmentV2Model>;
export const EnrollmentV1BasicEnrollmentArray: ArrayConstructor = Array;
