// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Model for Account Payor Relations
*/
export class AccountOrderRelationModel {
    /**
    * @description Coverage Order of Payor to Account
    */
    'coverageOrder'?: number;
    /**
    * @description Relation Entity identifier
    */
    'relatedId'?: number;
    /**
    * @description Flag indicating whether this instance is Primary (true) or not (false)
    */
    'isPrimary'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof AccountOrderRelationModel>> = [
        {
            "name":     "coverageOrder",
            "baseName": "coverageOrder",
            "type":     "number"
        },
        {
            "name":     "relatedId",
            "baseName": "relatedId",
            "type":     "number"
        },
        {
            "name":     "isPrimary",
            "baseName": "isPrimary",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return AccountOrderRelationModel.attributeTypeMap;
    }
}
