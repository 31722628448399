// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as enums from '@trialcard/enums';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';


export enum TCSPaymentType {
    None = 0,
    CheckToSite = 1,
    CheckToPatient = 2,
    DebitCard = 3,
    CheckPayableToSite = 4,
    ElectricFundsTransfer = 5
}
