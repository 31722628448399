// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class GatewayClaimGroupViewSearchModel {
    'claimIds': Array<number>;
    'claimGroupIds': Array<number>;
    'memberNumbers': Array<string>;
    /**
    * @description Promotion ID to Retrieve
    */
    'promotionId'?: number | null;
    /**
    * @description Add adjustment claims
    */
    'includeAdjustments'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof GatewayClaimGroupViewSearchModel>> = [
        {
            "name":     "claimIds",
            "baseName": "claimIds",
            "type":     "Array<number>"
        },
        {
            "name":     "claimGroupIds",
            "baseName": "claimGroupIds",
            "type":     "Array<number>"
        },
        {
            "name":     "memberNumbers",
            "baseName": "memberNumbers",
            "type":     "Array<string>"
        },
        {
            "name":     "promotionId",
            "baseName": "promotionId",
            "type":     "number"
        },
        {
            "name":     "includeAdjustments",
            "baseName": "includeAdjustments",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return GatewayClaimGroupViewSearchModel.attributeTypeMap;
    }
}
