// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BasicEnrollmentAddress } from './basicEnrollmentAddress.js';
import type { BasicEnrollmentContact } from './basicEnrollmentContact.js';
import type { BasicEnrollmentEthnicity } from './basicEnrollmentEthnicity.js';
import type { BasicEnrollmentGender } from './basicEnrollmentGender.js';
import type { BasicEnrollmentRace } from './basicEnrollmentRace.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class BasicEnrollmentPatient {
    'firstName': string;
    'middleName'?: string;
    'lastName': string;
    'suffix'?: string;
    'dateOfBirth': Moment;
    'gender'?: BasicEnrollmentGender;
    'race'?: BasicEnrollmentRace;
    'ethnicity'?: BasicEnrollmentEthnicity;
    'ssn'?: string;
    'emailAddress'?: string;
    'homePhone'?: string;
    'homeVoicemail'?: boolean;
    'cellPhone'?: string;
    'cellVoicemail'?: boolean;
    'workPhone'?: string;
    'workPhoneExtension'?: string;
    'workVoicemail'?: boolean;
    'emailOptIn'?: boolean;
    'emailPreferred'?: boolean;
    'mailOptIn'?: boolean;
    'mailPreferred'?: boolean;
    'phoneOptIn'?: boolean;
    'phonePreferred'?: boolean;
    'textOptIn'?: boolean;
    'textPreferred'?: boolean;
    'contact'?: BasicEnrollmentContact | null;
    'address': BasicEnrollmentAddress | null;
    'thirdPartyId'?: string;
    'pharmacyMemberNumber'?: string;
    'medicalMemberNumber'?: string;
    'universalHubId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof BasicEnrollmentPatient>> = [
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "middleName",
            "baseName": "middleName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "suffix",
            "baseName": "suffix",
            "type":     "string"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "Date"
        },
        {
            "name":     "gender",
            "baseName": "gender",
            "type":     "BasicEnrollmentGender"
        },
        {
            "name":     "race",
            "baseName": "race",
            "type":     "BasicEnrollmentRace"
        },
        {
            "name":     "ethnicity",
            "baseName": "ethnicity",
            "type":     "BasicEnrollmentEthnicity"
        },
        {
            "name":     "ssn",
            "baseName": "ssn",
            "type":     "string"
        },
        {
            "name":     "emailAddress",
            "baseName": "emailAddress",
            "type":     "string"
        },
        {
            "name":     "homePhone",
            "baseName": "homePhone",
            "type":     "string"
        },
        {
            "name":     "homeVoicemail",
            "baseName": "homeVoicemail",
            "type":     "boolean"
        },
        {
            "name":     "cellPhone",
            "baseName": "cellPhone",
            "type":     "string"
        },
        {
            "name":     "cellVoicemail",
            "baseName": "cellVoicemail",
            "type":     "boolean"
        },
        {
            "name":     "workPhone",
            "baseName": "workPhone",
            "type":     "string"
        },
        {
            "name":     "workPhoneExtension",
            "baseName": "workPhoneExtension",
            "type":     "string"
        },
        {
            "name":     "workVoicemail",
            "baseName": "workVoicemail",
            "type":     "boolean"
        },
        {
            "name":     "emailOptIn",
            "baseName": "emailOptIn",
            "type":     "boolean"
        },
        {
            "name":     "emailPreferred",
            "baseName": "emailPreferred",
            "type":     "boolean"
        },
        {
            "name":     "mailOptIn",
            "baseName": "mailOptIn",
            "type":     "boolean"
        },
        {
            "name":     "mailPreferred",
            "baseName": "mailPreferred",
            "type":     "boolean"
        },
        {
            "name":     "phoneOptIn",
            "baseName": "phoneOptIn",
            "type":     "boolean"
        },
        {
            "name":     "phonePreferred",
            "baseName": "phonePreferred",
            "type":     "boolean"
        },
        {
            "name":     "textOptIn",
            "baseName": "textOptIn",
            "type":     "boolean"
        },
        {
            "name":     "textPreferred",
            "baseName": "textPreferred",
            "type":     "boolean"
        },
        {
            "name":     "contact",
            "baseName": "contact",
            "type":     "BasicEnrollmentContact"
        },
        {
            "name":     "address",
            "baseName": "address",
            "type":     "BasicEnrollmentAddress"
        },
        {
            "name":     "thirdPartyId",
            "baseName": "thirdPartyId",
            "type":     "string"
        },
        {
            "name":     "pharmacyMemberNumber",
            "baseName": "pharmacyMemberNumber",
            "type":     "string"
        },
        {
            "name":     "medicalMemberNumber",
            "baseName": "medicalMemberNumber",
            "type":     "string"
        },
        {
            "name":     "universalHubId",
            "baseName": "universalHubId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return BasicEnrollmentPatient.attributeTypeMap;
    }
}
