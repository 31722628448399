// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Create Model for a Task
*/
export class NoteTaskCreateModel {
    'accountId'?: number | null;
    'caseId'?: number | null;
    'assignedUserId'?: number | null;
    'dueDate'?: Moment;
    'isCompleted'?: boolean;
    'completionDate'?: Moment | null;
    'documentId'?: number | null;
    'resolution'?: string | null;
    'typeId'?: number;
    'subject'?: string | null;
    'body'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof NoteTaskCreateModel>> = [
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "caseId",
            "baseName": "caseId",
            "type":     "number"
        },
        {
            "name":     "assignedUserId",
            "baseName": "assignedUserId",
            "type":     "number"
        },
        {
            "name":     "dueDate",
            "baseName": "dueDate",
            "type":     "Date"
        },
        {
            "name":     "isCompleted",
            "baseName": "isCompleted",
            "type":     "boolean"
        },
        {
            "name":     "completionDate",
            "baseName": "completionDate",
            "type":     "Date"
        },
        {
            "name":     "documentId",
            "baseName": "documentId",
            "type":     "number"
        },
        {
            "name":     "resolution",
            "baseName": "resolution",
            "type":     "string"
        },
        {
            "name":     "typeId",
            "baseName": "typeId",
            "type":     "number"
        },
        {
            "name":     "subject",
            "baseName": "subject",
            "type":     "string"
        },
        {
            "name":     "body",
            "baseName": "body",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return NoteTaskCreateModel.attributeTypeMap;
    }
}
