// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EnterpriseCoordinatorPayorServiceModelsPayorBenefitModel } from './enterpriseCoordinatorPayorServiceModelsPayorBenefitModel.js';
import type { EnterpriseCoordinatorSharedAddressUpsearchModel } from './enterpriseCoordinatorSharedAddressUpsearchModel.js';
import type { EnterpriseCoordinatorSharedContactUpsearchModel } from './enterpriseCoordinatorSharedContactUpsearchModel.js';
import type { EnterpriseCoordinatorSharedEmailAddressUpsearchModel } from './enterpriseCoordinatorSharedEmailAddressUpsearchModel.js';
import type { EnterpriseCoordinatorSharedPhoneUpsearchModel } from './enterpriseCoordinatorSharedPhoneUpsearchModel.js';
import type { EnterpriseCoordinatorSharedWebAddressUpsearchModel } from './enterpriseCoordinatorSharedWebAddressUpsearchModel.js';
import type { PayorType } from './payorType.js';
import type { RoleType } from './roleType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel {
    'payorId'?: number;
    'payorName'?: string;
    'payorType'?: PayorType;
    'payorBin'?: string;
    'payorPcn'?: string;
    'payorGroup'?: string;
    'isPlaceholder'?: boolean;
    'accountRoleType'?: RoleType;
    'accountRelationshipCoverageOrder'?: number;
    'accountRelationshipIsPrimary'?: boolean;
    'webAddresses'?: Array<EnterpriseCoordinatorSharedWebAddressUpsearchModel>;
    'addresses'?: Array<EnterpriseCoordinatorSharedAddressUpsearchModel>;
    'phoneNumbers'?: Array<EnterpriseCoordinatorSharedPhoneUpsearchModel>;
    'emailAddresses'?: Array<EnterpriseCoordinatorSharedEmailAddressUpsearchModel>;
    'contacts'?: Array<EnterpriseCoordinatorSharedContactUpsearchModel>;
    'payorBenefit'?: EnterpriseCoordinatorPayorServiceModelsPayorBenefitModel | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel>> = [
        {
            "name":     "payorId",
            "baseName": "payorId",
            "type":     "number"
        },
        {
            "name":     "payorName",
            "baseName": "payorName",
            "type":     "string"
        },
        {
            "name":     "payorType",
            "baseName": "payorType",
            "type":     "PayorType"
        },
        {
            "name":     "payorBin",
            "baseName": "payorBin",
            "type":     "string"
        },
        {
            "name":     "payorPcn",
            "baseName": "payorPcn",
            "type":     "string"
        },
        {
            "name":     "payorGroup",
            "baseName": "payorGroup",
            "type":     "string"
        },
        {
            "name":     "isPlaceholder",
            "baseName": "isPlaceholder",
            "type":     "boolean"
        },
        {
            "name":     "accountRoleType",
            "baseName": "accountRoleType",
            "type":     "RoleType"
        },
        {
            "name":     "accountRelationshipCoverageOrder",
            "baseName": "accountRelationshipCoverageOrder",
            "type":     "number"
        },
        {
            "name":     "accountRelationshipIsPrimary",
            "baseName": "accountRelationshipIsPrimary",
            "type":     "boolean"
        },
        {
            "name":     "webAddresses",
            "baseName": "webAddresses",
            "type":     "Array<EnterpriseCoordinatorSharedWebAddressUpsearchModel>"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<EnterpriseCoordinatorSharedAddressUpsearchModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<EnterpriseCoordinatorSharedPhoneUpsearchModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<EnterpriseCoordinatorSharedEmailAddressUpsearchModel>"
        },
        {
            "name":     "contacts",
            "baseName": "contacts",
            "type":     "Array<EnterpriseCoordinatorSharedContactUpsearchModel>"
        },
        {
            "name":     "payorBenefit",
            "baseName": "payorBenefit",
            "type":     "EnterpriseCoordinatorPayorServiceModelsPayorBenefitModel"
        },
    ];

    static getAttributeTypeMap() {
        return EnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel.attributeTypeMap;
    }
}
