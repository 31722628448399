// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PriorAuthorizationFollowUpInfo {
    'priorAuthNumber'?: string | null;
    'testName'?: string | null;
    'priorAuthExpirationDate'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PriorAuthorizationFollowUpInfo>> = [
        {
            "name":     "priorAuthNumber",
            "baseName": "priorAuthNumber",
            "type":     "string"
        },
        {
            "name":     "testName",
            "baseName": "testName",
            "type":     "string"
        },
        {
            "name":     "priorAuthExpirationDate",
            "baseName": "priorAuthExpirationDate",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PriorAuthorizationFollowUpInfo.attributeTypeMap;
    }
}
