// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PharmacySharedAddressUpsearchModel } from './pharmacySharedAddressUpsearchModel.js';
import type { PharmacySharedContactUpsearchModel } from './pharmacySharedContactUpsearchModel.js';
import type { PharmacySharedEmailAddressUpsearchModel } from './pharmacySharedEmailAddressUpsearchModel.js';
import type { PharmacySharedPhoneUpsearchModel } from './pharmacySharedPhoneUpsearchModel.js';
import type { PharmacySharedWebAddressUpsearchModel } from './pharmacySharedWebAddressUpsearchModel.js';
import type { PharmacySpecialization } from './pharmacySpecialization.js';
import type { PharmacyType } from './pharmacyType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyUpsearchModel {
    'pharmacyId'?: number;
    'addresses'?: Array<PharmacySharedAddressUpsearchModel>;
    'contacts'?: Array<PharmacySharedContactUpsearchModel>;
    'emailAddresses'?: Array<PharmacySharedEmailAddressUpsearchModel>;
    'phoneNumbers'?: Array<PharmacySharedPhoneUpsearchModel>;
    'webAddresses'?: Array<PharmacySharedWebAddressUpsearchModel>;
    'pharmacyName'?: string;
    'nabp'?: string;
    'npi'?: string;
    'pharmacyType'?: PharmacyType;
    'pharmacySpecialization'?: PharmacySpecialization;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyUpsearchModel>> = [
        {
            "name":     "pharmacyId",
            "baseName": "pharmacyId",
            "type":     "number"
        },
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<PharmacySharedAddressUpsearchModel>"
        },
        {
            "name":     "contacts",
            "baseName": "contacts",
            "type":     "Array<PharmacySharedContactUpsearchModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<PharmacySharedEmailAddressUpsearchModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<PharmacySharedPhoneUpsearchModel>"
        },
        {
            "name":     "webAddresses",
            "baseName": "webAddresses",
            "type":     "Array<PharmacySharedWebAddressUpsearchModel>"
        },
        {
            "name":     "pharmacyName",
            "baseName": "pharmacyName",
            "type":     "string"
        },
        {
            "name":     "nabp",
            "baseName": "nabp",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "pharmacyType",
            "baseName": "pharmacyType",
            "type":     "PharmacyType"
        },
        {
            "name":     "pharmacySpecialization",
            "baseName": "pharmacySpecialization",
            "type":     "PharmacySpecialization"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyUpsearchModel.attributeTypeMap;
    }
}
