// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as enums from '@trialcard/enums';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';


export enum BasicEnrollmentPaymentType {
    debitcard = 'debitcard',
    check = 'check',
    virtualdebitcard = 'virtualdebitcard',
    thirdpartyadjudication = 'thirdpartyadjudication',
    sitecheckforpatient = 'sitecheckforpatient',
    sitecheckforsite = 'sitecheckforsite',
    efttosite = 'efttosite',
    copaycard = 'copaycard'
}
