// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  https://openapi-generator.tech  Do not edit the class manually.
*/
export class PharmacyPointOfContactModel {
    'id'?: number;
    'firstName'?: string;
    'lastName'?: string;
    'phoneNumber'?: string;
    'phoneNumberExtension'?: string;
    'faxNumber'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyPointOfContactModel>> = [
        {
            "name":     "id",
            "baseName": "id",
            "type":     "number"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "phoneNumber",
            "baseName": "phoneNumber",
            "type":     "string"
        },
        {
            "name":     "phoneNumberExtension",
            "baseName": "phoneNumberExtension",
            "type":     "string"
        },
        {
            "name":     "faxNumber",
            "baseName": "faxNumber",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyPointOfContactModel.attributeTypeMap;
    }
}
