// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { SurveyQuestionCategoryModel } from './surveyQuestionCategoryModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SurveySessionValueReadModel {
    'questionText'?: string;
    'answerCategoryId'?: number;
    'answerCategoryCode'?: string;
    'answerCategoryDescription'?: string;
    'questionCategories'?: Array<SurveyQuestionCategoryModel>;
    'surveySessionId'?: number;
    'questionId'?: number;
    'answerId'?: number;
    'answerValue'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SurveySessionValueReadModel>> = [
        {
            "name":     "questionText",
            "baseName": "questionText",
            "type":     "string"
        },
        {
            "name":     "answerCategoryId",
            "baseName": "answerCategoryId",
            "type":     "number"
        },
        {
            "name":     "answerCategoryCode",
            "baseName": "answerCategoryCode",
            "type":     "string"
        },
        {
            "name":     "answerCategoryDescription",
            "baseName": "answerCategoryDescription",
            "type":     "string"
        },
        {
            "name":     "questionCategories",
            "baseName": "questionCategories",
            "type":     "Array<SurveyQuestionCategoryModel>"
        },
        {
            "name":     "surveySessionId",
            "baseName": "surveySessionId",
            "type":     "number"
        },
        {
            "name":     "questionId",
            "baseName": "questionId",
            "type":     "number"
        },
        {
            "name":     "answerId",
            "baseName": "answerId",
            "type":     "number"
        },
        {
            "name":     "answerValue",
            "baseName": "answerValue",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return SurveySessionValueReadModel.attributeTypeMap;
    }
}
