// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ClsInboundPrescription {
    'preferedPharmacy'?: string;
    'cartridgePenNeedleGauge'?: string;
    'forVial'?: string;
    'syringeNeedleGauge'?: string;
    'ancillarySuppliesDaysSupply'?: number;
    'rxDispenseType'?: string;
    'startDate'?: string;
    'rxSignatureFlag'?: string;
    'daysSupply'?: number;
    'product'?: string;
    'rxDosingDirections'?: string;
    'requestedQuantity'?: number;
    'refills'?: number;
    'rxSignatureDate'?: string;
    'primarydiagnosisName'?: string;
    'primaryicdCode'?: string;
    'secondarydiagnosisName'?: string;
    'secondaryicdCode'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClsInboundPrescription>> = [
        {
            "name":     "preferedPharmacy",
            "baseName": "preferedPharmacy",
            "type":     "string"
        },
        {
            "name":     "cartridgePenNeedleGauge",
            "baseName": "cartridgePenNeedleGauge",
            "type":     "string"
        },
        {
            "name":     "forVial",
            "baseName": "forVial",
            "type":     "string"
        },
        {
            "name":     "syringeNeedleGauge",
            "baseName": "syringeNeedleGauge",
            "type":     "string"
        },
        {
            "name":     "ancillarySuppliesDaysSupply",
            "baseName": "ancillarySuppliesDaysSupply",
            "type":     "number"
        },
        {
            "name":     "rxDispenseType",
            "baseName": "rxDispenseType",
            "type":     "string"
        },
        {
            "name":     "startDate",
            "baseName": "startDate",
            "type":     "string"
        },
        {
            "name":     "rxSignatureFlag",
            "baseName": "rxSignatureFlag",
            "type":     "string"
        },
        {
            "name":     "daysSupply",
            "baseName": "daysSupply",
            "type":     "number"
        },
        {
            "name":     "product",
            "baseName": "product",
            "type":     "string"
        },
        {
            "name":     "rxDosingDirections",
            "baseName": "rxDosingDirections",
            "type":     "string"
        },
        {
            "name":     "requestedQuantity",
            "baseName": "requestedQuantity",
            "type":     "number"
        },
        {
            "name":     "refills",
            "baseName": "refills",
            "type":     "number"
        },
        {
            "name":     "rxSignatureDate",
            "baseName": "rxSignatureDate",
            "type":     "string"
        },
        {
            "name":     "primarydiagnosisName",
            "baseName": "primarydiagnosisName",
            "type":     "string"
        },
        {
            "name":     "primaryicdCode",
            "baseName": "primaryicdCode",
            "type":     "string"
        },
        {
            "name":     "secondarydiagnosisName",
            "baseName": "secondarydiagnosisName",
            "type":     "string"
        },
        {
            "name":     "secondaryicdCode",
            "baseName": "secondaryicdCode",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ClsInboundPrescription.attributeTypeMap;
    }
}
