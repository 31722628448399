// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PayorSharedAddressUpsearchModel } from './payorSharedAddressUpsearchModel.js';
import type { PayorSharedEmailAddressUpsearchModel } from './payorSharedEmailAddressUpsearchModel.js';
import type { PayorSharedPhoneUpsearchModel } from './payorSharedPhoneUpsearchModel.js';
import type { PayorSharedWebAddressUpsearchModel } from './payorSharedWebAddressUpsearchModel.js';
import type { RoleType } from './roleType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PayorSharedContactUpsearchModel {
    'addresses'?: Array<PayorSharedAddressUpsearchModel>;
    'emailAddresses'?: Array<PayorSharedEmailAddressUpsearchModel>;
    'phoneNumbers'?: Array<PayorSharedPhoneUpsearchModel>;
    'webAddresses'?: Array<PayorSharedWebAddressUpsearchModel>;
    'contactId'?: number;
    'contactType'?: RoleType;
    'firstName'?: string;
    'lastName'?: string;
    'occupationDescription'?: string;
    'isMainContact'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PayorSharedContactUpsearchModel>> = [
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<PayorSharedAddressUpsearchModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<PayorSharedEmailAddressUpsearchModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<PayorSharedPhoneUpsearchModel>"
        },
        {
            "name":     "webAddresses",
            "baseName": "webAddresses",
            "type":     "Array<PayorSharedWebAddressUpsearchModel>"
        },
        {
            "name":     "contactId",
            "baseName": "contactId",
            "type":     "number"
        },
        {
            "name":     "contactType",
            "baseName": "contactType",
            "type":     "RoleType"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "occupationDescription",
            "baseName": "occupationDescription",
            "type":     "string"
        },
        {
            "name":     "isMainContact",
            "baseName": "isMainContact",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return PayorSharedContactUpsearchModel.attributeTypeMap;
    }
}
