// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PatientAccountGetCaseGetCasePayorResponseModelBenefitData } from './patientAccountGetCaseGetCasePayorResponseModelBenefitData.js';
import type { PayorModel } from './payorModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SiteAccountGetCaseGetCasePayorResponseModel {
    'payorData': PayorModel | null;
    'benefitData': PatientAccountGetCaseGetCasePayorResponseModelBenefitData;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SiteAccountGetCaseGetCasePayorResponseModel>> = [
        {
            "name":     "payorData",
            "baseName": "payorData",
            "type":     "PayorModel"
        },
        {
            "name":     "benefitData",
            "baseName": "benefitData",
            "type":     "PatientAccountGetCaseGetCasePayorResponseModelBenefitData"
        },
    ];

    static getAttributeTypeMap() {
        return SiteAccountGetCaseGetCasePayorResponseModel.attributeTypeMap;
    }
}
