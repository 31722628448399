// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EnrollmentIsValidMemberNumberRequestModel {
    'memberNumber'?: string;
    'haveACard'?: boolean;
    'partyId'?: number;
    'checkForEnrollment'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnrollmentIsValidMemberNumberRequestModel>> = [
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "haveACard",
            "baseName": "haveACard",
            "type":     "boolean"
        },
        {
            "name":     "partyId",
            "baseName": "partyId",
            "type":     "number"
        },
        {
            "name":     "checkForEnrollment",
            "baseName": "checkForEnrollment",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return EnrollmentIsValidMemberNumberRequestModel.attributeTypeMap;
    }
}
