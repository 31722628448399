// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Model for creating, updating or deleting Prescription External ID
*/
export class PrescriptionExternalIdModel {
    'externalId'?: string | null;
    'externalIdTypeId'?: number;
    'externalIdTypeCode'?: string | null;
    'externalIdTypeDescription'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PrescriptionExternalIdModel>> = [
        {
            "name":     "externalId",
            "baseName": "externalId",
            "type":     "string"
        },
        {
            "name":     "externalIdTypeId",
            "baseName": "externalIdTypeId",
            "type":     "number"
        },
        {
            "name":     "externalIdTypeCode",
            "baseName": "externalIdTypeCode",
            "type":     "string"
        },
        {
            "name":     "externalIdTypeDescription",
            "baseName": "externalIdTypeDescription",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PrescriptionExternalIdModel.attributeTypeMap;
    }
}
