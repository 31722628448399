// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ClsInboundPayor {
    'cardholderFirstName'?: string;
    'cardholderLastName'?: string;
    'medical'?: string;
    'planType'?: string;
    'planGroup'?: string;
    'effectiveDate'?: string;
    'expirationDate'?: string;
    'npi'?: string;
    'rxId'?: string;
    'rxBin'?: string;
    'rxPCN'?: string;
    'rxGroup'?: string;
    'cardholderRelationshipToPatient'?: string;
    'cardholderName'?: string;
    'cardholderDob'?: string;
    'cardholderEmployer'?: string;
    'personCode'?: string;
    'kindOfHealthInsurance'?: string;
    'terminationDate'?: string;
    'pbmMemberId'?: string;
    'insuranceSubType'?: string;
    'medicalInsurance'?: string;
    'medicalInsuranceId'?: string;
    'prescriptionInsurance'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClsInboundPayor>> = [
        {
            "name":     "cardholderFirstName",
            "baseName": "cardholderFirstName",
            "type":     "string"
        },
        {
            "name":     "cardholderLastName",
            "baseName": "cardholderLastName",
            "type":     "string"
        },
        {
            "name":     "medical",
            "baseName": "medical",
            "type":     "string"
        },
        {
            "name":     "planType",
            "baseName": "planType",
            "type":     "string"
        },
        {
            "name":     "planGroup",
            "baseName": "planGroup",
            "type":     "string"
        },
        {
            "name":     "effectiveDate",
            "baseName": "effectiveDate",
            "type":     "string"
        },
        {
            "name":     "expirationDate",
            "baseName": "expirationDate",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "rxId",
            "baseName": "rxId",
            "type":     "string"
        },
        {
            "name":     "rxBin",
            "baseName": "rxBin",
            "type":     "string"
        },
        {
            "name":     "rxPCN",
            "baseName": "rxPCN",
            "type":     "string"
        },
        {
            "name":     "rxGroup",
            "baseName": "rxGroup",
            "type":     "string"
        },
        {
            "name":     "cardholderRelationshipToPatient",
            "baseName": "cardholderRelationshipToPatient",
            "type":     "string"
        },
        {
            "name":     "cardholderName",
            "baseName": "cardholderName",
            "type":     "string"
        },
        {
            "name":     "cardholderDob",
            "baseName": "cardholderDob",
            "type":     "string"
        },
        {
            "name":     "cardholderEmployer",
            "baseName": "cardholderEmployer",
            "type":     "string"
        },
        {
            "name":     "personCode",
            "baseName": "personCode",
            "type":     "string"
        },
        {
            "name":     "kindOfHealthInsurance",
            "baseName": "kindOfHealthInsurance",
            "type":     "string"
        },
        {
            "name":     "terminationDate",
            "baseName": "terminationDate",
            "type":     "string"
        },
        {
            "name":     "pbmMemberId",
            "baseName": "pbmMemberId",
            "type":     "string"
        },
        {
            "name":     "insuranceSubType",
            "baseName": "insuranceSubType",
            "type":     "string"
        },
        {
            "name":     "medicalInsurance",
            "baseName": "medicalInsurance",
            "type":     "string"
        },
        {
            "name":     "medicalInsuranceId",
            "baseName": "medicalInsuranceId",
            "type":     "string"
        },
        {
            "name":     "prescriptionInsurance",
            "baseName": "prescriptionInsurance",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ClsInboundPayor.attributeTypeMap;
    }
}
