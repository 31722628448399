// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Eligibility Rule Result Model
*/
export class EnrollmentV2RulesEligibilityRuleResultModel {
    /**
    * @description Reject reason code (should match [TCEnterpriseDB].[dbo].[CONDITION_TYPE])
    */
    'rejectReasonCode'?: string | null;
    /**
    * @description Did the rule pass?
    */
    'passed'?: boolean;
    /**
    * @description Returns the description matching the Reject Reason Code
    */
    'rejectReasonDescription'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnrollmentV2RulesEligibilityRuleResultModel>> = [
        {
            "name":     "rejectReasonCode",
            "baseName": "rejectReasonCode",
            "type":     "string"
        },
        {
            "name":     "passed",
            "baseName": "passed",
            "type":     "boolean"
        },
        {
            "name":     "rejectReasonDescription",
            "baseName": "rejectReasonDescription",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return EnrollmentV2RulesEligibilityRuleResultModel.attributeTypeMap;
    }
}
