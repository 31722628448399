// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PharmacyBiDiagnosis } from './pharmacyBiDiagnosis.js';
import type { PharmacyBiMedical } from './pharmacyBiMedical.js';
import type { PharmacyBiPatient } from './pharmacyBiPatient.js';
import type { PharmacyBiPayor } from './pharmacyBiPayor.js';
import type { PharmacyBiPractice } from './pharmacyBiPractice.js';
import type { PharmacyBiPrescriber } from './pharmacyBiPrescriber.js';
import type { PharmacyBiPrescription } from './pharmacyBiPrescription.js';
import type { PharmacyBiSpecialtyPharmacy } from './pharmacyBiSpecialtyPharmacy.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyBiRequestBase {
    'diagnosis'?: PharmacyBiDiagnosis | null;
    'medical'?: PharmacyBiMedical | null;
    'patient'?: PharmacyBiPatient | null;
    'payor'?: PharmacyBiPayor | null;
    'practice'?: PharmacyBiPractice | null;
    'prescriber'?: PharmacyBiPrescriber | null;
    'prescription'?: PharmacyBiPrescription | null;
    'specialtyPharmacy'?: PharmacyBiSpecialtyPharmacy | null;
    'pharmacyNPI'?: string | null;
    'programId'?: string | null;
    'project'?: string;
    'providerProgramId'?: string | null;
    'clientId1'?: string;
    'clientId2'?: string;
    'clientId3'?: string;
    'clientId4'?: string;
    'clientId5'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyBiRequestBase>> = [
        {
            "name":     "diagnosis",
            "baseName": "diagnosis",
            "type":     "PharmacyBiDiagnosis"
        },
        {
            "name":     "medical",
            "baseName": "medical",
            "type":     "PharmacyBiMedical"
        },
        {
            "name":     "patient",
            "baseName": "patient",
            "type":     "PharmacyBiPatient"
        },
        {
            "name":     "payor",
            "baseName": "payor",
            "type":     "PharmacyBiPayor"
        },
        {
            "name":     "practice",
            "baseName": "practice",
            "type":     "PharmacyBiPractice"
        },
        {
            "name":     "prescriber",
            "baseName": "prescriber",
            "type":     "PharmacyBiPrescriber"
        },
        {
            "name":     "prescription",
            "baseName": "prescription",
            "type":     "PharmacyBiPrescription"
        },
        {
            "name":     "specialtyPharmacy",
            "baseName": "specialtyPharmacy",
            "type":     "PharmacyBiSpecialtyPharmacy"
        },
        {
            "name":     "pharmacyNPI",
            "baseName": "pharmacyNPI",
            "type":     "string"
        },
        {
            "name":     "programId",
            "baseName": "programId",
            "type":     "string"
        },
        {
            "name":     "project",
            "baseName": "project",
            "type":     "string"
        },
        {
            "name":     "providerProgramId",
            "baseName": "providerProgramId",
            "type":     "string"
        },
        {
            "name":     "clientId1",
            "baseName": "clientId1",
            "type":     "string"
        },
        {
            "name":     "clientId2",
            "baseName": "clientId2",
            "type":     "string"
        },
        {
            "name":     "clientId3",
            "baseName": "clientId3",
            "type":     "string"
        },
        {
            "name":     "clientId4",
            "baseName": "clientId4",
            "type":     "string"
        },
        {
            "name":     "clientId5",
            "baseName": "clientId5",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyBiRequestBase.attributeTypeMap;
    }
}
