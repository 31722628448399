// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EnrollmentEligibility } from './enrollmentEligibility.js';
import type { EnrollmentScenario } from './enrollmentScenario.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Models what the outcome of processing the Enrollment Intake should be
*/
export class EnrollmentV2ProcessingOutcomeModel {
    'eligibility'?: EnrollmentEligibility;
    'scenario'?: EnrollmentScenario;
    /**
    * @description Previously existing Enrollment (for update scenarios)
    */
    'existingEnrollmentId'?: number | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnrollmentV2ProcessingOutcomeModel>> = [
        {
            "name":     "eligibility",
            "baseName": "eligibility",
            "type":     "EnrollmentEligibility"
        },
        {
            "name":     "scenario",
            "baseName": "scenario",
            "type":     "EnrollmentScenario"
        },
        {
            "name":     "existingEnrollmentId",
            "baseName": "existingEnrollmentId",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return EnrollmentV2ProcessingOutcomeModel.attributeTypeMap;
    }
}
