// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RoleType } from './roleType.js';
import type { SharedCaseCaseOrderedRelationshipModel } from './sharedCaseCaseOrderedRelationshipModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SharedCaseCaseRoleTypeRelationshipModel {
    'roleType'?: RoleType;
    'caseRelations'?: Array<SharedCaseCaseOrderedRelationshipModel> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SharedCaseCaseRoleTypeRelationshipModel>> = [
        {
            "name":     "roleType",
            "baseName": "roleType",
            "type":     "RoleType"
        },
        {
            "name":     "caseRelations",
            "baseName": "caseRelations",
            "type":     "Array<SharedCaseCaseOrderedRelationshipModel>"
        },
    ];

    static getAttributeTypeMap() {
        return SharedCaseCaseRoleTypeRelationshipModel.attributeTypeMap;
    }
}
