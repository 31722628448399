// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { TCQpSignatureModelAttestation } from './tCQpSignatureModelAttestation.js';
import type { TCQpSignatureModelEnterpriseDBDocument } from './tCQpSignatureModelEnterpriseDBDocument.js';
import type { TCQpSignatureModelField } from './tCQpSignatureModelField.js';
import type { TCQpSignatureModelRecipient } from './tCQpSignatureModelRecipient.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class TCQpSignatureModelEmailSignatureTemplateRequest {
    'programId'?: number;
    'accountId'?: number | null;
    'caseId'?: number | null;
    'documentType'?: string | null;
    'brandId'?: string | null;
    'senderId'?: string | null;
    'templateId'?: string | null;
    'expireInDays'?: number | null;
    'emailSubject'?: string | null;
    'emailBody'?: string | null;
    'send'?: boolean;
    'embedded'?: boolean;
    'replyEmailAddressOverride'?: string | null;
    'externalPartyCode'?: string | null;
    'externalProgramCode'?: string | null;
    'recipients'?: Array<TCQpSignatureModelRecipient> | null;
    'fields'?: Array<TCQpSignatureModelField> | null;
    'attestations'?: Array<TCQpSignatureModelAttestation> | null;
    'enterpriseDBDocuments'?: Array<TCQpSignatureModelEnterpriseDBDocument> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof TCQpSignatureModelEmailSignatureTemplateRequest>> = [
        {
            "name":     "programId",
            "baseName": "programId",
            "type":     "number"
        },
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "caseId",
            "baseName": "caseId",
            "type":     "number"
        },
        {
            "name":     "documentType",
            "baseName": "documentType",
            "type":     "string"
        },
        {
            "name":     "brandId",
            "baseName": "brandId",
            "type":     "string"
        },
        {
            "name":     "senderId",
            "baseName": "senderId",
            "type":     "string"
        },
        {
            "name":     "templateId",
            "baseName": "templateId",
            "type":     "string"
        },
        {
            "name":     "expireInDays",
            "baseName": "expireInDays",
            "type":     "number"
        },
        {
            "name":     "emailSubject",
            "baseName": "emailSubject",
            "type":     "string"
        },
        {
            "name":     "emailBody",
            "baseName": "emailBody",
            "type":     "string"
        },
        {
            "name":     "send",
            "baseName": "send",
            "type":     "boolean"
        },
        {
            "name":     "embedded",
            "baseName": "embedded",
            "type":     "boolean"
        },
        {
            "name":     "replyEmailAddressOverride",
            "baseName": "replyEmailAddressOverride",
            "type":     "string"
        },
        {
            "name":     "externalPartyCode",
            "baseName": "externalPartyCode",
            "type":     "string"
        },
        {
            "name":     "externalProgramCode",
            "baseName": "externalProgramCode",
            "type":     "string"
        },
        {
            "name":     "recipients",
            "baseName": "recipients",
            "type":     "Array<TCQpSignatureModelRecipient>"
        },
        {
            "name":     "fields",
            "baseName": "fields",
            "type":     "Array<TCQpSignatureModelField>"
        },
        {
            "name":     "attestations",
            "baseName": "attestations",
            "type":     "Array<TCQpSignatureModelAttestation>"
        },
        {
            "name":     "enterpriseDBDocuments",
            "baseName": "enterpriseDBDocuments",
            "type":     "Array<TCQpSignatureModelEnterpriseDBDocument>"
        },
    ];

    static getAttributeTypeMap() {
        return TCQpSignatureModelEmailSignatureTemplateRequest.attributeTypeMap;
    }
}
