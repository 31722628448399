// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ClaimLookupModel } from './claimLookupModel.js';
import type { GatewayClaimViewModel } from './gatewayClaimViewModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class GatewayClaimGroupViewModel {
    'claims': Array<GatewayClaimViewModel>;
    /**
    * @description Member Number
    */
    'memberNumber'?: string | null;
    /**
    * @description Card Promotion ID
    */
    'promotionId'?: number | null;
    /**
    * @description Processor ID
    */
    'processorId'?: number | null;
    /**
    * @description Total benefit paid. Sum claim\\\'s benefit paid
    */
    'benefitPaid'?: number;
    /**
    * @description Remaining Cost
    */
    'remainingCost'?: number;
    /**
    * @description Claim Type ID. Medical or Pharmacy
    */
    'claimTypeId'?: number;
    /**
    * @description Patient Date of Birth
    */
    'patientDateOfBirth'?: Moment | null;
    /**
    * @description Date processed. Date when claim group was processed
    */
    'dateProcessed'?: Moment | null;
    /**
    * @description The user identifier of the original processor of the claim
    */
    'processedBy'?: number | null;
    /**
    * @description Processor Group Number
    */
    'processorGroupNumber'?: string | null;
    /**
    * @description Drug Name
    */
    'drugName'?: string | null;
    /**
    * @description Days Supply
    */
    'daysSupply'?: number | null;
    /**
    * @description Prescriber NPI
    */
    'prescriberNpi'?: string | null;
    /**
    * @description Rx Written Date
    */
    'rxWrittenDate'?: Moment | null;
    /**
    * @description Other Coverage Code
    */
    'otherCoverageCode'?: string | null;
    /**
    * @description Prior Authorization Number
    */
    'priorAuthorizationNumber'?: string | null;
    /**
    * @description Claim Group ID. Groups an EOB for Medical Claims. Pharmacy Claims always have a unique Claim Group ID.
    */
    'claimGroupId'?: number;
    /**
    * @description Payment Method Type ID
    */
    'paymentMethodTypeId'?: number | null;
    /**
    * @description Indicates if claim is migrated
    */
    'isMigrated'?: boolean;
    /**
    * @description Check Number
    */
    'checkNumber'?: string | null;
    /**
    * @description ABF EOB Number
    */
    'abfEobNumber'?: string | null;
    /**
    * @description ABF Check Number
    */
    'abfCheckNumber'?: string | null;
    /**
    * @description Parent Claim Group ID. Claim group the adjustment refers to.
    */
    'parentClaimGroupId'?: number | null;
    /**
    * @description Claim Group status ID
    */
    'claimGroupStatusId'?: number;
    'claimGroupStatus'?: ClaimLookupModel | null;
    'claimType'?: ClaimLookupModel | null;
    /**
    * @description Payment batch ID
    */
    'paymentBatchId'?: number | null;
    /**
    * @description Payment batch status ID
    */
    'paymentBatchStatusId'?: number | null;
    'paymentBatchStatus'?: ClaimLookupModel | null;
    'paymentMethodType'?: ClaimLookupModel | null;
    'processor'?: ClaimLookupModel | null;
    /**
    * @description Initial cost of medical procedure or drug
    */
    'initialCost'?: number;
    /**
    * @description Prescriber Name
    */
    'prescriberName'?: string | null;
    /**
    * @description Site or Pharmacy NPI
    */
    'siteNpi'?: string | null;
    /**
    * @description Site or Pharmacy Name
    */
    'siteName'?: string | null;
    /**
    * @description Site or Pharmacy Address One
    */
    'siteAddressOne'?: string | null;
    /**
    * @description Site or Pharmacy Address Two
    */
    'siteAddressTwo'?: string | null;
    /**
    * @description Site or Pharmacy City
    */
    'siteCity'?: string | null;
    /**
    * @description Site or Pharmacy State
    */
    'siteState'?: string | null;
    /**
    * @description Site or Pharmacy ZIP Code
    */
    'siteZip'?: string | null;
    /**
    * @description Transaction batch number (exception: Transaction number for TC pharmacy claims)
    */
    'claimBatchNumber'?: string | null;
    /**
    * @description Transaction number (exception: AbfEobNumber for IMS claims)
    */
    'claimNumber'?: string | null;
    /**
    * @description Average Wholesale Price
    */
    'averageWholesalePrice'?: number | null;
    /**
    * @description Usual And Customary Cost
    */
    'usualAndCustomaryCost'?: number | null;
    /**
    * @description Ingredient Cost
    */
    'ingredientCost'?: number | null;
    /**
    * @description Sales Tax
    */
    'salesTax'?: number | null;
    /**
    * @description Dispensing Fee
    */
    'dispensingFee'?: number | null;
    /**
    * @description Patient Gender
    */
    'patientGender'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof GatewayClaimGroupViewModel>> = [
        {
            "name":     "claims",
            "baseName": "claims",
            "type":     "Array<GatewayClaimViewModel>"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "promotionId",
            "baseName": "promotionId",
            "type":     "number"
        },
        {
            "name":     "processorId",
            "baseName": "processorId",
            "type":     "number"
        },
        {
            "name":     "benefitPaid",
            "baseName": "benefitPaid",
            "type":     "number"
        },
        {
            "name":     "remainingCost",
            "baseName": "remainingCost",
            "type":     "number"
        },
        {
            "name":     "claimTypeId",
            "baseName": "claimTypeId",
            "type":     "number"
        },
        {
            "name":     "patientDateOfBirth",
            "baseName": "patientDateOfBirth",
            "type":     "Date"
        },
        {
            "name":     "dateProcessed",
            "baseName": "dateProcessed",
            "type":     "Date"
        },
        {
            "name":     "processedBy",
            "baseName": "processedBy",
            "type":     "number"
        },
        {
            "name":     "processorGroupNumber",
            "baseName": "processorGroupNumber",
            "type":     "string"
        },
        {
            "name":     "drugName",
            "baseName": "drugName",
            "type":     "string"
        },
        {
            "name":     "daysSupply",
            "baseName": "daysSupply",
            "type":     "number"
        },
        {
            "name":     "prescriberNpi",
            "baseName": "prescriberNpi",
            "type":     "string"
        },
        {
            "name":     "rxWrittenDate",
            "baseName": "rxWrittenDate",
            "type":     "Date"
        },
        {
            "name":     "otherCoverageCode",
            "baseName": "otherCoverageCode",
            "type":     "string"
        },
        {
            "name":     "priorAuthorizationNumber",
            "baseName": "priorAuthorizationNumber",
            "type":     "string"
        },
        {
            "name":     "claimGroupId",
            "baseName": "claimGroupId",
            "type":     "number"
        },
        {
            "name":     "paymentMethodTypeId",
            "baseName": "paymentMethodTypeId",
            "type":     "number"
        },
        {
            "name":     "isMigrated",
            "baseName": "isMigrated",
            "type":     "boolean"
        },
        {
            "name":     "checkNumber",
            "baseName": "checkNumber",
            "type":     "string"
        },
        {
            "name":     "abfEobNumber",
            "baseName": "abfEobNumber",
            "type":     "string"
        },
        {
            "name":     "abfCheckNumber",
            "baseName": "abfCheckNumber",
            "type":     "string"
        },
        {
            "name":     "parentClaimGroupId",
            "baseName": "parentClaimGroupId",
            "type":     "number"
        },
        {
            "name":     "claimGroupStatusId",
            "baseName": "claimGroupStatusId",
            "type":     "number"
        },
        {
            "name":     "claimGroupStatus",
            "baseName": "claimGroupStatus",
            "type":     "ClaimLookupModel"
        },
        {
            "name":     "claimType",
            "baseName": "claimType",
            "type":     "ClaimLookupModel"
        },
        {
            "name":     "paymentBatchId",
            "baseName": "paymentBatchId",
            "type":     "number"
        },
        {
            "name":     "paymentBatchStatusId",
            "baseName": "paymentBatchStatusId",
            "type":     "number"
        },
        {
            "name":     "paymentBatchStatus",
            "baseName": "paymentBatchStatus",
            "type":     "ClaimLookupModel"
        },
        {
            "name":     "paymentMethodType",
            "baseName": "paymentMethodType",
            "type":     "ClaimLookupModel"
        },
        {
            "name":     "processor",
            "baseName": "processor",
            "type":     "ClaimLookupModel"
        },
        {
            "name":     "initialCost",
            "baseName": "initialCost",
            "type":     "number"
        },
        {
            "name":     "prescriberName",
            "baseName": "prescriberName",
            "type":     "string"
        },
        {
            "name":     "siteNpi",
            "baseName": "siteNpi",
            "type":     "string"
        },
        {
            "name":     "siteName",
            "baseName": "siteName",
            "type":     "string"
        },
        {
            "name":     "siteAddressOne",
            "baseName": "siteAddressOne",
            "type":     "string"
        },
        {
            "name":     "siteAddressTwo",
            "baseName": "siteAddressTwo",
            "type":     "string"
        },
        {
            "name":     "siteCity",
            "baseName": "siteCity",
            "type":     "string"
        },
        {
            "name":     "siteState",
            "baseName": "siteState",
            "type":     "string"
        },
        {
            "name":     "siteZip",
            "baseName": "siteZip",
            "type":     "string"
        },
        {
            "name":     "claimBatchNumber",
            "baseName": "claimBatchNumber",
            "type":     "string"
        },
        {
            "name":     "claimNumber",
            "baseName": "claimNumber",
            "type":     "string"
        },
        {
            "name":     "averageWholesalePrice",
            "baseName": "averageWholesalePrice",
            "type":     "number"
        },
        {
            "name":     "usualAndCustomaryCost",
            "baseName": "usualAndCustomaryCost",
            "type":     "number"
        },
        {
            "name":     "ingredientCost",
            "baseName": "ingredientCost",
            "type":     "number"
        },
        {
            "name":     "salesTax",
            "baseName": "salesTax",
            "type":     "number"
        },
        {
            "name":     "dispensingFee",
            "baseName": "dispensingFee",
            "type":     "number"
        },
        {
            "name":     "patientGender",
            "baseName": "patientGender",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return GatewayClaimGroupViewModel.attributeTypeMap;
    }
}
