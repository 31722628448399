// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RoleType } from './roleType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Model for Case Pharmacy Benefits
*/
export class PayorCasePharmacyBenefitModel {
    /**
    * @description Date of Birth
    */
    'dateOfBirth'?: Moment | null;
    /**
    * @description Policy Holder First Name
    */
    'policyHolderFirstName'?: string | null;
    /**
    * @description Policy Holder Last Name
    */
    'policyHolderLastName'?: string | null;
    'relationshipToPatient'?: RoleType;
    /**
    * @description Insurance Phone
    */
    'phone'?: string | null;
    /**
    * @description Insurance Phone Extension
    */
    'extension'?: string | null;
    /**
    * @description Group Number
    */
    'groupNumber'?: string | null;
    /**
    * @description Policy Number
    */
    'policyNumber'?: string | null;
    /**
    * @description Name of Employer
    */
    'employer'?: string | null;
    /**
    * @description Person Code
    */
    'personCode'?: string | null;
    'planType'?: RoleType;
    /**
    * @description Plan Effective Date
    */
    'planEffectiveDate'?: Moment | null;
    /**
    * @description Plan Expiration Date
    */
    'planExpirationDate'?: Moment | null;
    /**
    * @description Plan Termination Date
    */
    'planTerminationDate'?: Moment | null;
    /**
    * @description Name
    */
    'name'?: string | null;
    /**
    * @description Policy Eligible Status ID
    */
    'policyEligibleStatusId'?: number | null;
    /**
    * @description National Council for Prescription Drug Programs (NCPDP) Processor ID Number (BIN)
    */
    'bin'?: string | null;
    /**
    * @description Processor Control Number (PCN)
    */
    'pcn'?: string | null;
    /**
    * @description Case identifier
    */
    'caseId'?: number;
    /**
    * @description Payor identifier
    */
    'payorId'?: number;
    /**
    * @description Flag indicating if this Pharmacy Benefit is Primary for the Case (true) or not (false)
    */
    'isPrimary'?: boolean;
    /**
    * @description Order of coverage for the Payor on the Case
    */
    'coverageOrder'?: number | null;
    /**
    * @description The date and time (in UTC) when this record was created
    */
    'createDate'?: Moment;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PayorCasePharmacyBenefitModel>> = [
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "Date"
        },
        {
            "name":     "policyHolderFirstName",
            "baseName": "policyHolderFirstName",
            "type":     "string"
        },
        {
            "name":     "policyHolderLastName",
            "baseName": "policyHolderLastName",
            "type":     "string"
        },
        {
            "name":     "relationshipToPatient",
            "baseName": "relationshipToPatient",
            "type":     "RoleType"
        },
        {
            "name":     "phone",
            "baseName": "phone",
            "type":     "string"
        },
        {
            "name":     "extension",
            "baseName": "extension",
            "type":     "string"
        },
        {
            "name":     "groupNumber",
            "baseName": "groupNumber",
            "type":     "string"
        },
        {
            "name":     "policyNumber",
            "baseName": "policyNumber",
            "type":     "string"
        },
        {
            "name":     "employer",
            "baseName": "employer",
            "type":     "string"
        },
        {
            "name":     "personCode",
            "baseName": "personCode",
            "type":     "string"
        },
        {
            "name":     "planType",
            "baseName": "planType",
            "type":     "RoleType"
        },
        {
            "name":     "planEffectiveDate",
            "baseName": "planEffectiveDate",
            "type":     "Date"
        },
        {
            "name":     "planExpirationDate",
            "baseName": "planExpirationDate",
            "type":     "Date"
        },
        {
            "name":     "planTerminationDate",
            "baseName": "planTerminationDate",
            "type":     "Date"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "policyEligibleStatusId",
            "baseName": "policyEligibleStatusId",
            "type":     "number"
        },
        {
            "name":     "bin",
            "baseName": "bin",
            "type":     "string"
        },
        {
            "name":     "pcn",
            "baseName": "pcn",
            "type":     "string"
        },
        {
            "name":     "caseId",
            "baseName": "caseId",
            "type":     "number"
        },
        {
            "name":     "payorId",
            "baseName": "payorId",
            "type":     "number"
        },
        {
            "name":     "isPrimary",
            "baseName": "isPrimary",
            "type":     "boolean"
        },
        {
            "name":     "coverageOrder",
            "baseName": "coverageOrder",
            "type":     "number"
        },
        {
            "name":     "createDate",
            "baseName": "createDate",
            "type":     "Date"
        },
    ];

    static getAttributeTypeMap() {
        return PayorCasePharmacyBenefitModel.attributeTypeMap;
    }
}
