// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RoleType } from './roleType.js';
import type { TrialcardEscoordV1ModelsSharedAddressUpsearchModel } from './trialcardEscoordV1ModelsSharedAddressUpsearchModel.js';
import type { TrialcardEscoordV1ModelsSharedEmailAddressUpsearchModel } from './trialcardEscoordV1ModelsSharedEmailAddressUpsearchModel.js';
import type { TrialcardEscoordV1ModelsSharedPhoneUpsearchModel } from './trialcardEscoordV1ModelsSharedPhoneUpsearchModel.js';
import type { TrialcardEscoordV1ModelsSharedWebAddressUpsearchModel } from './trialcardEscoordV1ModelsSharedWebAddressUpsearchModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class TrialcardEscoordV1ModelsSharedContactUpsearchModel {
    'addresses'?: Array<TrialcardEscoordV1ModelsSharedAddressUpsearchModel>;
    'emailAddresses'?: Array<TrialcardEscoordV1ModelsSharedEmailAddressUpsearchModel>;
    'phoneNumbers'?: Array<TrialcardEscoordV1ModelsSharedPhoneUpsearchModel>;
    'webAddresses'?: Array<TrialcardEscoordV1ModelsSharedWebAddressUpsearchModel>;
    'contactId'?: number;
    'contactType'?: RoleType;
    'firstName'?: string;
    'lastName'?: string;
    'occupationDescription'?: string;
    'isMainContact'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof TrialcardEscoordV1ModelsSharedContactUpsearchModel>> = [
        {
            "name":     "addresses",
            "baseName": "addresses",
            "type":     "Array<TrialcardEscoordV1ModelsSharedAddressUpsearchModel>"
        },
        {
            "name":     "emailAddresses",
            "baseName": "emailAddresses",
            "type":     "Array<TrialcardEscoordV1ModelsSharedEmailAddressUpsearchModel>"
        },
        {
            "name":     "phoneNumbers",
            "baseName": "phoneNumbers",
            "type":     "Array<TrialcardEscoordV1ModelsSharedPhoneUpsearchModel>"
        },
        {
            "name":     "webAddresses",
            "baseName": "webAddresses",
            "type":     "Array<TrialcardEscoordV1ModelsSharedWebAddressUpsearchModel>"
        },
        {
            "name":     "contactId",
            "baseName": "contactId",
            "type":     "number"
        },
        {
            "name":     "contactType",
            "baseName": "contactType",
            "type":     "RoleType"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "occupationDescription",
            "baseName": "occupationDescription",
            "type":     "string"
        },
        {
            "name":     "isMainContact",
            "baseName": "isMainContact",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return TrialcardEscoordV1ModelsSharedContactUpsearchModel.attributeTypeMap;
    }
}
