// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CoverMyMedsInboundEnrollmentCaregiverModel } from './coverMyMedsInboundEnrollmentCaregiverModel.js';
import type { CoverMyMedsInboundEnrollmentPatientModel } from './coverMyMedsInboundEnrollmentPatientModel.js';
import type { CoverMyMedsInboundEnrollmentPrescriptionDefinitionModel } from './coverMyMedsInboundEnrollmentPrescriptionDefinitionModel.js';
import type { CoverMyMedsInboundEnrollmentProviderModel } from './coverMyMedsInboundEnrollmentProviderModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Hub enrollment
*/
export class CoverMyMedsInboundEnrollmentHubEnrollmentModel {
    /**
    * @description Are Substitutions allowed?<br />  Saved to prescription physician\\\'s signature
    */
    'substitutionsAllowed'?: boolean | null;
    'caregiver'?: CoverMyMedsInboundEnrollmentCaregiverModel | null;
    /**
    * @description Diagnosis (255 max chars)<br />  Mapped to Diagnosis/\\\'Health Codes\\\'
    */
    'diagnosis'?: string | null;
    /**
    * @description Date of diagnosis<br />  Saved to survey as \\\'Date of Diagnosis\\\'
    */
    'diagnosisDate'?: string | null;
    /**
    * @description Free form diagnosis field when selecting other (255 max chars)<br />  Saved to survey as \\\'Other Diagnosis\\\'
    */
    'otherDiagnosis'?: string | null;
    /**
    * @description Any drug allergies (1000 max chars)<br />  Saved to survey as \\\'Drug Allergies\\\'
    */
    'drugAllergies'?: string | null;
    /**
    * @description Has a patient representative signed instead. (required)<br />  Saved as consent/attestation and determines if patient or guardian consent
    */
    'hasPatientRepresentative'?: boolean;
    /**
    * @description Does Provider have a preferred specialty Pharmacy?<br />  Saved to survey as \\\'Provider SP Listed in Patient Start Form?\\\'
    */
    'hcpHasPreferredSpecialtyPharmacy'?: boolean | null;
    /**
    * @description HCP preferred pharmacy. If none then no preferred specialty pharmacy (255 max chars)<br />  Saved as \\\'Pharmacy Name\\\'
    */
    'hcpPreferredSpecialtyPharmacy'?: string | null;
    /**
    * @description Consented to copay program<br />  Saved as copay consent (attestation)
    */
    'isEnrollmentCopayConsented'?: boolean | null;
    /**
    * @description Has HCP sent Rx to pharmacy<br />  Saved to survey as \\\'Provider Sent Script to Preferred SP\\\'
    */
    'isPrescriptionSentToSpecialtyPharmacy'?: boolean | null;
    /**
    * @description Is telephone consumer protection act consented<br />  Saved as services and communications \\\'Text Opt In\\\' (Contact Preferences)
    */
    'isTcpaConsented'?: boolean | null;
    /**
    * @description Enrollment specific key. (required, 16 max chars)<br />  Saved as Saved as case external ID type \\\'CMMEnrollmentKey\\\'
    */
    'key'?: string | null;
    /**
    * @description Medical insurance card ID (255 max chars)<br />  Saved as medical payor \\\'Cardholder\\\'s ID\\\' (PBM Policy Number)
    */
    'medicalCardId'?: string | null;
    /**
    * @description Medical insurance group ID (15 max chars)<br />  Saved as medical primary \\\'Medical Group\\\' (Payor Group)
    */
    'medicalGroupId'?: string | null;
    /**
    * @description Medical insurance carrier (255 max chars)<br />  Saved as medical primary \\\'Insurance Name\\\' (Payor Name)
    */
    'medicalInsuranceCarrier'?: string | null;
    /**
    * @description No Known Drug Allergies<br />  Saved to survey as \\\'No Known Allergies\\\'
    */
    'noKnownDrugAllergies'?: boolean | null;
    'patient'?: CoverMyMedsInboundEnrollmentPatientModel | null;
    /**
    * @description Date of the Patient Marketing Signature(consent signature to help with Hipaa logic)<br />  (Not used)
    */
    'patientMarketingSignatureDate'?: string | null;
    /**
    * @description Date of patient signature. (required)<br />  Saved as consent/attestation patient or guardian consent start/end dates
    */
    'patientSignatureDate'?: string;
    /**
    * @description Date of the Patient Copay Signature<br />  Saved as PatientCAPProgram consent/attestation
    */
    'patientCopaySignatureDate'?: string | null;
    /**
    * @description Date the HIPAA authorization was received. (required)<br />  (Not Used)
    */
    'patientSignatureReceivedDate'?: string;
    /**
    * @description PDF of enrollment form and associated documents. (required)<br />  attached to account and/or case
    */
    'pdf'?: string | null;
    /**
    * @description Preferred contact method(s), (255 max chars)<br />  Csv format, possible values: \\\"Cell Phone\\\", \\\"Home Phone\\\"<br />  (Not Used)
    */
    'preferredContact'?: string | null;
    /**
    * @description Preferred language (255 max chars)<br />  Mapped to patient\\\'s \\\'Preferred Language\\\'
    */
    'preferredLang'?: Array<string> | null;
    /**
    * @description Free form language field (255 max chars)<br />  Mapped to patient\\\'s \\\'Preferred Language\\\' if TC.Enterprise.CoverMyMeds.Service.Public.Models.Inbound.Enrollment.HubEnrollmentModel.PreferredLanguages is unknown
    */
    'preferredLangOther'?: string | null;
    /**
    * @description Insurance BIN (6 max chars)<br />  Saved as PBM \\\'Rx Bin\\\' (pharmacy PBM bin and payor bin)
    */
    'prescriptionBin'?: string | null;
    /**
    * @description TC.Enterprise.CoverMyMeds.Service.Public.Models.Inbound.Enrollment.PatientModel. (required)
    */
    'prescriptionDefinitions'?: Array<CoverMyMedsInboundEnrollmentPrescriptionDefinitionModel> | null;
    /**
    * @description Insurance group ID (15 max chars)<br />  Saved as PBM \\\'Rx Group Number\\\' (pharmacy PBM group number and payor group)
    */
    'prescriptionGroupId'?: string | null;
    /**
    * @description Insurance carrier (255 max chars)<br />  Saved as PBM Name (pharmacy payor name)
    */
    'prescriptionInsuranceCarrier'?: string | null;
    /**
    * @description Insurance phone number (Must be between 10 and 15 chars)<br />  Saved as PBM \\\'Help Desk\\\' (pharmacy PBM phone)
    */
    'prescriptionInsurancePhone'?: string | null;
    /**
    * @description Insurance Member ID (255 max chars)<br />  Saved as PBM \\\'Rx Member ID\\\' (pharmacy PBM policy number)
    */
    'prescriptionMemberId'?: string | null;
    /**
    * @description Insurance PCN (10 max chars)<br />  Saved as PBM \\\'RX PCN #\\\' (pharmacy PBM and payor pcn)
    */
    'prescriptionPcn'?: string | null;
    /**
    * @description Prior Therapies (1000 max chars)<br />  Saved to survey as \\\'Prior Therapies\\\'
    */
    'priorTherapies'?: string | null;
    'provider'?: CoverMyMedsInboundEnrollmentProviderModel | null;
    /**
    * @description Date of provider signature (TC Prescription \\\'WrittenOn\\\'). (required)<br />  Saved as prescription \\\'Signature Date\\\' and \\\'Rx Start Date\\\'
    */
    'providerSignatureDate'?: string;
    /**
    * @description The date a sample pack was provided to the patient<br />  Saved to survey as \\\'Sample Pack Received\\\' and \\\'Date Provided\\\'
    */
    'samplePackProvidedDate'?: string | null;
    /**
    * @description Secondary medical Insurance ID (255 max chars)<br />  Saved as secondary medical \\\'Cardholder\\\'s ID\\\' (PBM PolicyNumber)
    */
    'secondaryMedicalCardId'?: string | null;
    /**
    * @description Secondary medical group ID (15 max chars)<br />  Saved as secondary medical \\\'Medical Group\\\' (PBM GroupNumber)
    */
    'secondaryMedicalGroupId'?: string | null;
    /**
    * @description Secondary medical insurance carrier (255 max chars)<br />  Saved as secondary medical \\\'Insurance Name\\\' (Payor Name)
    */
    'secondaryMedicalInsuranceCarrier'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof CoverMyMedsInboundEnrollmentHubEnrollmentModel>> = [
        {
            "name":     "substitutionsAllowed",
            "baseName": "substitutionsAllowed",
            "type":     "boolean"
        },
        {
            "name":     "caregiver",
            "baseName": "caregiver",
            "type":     "CoverMyMedsInboundEnrollmentCaregiverModel"
        },
        {
            "name":     "diagnosis",
            "baseName": "diagnosis",
            "type":     "string"
        },
        {
            "name":     "diagnosisDate",
            "baseName": "diagnosisDate",
            "type":     "string"
        },
        {
            "name":     "otherDiagnosis",
            "baseName": "otherDiagnosis",
            "type":     "string"
        },
        {
            "name":     "drugAllergies",
            "baseName": "drugAllergies",
            "type":     "string"
        },
        {
            "name":     "hasPatientRepresentative",
            "baseName": "hasPatientRepresentative",
            "type":     "boolean"
        },
        {
            "name":     "hcpHasPreferredSpecialtyPharmacy",
            "baseName": "hcpHasPreferredSpecialtyPharmacy",
            "type":     "boolean"
        },
        {
            "name":     "hcpPreferredSpecialtyPharmacy",
            "baseName": "hcpPreferredSpecialtyPharmacy",
            "type":     "string"
        },
        {
            "name":     "isEnrollmentCopayConsented",
            "baseName": "isEnrollmentCopayConsented",
            "type":     "boolean"
        },
        {
            "name":     "isPrescriptionSentToSpecialtyPharmacy",
            "baseName": "isPrescriptionSentToSpecialtyPharmacy",
            "type":     "boolean"
        },
        {
            "name":     "isTcpaConsented",
            "baseName": "isTcpaConsented",
            "type":     "boolean"
        },
        {
            "name":     "key",
            "baseName": "key",
            "type":     "string"
        },
        {
            "name":     "medicalCardId",
            "baseName": "medicalCardId",
            "type":     "string"
        },
        {
            "name":     "medicalGroupId",
            "baseName": "medicalGroupId",
            "type":     "string"
        },
        {
            "name":     "medicalInsuranceCarrier",
            "baseName": "medicalInsuranceCarrier",
            "type":     "string"
        },
        {
            "name":     "noKnownDrugAllergies",
            "baseName": "noKnownDrugAllergies",
            "type":     "boolean"
        },
        {
            "name":     "patient",
            "baseName": "patient",
            "type":     "CoverMyMedsInboundEnrollmentPatientModel"
        },
        {
            "name":     "patientMarketingSignatureDate",
            "baseName": "patientMarketingSignatureDate",
            "type":     "string"
        },
        {
            "name":     "patientSignatureDate",
            "baseName": "patientSignatureDate",
            "type":     "string"
        },
        {
            "name":     "patientCopaySignatureDate",
            "baseName": "patientCopaySignatureDate",
            "type":     "string"
        },
        {
            "name":     "patientSignatureReceivedDate",
            "baseName": "patientSignatureReceivedDate",
            "type":     "string"
        },
        {
            "name":     "pdf",
            "baseName": "pdf",
            "type":     "string"
        },
        {
            "name":     "preferredContact",
            "baseName": "preferredContact",
            "type":     "string"
        },
        {
            "name":     "preferredLang",
            "baseName": "preferredLang",
            "type":     "Array<string>"
        },
        {
            "name":     "preferredLangOther",
            "baseName": "preferredLangOther",
            "type":     "string"
        },
        {
            "name":     "prescriptionBin",
            "baseName": "prescriptionBin",
            "type":     "string"
        },
        {
            "name":     "prescriptionDefinitions",
            "baseName": "prescriptionDefinitions",
            "type":     "Array<CoverMyMedsInboundEnrollmentPrescriptionDefinitionModel>"
        },
        {
            "name":     "prescriptionGroupId",
            "baseName": "prescriptionGroupId",
            "type":     "string"
        },
        {
            "name":     "prescriptionInsuranceCarrier",
            "baseName": "prescriptionInsuranceCarrier",
            "type":     "string"
        },
        {
            "name":     "prescriptionInsurancePhone",
            "baseName": "prescriptionInsurancePhone",
            "type":     "string"
        },
        {
            "name":     "prescriptionMemberId",
            "baseName": "prescriptionMemberId",
            "type":     "string"
        },
        {
            "name":     "prescriptionPcn",
            "baseName": "prescriptionPcn",
            "type":     "string"
        },
        {
            "name":     "priorTherapies",
            "baseName": "priorTherapies",
            "type":     "string"
        },
        {
            "name":     "provider",
            "baseName": "provider",
            "type":     "CoverMyMedsInboundEnrollmentProviderModel"
        },
        {
            "name":     "providerSignatureDate",
            "baseName": "providerSignatureDate",
            "type":     "string"
        },
        {
            "name":     "samplePackProvidedDate",
            "baseName": "samplePackProvidedDate",
            "type":     "string"
        },
        {
            "name":     "secondaryMedicalCardId",
            "baseName": "secondaryMedicalCardId",
            "type":     "string"
        },
        {
            "name":     "secondaryMedicalGroupId",
            "baseName": "secondaryMedicalGroupId",
            "type":     "string"
        },
        {
            "name":     "secondaryMedicalInsuranceCarrier",
            "baseName": "secondaryMedicalInsuranceCarrier",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return CoverMyMedsInboundEnrollmentHubEnrollmentModel.attributeTypeMap;
    }
}
