// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class NpiBasicResponseModel {
    'replacement_npi'?: string;
    'ein'?: string;
    'organization_name'?: string;
    'last_name'?: string;
    'first_name'?: string;
    'middle_name'?: string;
    'name_prefix'?: string;
    'name_suffix'?: string;
    'credential'?: string;
    'enumeration_date'?: string;
    'last_updated'?: string;
    'deactivation_reason_code'?: string;
    'deactivation_date'?: string;
    'reactivation_date'?: string;
    'gender'?: string;
    'authorized_official_last_name'?: string;
    'authorized_official_first_name'?: string;
    'authorized_official_middle_name'?: string;
    'authorized_official_title_or_position'?: string;
    'authorized_official_telephone_number'?: string;
    'sole_proprietor'?: string;
    'organizational_subpart'?: string;
    'parent_organization_legal_business_name'?: string;
    'parent_organization_ein'?: string;
    'authorized_official_name_prefix'?: string;
    'authorized_official_name_suffix'?: string;
    'authorized_official_credential'?: string;
    'certification_date'?: string;
    'status'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof NpiBasicResponseModel>> = [
        {
            "name":     "replacement_npi",
            "baseName": "replacement_npi",
            "type":     "string"
        },
        {
            "name":     "ein",
            "baseName": "ein",
            "type":     "string"
        },
        {
            "name":     "organization_name",
            "baseName": "organization_name",
            "type":     "string"
        },
        {
            "name":     "last_name",
            "baseName": "last_name",
            "type":     "string"
        },
        {
            "name":     "first_name",
            "baseName": "first_name",
            "type":     "string"
        },
        {
            "name":     "middle_name",
            "baseName": "middle_name",
            "type":     "string"
        },
        {
            "name":     "name_prefix",
            "baseName": "name_prefix",
            "type":     "string"
        },
        {
            "name":     "name_suffix",
            "baseName": "name_suffix",
            "type":     "string"
        },
        {
            "name":     "credential",
            "baseName": "credential",
            "type":     "string"
        },
        {
            "name":     "enumeration_date",
            "baseName": "enumeration_date",
            "type":     "string"
        },
        {
            "name":     "last_updated",
            "baseName": "last_updated",
            "type":     "string"
        },
        {
            "name":     "deactivation_reason_code",
            "baseName": "deactivation_reason_code",
            "type":     "string"
        },
        {
            "name":     "deactivation_date",
            "baseName": "deactivation_date",
            "type":     "string"
        },
        {
            "name":     "reactivation_date",
            "baseName": "reactivation_date",
            "type":     "string"
        },
        {
            "name":     "gender",
            "baseName": "gender",
            "type":     "string"
        },
        {
            "name":     "authorized_official_last_name",
            "baseName": "authorized_official_last_name",
            "type":     "string"
        },
        {
            "name":     "authorized_official_first_name",
            "baseName": "authorized_official_first_name",
            "type":     "string"
        },
        {
            "name":     "authorized_official_middle_name",
            "baseName": "authorized_official_middle_name",
            "type":     "string"
        },
        {
            "name":     "authorized_official_title_or_position",
            "baseName": "authorized_official_title_or_position",
            "type":     "string"
        },
        {
            "name":     "authorized_official_telephone_number",
            "baseName": "authorized_official_telephone_number",
            "type":     "string"
        },
        {
            "name":     "sole_proprietor",
            "baseName": "sole_proprietor",
            "type":     "string"
        },
        {
            "name":     "organizational_subpart",
            "baseName": "organizational_subpart",
            "type":     "string"
        },
        {
            "name":     "parent_organization_legal_business_name",
            "baseName": "parent_organization_legal_business_name",
            "type":     "string"
        },
        {
            "name":     "parent_organization_ein",
            "baseName": "parent_organization_ein",
            "type":     "string"
        },
        {
            "name":     "authorized_official_name_prefix",
            "baseName": "authorized_official_name_prefix",
            "type":     "string"
        },
        {
            "name":     "authorized_official_name_suffix",
            "baseName": "authorized_official_name_suffix",
            "type":     "string"
        },
        {
            "name":     "authorized_official_credential",
            "baseName": "authorized_official_credential",
            "type":     "string"
        },
        {
            "name":     "certification_date",
            "baseName": "certification_date",
            "type":     "string"
        },
        {
            "name":     "status",
            "baseName": "status",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return NpiBasicResponseModel.attributeTypeMap;
    }
}
