// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Enrollment Flags Model
*/
export class EnrollmentV2EnrollmentFlags {
    /**
    * @description One or More Days of Enrollment
    */
    'lastOneOrMoreDays'?: boolean;
    /**
    * @description Is Active Enrollment For Current Date
    */
    'isActiveEnrollmentForCurrentDate'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnrollmentV2EnrollmentFlags>> = [
        {
            "name":     "lastOneOrMoreDays",
            "baseName": "lastOneOrMoreDays",
            "type":     "boolean"
        },
        {
            "name":     "isActiveEnrollmentForCurrentDate",
            "baseName": "isActiveEnrollmentForCurrentDate",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return EnrollmentV2EnrollmentFlags.attributeTypeMap;
    }
}
