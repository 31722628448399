// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ProgramV1ProgramV1GetProgramDefaultResponse {
    'success': boolean;
    'messages': Array<string>;
    'data': ProgramV1ProgramV1GetProgramDefaultResponse | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ProgramV1ProgramV1GetProgramDefaultResponse>> = [
        {
            "name":     "success",
            "baseName": "success",
            "type":     "boolean"
        },
        {
            "name":     "messages",
            "baseName": "messages",
            "type":     "Array<string>"
        },
        {
            "name":     "data",
            "baseName": "data",
            "type":     "ProgramV1ProgramV1GetProgramDefaultResponse"
        },
    ];

    static getAttributeTypeMap() {
        return ProgramV1ProgramV1GetProgramDefaultResponse.attributeTypeMap;
    }
}
