// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiIndividual {
    'deductibleMet'?: number;
    'deductibleTotal'?: number;
    'oopMax'?: number;
    'oopMet'?: number;
    'individualDeductibleExists'?: boolean;
    'familyDeductibleExists'?: boolean;
    'individualOopMaximumExists'?: boolean;
    'familyOopMaximumExists'?: boolean;
    'coinsuranceWaivedAfterOop'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiIndividual>> = [
        {
            "name":     "deductibleMet",
            "baseName": "deductibleMet",
            "type":     "number"
        },
        {
            "name":     "deductibleTotal",
            "baseName": "deductibleTotal",
            "type":     "number"
        },
        {
            "name":     "oopMax",
            "baseName": "oopMax",
            "type":     "number"
        },
        {
            "name":     "oopMet",
            "baseName": "oopMet",
            "type":     "number"
        },
        {
            "name":     "individualDeductibleExists",
            "baseName": "individualDeductibleExists",
            "type":     "boolean"
        },
        {
            "name":     "familyDeductibleExists",
            "baseName": "familyDeductibleExists",
            "type":     "boolean"
        },
        {
            "name":     "individualOopMaximumExists",
            "baseName": "individualOopMaximumExists",
            "type":     "boolean"
        },
        {
            "name":     "familyOopMaximumExists",
            "baseName": "familyOopMaximumExists",
            "type":     "boolean"
        },
        {
            "name":     "coinsuranceWaivedAfterOop",
            "baseName": "coinsuranceWaivedAfterOop",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiIndividual.attributeTypeMap;
    }
}
