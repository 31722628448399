// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EnrollmentClientIdentifiersModel } from './enrollmentClientIdentifiersModel.js';
import type { EnrollmentDemographicsModel } from './enrollmentDemographicsModel.js';
import type { EnrollmentInsuranceVerificationModel } from './enrollmentInsuranceVerificationModel.js';
import type { EnrollmentSurveyQuestionModel } from './enrollmentSurveyQuestionModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EnrollmentInsVerRequestModel {
    'insuranceVerification'?: EnrollmentInsuranceVerificationModel | null;
    'survey'?: Array<EnrollmentSurveyQuestionModel>;
    'demographics'?: EnrollmentDemographicsModel | null;
    'clientIdentifiers'?: EnrollmentClientIdentifiersModel | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnrollmentInsVerRequestModel>> = [
        {
            "name":     "insuranceVerification",
            "baseName": "insuranceVerification",
            "type":     "EnrollmentInsuranceVerificationModel"
        },
        {
            "name":     "survey",
            "baseName": "survey",
            "type":     "Array<EnrollmentSurveyQuestionModel>"
        },
        {
            "name":     "demographics",
            "baseName": "demographics",
            "type":     "EnrollmentDemographicsModel"
        },
        {
            "name":     "clientIdentifiers",
            "baseName": "clientIdentifiers",
            "type":     "EnrollmentClientIdentifiersModel"
        },
    ];

    static getAttributeTypeMap() {
        return EnrollmentInsVerRequestModel.attributeTypeMap;
    }
}
