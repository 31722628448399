// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RoleType } from './roleType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Save a Contact on an Account
*/
export class AccountContactModel {
    /**
    * @description Account Contact Id
    */
    'accountContactId'?: number;
    'roleType'?: RoleType;
    /**
    * @description First Name
    */
    'firstName'?: string | null;
    /**
    * @description Last Name
    */
    'lastName'?: string | null;
    /**
    * @description Phone Number
    */
    'phoneNumber'?: string | null;
    /**
    * @description Alternate Phone Number
    */
    'altPhoneNumber'?: string | null;
    /**
    * @description Email
    */
    'email'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof AccountContactModel>> = [
        {
            "name":     "accountContactId",
            "baseName": "accountContactId",
            "type":     "number"
        },
        {
            "name":     "roleType",
            "baseName": "roleType",
            "type":     "RoleType"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "phoneNumber",
            "baseName": "phoneNumber",
            "type":     "string"
        },
        {
            "name":     "altPhoneNumber",
            "baseName": "altPhoneNumber",
            "type":     "string"
        },
        {
            "name":     "email",
            "baseName": "email",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return AccountContactModel.attributeTypeMap;
    }
}
