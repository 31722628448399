// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PorticoPortalModel } from './porticoPortalModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class GetPortalConfigurationResponseModel {
    'portalConfig': PorticoPortalModel | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof GetPortalConfigurationResponseModel>> = [
        {
            "name":     "portalConfig",
            "baseName": "portalConfig",
            "type":     "PorticoPortalModel"
        },
    ];

    static getAttributeTypeMap() {
        return GetPortalConfigurationResponseModel.attributeTypeMap;
    }
}
