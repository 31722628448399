// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PriorAuthEntityOnFileModel } from './priorAuthEntityOnFileModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PriorAuthInfoDetailModel {
    'priorAuthStatus'?: string | null;
    'priorAuthStartDate'?: string | null;
    'priorAuthEndDate'?: string | null;
    'priorAuthApprovalNumber'?: string | null;
    'priorAuthCodesOnFile'?: Array<string> | null;
    'productSourcingMethod'?: string | null;
    'priorAuthApprovalLevel'?: string | null;
    'quantityLimit'?: string | null;
    'quantityLimitExists'?: string | null;
    'priorAuthLockedToSpecificEntity'?: string | null;
    'priorAuthDoseOnFile'?: string | null;
    'priorAuthEntityOnFile': PriorAuthEntityOnFileModel | null;
    'priorAuthApprovalDate'?: string | null;
    'priorAuthCompletionDate'?: string | null;
    'priorAuthDenialReason'?: string | null;
    'priorAuthDenialReasonOther'?: string | null;
    'priorAuthSubmissionMethods'?: Array<string> | null;
    'priorAuthTurnaroundNotes'?: string | null;
    'priorAuthOnFileApplies'?: string | null;
    'priorAuthNotificationMethods'?: Array<string> | null;
    'priorAuthSubmissionDate'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PriorAuthInfoDetailModel>> = [
        {
            "name":     "priorAuthStatus",
            "baseName": "priorAuthStatus",
            "type":     "string"
        },
        {
            "name":     "priorAuthStartDate",
            "baseName": "priorAuthStartDate",
            "type":     "string"
        },
        {
            "name":     "priorAuthEndDate",
            "baseName": "priorAuthEndDate",
            "type":     "string"
        },
        {
            "name":     "priorAuthApprovalNumber",
            "baseName": "priorAuthApprovalNumber",
            "type":     "string"
        },
        {
            "name":     "priorAuthCodesOnFile",
            "baseName": "priorAuthCodesOnFile",
            "type":     "Array<string>"
        },
        {
            "name":     "productSourcingMethod",
            "baseName": "productSourcingMethod",
            "type":     "string"
        },
        {
            "name":     "priorAuthApprovalLevel",
            "baseName": "priorAuthApprovalLevel",
            "type":     "string"
        },
        {
            "name":     "quantityLimit",
            "baseName": "quantityLimit",
            "type":     "string"
        },
        {
            "name":     "quantityLimitExists",
            "baseName": "quantityLimitExists",
            "type":     "string"
        },
        {
            "name":     "priorAuthLockedToSpecificEntity",
            "baseName": "priorAuthLockedToSpecificEntity",
            "type":     "string"
        },
        {
            "name":     "priorAuthDoseOnFile",
            "baseName": "priorAuthDoseOnFile",
            "type":     "string"
        },
        {
            "name":     "priorAuthEntityOnFile",
            "baseName": "priorAuthEntityOnFile",
            "type":     "PriorAuthEntityOnFileModel"
        },
        {
            "name":     "priorAuthApprovalDate",
            "baseName": "priorAuthApprovalDate",
            "type":     "string"
        },
        {
            "name":     "priorAuthCompletionDate",
            "baseName": "priorAuthCompletionDate",
            "type":     "string"
        },
        {
            "name":     "priorAuthDenialReason",
            "baseName": "priorAuthDenialReason",
            "type":     "string"
        },
        {
            "name":     "priorAuthDenialReasonOther",
            "baseName": "priorAuthDenialReasonOther",
            "type":     "string"
        },
        {
            "name":     "priorAuthSubmissionMethods",
            "baseName": "priorAuthSubmissionMethods",
            "type":     "Array<string>"
        },
        {
            "name":     "priorAuthTurnaroundNotes",
            "baseName": "priorAuthTurnaroundNotes",
            "type":     "string"
        },
        {
            "name":     "priorAuthOnFileApplies",
            "baseName": "priorAuthOnFileApplies",
            "type":     "string"
        },
        {
            "name":     "priorAuthNotificationMethods",
            "baseName": "priorAuthNotificationMethods",
            "type":     "Array<string>"
        },
        {
            "name":     "priorAuthSubmissionDate",
            "baseName": "priorAuthSubmissionDate",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PriorAuthInfoDetailModel.attributeTypeMap;
    }
}
