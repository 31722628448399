// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ClsInboundConsent {
    'service': string;
    'patientresponse'?: string;
    'patientresponsedate'?: string;
    'hcpresponse'?: string;
    'hcpresponsedate'?: string;
    'legalguardianresponse'?: string;
    'legalguardianresponsedate'?: string;
    'consenttype': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClsInboundConsent>> = [
        {
            "name":     "service",
            "baseName": "service",
            "type":     "string"
        },
        {
            "name":     "patientresponse",
            "baseName": "patientresponse",
            "type":     "string"
        },
        {
            "name":     "patientresponsedate",
            "baseName": "patientresponsedate",
            "type":     "string"
        },
        {
            "name":     "hcpresponse",
            "baseName": "hcpresponse",
            "type":     "string"
        },
        {
            "name":     "hcpresponsedate",
            "baseName": "hcpresponsedate",
            "type":     "string"
        },
        {
            "name":     "legalguardianresponse",
            "baseName": "legalguardianresponse",
            "type":     "string"
        },
        {
            "name":     "legalguardianresponsedate",
            "baseName": "legalguardianresponsedate",
            "type":     "string"
        },
        {
            "name":     "consenttype",
            "baseName": "consenttype",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ClsInboundConsent.attributeTypeMap;
    }
}
