// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { MedicalBiAddress } from './medicalBiAddress.js';
import type { MedicalBiGender } from './medicalBiGender.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiPatientResult {
    'receivesSubsidies'?: string;
    'address': MedicalBiAddress | null;
    'birthDate': Moment;
    'firstName': string;
    'gender'?: MedicalBiGender;
    'lastName': string;
    'middleName'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiPatientResult>> = [
        {
            "name":     "receivesSubsidies",
            "baseName": "receivesSubsidies",
            "type":     "string"
        },
        {
            "name":     "address",
            "baseName": "address",
            "type":     "MedicalBiAddress"
        },
        {
            "name":     "birthDate",
            "baseName": "birthDate",
            "type":     "Date"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "gender",
            "baseName": "gender",
            "type":     "MedicalBiGender"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "middleName",
            "baseName": "middleName",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiPatientResult.attributeTypeMap;
    }
}
