// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  https://openapi-generator.tech  Do not edit the class manually.
*/
export class ExtClaimFileOutputModel {
    'fileIdentifier'?: string;
    'fileName'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtClaimFileOutputModel>> = [
        {
            "name":     "fileIdentifier",
            "baseName": "fileIdentifier",
            "type":     "string"
        },
        {
            "name":     "fileName",
            "baseName": "fileName",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ExtClaimFileOutputModel.attributeTypeMap;
    }
}
