// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ClaimAccumulatorModel {
    'accumulatorId'?: number;
    'accumulatorTypeId'?: number;
    'memberNumbers'?: Array<string>;
    'balance'?: number;
    'availableBalance'?: number;
    'startDate'?: Moment;
    'endDate'?: Moment;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimAccumulatorModel>> = [
        {
            "name":     "accumulatorId",
            "baseName": "accumulatorId",
            "type":     "number"
        },
        {
            "name":     "accumulatorTypeId",
            "baseName": "accumulatorTypeId",
            "type":     "number"
        },
        {
            "name":     "memberNumbers",
            "baseName": "memberNumbers",
            "type":     "Array<string>"
        },
        {
            "name":     "balance",
            "baseName": "balance",
            "type":     "number"
        },
        {
            "name":     "availableBalance",
            "baseName": "availableBalance",
            "type":     "number"
        },
        {
            "name":     "startDate",
            "baseName": "startDate",
            "type":     "Date"
        },
        {
            "name":     "endDate",
            "baseName": "endDate",
            "type":     "Date"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimAccumulatorModel.attributeTypeMap;
    }
}
