// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { TCSCommunicationType } from './tCSCommunicationType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EnrollmentDemographicsModel {
    'personId'?: number;
    'firstName'?: string;
    'lastName'?: string;
    'gender'?: string;
    'dateOfBirth'?: string;
    'email'?: string;
    'homePhone'?: string;
    'mobilePhone'?: string;
    'address1'?: string;
    'address2'?: string;
    'city'?: string;
    'state'?: string;
    'postalCode'?: string;
    'country'?: string;
    'memberNumber'?: string;
    'communicationTypes'?: TCSCommunicationType;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnrollmentDemographicsModel>> = [
        {
            "name":     "personId",
            "baseName": "personId",
            "type":     "number"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "gender",
            "baseName": "gender",
            "type":     "string"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "string"
        },
        {
            "name":     "email",
            "baseName": "email",
            "type":     "string"
        },
        {
            "name":     "homePhone",
            "baseName": "homePhone",
            "type":     "string"
        },
        {
            "name":     "mobilePhone",
            "baseName": "mobilePhone",
            "type":     "string"
        },
        {
            "name":     "address1",
            "baseName": "address1",
            "type":     "string"
        },
        {
            "name":     "address2",
            "baseName": "address2",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "postalCode",
            "baseName": "postalCode",
            "type":     "string"
        },
        {
            "name":     "country",
            "baseName": "country",
            "type":     "string"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "communicationTypes",
            "baseName": "communicationTypes",
            "type":     "TCSCommunicationType"
        },
    ];

    static getAttributeTypeMap() {
        return EnrollmentDemographicsModel.attributeTypeMap;
    }
}
