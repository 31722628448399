// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ClaimStagingAnsi837MedicalClaimServiceLineCobAdjustmentModel } from './claimStagingAnsi837MedicalClaimServiceLineCobAdjustmentModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Medical Claim service model describes any service that a provider performed for a patient.
*/
export class ClaimStagingAnsi837MedicalClaimServiceLineModel {
    /**
    * @description Date of the service
    */
    'dateOfService'?: Moment;
    /**
    * @description Revenue code
    */
    'revenueCode'?: string | null;
    /**
    * @description Number of units for drug
    */
    'units'?: number;
    /**
    * @description Charged amount is the total billed amount for each service line.  Also known as \\\"Billed Amount\\\" or \\\"Total Charge\\\" TC.Enterprise.Claim.Service.Public.Models.Staging.Ansi837MedicalClaimModel.TotalCharge
    */
    'charge'?: number;
    /**
    * @description Page number (consecutive number of the page in the document)
    */
    'pageNumber'?: number;
    /**
    * @description Line number (consecutive number of the service within medical claim)
    */
    'lineNumber'?: number;
    /**
    * @description Procedure Type ID known as Service Code aka JCODE
    */
    'procedureCode'?: string | null;
    /**
    * @description National Drug Code (unique 11-digit number)
    */
    'ndcCode'?: string | null;
    /**
    * @description The type of measurement for units field
    */
    'measurementType'?: string | null;
    /**
    * @description Approved amount
    */
    'approvedAmount'?: number;
    /**
    * @description CoInsurance amount
    */
    'coInsuranceAmount'?: number;
    /**
    * @description Deductible amount
    */
    'deductibleAmount'?: number;
    /**
    * @description Copay amount
    */
    'copayAmount'?: number;
    /**
    * @description Insurance paid amount
    */
    'insurancePaidAmount'?: number;
    /**
    * @description List of COB other adjustments  TC.Enterprise.Claim.Service.Public.Models.Staging.Ansi837MedicalClaimServiceLineCobAdjustmentModel
    */
    'cobAdjustments'?: Array<ClaimStagingAnsi837MedicalClaimServiceLineCobAdjustmentModel> | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimStagingAnsi837MedicalClaimServiceLineModel>> = [
        {
            "name":     "dateOfService",
            "baseName": "dateOfService",
            "type":     "Date"
        },
        {
            "name":     "revenueCode",
            "baseName": "revenueCode",
            "type":     "string"
        },
        {
            "name":     "units",
            "baseName": "units",
            "type":     "number"
        },
        {
            "name":     "charge",
            "baseName": "charge",
            "type":     "number"
        },
        {
            "name":     "pageNumber",
            "baseName": "pageNumber",
            "type":     "number"
        },
        {
            "name":     "lineNumber",
            "baseName": "lineNumber",
            "type":     "number"
        },
        {
            "name":     "procedureCode",
            "baseName": "procedureCode",
            "type":     "string"
        },
        {
            "name":     "ndcCode",
            "baseName": "ndcCode",
            "type":     "string"
        },
        {
            "name":     "measurementType",
            "baseName": "measurementType",
            "type":     "string"
        },
        {
            "name":     "approvedAmount",
            "baseName": "approvedAmount",
            "type":     "number"
        },
        {
            "name":     "coInsuranceAmount",
            "baseName": "coInsuranceAmount",
            "type":     "number"
        },
        {
            "name":     "deductibleAmount",
            "baseName": "deductibleAmount",
            "type":     "number"
        },
        {
            "name":     "copayAmount",
            "baseName": "copayAmount",
            "type":     "number"
        },
        {
            "name":     "insurancePaidAmount",
            "baseName": "insurancePaidAmount",
            "type":     "number"
        },
        {
            "name":     "cobAdjustments",
            "baseName": "cobAdjustments",
            "type":     "Array<ClaimStagingAnsi837MedicalClaimServiceLineCobAdjustmentModel>"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimStagingAnsi837MedicalClaimServiceLineModel.attributeTypeMap;
    }
}
