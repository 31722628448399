// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ClaimAdjudicationResponseLineItem } from './claimAdjudicationResponseLineItem.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ClaimAdjudicationResponse {
    'wasPreviouslyAdjudicated'?: boolean;
    'lineItems'?: Array<ClaimAdjudicationResponseLineItem>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimAdjudicationResponse>> = [
        {
            "name":     "wasPreviouslyAdjudicated",
            "baseName": "wasPreviouslyAdjudicated",
            "type":     "boolean"
        },
        {
            "name":     "lineItems",
            "baseName": "lineItems",
            "type":     "Array<ClaimAdjudicationResponseLineItem>"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimAdjudicationResponse.attributeTypeMap;
    }
}
