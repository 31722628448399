// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as enums from '@trialcard/enums';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).  https://openapi-generator.tech  Do not edit the class manually.
*/

export enum CaseLocationPurposeType {
    UNKNOWN = 0,
    CORPORATEHEADQUARTERS = 1,
    SALESOFFICE = 2,
    RESIDENTIALADDRESS = 3,
    WORKADDRESS = 4,
    SEASONALADDRESS = 5,
    MAILINGADDRESS = 6,
    BILLINGADDRESS = 7,
    SHIPPINGADDRESS = 8
}
