// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PorticoDataGroupModel } from './porticoDataGroupModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PorticoPortalDataGroupModel {
    'id'?: string;
    'portalId'?: string;
    'dataGroupId'?: string;
    'priority'?: number;
    'createdBy'?: number;
    'createdOn'?: Moment;
    'dataGroup'?: PorticoDataGroupModel | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PorticoPortalDataGroupModel>> = [
        {
            "name":     "id",
            "baseName": "id",
            "type":     "string"
        },
        {
            "name":     "portalId",
            "baseName": "portalId",
            "type":     "string"
        },
        {
            "name":     "dataGroupId",
            "baseName": "dataGroupId",
            "type":     "string"
        },
        {
            "name":     "priority",
            "baseName": "priority",
            "type":     "number"
        },
        {
            "name":     "createdBy",
            "baseName": "createdBy",
            "type":     "number"
        },
        {
            "name":     "createdOn",
            "baseName": "createdOn",
            "type":     "Date"
        },
        {
            "name":     "dataGroup",
            "baseName": "dataGroup",
            "type":     "PorticoDataGroupModel"
        },
    ];

    static getAttributeTypeMap() {
        return PorticoPortalDataGroupModel.attributeTypeMap;
    }
}
