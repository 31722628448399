// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class NoteConditionRelationshipModel {
    'conditionTypeId'?: number;
    'conditionCode'?: string;
    'conditionDescription'?: string;
    'conditionCompletedDate'?: Moment;
    'conditionCompletedBy'?: number;
    'conditionCompleted'?: boolean;
    'conditionCreatedDate'?: Moment;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof NoteConditionRelationshipModel>> = [
        {
            "name":     "conditionTypeId",
            "baseName": "conditionTypeId",
            "type":     "number"
        },
        {
            "name":     "conditionCode",
            "baseName": "conditionCode",
            "type":     "string"
        },
        {
            "name":     "conditionDescription",
            "baseName": "conditionDescription",
            "type":     "string"
        },
        {
            "name":     "conditionCompletedDate",
            "baseName": "conditionCompletedDate",
            "type":     "Date"
        },
        {
            "name":     "conditionCompletedBy",
            "baseName": "conditionCompletedBy",
            "type":     "number"
        },
        {
            "name":     "conditionCompleted",
            "baseName": "conditionCompleted",
            "type":     "boolean"
        },
        {
            "name":     "conditionCreatedDate",
            "baseName": "conditionCreatedDate",
            "type":     "Date"
        },
    ];

    static getAttributeTypeMap() {
        return NoteConditionRelationshipModel.attributeTypeMap;
    }
}
