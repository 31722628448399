// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyBiFormulary {
    'drug'?: string | null;
    'exceptionRequired'?: string | null;
    'exceptionStatus'?: string | null;
    'obtainFormularyExceptionDocument'?: string | null;
    'obtainFormularyExceptionFax'?: string | null;
    'obtainFormularyExceptionNotificationMethod'?: string | null;
    'obtainFormularyExceptionOrg'?: string | null;
    'obtainFormularyExceptionPhone'?: string | null;
    'obtainFormularyExceptionTurnaroundTime'?: string | null;
    'obtainFormularyExceptionWebsite'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyBiFormulary>> = [
        {
            "name":     "drug",
            "baseName": "drug",
            "type":     "string"
        },
        {
            "name":     "exceptionRequired",
            "baseName": "exceptionRequired",
            "type":     "string"
        },
        {
            "name":     "exceptionStatus",
            "baseName": "exceptionStatus",
            "type":     "string"
        },
        {
            "name":     "obtainFormularyExceptionDocument",
            "baseName": "obtainFormularyExceptionDocument",
            "type":     "string"
        },
        {
            "name":     "obtainFormularyExceptionFax",
            "baseName": "obtainFormularyExceptionFax",
            "type":     "string"
        },
        {
            "name":     "obtainFormularyExceptionNotificationMethod",
            "baseName": "obtainFormularyExceptionNotificationMethod",
            "type":     "string"
        },
        {
            "name":     "obtainFormularyExceptionOrg",
            "baseName": "obtainFormularyExceptionOrg",
            "type":     "string"
        },
        {
            "name":     "obtainFormularyExceptionPhone",
            "baseName": "obtainFormularyExceptionPhone",
            "type":     "string"
        },
        {
            "name":     "obtainFormularyExceptionTurnaroundTime",
            "baseName": "obtainFormularyExceptionTurnaroundTime",
            "type":     "string"
        },
        {
            "name":     "obtainFormularyExceptionWebsite",
            "baseName": "obtainFormularyExceptionWebsite",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyBiFormulary.attributeTypeMap;
    }
}
