// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BasicEnrollmentAddress } from './basicEnrollmentAddress.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class BasicEnrollmentPharmacy {
    'name'?: string;
    'phoneNumber'?: string;
    'phoneNumberExtension'?: string;
    'faxNumber'?: string;
    'faxNumberExtension'?: string;
    'nabp'?: string;
    'npi'?: string;
    'address'?: BasicEnrollmentAddress | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof BasicEnrollmentPharmacy>> = [
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "phoneNumber",
            "baseName": "phoneNumber",
            "type":     "string"
        },
        {
            "name":     "phoneNumberExtension",
            "baseName": "phoneNumberExtension",
            "type":     "string"
        },
        {
            "name":     "faxNumber",
            "baseName": "faxNumber",
            "type":     "string"
        },
        {
            "name":     "faxNumberExtension",
            "baseName": "faxNumberExtension",
            "type":     "string"
        },
        {
            "name":     "nabp",
            "baseName": "nabp",
            "type":     "string"
        },
        {
            "name":     "npi",
            "baseName": "npi",
            "type":     "string"
        },
        {
            "name":     "address",
            "baseName": "address",
            "type":     "BasicEnrollmentAddress"
        },
    ];

    static getAttributeTypeMap() {
        return BasicEnrollmentPharmacy.attributeTypeMap;
    }
}
