// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PorticoDataValueModel } from './porticoDataValueModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PorticoDataRowModel {
    'id'?: string;
    'dataTableId'?: string;
    'dataGroupId'?: string;
    'key'?: string;
    'createdBy'?: number;
    'createdOn'?: Moment;
    'dataValues': Array<PorticoDataValueModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PorticoDataRowModel>> = [
        {
            "name":     "id",
            "baseName": "id",
            "type":     "string"
        },
        {
            "name":     "dataTableId",
            "baseName": "dataTableId",
            "type":     "string"
        },
        {
            "name":     "dataGroupId",
            "baseName": "dataGroupId",
            "type":     "string"
        },
        {
            "name":     "key",
            "baseName": "key",
            "type":     "string"
        },
        {
            "name":     "createdBy",
            "baseName": "createdBy",
            "type":     "number"
        },
        {
            "name":     "createdOn",
            "baseName": "createdOn",
            "type":     "Date"
        },
        {
            "name":     "dataValues",
            "baseName": "dataValues",
            "type":     "Array<PorticoDataValueModel>"
        },
    ];

    static getAttributeTypeMap() {
        return PorticoDataRowModel.attributeTypeMap;
    }
}
