// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { LocationPurposeType } from './locationPurposeType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Account Address.
*/
export class AccountAddressModel {
    /**
    * @description Account Address ID.
    */
    'id'?: number;
    /**
    * @description Receiver\\\'s First Name.
    */
    'receiverFirstName'?: string | null;
    /**
    * @description Receiver\\\'s Last Name.
    */
    'receiverLastName'?: string | null;
    'addressType'?: LocationPurposeType;
    'addressOne'?: string | null;
    'addressTwo'?: string | null;
    'city'?: string | null;
    'state'?: string | null;
    'zip'?: string | null;
    'country'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof AccountAddressModel>> = [
        {
            "name":     "id",
            "baseName": "id",
            "type":     "number"
        },
        {
            "name":     "receiverFirstName",
            "baseName": "receiverFirstName",
            "type":     "string"
        },
        {
            "name":     "receiverLastName",
            "baseName": "receiverLastName",
            "type":     "string"
        },
        {
            "name":     "addressType",
            "baseName": "addressType",
            "type":     "LocationPurposeType"
        },
        {
            "name":     "addressOne",
            "baseName": "addressOne",
            "type":     "string"
        },
        {
            "name":     "addressTwo",
            "baseName": "addressTwo",
            "type":     "string"
        },
        {
            "name":     "city",
            "baseName": "city",
            "type":     "string"
        },
        {
            "name":     "state",
            "baseName": "state",
            "type":     "string"
        },
        {
            "name":     "zip",
            "baseName": "zip",
            "type":     "string"
        },
        {
            "name":     "country",
            "baseName": "country",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return AccountAddressModel.attributeTypeMap;
    }
}
