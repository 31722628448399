// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiHomeHealth {
    'isDeductibleExists'?: string;
    'totalDeductible'?: number;
    'deductibleMetAmount'?: number;
    'oopMaximum'?: number;
    'isOopMaximumExists'?: string;
    'oopMaximumMetAmount'?: number;
    'benefitsCoinsurance'?: number;
    'benefitsCopay'?: number;
    'benefitsDeductibleApplies'?: string;
    'isCovered'?: string;
    'obtainPriorAuthFax'?: string;
    'obtainPriorAuthPhone'?: string;
    'isPriorAuthRequired'?: string;
    'providerName'?: string;
    'providerPhone'?: string;
    'services'?: string;
    'isBoundRequired'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiHomeHealth>> = [
        {
            "name":     "isDeductibleExists",
            "baseName": "isDeductibleExists",
            "type":     "string"
        },
        {
            "name":     "totalDeductible",
            "baseName": "totalDeductible",
            "type":     "number"
        },
        {
            "name":     "deductibleMetAmount",
            "baseName": "deductibleMetAmount",
            "type":     "number"
        },
        {
            "name":     "oopMaximum",
            "baseName": "oopMaximum",
            "type":     "number"
        },
        {
            "name":     "isOopMaximumExists",
            "baseName": "isOopMaximumExists",
            "type":     "string"
        },
        {
            "name":     "oopMaximumMetAmount",
            "baseName": "oopMaximumMetAmount",
            "type":     "number"
        },
        {
            "name":     "benefitsCoinsurance",
            "baseName": "benefitsCoinsurance",
            "type":     "number"
        },
        {
            "name":     "benefitsCopay",
            "baseName": "benefitsCopay",
            "type":     "number"
        },
        {
            "name":     "benefitsDeductibleApplies",
            "baseName": "benefitsDeductibleApplies",
            "type":     "string"
        },
        {
            "name":     "isCovered",
            "baseName": "isCovered",
            "type":     "string"
        },
        {
            "name":     "obtainPriorAuthFax",
            "baseName": "obtainPriorAuthFax",
            "type":     "string"
        },
        {
            "name":     "obtainPriorAuthPhone",
            "baseName": "obtainPriorAuthPhone",
            "type":     "string"
        },
        {
            "name":     "isPriorAuthRequired",
            "baseName": "isPriorAuthRequired",
            "type":     "string"
        },
        {
            "name":     "providerName",
            "baseName": "providerName",
            "type":     "string"
        },
        {
            "name":     "providerPhone",
            "baseName": "providerPhone",
            "type":     "string"
        },
        {
            "name":     "services",
            "baseName": "services",
            "type":     "string"
        },
        {
            "name":     "isBoundRequired",
            "baseName": "isBoundRequired",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiHomeHealth.attributeTypeMap;
    }
}
