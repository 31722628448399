// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BasicEnrollmentMedicalPayor } from './basicEnrollmentMedicalPayor.js';
import type { BasicEnrollmentPatient } from './basicEnrollmentPatient.js';
import type { BasicEnrollmentPaymentType } from './basicEnrollmentPaymentType.js';
import type { BasicEnrollmentPharmacy } from './basicEnrollmentPharmacy.js';
import type { BasicEnrollmentPharmacyPayor } from './basicEnrollmentPharmacyPayor.js';
import type { BasicEnrollmentPrescriber } from './basicEnrollmentPrescriber.js';
import type { BasicEnrollmentPrescription } from './basicEnrollmentPrescription.js';
import type { BasicEnrollmentSite } from './basicEnrollmentSite.js';
import type { BasicEnrollmentSurvey } from './basicEnrollmentSurvey.js';
import type { EnrollPatientAccountAttestationAttribute } from './enrollPatientAccountAttestationAttribute.js';
import type { EnrollPatientCaseInitiator } from './enrollPatientCaseInitiator.js';
import type { EnrollPatientCaseSource } from './enrollPatientCaseSource.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class BasicEnrollmentRequest {
    'patient': BasicEnrollmentPatient | null;
    'medicalPayors'?: Array<BasicEnrollmentMedicalPayor>;
    'pharmacyPayors'?: Array<BasicEnrollmentPharmacyPayor>;
    'pharmacies'?: Array<BasicEnrollmentPharmacy>;
    'prescribers'?: Array<BasicEnrollmentPrescriber>;
    'prescriptions'?: Array<BasicEnrollmentPrescription>;
    'sites'?: Array<BasicEnrollmentSite>;
    'surveys'?: Array<BasicEnrollmentSurvey>;
    'diagnosisCodes'?: Array<string>;
    'enrollmentPaymentType'?: BasicEnrollmentPaymentType;
    'thirdPartyCaseId'?: string;
    'thirdPartyEnrollmentId'?: string;
    'caseInitiator'?: EnrollPatientCaseInitiator;
    'caseSource'?: EnrollPatientCaseSource;
    'accountAttestations'?: Array<EnrollPatientAccountAttestationAttribute>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof BasicEnrollmentRequest>> = [
        {
            "name":     "patient",
            "baseName": "patient",
            "type":     "BasicEnrollmentPatient"
        },
        {
            "name":     "medicalPayors",
            "baseName": "medicalPayors",
            "type":     "Array<BasicEnrollmentMedicalPayor>"
        },
        {
            "name":     "pharmacyPayors",
            "baseName": "pharmacyPayors",
            "type":     "Array<BasicEnrollmentPharmacyPayor>"
        },
        {
            "name":     "pharmacies",
            "baseName": "pharmacies",
            "type":     "Array<BasicEnrollmentPharmacy>"
        },
        {
            "name":     "prescribers",
            "baseName": "prescribers",
            "type":     "Array<BasicEnrollmentPrescriber>"
        },
        {
            "name":     "prescriptions",
            "baseName": "prescriptions",
            "type":     "Array<BasicEnrollmentPrescription>"
        },
        {
            "name":     "sites",
            "baseName": "sites",
            "type":     "Array<BasicEnrollmentSite>"
        },
        {
            "name":     "surveys",
            "baseName": "surveys",
            "type":     "Array<BasicEnrollmentSurvey>"
        },
        {
            "name":     "diagnosisCodes",
            "baseName": "diagnosisCodes",
            "type":     "Array<string>"
        },
        {
            "name":     "enrollmentPaymentType",
            "baseName": "enrollmentPaymentType",
            "type":     "BasicEnrollmentPaymentType"
        },
        {
            "name":     "thirdPartyCaseId",
            "baseName": "thirdPartyCaseId",
            "type":     "string"
        },
        {
            "name":     "thirdPartyEnrollmentId",
            "baseName": "thirdPartyEnrollmentId",
            "type":     "string"
        },
        {
            "name":     "caseInitiator",
            "baseName": "caseInitiator",
            "type":     "EnrollPatientCaseInitiator"
        },
        {
            "name":     "caseSource",
            "baseName": "caseSource",
            "type":     "EnrollPatientCaseSource"
        },
        {
            "name":     "accountAttestations",
            "baseName": "accountAttestations",
            "type":     "Array<EnrollPatientAccountAttestationAttribute>"
        },
    ];

    static getAttributeTypeMap() {
        return BasicEnrollmentRequest.attributeTypeMap;
    }
}
