// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyBiDeductible {
    'appliedAmount'?: string | null;
    'includedInOop'?: string | null;
    'remainingAmount'?: string | null;
    'canUseCoPayAssistance'?: string | null;
    'fullAmountCoPayAssistanceApplies'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyBiDeductible>> = [
        {
            "name":     "appliedAmount",
            "baseName": "appliedAmount",
            "type":     "string"
        },
        {
            "name":     "includedInOop",
            "baseName": "includedInOop",
            "type":     "string"
        },
        {
            "name":     "remainingAmount",
            "baseName": "remainingAmount",
            "type":     "string"
        },
        {
            "name":     "canUseCoPayAssistance",
            "baseName": "canUseCoPayAssistance",
            "type":     "string"
        },
        {
            "name":     "fullAmountCoPayAssistanceApplies",
            "baseName": "fullAmountCoPayAssistanceApplies",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyBiDeductible.attributeTypeMap;
    }
}
