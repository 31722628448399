// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class UserMetadataModel {
    /**
     * @type { string }
     */
    [key: string]: string;

    static readonly additionalProperties = true;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<
        { name: string; baseName: string; type: string }
    > = [];

    static getAttributeTypeMap() {
        return UserMetadataModel.attributeTypeMap;
    }
}
