// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MangoHubVerifyPatientResponse {
    'isVerified': boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MangoHubVerifyPatientResponse>> = [
        {
            "name":     "isVerified",
            "baseName": "isVerified",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return MangoHubVerifyPatientResponse.attributeTypeMap;
    }
}
