// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as enums from '@trialcard/enums';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';


export enum HhsRace {
    White = 1,
    Black = 2,
    Asian = 3,
    NativeAmerican = 4,
    PacificIslander = 5,
    Multiple = 6,
    NoAnswer = 9
}
