// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ClaimAdjudicationPharmacyInsurance {
    'pbmName'?: string;
    'rxGroup'?: string;
    'rxMemberId'?: string;
    'rxPcn'?: string;
    'rxBin'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimAdjudicationPharmacyInsurance>> = [
        {
            "name":     "pbmName",
            "baseName": "pbmName",
            "type":     "string"
        },
        {
            "name":     "rxGroup",
            "baseName": "rxGroup",
            "type":     "string"
        },
        {
            "name":     "rxMemberId",
            "baseName": "rxMemberId",
            "type":     "string"
        },
        {
            "name":     "rxPcn",
            "baseName": "rxPcn",
            "type":     "string"
        },
        {
            "name":     "rxBin",
            "baseName": "rxBin",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimAdjudicationPharmacyInsurance.attributeTypeMap;
    }
}
