// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { LocationCorrection } from './locationCorrection.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class LocationAddressCorrectionModel {
    'correction'?: LocationCorrection;
    'description'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof LocationAddressCorrectionModel>> = [
        {
            "name":     "correction",
            "baseName": "correction",
            "type":     "LocationCorrection"
        },
        {
            "name":     "description",
            "baseName": "description",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return LocationAddressCorrectionModel.attributeTypeMap;
    }
}
