// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ExtEnrollmentHistoryModel } from './extEnrollmentHistoryModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ExtEnrollmentPatientLookupResultModel {
    'patientId'?: string;
    'memberNumber'?: string;
    'enrollments': Array<ExtEnrollmentHistoryModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtEnrollmentPatientLookupResultModel>> = [
        {
            "name":     "patientId",
            "baseName": "patientId",
            "type":     "string"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "enrollments",
            "baseName": "enrollments",
            "type":     "Array<ExtEnrollmentHistoryModel>"
        },
    ];

    static getAttributeTypeMap() {
        return ExtEnrollmentPatientLookupResultModel.attributeTypeMap;
    }
}
