// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BasicEnrollmentAddress } from './basicEnrollmentAddress.js';
import type { BasicEnrollmentPointOfContact } from './basicEnrollmentPointOfContact.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class BasicEnrollmentSite {
    'description'?: string;
    'phoneNumber'?: string;
    'phoneNumberExtension'?: string;
    'faxNumber'?: string;
    'faxNumberExtension'?: string;
    'pointOfContacts'?: Array<BasicEnrollmentPointOfContact>;
    'address'?: BasicEnrollmentAddress | null;
    'stateTaxId'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof BasicEnrollmentSite>> = [
        {
            "name":     "description",
            "baseName": "description",
            "type":     "string"
        },
        {
            "name":     "phoneNumber",
            "baseName": "phoneNumber",
            "type":     "string"
        },
        {
            "name":     "phoneNumberExtension",
            "baseName": "phoneNumberExtension",
            "type":     "string"
        },
        {
            "name":     "faxNumber",
            "baseName": "faxNumber",
            "type":     "string"
        },
        {
            "name":     "faxNumberExtension",
            "baseName": "faxNumberExtension",
            "type":     "string"
        },
        {
            "name":     "pointOfContacts",
            "baseName": "pointOfContacts",
            "type":     "Array<BasicEnrollmentPointOfContact>"
        },
        {
            "name":     "address",
            "baseName": "address",
            "type":     "BasicEnrollmentAddress"
        },
        {
            "name":     "stateTaxId",
            "baseName": "stateTaxId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return BasicEnrollmentSite.attributeTypeMap;
    }
}
