// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EnterpriseCoordinatorAccountServiceModelsAccountPayorRelationModel } from './enterpriseCoordinatorAccountServiceModelsAccountPayorRelationModel.js';
import type { EnterpriseCoordinatorAccountServiceModelsAccountRelationModel } from './enterpriseCoordinatorAccountServiceModelsAccountRelationModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EnterpriseCoordinatorSiteAccountUpsearchResponseModel {
    'partyId'?: number;
    'accountId'?: number;
    'prescribers'?: Array<EnterpriseCoordinatorAccountServiceModelsAccountRelationModel>;
    'sites'?: Array<EnterpriseCoordinatorAccountServiceModelsAccountRelationModel>;
    'pharmacies'?: Array<EnterpriseCoordinatorAccountServiceModelsAccountRelationModel>;
    'payors'?: Array<EnterpriseCoordinatorAccountServiceModelsAccountPayorRelationModel>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnterpriseCoordinatorSiteAccountUpsearchResponseModel>> = [
        {
            "name":     "partyId",
            "baseName": "partyId",
            "type":     "number"
        },
        {
            "name":     "accountId",
            "baseName": "accountId",
            "type":     "number"
        },
        {
            "name":     "prescribers",
            "baseName": "prescribers",
            "type":     "Array<EnterpriseCoordinatorAccountServiceModelsAccountRelationModel>"
        },
        {
            "name":     "sites",
            "baseName": "sites",
            "type":     "Array<EnterpriseCoordinatorAccountServiceModelsAccountRelationModel>"
        },
        {
            "name":     "pharmacies",
            "baseName": "pharmacies",
            "type":     "Array<EnterpriseCoordinatorAccountServiceModelsAccountRelationModel>"
        },
        {
            "name":     "payors",
            "baseName": "payors",
            "type":     "Array<EnterpriseCoordinatorAccountServiceModelsAccountPayorRelationModel>"
        },
    ];

    static getAttributeTypeMap() {
        return EnterpriseCoordinatorSiteAccountUpsearchResponseModel.attributeTypeMap;
    }
}
