// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiReferral {
    'effectiveDate'?: Moment;
    'number'?: string;
    'onFile'?: boolean;
    'recertDate'?: Moment;
    'required'?: boolean;
    'requirements'?: string;
    'visitsApproved'?: string;
    'visitsUsed'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiReferral>> = [
        {
            "name":     "effectiveDate",
            "baseName": "effectiveDate",
            "type":     "Date"
        },
        {
            "name":     "number",
            "baseName": "number",
            "type":     "string"
        },
        {
            "name":     "onFile",
            "baseName": "onFile",
            "type":     "boolean"
        },
        {
            "name":     "recertDate",
            "baseName": "recertDate",
            "type":     "Date"
        },
        {
            "name":     "required",
            "baseName": "required",
            "type":     "boolean"
        },
        {
            "name":     "requirements",
            "baseName": "requirements",
            "type":     "string"
        },
        {
            "name":     "visitsApproved",
            "baseName": "visitsApproved",
            "type":     "string"
        },
        {
            "name":     "visitsUsed",
            "baseName": "visitsUsed",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiReferral.attributeTypeMap;
    }
}
