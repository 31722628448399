// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PlanType } from './planType.js';
import type { RoleType } from './roleType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SimpleIntakePayorModel {
    'id'?: number;
    'bin'?: string;
    'dateOfBirth'?: Moment;
    'employer'?: string;
    'group'?: string;
    'name'?: string;
    'pcn'?: string;
    'personCode'?: string;
    'phone'?: string;
    'planType'?: PlanType;
    'planEffectiveDate'?: Moment;
    'planExpirationDate'?: Moment;
    'planTerminationDate'?: Moment;
    'policyHolderFirstName'?: string;
    'policyHolderLastName'?: string;
    'policyName'?: string;
    'policyNumber'?: string;
    'relationship'?: RoleType;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SimpleIntakePayorModel>> = [
        {
            "name":     "id",
            "baseName": "id",
            "type":     "number"
        },
        {
            "name":     "bin",
            "baseName": "bin",
            "type":     "string"
        },
        {
            "name":     "dateOfBirth",
            "baseName": "dateOfBirth",
            "type":     "Date"
        },
        {
            "name":     "employer",
            "baseName": "employer",
            "type":     "string"
        },
        {
            "name":     "group",
            "baseName": "group",
            "type":     "string"
        },
        {
            "name":     "name",
            "baseName": "name",
            "type":     "string"
        },
        {
            "name":     "pcn",
            "baseName": "pcn",
            "type":     "string"
        },
        {
            "name":     "personCode",
            "baseName": "personCode",
            "type":     "string"
        },
        {
            "name":     "phone",
            "baseName": "phone",
            "type":     "string"
        },
        {
            "name":     "planType",
            "baseName": "planType",
            "type":     "PlanType"
        },
        {
            "name":     "planEffectiveDate",
            "baseName": "planEffectiveDate",
            "type":     "Date"
        },
        {
            "name":     "planExpirationDate",
            "baseName": "planExpirationDate",
            "type":     "Date"
        },
        {
            "name":     "planTerminationDate",
            "baseName": "planTerminationDate",
            "type":     "Date"
        },
        {
            "name":     "policyHolderFirstName",
            "baseName": "policyHolderFirstName",
            "type":     "string"
        },
        {
            "name":     "policyHolderLastName",
            "baseName": "policyHolderLastName",
            "type":     "string"
        },
        {
            "name":     "policyName",
            "baseName": "policyName",
            "type":     "string"
        },
        {
            "name":     "policyNumber",
            "baseName": "policyNumber",
            "type":     "string"
        },
        {
            "name":     "relationship",
            "baseName": "relationship",
            "type":     "RoleType"
        },
    ];

    static getAttributeTypeMap() {
        return SimpleIntakePayorModel.attributeTypeMap;
    }
}
