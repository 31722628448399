// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SiteAccountGetCaseGetCaseRequestModel {
    'caseId': number;
    'includeDiagnoses'?: boolean;
    'includePayors'?: boolean;
    'includePharmacies'?: boolean;
    'includePrescribers'?: boolean;
    'includePrescriptions'?: boolean;
    'includeSites'?: boolean;
    'includeSurveys'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SiteAccountGetCaseGetCaseRequestModel>> = [
        {
            "name":     "caseId",
            "baseName": "caseId",
            "type":     "number"
        },
        {
            "name":     "includeDiagnoses",
            "baseName": "includeDiagnoses",
            "type":     "boolean"
        },
        {
            "name":     "includePayors",
            "baseName": "includePayors",
            "type":     "boolean"
        },
        {
            "name":     "includePharmacies",
            "baseName": "includePharmacies",
            "type":     "boolean"
        },
        {
            "name":     "includePrescribers",
            "baseName": "includePrescribers",
            "type":     "boolean"
        },
        {
            "name":     "includePrescriptions",
            "baseName": "includePrescriptions",
            "type":     "boolean"
        },
        {
            "name":     "includeSites",
            "baseName": "includeSites",
            "type":     "boolean"
        },
        {
            "name":     "includeSurveys",
            "baseName": "includeSurveys",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return SiteAccountGetCaseGetCaseRequestModel.attributeTypeMap;
    }
}
