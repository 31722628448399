// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PrescriberLicenseType } from './prescriberLicenseType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PrescriberLicenseUpsearchModel {
    'prescriberLicenseId'?: number;
    'licenseType'?: PrescriberLicenseType;
    'licenseNumber'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PrescriberLicenseUpsearchModel>> = [
        {
            "name":     "prescriberLicenseId",
            "baseName": "prescriberLicenseId",
            "type":     "number"
        },
        {
            "name":     "licenseType",
            "baseName": "licenseType",
            "type":     "PrescriberLicenseType"
        },
        {
            "name":     "licenseNumber",
            "baseName": "licenseNumber",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return PrescriberLicenseUpsearchModel.attributeTypeMap;
    }
}
