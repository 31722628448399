// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AccountExternalIdType } from './accountExternalIdType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class GetEnrollmentByExternalIdRequestModel {
    'externalId': string;
    'accountExternalIdType': AccountExternalIdType;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof GetEnrollmentByExternalIdRequestModel>> = [
        {
            "name":     "externalId",
            "baseName": "externalId",
            "type":     "string"
        },
        {
            "name":     "accountExternalIdType",
            "baseName": "accountExternalIdType",
            "type":     "AccountExternalIdType"
        },
    ];

    static getAttributeTypeMap() {
        return GetEnrollmentByExternalIdRequestModel.attributeTypeMap;
    }
}
