// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PharmacyCardFinderRequestSearchBase } from './pharmacyCardFinderRequestSearchBase.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class PharmacyCardFinderRequestSearchResponse {
    'requests'?: Array<PharmacyCardFinderRequestSearchBase>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof PharmacyCardFinderRequestSearchResponse>> = [
        {
            "name":     "requests",
            "baseName": "requests",
            "type":     "Array<PharmacyCardFinderRequestSearchBase>"
        },
    ];

    static getAttributeTypeMap() {
        return PharmacyCardFinderRequestSearchResponse.attributeTypeMap;
    }
}
