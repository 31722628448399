// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { UserMetadataModel } from './userMetadataModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class UsersInternalRequestModel {
    'email'?: string;
    'password'?: string;
    'firstName'?: string;
    'lastName'?: string;
    'pictureUrl'?: string;
    'phoneNumber'?: string;
    'blocked'?: boolean;
    'emailVerified'?: boolean;
    'phoneVerified'?: boolean;
    'verifyEmail'?: boolean;
    'linkedAccount'?: number;
    'resetPasswordEmail'?: boolean;
    'userMetadata'?: UserMetadataModel | null;
    'roles'?: Array<string>;
    'portalUserDatabase'?: string;
    'portalClientId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof UsersInternalRequestModel>> = [
        {
            "name":     "email",
            "baseName": "email",
            "type":     "string"
        },
        {
            "name":     "password",
            "baseName": "password",
            "type":     "string"
        },
        {
            "name":     "firstName",
            "baseName": "firstName",
            "type":     "string"
        },
        {
            "name":     "lastName",
            "baseName": "lastName",
            "type":     "string"
        },
        {
            "name":     "pictureUrl",
            "baseName": "pictureUrl",
            "type":     "string"
        },
        {
            "name":     "phoneNumber",
            "baseName": "phoneNumber",
            "type":     "string"
        },
        {
            "name":     "blocked",
            "baseName": "blocked",
            "type":     "boolean"
        },
        {
            "name":     "emailVerified",
            "baseName": "emailVerified",
            "type":     "boolean"
        },
        {
            "name":     "phoneVerified",
            "baseName": "phoneVerified",
            "type":     "boolean"
        },
        {
            "name":     "verifyEmail",
            "baseName": "verifyEmail",
            "type":     "boolean"
        },
        {
            "name":     "linkedAccount",
            "baseName": "linkedAccount",
            "type":     "number"
        },
        {
            "name":     "resetPasswordEmail",
            "baseName": "resetPasswordEmail",
            "type":     "boolean"
        },
        {
            "name":     "userMetadata",
            "baseName": "userMetadata",
            "type":     "UserMetadataModel"
        },
        {
            "name":     "roles",
            "baseName": "roles",
            "type":     "Array<string>"
        },
        {
            "name":     "portalUserDatabase",
            "baseName": "portalUserDatabase",
            "type":     "string"
        },
        {
            "name":     "portalClientId",
            "baseName": "portalClientId",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return UsersInternalRequestModel.attributeTypeMap;
    }
}
