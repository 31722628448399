// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ClaimAdjudicationResponseLineItemAdjudicationCode } from './claimAdjudicationResponseLineItemAdjudicationCode.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ClaimAdjudicationResponseLineItem {
    'amountPaid'?: number;
    'claimLineId'?: number;
    'rejectionCodes'?: Array<string>;
    'adjudicationCodes'?: Array<ClaimAdjudicationResponseLineItemAdjudicationCode>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ClaimAdjudicationResponseLineItem>> = [
        {
            "name":     "amountPaid",
            "baseName": "amountPaid",
            "type":     "number"
        },
        {
            "name":     "claimLineId",
            "baseName": "claimLineId",
            "type":     "number"
        },
        {
            "name":     "rejectionCodes",
            "baseName": "rejectionCodes",
            "type":     "Array<string>"
        },
        {
            "name":     "adjudicationCodes",
            "baseName": "adjudicationCodes",
            "type":     "Array<ClaimAdjudicationResponseLineItemAdjudicationCode>"
        },
    ];

    static getAttributeTypeMap() {
        return ClaimAdjudicationResponseLineItem.attributeTypeMap;
    }
}
