// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

/**
* @description Additional payment information
*/
export class DigitalpaymentTransactionReference {
    'transactionKeyId'?: string | null;
    'transactionKeyValue'?: string | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof DigitalpaymentTransactionReference>> = [
        {
            "name":     "transactionKeyId",
            "baseName": "transactionKeyId",
            "type":     "string"
        },
        {
            "name":     "transactionKeyValue",
            "baseName": "transactionKeyValue",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return DigitalpaymentTransactionReference.attributeTypeMap;
    }
}
