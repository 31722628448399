// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { EmailEmailContact } from './emailEmailContact.js';
import type { MessageContentType } from './messageContentType.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EmailSendEmailSingle {
    'contact'?: EmailEmailContact | null;
    'memberNumber'?: string;
    'parameters'?: string;
    'fromName'?: string;
    'fromAddress'?: string;
    'emailName'?: string;
    'tag'?: string;
    'deliveryType'?: string;
    'messageContentType'?: MessageContentType;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EmailSendEmailSingle>> = [
        {
            "name":     "contact",
            "baseName": "contact",
            "type":     "EmailEmailContact"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "parameters",
            "baseName": "parameters",
            "type":     "string"
        },
        {
            "name":     "fromName",
            "baseName": "fromName",
            "type":     "string"
        },
        {
            "name":     "fromAddress",
            "baseName": "fromAddress",
            "type":     "string"
        },
        {
            "name":     "emailName",
            "baseName": "emailName",
            "type":     "string"
        },
        {
            "name":     "tag",
            "baseName": "tag",
            "type":     "string"
        },
        {
            "name":     "deliveryType",
            "baseName": "deliveryType",
            "type":     "string"
        },
        {
            "name":     "messageContentType",
            "baseName": "messageContentType",
            "type":     "MessageContentType"
        },
    ];

    static getAttributeTypeMap() {
        return EmailSendEmailSingle.attributeTypeMap;
    }
}
