// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SimpleIntakeOptInsModel {
    'email'?: boolean;
    'mail'?: boolean;
    'phone'?: boolean;
    'text'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SimpleIntakeOptInsModel>> = [
        {
            "name":     "email",
            "baseName": "email",
            "type":     "boolean"
        },
        {
            "name":     "mail",
            "baseName": "mail",
            "type":     "boolean"
        },
        {
            "name":     "phone",
            "baseName": "phone",
            "type":     "boolean"
        },
        {
            "name":     "text",
            "baseName": "text",
            "type":     "boolean"
        },
    ];

    static getAttributeTypeMap() {
        return SimpleIntakeOptInsModel.attributeTypeMap;
    }
}
