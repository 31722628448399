// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class CreateCardResponseModel {
    'pcn'?: string;
    'bin'?: string;
    'cardActivationDate'?: Moment;
    'cardEndDate'?: Moment;
    'sponsorNumber'?: string;
    'memberNumber'?: string;
    'groupNumber'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof CreateCardResponseModel>> = [
        {
            "name":     "pcn",
            "baseName": "pcn",
            "type":     "string"
        },
        {
            "name":     "bin",
            "baseName": "bin",
            "type":     "string"
        },
        {
            "name":     "cardActivationDate",
            "baseName": "cardActivationDate",
            "type":     "Date"
        },
        {
            "name":     "cardEndDate",
            "baseName": "cardEndDate",
            "type":     "Date"
        },
        {
            "name":     "sponsorNumber",
            "baseName": "sponsorNumber",
            "type":     "string"
        },
        {
            "name":     "memberNumber",
            "baseName": "memberNumber",
            "type":     "string"
        },
        {
            "name":     "groupNumber",
            "baseName": "groupNumber",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return CreateCardResponseModel.attributeTypeMap;
    }
}
