// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class DebitCardV2SecuredGetDebitCardDetailResponseModel {
    'vdcNumber'?: string;
    'vdcCvc'?: string;
    'vdcZip'?: string;
    'last4'?: string;
    'effectiveDate'?: Moment;
    'expirationDate'?: Moment;
    'balance'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof DebitCardV2SecuredGetDebitCardDetailResponseModel>> = [
        {
            "name":     "vdcNumber",
            "baseName": "vdcNumber",
            "type":     "string"
        },
        {
            "name":     "vdcCvc",
            "baseName": "vdcCvc",
            "type":     "string"
        },
        {
            "name":     "vdcZip",
            "baseName": "vdcZip",
            "type":     "string"
        },
        {
            "name":     "last4",
            "baseName": "last4",
            "type":     "string"
        },
        {
            "name":     "effectiveDate",
            "baseName": "effectiveDate",
            "type":     "Date"
        },
        {
            "name":     "expirationDate",
            "baseName": "expirationDate",
            "type":     "Date"
        },
        {
            "name":     "balance",
            "baseName": "balance",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return DebitCardV2SecuredGetDebitCardDetailResponseModel.attributeTypeMap;
    }
}
