// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { MangoHubGetPatientInfo } from './mangoHubGetPatientInfo.js';
import type { MangoHubGetPatientSummaryDiagnosis } from './mangoHubGetPatientSummaryDiagnosis.js';
import type { MangoHubGetPatientSummaryMedicalPayor } from './mangoHubGetPatientSummaryMedicalPayor.js';
import type { MangoHubGetPatientSummaryPharmacy } from './mangoHubGetPatientSummaryPharmacy.js';
import type { MangoHubGetPatientSummaryPharmacyPayor } from './mangoHubGetPatientSummaryPharmacyPayor.js';
import type { MangoHubGetPatientSummaryPrescriber } from './mangoHubGetPatientSummaryPrescriber.js';
import type { MangoHubGetPatientSummaryPrescription } from './mangoHubGetPatientSummaryPrescription.js';
import type { MangoHubSelectedService } from './mangoHubSelectedService.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MangoHubGetPatientSummaryDetails {
    'isiLink': string;
    'selectedServices': Array<MangoHubSelectedService>;
    'prescriptions': Array<MangoHubGetPatientSummaryPrescription>;
    'indications': Array<MangoHubGetPatientSummaryDiagnosis>;
    'prescriber': MangoHubGetPatientSummaryPrescriber | null;
    'patientInfo': MangoHubGetPatientInfo | null;
    'medicalPayors': Array<MangoHubGetPatientSummaryMedicalPayor>;
    'pharmacyPayors': Array<MangoHubGetPatientSummaryPharmacyPayor>;
    'pharmacy': MangoHubGetPatientSummaryPharmacy | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MangoHubGetPatientSummaryDetails>> = [
        {
            "name":     "isiLink",
            "baseName": "isiLink",
            "type":     "string"
        },
        {
            "name":     "selectedServices",
            "baseName": "selectedServices",
            "type":     "Array<MangoHubSelectedService>"
        },
        {
            "name":     "prescriptions",
            "baseName": "prescriptions",
            "type":     "Array<MangoHubGetPatientSummaryPrescription>"
        },
        {
            "name":     "indications",
            "baseName": "indications",
            "type":     "Array<MangoHubGetPatientSummaryDiagnosis>"
        },
        {
            "name":     "prescriber",
            "baseName": "prescriber",
            "type":     "MangoHubGetPatientSummaryPrescriber"
        },
        {
            "name":     "patientInfo",
            "baseName": "patientInfo",
            "type":     "MangoHubGetPatientInfo"
        },
        {
            "name":     "medicalPayors",
            "baseName": "medicalPayors",
            "type":     "Array<MangoHubGetPatientSummaryMedicalPayor>"
        },
        {
            "name":     "pharmacyPayors",
            "baseName": "pharmacyPayors",
            "type":     "Array<MangoHubGetPatientSummaryPharmacyPayor>"
        },
        {
            "name":     "pharmacy",
            "baseName": "pharmacy",
            "type":     "MangoHubGetPatientSummaryPharmacy"
        },
    ];

    static getAttributeTypeMap() {
        return MangoHubGetPatientSummaryDetails.attributeTypeMap;
    }
}
