// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ExtEnrollmentAlternateCardId } from './extEnrollmentAlternateCardId.js';
import type { ExtEnrollmentDocumentModel } from './extEnrollmentDocumentModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ExtEnrollmentHybridEnrollmentHistoryModel {
    'bin'?: string;
    'sponsorNumber'?: string;
    'groupNumber'?: string;
    'cardAlternateIDs': Array<ExtEnrollmentAlternateCardId>;
    'documents': Array<ExtEnrollmentDocumentModel>;
    'enrollmentId'?: string;
    'claimEligibilityDate'?: Moment;
    'enrollmentStartDate'?: Moment;
    'enrollmentEndDate'?: Moment;
    'enrollmentStatus'?: string;
    'totalBenefitAmount'?: number;
    'remainingBenefitAmount'?: number;
    'totalPaidAmount'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtEnrollmentHybridEnrollmentHistoryModel>> = [
        {
            "name":     "bin",
            "baseName": "bin",
            "type":     "string"
        },
        {
            "name":     "sponsorNumber",
            "baseName": "sponsorNumber",
            "type":     "string"
        },
        {
            "name":     "groupNumber",
            "baseName": "groupNumber",
            "type":     "string"
        },
        {
            "name":     "cardAlternateIDs",
            "baseName": "cardAlternateIDs",
            "type":     "Array<ExtEnrollmentAlternateCardId>"
        },
        {
            "name":     "documents",
            "baseName": "documents",
            "type":     "Array<ExtEnrollmentDocumentModel>"
        },
        {
            "name":     "enrollmentId",
            "baseName": "enrollmentId",
            "type":     "string"
        },
        {
            "name":     "claimEligibilityDate",
            "baseName": "claimEligibilityDate",
            "type":     "Date"
        },
        {
            "name":     "enrollmentStartDate",
            "baseName": "enrollmentStartDate",
            "type":     "Date"
        },
        {
            "name":     "enrollmentEndDate",
            "baseName": "enrollmentEndDate",
            "type":     "Date"
        },
        {
            "name":     "enrollmentStatus",
            "baseName": "enrollmentStatus",
            "type":     "string"
        },
        {
            "name":     "totalBenefitAmount",
            "baseName": "totalBenefitAmount",
            "type":     "number"
        },
        {
            "name":     "remainingBenefitAmount",
            "baseName": "remainingBenefitAmount",
            "type":     "number"
        },
        {
            "name":     "totalPaidAmount",
            "baseName": "totalPaidAmount",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return ExtEnrollmentHybridEnrollmentHistoryModel.attributeTypeMap;
    }
}
