// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ExtEnrollmentClientIdentifiersModel } from './extEnrollmentClientIdentifiersModel.js';
import type { ExtEnrollmentDemographicsModel } from './extEnrollmentDemographicsModel.js';
import type { ExtEnrollmentInsuranceVerificationModel } from './extEnrollmentInsuranceVerificationModel.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class ExtEnrollmentUpdateRequestModel {
    'insuranceVerification'?: ExtEnrollmentInsuranceVerificationModel | null;
    'demographics'?: ExtEnrollmentDemographicsModel | null;
    'clientIdentifiers'?: ExtEnrollmentClientIdentifiersModel | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof ExtEnrollmentUpdateRequestModel>> = [
        {
            "name":     "insuranceVerification",
            "baseName": "insuranceVerification",
            "type":     "ExtEnrollmentInsuranceVerificationModel"
        },
        {
            "name":     "demographics",
            "baseName": "demographics",
            "type":     "ExtEnrollmentDemographicsModel"
        },
        {
            "name":     "clientIdentifiers",
            "baseName": "clientIdentifiers",
            "type":     "ExtEnrollmentClientIdentifiersModel"
        },
    ];

    static getAttributeTypeMap() {
        return ExtEnrollmentUpdateRequestModel.attributeTypeMap;
    }
}
