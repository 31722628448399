// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class MedicalBiCptPriorAuth {
    'priorAuthSubmissionMethod'?: string;
    'priorAuthSubmissionMethods'?: Array<string>;
    'priorAuthNotificationMethod'?: string;
    'priorAuthNotificationMethods'?: Array<string>;
    'obtainPriorAuthFormLink'?: string;
    'obtainPriorAuthOrg'?: string;
    'obtainPriorAuthPhone'?: string;
    'obtainPriorAuthWebsite'?: string;
    'obtainPriorAuthFax'?: string;
    'obtainPriorAuthAddress'?: string;
    'obtainPriorAuthRequirements'?: Array<string>;
    'priorAuthTurnaroundTime'?: string;
    'priorAuthApprovedQuantity'?: string;
    'priorAuthApprovedQuantityUsed'?: string;
    'priorAuthApprovedTreatments'?: string;
    'priorAuthApprovedTreatmentsUsed'?: string;
    'priorAuthStartDate'?: string;
    'priorAuthEndDate'?: string;
    'priorAuthApprovalNumber'?: string;
    'predeterminationNumber'?: string;
    'predeterminationStartDate'?: string;
    'predeterminationEndDate'?: string;
    'predeterminationApprovedQuantity'?: string;
    'predeterminationApprovedQuantityUsed'?: string;
    'obtainPreDeterminationOrg'?: string;
    'obtainPredeterminationFax'?: string;
    'obtainPredeterminationPhone'?: string;
    'obtainPredeterminationWebsite'?: string;
    'predeterminationTurnaroundTime'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof MedicalBiCptPriorAuth>> = [
        {
            "name":     "priorAuthSubmissionMethod",
            "baseName": "priorAuthSubmissionMethod",
            "type":     "string"
        },
        {
            "name":     "priorAuthSubmissionMethods",
            "baseName": "priorAuthSubmissionMethods",
            "type":     "Array<string>"
        },
        {
            "name":     "priorAuthNotificationMethod",
            "baseName": "priorAuthNotificationMethod",
            "type":     "string"
        },
        {
            "name":     "priorAuthNotificationMethods",
            "baseName": "priorAuthNotificationMethods",
            "type":     "Array<string>"
        },
        {
            "name":     "obtainPriorAuthFormLink",
            "baseName": "obtainPriorAuthFormLink",
            "type":     "string"
        },
        {
            "name":     "obtainPriorAuthOrg",
            "baseName": "obtainPriorAuthOrg",
            "type":     "string"
        },
        {
            "name":     "obtainPriorAuthPhone",
            "baseName": "obtainPriorAuthPhone",
            "type":     "string"
        },
        {
            "name":     "obtainPriorAuthWebsite",
            "baseName": "obtainPriorAuthWebsite",
            "type":     "string"
        },
        {
            "name":     "obtainPriorAuthFax",
            "baseName": "obtainPriorAuthFax",
            "type":     "string"
        },
        {
            "name":     "obtainPriorAuthAddress",
            "baseName": "obtainPriorAuthAddress",
            "type":     "string"
        },
        {
            "name":     "obtainPriorAuthRequirements",
            "baseName": "obtainPriorAuthRequirements",
            "type":     "Array<string>"
        },
        {
            "name":     "priorAuthTurnaroundTime",
            "baseName": "priorAuthTurnaroundTime",
            "type":     "string"
        },
        {
            "name":     "priorAuthApprovedQuantity",
            "baseName": "priorAuthApprovedQuantity",
            "type":     "string"
        },
        {
            "name":     "priorAuthApprovedQuantityUsed",
            "baseName": "priorAuthApprovedQuantityUsed",
            "type":     "string"
        },
        {
            "name":     "priorAuthApprovedTreatments",
            "baseName": "priorAuthApprovedTreatments",
            "type":     "string"
        },
        {
            "name":     "priorAuthApprovedTreatmentsUsed",
            "baseName": "priorAuthApprovedTreatmentsUsed",
            "type":     "string"
        },
        {
            "name":     "priorAuthStartDate",
            "baseName": "priorAuthStartDate",
            "type":     "string"
        },
        {
            "name":     "priorAuthEndDate",
            "baseName": "priorAuthEndDate",
            "type":     "string"
        },
        {
            "name":     "priorAuthApprovalNumber",
            "baseName": "priorAuthApprovalNumber",
            "type":     "string"
        },
        {
            "name":     "predeterminationNumber",
            "baseName": "predeterminationNumber",
            "type":     "string"
        },
        {
            "name":     "predeterminationStartDate",
            "baseName": "predeterminationStartDate",
            "type":     "string"
        },
        {
            "name":     "predeterminationEndDate",
            "baseName": "predeterminationEndDate",
            "type":     "string"
        },
        {
            "name":     "predeterminationApprovedQuantity",
            "baseName": "predeterminationApprovedQuantity",
            "type":     "string"
        },
        {
            "name":     "predeterminationApprovedQuantityUsed",
            "baseName": "predeterminationApprovedQuantityUsed",
            "type":     "string"
        },
        {
            "name":     "obtainPreDeterminationOrg",
            "baseName": "obtainPreDeterminationOrg",
            "type":     "string"
        },
        {
            "name":     "obtainPredeterminationFax",
            "baseName": "obtainPredeterminationFax",
            "type":     "string"
        },
        {
            "name":     "obtainPredeterminationPhone",
            "baseName": "obtainPredeterminationPhone",
            "type":     "string"
        },
        {
            "name":     "obtainPredeterminationWebsite",
            "baseName": "obtainPredeterminationWebsite",
            "type":     "string"
        },
        {
            "name":     "predeterminationTurnaroundTime",
            "baseName": "predeterminationTurnaroundTime",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return MedicalBiCptPriorAuth.attributeTypeMap;
    }
}
