// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { SiteAccountContact } from './siteAccountContact.js';
import type { SiteDetails } from './siteDetails.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class SiteAccountRequestModel {
    'siteAccount': SiteDetails | null;
    'siteAccountContact': SiteAccountContact | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof SiteAccountRequestModel>> = [
        {
            "name":     "siteAccount",
            "baseName": "siteAccount",
            "type":     "SiteDetails"
        },
        {
            "name":     "siteAccountContact",
            "baseName": "siteAccountContact",
            "type":     "SiteAccountContact"
        },
    ];

    static getAttributeTypeMap() {
        return SiteAccountRequestModel.attributeTypeMap;
    }
}
