// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { BestFitPharmacyRequestSearchBase } from './bestFitPharmacyRequestSearchBase.js';
import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class BestFitPharmacyRequestSearchResponse {
    'requests'?: Array<BestFitPharmacyRequestSearchBase>;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof BestFitPharmacyRequestSearchResponse>> = [
        {
            "name":     "requests",
            "baseName": "requests",
            "type":     "Array<BestFitPharmacyRequestSearchBase>"
        },
    ];

    static getAttributeTypeMap() {
        return BestFitPharmacyRequestSearchResponse.attributeTypeMap;
    }
}
