// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class CancelWorkflowRequestModel {
    'patientAccountId': number;
    'workflowId': number;
    'caseTypeStatusRelationshipId': number;
    'reason': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof CancelWorkflowRequestModel>> = [
        {
            "name":     "patientAccountId",
            "baseName": "patientAccountId",
            "type":     "number"
        },
        {
            "name":     "workflowId",
            "baseName": "workflowId",
            "type":     "number"
        },
        {
            "name":     "caseTypeStatusRelationshipId",
            "baseName": "caseTypeStatusRelationshipId",
            "type":     "number"
        },
        {
            "name":     "reason",
            "baseName": "reason",
            "type":     "string"
        },
    ];

    static getAttributeTypeMap() {
        return CancelWorkflowRequestModel.attributeTypeMap;
    }
}
