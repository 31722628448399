// tslint:disable
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Moment } from 'moment';
import type { IAttributeTypeMap, RequestFile } from '@trialcard/shared';

export class EnrollmentStatusResponseModel {
    'pharmacyMemberNumber': string | null;
    'medicalMemberNumber': string | null;
    'startDate': Moment | null;
    'endDate': Moment | null;
    'enrollmentStatus': string | null;
    'claimEligibilityDate': Moment | null;
    'maxBenefitAmount': number | null;

    static readonly discriminator: string | undefined = undefined;

    static readonly additionalProperties = false;

    
    static readonly attributeTypeMap: ReadonlyArray<IAttributeTypeMap<keyof EnrollmentStatusResponseModel>> = [
        {
            "name":     "pharmacyMemberNumber",
            "baseName": "pharmacyMemberNumber",
            "type":     "string"
        },
        {
            "name":     "medicalMemberNumber",
            "baseName": "medicalMemberNumber",
            "type":     "string"
        },
        {
            "name":     "startDate",
            "baseName": "startDate",
            "type":     "Date"
        },
        {
            "name":     "endDate",
            "baseName": "endDate",
            "type":     "Date"
        },
        {
            "name":     "enrollmentStatus",
            "baseName": "enrollmentStatus",
            "type":     "string"
        },
        {
            "name":     "claimEligibilityDate",
            "baseName": "claimEligibilityDate",
            "type":     "Date"
        },
        {
            "name":     "maxBenefitAmount",
            "baseName": "maxBenefitAmount",
            "type":     "number"
        },
    ];

    static getAttributeTypeMap() {
        return EnrollmentStatusResponseModel.attributeTypeMap;
    }
}
